<div class="sidebar-wrapper" [ngClass]="(sideBarStatus == true) ? 'sideBarActive':''">
  <div class="sidebar-content">
    <div class="sidebar-brand">
      <a href="javascript:void(0)"><img src="{{Settings?.logo_Url}}"></a>
      <div id="close-sidebar" (click)="closeSideBar()">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div class="sidebar-header">
      <a href="javascript:void(0)" (click)="openLanguageModal()">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <g>
            <g>
              <path d="M217.982,201.586h-64.499c-5.537,0-10.026,4.489-10.026,10.026c0,5.537,4.489,10.026,10.026,10.026h53.547
            c-4.72,25.263-26.935,44.446-53.547,44.446c-30.037,0-54.473-24.436-54.473-54.473c0-30.037,24.436-54.473,54.473-54.473
            c14.55,0,28.229,5.667,38.518,15.955c3.916,3.916,10.264,3.916,14.178,0c3.916-3.916,3.916-10.264,0-14.178
            c-14.077-14.077-32.791-21.829-52.697-21.829c-41.094,0-74.525,33.431-74.525,74.525c0,41.094,33.431,74.525,74.525,74.525
            s74.525-33.431,74.525-74.525C228.008,206.075,223.519,201.586,217.982,201.586z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M470.331,92.24H269.728l-26.935-81.355c-1.358-4.104-5.195-6.875-9.518-6.875H41.669C18.693,4.01,0,22.703,0,45.679
            v332.412c0,22.976,18.693,41.669,41.669,41.669h203.145l27.073,81.369c1.364,4.097,5.195,6.861,9.513,6.861h188.932
            c22.976,0,41.669-18.693,41.669-41.669V133.909C512,110.933,493.307,92.24,470.331,92.24z M41.669,399.708
            c-11.919,0-21.616-9.697-21.616-21.616V45.679c0-11.919,9.697-21.616,21.616-21.616h184.364l70.691,213.516
            c0.004,0.015,0.009,0.028,0.015,0.043l53.664,162.086H41.669z M337.449,283.275c0.805,1.11,10.824,14.877,26.355,34.066
            c-4.377,5.756-9.015,11.474-13.91,17.036l-29.712-89.74h87.441c-6.196,13.031-16.938,33.813-31.692,55.736
            c-13.553-16.921-22.069-28.622-22.249-28.87c-3.251-4.482-9.519-5.481-14.002-2.23C335.198,272.523,334.2,278.791,337.449,283.275
            z M265.946,419.76h75.162l-55.503,59.084L265.946,419.76z M491.948,466.321c0,11.919-9.697,21.616-21.616,21.616H304.575
            l67.015-71.339l-0.004-0.003c0.293-0.312,0.571-0.64,0.823-0.991c1.885-2.609,2.402-5.966,1.39-9.022l-16.688-50.402
            c7.073-7.406,13.68-15.143,19.805-22.965c13.299,15.772,29.037,33.446,45.778,50.187c1.957,1.957,4.524,2.937,7.089,2.937
            s5.132-0.979,7.089-2.937c3.916-3.916,3.916-10.264,0-14.178c-17.461-17.461-34.013-36.244-47.687-52.632
            c21.251-30.503,35.033-59.504,40.535-71.954h21.454c5.537,0,10.026-4.489,10.026-10.026c0-5.537-4.489-10.026-10.026-10.026
            h-28.212c-0.017,0-0.036,0-0.054,0h-37.907v-18.047c0-5.537-4.489-10.026-10.026-10.026c-5.537,0-10.026,4.489-10.026,10.026
            v18.046h-51.406l-37.178-112.292h193.965c11.919,0,21.616,9.697,21.616,21.616V466.321z" />
            </g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
        </svg>
      </a>
      <a href="javascript:void(0)" (click)="CallMode('Light')" *ngIf="siteMode != 'Light'">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" style="width:22px;height:22px;"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 312.999 312.999"
          style="enable-background:new 0 0 312.999 312.999;" xml:space="preserve">
          <g>
            <g>
              <path d="M305.6,178.053c-3.2-0.8-6.4,0-9.2,2c-10.4,8.8-22.4,16-35.6,20.8c-12.4,4.8-26,7.2-40.4,7.2c-32.4,0-62-13.2-83.2-34.4
         c-21.2-21.2-34.4-50.8-34.4-83.2c0-13.6,2.4-26.8,6.4-38.8c4.4-12.8,10.8-24.4,19.2-34.4c3.6-4.4,2.8-10.8-1.6-14.4
         c-2.8-2-6-2.8-9.2-2c-34,9.2-63.6,29.6-84.8,56.8c-20.4,26.8-32.8,60-32.8,96.4c0,43.6,17.6,83.2,46.4,112s68.4,46.4,112,46.4
         c36.8,0,70.8-12.8,98-34c27.6-21.6,47.6-52.4,56-87.6C314.4,184.853,311.2,179.253,305.6,178.053z M244.4,261.653
         c-23.2,18.4-52.8,29.6-85.2,29.6c-38,0-72.4-15.6-97.2-40.4c-24.8-24.8-40.4-59.2-40.4-97.2c0-31.6,10.4-60.4,28.4-83.6
         c12.4-16,28-29.2,46-38.4c-2,4.4-4,8.8-5.6,13.6c-5.2,14.4-7.6,29.6-7.6,45.6c0,38,15.6,72.8,40.4,97.6s59.6,40.4,97.6,40.4
         c16.8,0,32.8-2.8,47.6-8.4c5.2-2,10.4-4,15.2-6.4C274,232.453,260.8,248.853,244.4,261.653z" />
            </g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
        </svg>
      </a>
      <a href="javascript:void(0)" (click)="CallMode('Dark')" *ngIf="siteMode != 'Dark'">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 302.4 302.4" style="enable-background:new 0 0 302.4 302.4;" xml:space="preserve">
          <g>
            <g>
              <path d="M204.8,97.6C191.2,84,172,75.2,151.2,75.2s-40,8.4-53.6,22.4c-13.6,13.6-22.4,32.8-22.4,53.6s8.8,40,22.4,53.6
               c13.6,13.6,32.8,22.4,53.6,22.4s40-8.4,53.6-22.4c13.6-13.6,22.4-32.8,22.4-53.6S218.8,111.2,204.8,97.6z M190.4,190.4
               c-10,10-24,16-39.2,16s-29.2-6-39.2-16s-16-24-16-39.2s6-29.2,16-39.2s24-16,39.2-16s29.2,6,39.2,16s16,24,16,39.2
               S200.4,180.4,190.4,190.4z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M292,140.8h-30.8c-5.6,0-10.4,4.8-10.4,10.4c0,5.6,4.8,10.4,10.4,10.4H292c5.6,0,10.4-4.8,10.4-10.4
               C302.4,145.6,297.6,140.8,292,140.8z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M151.2,250.8c-5.6,0-10.4,4.8-10.4,10.4V292c0,5.6,4.8,10.4,10.4,10.4c5.6,0,10.4-4.8,10.4-10.4v-30.8
               C161.6,255.6,156.8,250.8,151.2,250.8z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M258,243.6l-22-22c-3.6-4-10.4-4-14.4,0s-4,10.4,0,14.4l22,22c4,4,10.4,4,14.4,0S262,247.6,258,243.6z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M151.2,0c-5.6,0-10.4,4.8-10.4,10.4v30.8c0,5.6,4.8,10.4,10.4,10.4c5.6,0,10.4-4.8,10.4-10.4V10.4
               C161.6,4.8,156.8,0,151.2,0z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M258.4,44.4c-4-4-10.4-4-14.4,0l-22,22c-4,4-4,10.4,0,14.4c3.6,4,10.4,4,14.4,0l22-22C262.4,54.8,262.4,48.4,258.4,44.4z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M41.2,140.8H10.4c-5.6,0-10.4,4.8-10.4,10.4s4.4,10.4,10.4,10.4h30.8c5.6,0,10.4-4.8,10.4-10.4
               C51.6,145.6,46.8,140.8,41.2,140.8z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M80.4,221.6c-3.6-4-10.4-4-14.4,0l-22,22c-4,4-4,10.4,0,14.4s10.4,4,14.4,0l22-22C84.4,232,84.4,225.6,80.4,221.6z" />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M80.4,66.4l-22-22c-4-4-10.4-4-14.4,0s-4,10.4,0,14.4l22,22c4,4,10.4,4,14.4,0S84.4,70.4,80.4,66.4z" />
            </g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
        </svg>
      </a>
      <a href="javascript:void(0)" [routerLink]="['/login']" *ngIf="isLogin != true && Settings?.disable_login != 'true'">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <g>
            <g>
              <g>
                <path d="M131.5,472H60.693c-8.538,0-13.689-4.765-15.999-7.606c-3.988-4.906-5.533-11.29-4.236-17.519
  c20.769-99.761,108.809-172.616,210.445-174.98c1.693,0.063,3.39,0.105,5.097,0.105c1.722,0,3.434-0.043,5.142-0.107
  c24.853,0.567,49.129,5.24,72.236,13.917c10.34,3.885,21.871-1.352,25.754-11.693c3.883-10.34-1.352-21.871-11.693-25.754
  c-3.311-1.244-6.645-2.408-9.995-3.512C370.545,220.021,392,180.469,392,136C392,61.01,330.991,0,256,0
  c-74.991,0-136,61.01-136,136c0,44.509,21.492,84.092,54.643,108.918c-30.371,9.998-58.871,25.546-83.813,46.062
  c-45.732,37.617-77.529,90.086-89.532,147.743c-3.762,18.066,0.744,36.622,12.363,50.908C25.221,503.847,42.364,512,60.693,512
  H131.5c11.046,0,20-8.954,20-20C151.5,480.954,142.546,472,131.5,472z M160,136c0-52.935,43.065-96,96-96s96,43.065,96,96
  c0,51.367-40.554,93.438-91.326,95.885c-1.557-0.028-3.114-0.052-4.674-0.052c-1.564,0-3.127,0.023-4.689,0.051
  C200.546,229.43,160,187.362,160,136z" />
                <path d="M496.689,344.607c-8.561-19.15-27.845-31.558-49.176-31.607h-62.372c-0.045,0-0.087,0-0.133,0
  c-22.5,0-42.13,13.26-50.029,33.807c-1.051,2.734-2.336,6.178-3.677,10.193H200.356c-5.407,0-10.583,2.189-14.35,6.068
  l-34.356,35.388c-7.567,7.794-7.529,20.203,0.085,27.95l35,35.612c3.76,3.826,8.9,5.981,14.264,5.981h65c11.046,0,20-8.954,20-20
  c0-11.046-8.954-20-20-20h-56.614l-15.428-15.698L208.814,397h137.491c9.214,0,17.235-6.295,19.426-15.244
  c1.618-6.607,3.648-12.959,6.584-20.596c1.936-5.036,6.798-8.16,12.741-8.16c0.013,0,0.026,0,0.039,0h62.371
  c5.656,0.013,10.524,3.053,12.705,7.932c5.369,12.012,11.78,30.608,11.828,50.986c0.048,20.529-6.356,39.551-11.739,51.894
  c-2.17,4.978-7.079,8.188-12.56,8.188c-0.011,0-0.022,0-0.033,0h-63.125c-5.533-0.013-10.716-3.573-12.896-8.858
  c-2.339-5.671-4.366-12.146-6.197-19.797c-2.571-10.742-13.367-17.366-24.105-14.796c-10.743,2.571-17.367,13.364-14.796,24.106
  c2.321,9.699,4.978,18.118,8.121,25.738c8.399,20.364,27.939,33.555,49.827,33.606h63.125c0.043,0,0.083,0,0.126,0
  c21.351-0.001,40.647-12.63,49.18-32.201c6.912-15.851,15.137-40.511,15.072-67.975
  C511.935,384.434,503.638,360.153,496.689,344.607z" />
                <circle cx="431" cy="412" r="20" />
              </g>
            </g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
          <g>
          </g>
        </svg>
      </a>
      <a href="javascript:void(0)" (click)="logout()" *ngIf="isLogin != false">
        <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" version="1.1" viewBox="0 0 26 26"
          x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
          <g>
            <path d="M20.25,19.547V23c0,0.552-0.449,1-1,1h-14c-0.551,0-1-0.448-1-1V3c0-0.552,0.449-1,1-1h14
        c0.551,0,1,0.448,1,1v3.453l2,1.733V3c0-1.656-1.344-3-3-3h-14c-1.656,0-3,1.344-3,3v20c0,1.656,1.344,3,3,3h14
        c1.656,0,3-1.344,3-3v-5.187L20.25,19.547z"></path>
            <g>
              <path d="M16.25,19.5c0,0-1,0.281-1-1c0-0.29,0-1.257,0-2.5h-6c-0.552,0-1-0.449-1-1v-4c0-0.551,0.448-1,1-1
          h6c0-1.196,0-2.155,0-2.531c0-1.344,1-0.969,1-0.969l7.5,6.5L16.25,19.5z"></path>
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      </a>
    </div>
    <div class="sidebar-menu">
      <ul class="sidebar-nav">
        <li [routerLink]="['/trade']" [routerLinkActive]="['active']">
          <a href="javascript:void(0)" [routerLink]="['/trade']" [routerLinkActive]="['active']">
            <i class="fas fa-chart-bar"></i>
            <span class="hover hover-1">{{'Menu1' | translate}}</span>
          </a>
        </li>
        <li [routerLinkActive]="['active']">
          <a href="javascript:void(0)" [routerLink]="['/contracts']" [routerLinkActive]="['active']" (click)="getContractList()">
            <i class="fas fa-file-contract"></i>
            <span class="hover hover-1">{{'Menu2' | translate}}</span>
          </a>
          <ul class="sidebar-submenu">
            <li *ngFor="let item of contractsList | keyvalue: originalOrder; let i = index">
              <a href="javascript:void(0)" data-toggle="collapse" (click)="selectContracts(item.key)"
              [ngClass]="(selectedContractKey == item.key)?'is-active':''">
                <i class="fas" [ngClass]="(selectedContractKey == item.key)?'fa-angle-up':'fa-angle-down'"></i>{{item.key}}</a>
              <ul id="item.key" class="collapse" [ngClass]="(selectedContractKey == item.key)?'show':''">
                <li *ngFor="let item of item.value">
                  <a href="javascript:void(0)" title="{{item.aliasName}}"
                    [ngClass]="(contractItem.currencyName == item.name)?'is-active':''"
                    (click)="GetContractDetails(item.name)" style="padding: 8px 15px 8px 75px;">{{item.name}}</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li [routerLinkActive]="['active']">
          <a href="javascript:void(0)" [routerLink]="['/indices']" [routerLinkActive]="['active']" (click)="getIndicesList()">
            <i class="fas fa-file-contract"></i>
            <span class="hover hover-1">Indices</span>
          </a>
          <ul class="sidebar-submenu">
            <li *ngFor="let item of indicesList | keyvalue: originalOrder ; let i = index">
              <a href="javascript:void(0)" data-toggle="collapse" (click)="selectindices(item.key)"
              [ngClass]="(selectedindicesKey == item.key)?'is-active':''">
              <i class="fas" [ngClass]="(selectedindicesKey == item.key)?'fa-angle-up':'fa-angle-down'"></i>{{item.key}}</a>
              <ul id="item.key" class="collapse" [ngClass]="(selectedindicesKey == item.key)?'show':''">
                <li *ngFor="let item of item.value">
                  <a href="javascript:void(0)" title="{{item.aliasName}}"
                    [ngClass]="(indicesItem.currencyName == item.ticker)?'is-active':''"
                    (click)="GetIndicesDetails(item.ticker)" style="padding: 8px 15px 8px 75px;">{{item.ticker}}&nbsp;<span *ngIf="item.name">({{item.name}})</span></a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li *ngIf="isLogin != false" [routerLinkActive]="['active']">
          <a href="javascript:void(0)" [routerLink]="['/account']" [routerLinkActive]="['active']">
            <i class="fas fa-user"></i>
            <span class="hover hover-1">{{'Menu3' | translate}}</span>
          </a>
          <ul class="sidebar-submenu">
            <li>
              <a href="javascript:void(0)" [routerLink]="['/account/overview']"
                [routerLinkActive]="['is-active']">{{'account.Header' |translate}}</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/account/account-verification']"
                [routerLinkActive]="['is-active']">{{'account.Tab1' |translate}}</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/account/affiliates']"
                [routerLinkActive]="['is-active']">{{'account.Affiliate.Header' |translate}}</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/account/bonus']" [routerLinkActive]="['is-active']">Bonus
                Requests</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/account/api-keys']"
                [routerLinkActive]="['is-active']">{{'account.Apikeys.Header' |translate}}</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/account/security']"
                [routerLinkActive]="['is-active']">{{'account.Tab2' |translate}}</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/account/change-password']"
                [routerLinkActive]="['is-active']">{{'account.ChangePass.Header' |translate}}</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/account/settings']"
                [routerLinkActive]="['is-active']">Settings</a>
            </li>
          </ul>
        </li>
        <li *ngIf="apiService?.IsLogin" [routerLinkActive]="['active']">
          <a href="javascript:void(0)" [routerLink]="['/orders']" [routerLinkActive]="['active']">
            <i class="fas fa-boxes"></i>
            <span>Orders</span>
          </a>
          <ul class="sidebar-submenu">
            <li>
              <a href="javascript:void(0)" [routerLink]="['/orders/open-orders']" [routerLinkActive]="['is-active']">Open Orders</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/orders/order-history']" [routerLinkActive]="['is-active']">Order History</a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/orders/trade-history']" [routerLinkActive]="['is-active']">Trade History</a>
            </li>
          </ul>
        </li>
        <li *ngIf="isLogin != false" [routerLink]="['/wallet/overview']" [routerLinkActive]="['active']">
          <a href="javascript:void(0)" [routerLink]="['/wallet/overview']" [routerLinkActive]="['active']">
            <i class="fas fa-wallet"></i>
            <span class="hover hover-1">{{'Menu4' | translate}}</span>
          </a>
          <ul class="sidebar-submenu">
            <li>
              <a href="javascript:void(0)" [routerLink]="['/wallet/overview']" [routerLinkActive]="['is-active']">{{'account.Header' |translate}}</a>
            </li>
            <li>
                <a href="javascript:void(0)" [routerLink]="['/wallet/deposits']" [routerLinkActive]="['is-active']">{{'Wallet.Deposit.Header' |translate}}</a>
            </li>
            <li>
                <a href="javascript:void(0)" [routerLink]="['/wallet/withdrawals']" [routerLinkActive]="['is-active']">{{'Wallet.Withdrawal.Header' |translate}}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="Go-modal-container">
  <div class="Go-modal" [ngClass]="(IsLanguageModalOpen == true) ? 'IsActive' : ''" style="z-index: 9999">
    <div class="Go-modal-background" (click)="closeLanguageModal()"></div>
    <div class="Go-modal-content" style="width: 580px">
      <div class="Go-modal-header text-center">
        <strong>Select Language</strong>
      </div>
      <div class="Go-modal-body" style="padding: 1rem 1rem;">
        <div class="md-radio" *ngFor="let enum of enumdetails">
          <input id="enum_answer_{{enum.name}}" [value]='enum.name' type="radio" name="enums" [(ngModel)]="langSelected"
            (change)="switchLanguage()">
          <label for="enum_answer_{{enum.name}}">{{enum.name}}</label>
        </div>
      </div>
    </div>
  </div>
</div>