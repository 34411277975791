import {
  NgModule
} from '@angular/core';
import {
  Routes,
  RouterModule
} from '@angular/router';
import {
  AuthService
} from './guard/auth.service';
import {
  AuthGuard
} from './guard/auth.guard';

import {
  OrderbookComponent
} from './exchange/trade/orderbook/orderbook.component';
import {
  PlaceOrderComponent
} from './exchange/trade/place-order/place-order.component';
import {
  RecentTradesComponent
} from './exchange/trade/recent-trades/recent-trades.component';
import {
  DepthChartComponent
} from './exchange/trade/depth-chart/depth-chart.component';
import {
  AllSectionsComponent
} from './exchange/trade/all-sections/all-sections.component';
import {
  TradeComponent
} from './exchange/trade/trade/trade.component';
import {
  RegisterComponent
} from './exchange/user-authentications/register/register.component';
import {
  VerifyComponent
} from './exchange/user-authentications/verify/verify.component';
import {
  SigninComponent
} from './exchange/user-authentications/login/signin/signin.component';
import {
  AuthenticateComponent
} from './exchange/user-authentications/login/authenticate/authenticate.component';
import {
  EmailOtpComponent
} from './exchange/user-authentications/login/email-otp/email-otp.component';
import {
  ResetComponent
} from './exchange/user-authentications/reset-password/reset/reset.component';
import {
  ConfirmComponent
} from './exchange/user-authentications/reset-password/confirm/confirm.component';
import {
  AboutUsComponent
} from './exchange/legal/about-us/about-us.component';
import {
  ContactComponent
} from './exchange/legal/contact/contact.component';
import {
  PrivacyPolicyComponent
} from './exchange/legal/privacy-policy/privacy-policy.component';
import {
  TermsComponent
} from './exchange/legal/terms/terms.component';
import {
  RiskDisclosureComponent
} from './exchange/legal/risk-disclosure/risk-disclosure.component';
import {
  CookiePolicyComponent
} from './exchange/legal/cookie-policy/cookie-policy.component';
import {
  TradingRulesComponent
} from './exchange/legal/trading-rules/trading-rules.component';
import {
  FeesComponent
} from './exchange/legal/fees/fees.component';
import {
  SettingsResolver
} from './resolver/settings.resolver';
import {
  ReferralComponent
} from './exchange/user-authentications/referral/referral.component';
import {
  CurrencyListResolver
} from './resolver/currency-list.resolver';
import {
  ContractsListResolver
} from './resolver/contracts-list.resolver';
import {
  IndicesListResolver
} from './resolver/indices-list.resolver';
import {
  IndicesComponent
} from './exchange/contracts/indices/indices.component';
import {
  ContractsComponent
} from './exchange/contracts/contracts/contracts.component';
import {
  ApiDocumentationComponent
} from './exchange/legal/api-documentation/api-documentation.component';
import {
  WebsocketApiComponent
} from './exchange/legal/websocket-api/websocket-api.component';
import {
  BlockComponent
} from './exchange/user-authentications/block/block.component';
import { ContentLayoutComponent } from './exchange/shared/content-layout/content-layout.component';
import { TradingChartComponent } from './exchange/shared/trading-chart/trading-chart.component';
import { RedirectCustomerComponent } from './exchange/user-authentications/redirect-customer/redirect-customer.component';
import { FeatureDisabledComponent } from './exchange/user-authentications/feature-disabled/feature-disabled.component';

const routes: Routes = [
  {
  path: '',
  component: ContentLayoutComponent,
  children: [
    {
      path: '',
      redirectTo: 'trade',
      pathMatch: 'full',
      runGuardsAndResolvers: 'always',
    },
    {
      path: '',
      component: TradeComponent,
    },
    {
      path: 'trade',
      component: TradeComponent,
    },
    {
      path: 'trade/:id',
      component: TradeComponent,

      children: [{
          path: 'order-book',
          component: OrderbookComponent,
        },
        {
          path: 'place-order',
          component: PlaceOrderComponent,
        },
        {
          path: 'recent-trades',
          component: RecentTradesComponent,
        },
        {
          path: 'depth-chart',
          component: DepthChartComponent,
        },
        {
          path: 'all-section',
          component: AllSectionsComponent,
        },
      ]
    },
    {
      path: 'contracts',
      component: ContractsComponent,
      // resolve: {
      //   contractsListResolver: ContractsListResolver,
      // }
    },
    {
      path: 'contracts/:contractName',
      component: ContractsComponent,
      // resolve: {
      //   contractsListResolver: ContractsListResolver,
      // }
    },
    {
      path: 'indices',
      component: IndicesComponent,
      // resolve: {
      //   indicesListResolver: IndicesListResolver
      // }
    },
    {
      path: 'indices/:indicesName',
      component: IndicesComponent,
      // resolve: {
      //   indicesListResolver: IndicesListResolver
      // }
    },
    {
      path: 'block-account/:id',
      component: BlockComponent,
    },
    {
      path: 'feature-disabled',
      component: FeatureDisabledComponent,
    },
    {
      path: 'register',
      component: RegisterComponent,
    },
    {
      path: 'verify',
      component: VerifyComponent,
    },
    {
      path: 'redirect-customer',
      component: RedirectCustomerComponent,
    },
    {
      path: 'login',
      children: [{
          path: '',
          component: SigninComponent,
        },
        {
          path: 'authenticate',
          component: AuthenticateComponent,
        },
        {
          path: 'email-otp',
          component: EmailOtpComponent,
        },
      ]
    },
    {
      path: 'reset-password',
      children: [{
          path: '',
          component: ResetComponent,
        },
        {
          path: 'confirm/:id',
          component: ConfirmComponent,
        }
      ]
    },
    {
      path: 'account',
      loadChildren: () => import('./exchange/account/account.module').then(m => m.AccountModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'orders',
      loadChildren: () => import('./exchange/orders/orders.module').then(m => m.OrdersModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'wallet',
      loadChildren: () => import('./exchange/wallet/wallet.module').then(m => m.WalletModule),
      canActivate: [AuthGuard],
    },
    {
      path: 'about-us',
      component: AboutUsComponent
    },
    {
      path: 'contact-us',
      component: ContactComponent
    },
    {
      path: 'api-documentation',
      component: ApiDocumentationComponent
    },
    {
      path: 'websocket-api',
      component: WebsocketApiComponent
    },
    {
      path: 'privacy-policy',
      component: PrivacyPolicyComponent
    },
    {
      path: 'risk-disclosure',
      component: RiskDisclosureComponent
    },
    {
      path: 'cookie-policy',
      component: CookiePolicyComponent
    },
    {
      path: 'terms-of-service',
      component: TermsComponent
    },
    {
      path: 'fees',
      component: FeesComponent
    },
    {
      path: 'trading-rules',
      component: TradingRulesComponent
    },
    {
      path: 'ref/:id',
      component: ReferralComponent
    }]
  },

  {
    path: 'tradingview/:id',
    component: TradingChartComponent
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [SettingsResolver, AuthService, AuthGuard, CurrencyListResolver, ContractsListResolver, IndicesListResolver],
})
export class AppRoutingModule {}
