import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(public apiService: ApiService) { }

  terms: any;
  ngOnInit() {
this.getterms();
  }

 async getterms(){
  let result = await this.apiService.gettermscondition().catch(err => {
    this.apiService.ShowToastrAlert('', err.data, 'error');
  });
  if(result == undefined || result== null) {
    return
    }
    if (result.data !== undefined && result.data != null) {
      this.terms = result.data;
    }
  }


}
