import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/guard/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit {

  userDetails = {
    password: '',
    username: '',
    otp: ''
  };

  persistAuth = {
    isAuthenticated: false,
    authorization: null,
    gAuthEnabled: false,
  };

  SignInProcessing = false;
  otpProcessing = false;

  subscription: Subscription;

  passwordValidateError = '';
  otpValidateError = '';
  device: any;

  constructor(public apiService: ApiService, public router: Router, private auth: AuthService) {
    this.apiService.tempAuthToken.subscribe((data: any) => {
      if (data === undefined || data == null  || data.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.userDetails.username = data;
      }
    });
    this.apiService.requireDeviceWhitelisting.subscribe((data: any) => {
      this.device = data;
  });
   }

  ngOnInit() {
  }

  passwordValidate(): boolean {
    if (this.userDetails.password.trim() == '') {
      this.passwordValidateError = 'This field is required.';
      return false;
    } else if (this.userDetails.password.length !== 6) {
      this.passwordValidateError = 'Minimum length of 6 required.';
      return false;
    } else {
      this.passwordValidateError = '';
      return true;
    }
  }
  otpValidate(): boolean {
    if (this.userDetails.otp.trim() == '') {
      this.otpValidateError = 'This field is required.';
      return false;
    } else if (this.userDetails.otp.length < 6) {
      this.otpValidateError = 'Minimum length of 6 required.';
      return false;
    } else {
      this.otpValidateError = '';
      return true;
    }
  }


onKeyPress(event: any) {
  if (event.key === 'Enter') {
    this.generateBearerToken();
  }
}

submitAuth(event: any) {
  if ( event.ctrlKey) {
    return;
  }
  if (this.userDetails.password.length == 6) {
    this.generateBearerToken();
  }
}

async generateBearerToken() {
  if (this.passwordValidate() === false) {
    return;
  }
  if (this.device === true && this.otpValidate() === false){
    return;
  }
    this.SignInProcessing = true;

    let result = await this.apiService.tokenGenerate(this.userDetails).catch(err => {
      this.SignInProcessing = false;
      // this.router.navigate(['/login']);
      this.apiService.ShowToastrAlert('oops!', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      this.SignInProcessing = false;
      if (result.data.access_token != null && result.data.access_token !== undefined && result.data.access_token.length !== 0) {
        this.persistAuth.authorization = result.data.access_token;
        this.persistAuth.isAuthenticated = true;
        localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
        this.apiService.Updateisloginstatus();
        this.router.navigate(['/trade']);
        this.apiService.ShowToastrAlert('', 'Login Successful!', 'success');
      }
}

async sendOtp() {
  this.otpProcessing = true;
  let result = await  this.apiService.requestsendOTP().catch(err => {
    this.otpProcessing = false;
    this.apiService.ShowToastrAlert('oops!', err.message, 'error');
  });
  if(result == undefined || result== null) {
    return
    }
    this.otpProcessing = false;
    this.apiService.ShowToastrAlert('', result.data, 'success');

}

}
