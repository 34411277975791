import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-sections',
  templateUrl: './all-sections.component.html',
  styleUrls: ['./all-sections.component.scss']
})
export class AllSectionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
