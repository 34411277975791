import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  NgZone,
  AfterViewInit,
  HostListener,
  HostBinding,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  dateFormat
} from 'highcharts';
import {
  Subscription
} from 'rxjs';
import {
  OrdersComponent
} from '../../orders/orders.component';

@Component({
  selector: 'app-orderbook',
  templateUrl: './orderbook.component.html',
  styleUrls: ['./orderbook.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush // add this
})
export class OrderbookComponent implements OnInit, AfterViewInit, OnDestroy {
  sellOrders = {}; // main value
  buyOrders = {};
  sellOrdersToBeShown = {}; // filtered value
  buyOrdersToBeShown = {}; // filtered value
  showBuyOrders = {}; // filtered value
  dictBuyData = [];
  blinkSuccess: any;
  recentTradeCheck: any;
  // IsBlink = false;
  BuyTickerPlus: any;
  BuyTickerMinus: any;
  BuyTickerChange: any;

  SellTickerPlus: any;
  SellTickerMinus: any;
  SellTickerChange: any;

  avi: any;

  SellMaxVolume: any;
  BuyMaxVolume: any;
  maxValue: any;
  sellMaxValue: any;
  sellMinValue: any;
  buyMaxValue: any;
  SellBookWidth: number;
  BuyBookWidth: number;

  @HostBinding('style.height.px')
  elHeight: number;
  maxWithOrderbook: number;
  lastTradePrice: any;
  lastTradePriceChange = '';
  showPriceDownfall = false;
  BuyTickerData: any;
  contractName: any;
  recordCount = 200;
  orderBookData: any;
  orderBookBuyTimeStamp = 0;
  orderBookSellTimeStamp = 0;

  snapshotBuy: any;
  changedBuyTicker: any;
  deletedBuyTicker: any;
  snapshotBuyOrder: any;

  changedSellTicker: any;
  deletedSellTicker: any;
  snapshotSellOrder: any;

  loaded = false;
  currContract: any;

  ltdSub: Subscription;
  markPriceTicker: any;

  selectedPriceKey: number;
  cancel = {
    orderID: ''
  };


  constructor(public webSocketService: WebsocketService, private apiService: ApiService, private zone: NgZone, private el: ElementRef,
              private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.apiService.sendSelectorContract.subscribe((contractData: any) => {
      if (contractData != null && contractData !== undefined && contractData.length !== 0) {
        this.currContract = contractData;
      }
    });

    this.webSocketService.changedContractDetails.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.buyOrders = {};
        this.buyOrdersToBeShown = {};
        this.sellOrders = {};
        this.sellOrdersToBeShown = {};
        this.lastTradePrice = null;
      }
    });

    this.webSocketService.markPrice.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.markPriceTicker = data;
      }
    });

    this.webSocketService.BuyTicker.subscribe((dataBuyTicker: any) => {
      this.zone.run(() => { // <== added
        if (dataBuyTicker != null && dataBuyTicker !== undefined) {
          if (dataBuyTicker.type == 'snapshot') {
            this.buyOrders = {};
            this.buyOrdersToBeShown = {};
            const buySnapshot = dataBuyTicker.buy;
            Object.entries(buySnapshot).forEach(([key, value]) => {
              const temp = {};
              temp[key] = value;
              this.snapshotBuyOrder = temp;

              let Price: any;
              let Quantity: any;
              Price = parseFloat(Object.keys(this.snapshotBuyOrder)[0]);
              Quantity = Object.values(this.snapshotBuyOrder)[0];
              const tempBuyOrders = {
                q: Quantity,
                cs: Quantity,
              };
              this.buyOrders[Price] = tempBuyOrders;
            });
          } else if (dataBuyTicker.type == 'ticker') {
            const buyTicker = dataBuyTicker.buy;
            // console.group('buy ticker', buyTicker);
            this.BuyTickerPlus = null;
            this.BuyTickerMinus = null;
            const updated = {...buyTicker['inserted'], ...buyTicker['changed']};
            Object.entries(updated).forEach(([key, value]) => {
              let Price: any;
              let Quantity: any;
              Price = parseFloat(key);
              Quantity = value;
              const tempBuyOrders = {
                q: Quantity,
                cs: Quantity,
              };
              const existingVALUE = this.buyOrders[Price];
              const existingKEY = Price;

              if (existingVALUE == undefined || existingKEY == undefined) {
                // New value
                const getSpace = document.getElementById('buyBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_danger');
                  getSpace.classList.remove('blinker_success');
                  this.BuyTickerPlus = existingKEY;
                }

              } else if (existingVALUE.q > Quantity) {
                // Decreased
                const getSpace = document.getElementById('buyBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_danger');
                  getSpace.classList.remove('blinker_success');
                  this.BuyTickerMinus = existingKEY;
                }

              } else if (existingVALUE.q < Quantity) {
                // Increased
                const getSpace = document.getElementById('buyBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_success');
                  getSpace.classList.remove('blinker_danger');
                  this.BuyTickerPlus = existingKEY;
                }

              } else {
                // No change
              }
              this.buyOrders[Price] = tempBuyOrders;
            });

            Object.entries(buyTicker['deleted']).forEach(([key, value]) => {
              delete this.buyOrders[parseFloat(key)];
            });
          }

          let arrayKeys = [];
          const arrayKeysFinal = [];
          arrayKeys = Object.keys(this.buyOrders);
          arrayKeys.forEach((k2) => {
            arrayKeysFinal.push(parseFloat(k2));
          });

          arrayKeysFinal.sort((a, b) => b - a);

          let previousdata = undefined;
          this.buyOrdersToBeShown = {};
          arrayKeysFinal.forEach((element: any, index: any) => {

            // if (index >= 7) {
            //   return;
            // }
            if (index === 0) {
              this.buyMaxValue = parseFloat(element);
            }

            let existingdata = this.buyOrders[element];

            if (previousdata !== undefined) {
              existingdata['cs'] = previousdata['cs'] + existingdata['q'];
            }
            this.buyOrdersToBeShown[element] = existingdata;
            previousdata = existingdata;

            this.BuyMaxVolume = parseFloat(this.buyOrdersToBeShown[element].cs);
            if (this.SellMaxVolume == null && this.SellMaxVolume == undefined) {
              this.maxValue = parseFloat(this.BuyMaxVolume);
            } else if (this.BuyMaxVolume > this.SellMaxVolume) {
              this.maxValue = parseFloat(this.BuyMaxVolume);
            }

          });

          if (this.buyOrdersToBeShown != null && this.buyOrdersToBeShown !== undefined && Object.keys(this.buyOrdersToBeShown).length > 0) {
            if (document.getElementById('Orderbook') !== undefined && document.getElementById('Orderbook') !== null) {
              this.maxWithOrderbook = document.getElementById('Orderbook').offsetWidth;
            }
          }
        }
      });
    });

    this.webSocketService.SellTicker.subscribe((dataSellTicker: any) => {
      this.zone.run(() => { // <== added
        if (dataSellTicker != null && dataSellTicker !== undefined) {
          // const buyTimeStamp = Object.keys(parsedData)[0].split('-')[3];

          if (dataSellTicker.type == 'snapshot') {
            this.sellOrders = {};
            this.sellOrdersToBeShown = {};
            const sellSnapshot = dataSellTicker.sell;
            Object.entries(sellSnapshot).forEach(([key, value]) => {
              const temp = {};
              temp[key] = value;
              this.snapshotSellOrder = temp;

              let Price: any;
              let Quantity: any;
              Price = parseFloat(Object.keys(this.snapshotSellOrder)[0]);
              Quantity = Object.values(this.snapshotSellOrder)[0];
              const tempSellOrders = {
                q: Quantity,
                cs: Quantity,
              };
              this.sellOrders[Price] = tempSellOrders;
            });
          } else if (dataSellTicker.type == 'ticker') {
            const sellTicker = dataSellTicker.sell;
            // console.group('sell ticker', sellTicker);
            this.SellTickerPlus = null;
            this.SellTickerMinus = null;
            const updated = {...sellTicker['inserted'], ...sellTicker['changed']};
            Object.entries(updated).forEach(([key, value]) => {
              let Price: any;
              let Quantity: any;
              Price = parseFloat(key);
              Quantity = value;
              const tempSellOrders = {
                q: Quantity,
                cs: Quantity,
              };
              const existingVALUE = this.sellOrders[Price];
              const existingKEY = Price;

              if (existingVALUE == undefined || existingKEY == undefined) {
                // New value
                const getSpace = document.getElementById('sellBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_danger');
                  getSpace.classList.remove('blinker_success');
                  this.SellTickerPlus = existingKEY;
                }

              } else if (existingVALUE.q > Quantity) {
                // Decreased
                const getSpace = document.getElementById('sellBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_danger');
                  getSpace.classList.remove('blinker_success');
                  this.SellTickerMinus = existingKEY;
                }

              } else if (existingVALUE.q < Quantity) {
                // Increased
                const getSpace = document.getElementById('sellBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_success');
                  getSpace.classList.remove('blinker_danger');
                  this.SellTickerPlus = existingKEY;
                }

              } else {
                // No change
              }
              this.sellOrders[Price] = tempSellOrders;
              let messageBody = document.querySelector('#messageBody');
              if (messageBody != null && messageBody !== undefined) {
                messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
              }
            });

            Object.entries(sellTicker['deleted']).forEach(([key, value]) => {
              delete this.sellOrders[parseFloat(key)];
            });

          }

          let arrayKeys = [];
          const arrayKeysFinal = [];
          arrayKeys = Object.keys(this.sellOrders);
          arrayKeys.forEach((k2) => {
            arrayKeysFinal.push(parseFloat(k2));
          });

          arrayKeysFinal.sort((a, b) => a - b);

          let previousdata = undefined;

          this.sellOrdersToBeShown = {};
          arrayKeysFinal.forEach((element: any, index: any) => {
            // if (index >= 7) {
            //   return;
            // }
            this.sellMaxValue = parseFloat(element);

            let existingdata = this.sellOrders[element];
            if (previousdata !== undefined) {
              existingdata['cs'] = previousdata['cs'] + existingdata['q'];
            }

            this.sellOrdersToBeShown[element] = existingdata;
            previousdata = existingdata;

            this.SellMaxVolume = parseFloat(this.sellOrdersToBeShown[element].cs);
            if (this.BuyMaxVolume == null && this.BuyMaxVolume == undefined) {
              this.maxValue = parseFloat(this.SellMaxVolume);
            } else if (this.SellMaxVolume > this.BuyMaxVolume) {
              this.maxValue = parseFloat(this.SellMaxVolume);
            }
          });

          // tslint:disable-next-line:max-line-length
          if (this.sellOrdersToBeShown != null && this.sellOrdersToBeShown !== undefined && Object.keys(this.sellOrdersToBeShown).length > 0) {
            if (document.getElementById('Orderbook') !== undefined && document.getElementById('Orderbook') !== null) {
              this.maxWithOrderbook = document.getElementById('Orderbook').offsetWidth;
            }
          }

          const chatHistory = document.getElementById('messageBody');
          if (chatHistory != null && chatHistory !== undefined) {
            chatHistory.scrollTop = chatHistory.scrollHeight;
          }
        }
      });
    });

    this.ltdSub = this.apiService.lastTradeDetails.subscribe((lastPrice: any) => {
      if (lastPrice != null && lastPrice !== undefined && lastPrice.length !== 0) {
        this.lastTradePrice = lastPrice[0];
        const arraylength = lastPrice.length;
        let i = 1;
        while (i < arraylength) {
          if (this.lastTradePrice.p > lastPrice[i].p) {
            // console.log('last trade price higher', this.lastTradePrice, lastPrice[i]);
            if (this.lastTradePriceChange == 'high' || this.lastTradePriceChange == 'stillHigh') {
              this.lastTradePriceChange = 'stillHigh';
            } else if (this.lastTradePriceChange == 'low' || this.lastTradePriceChange == 'stillLow' || this.lastTradePriceChange == '') {
              this.lastTradePriceChange = 'high';
            }
            return;
          } else if (this.lastTradePrice.p < lastPrice[i].p) {
            // console.log('last trade price lower', this.lastTradePrice, lastPrice[i]);
            if (this.lastTradePriceChange == 'low' || this.lastTradePriceChange == 'stillLow') {
              this.lastTradePriceChange = 'stillLow';
            } else if (this.lastTradePriceChange == 'high' || this.lastTradePriceChange == 'stillHigh' || this.lastTradePriceChange == '') {
              this.lastTradePriceChange = 'low';
            }
            return;
          } else if (this.lastTradePrice.p == lastPrice[i].p) {
            i++;
          }
        }

        // if (existingPrice != null && existingPrice !== undefined) {
        //   if (existingPrice.p > this.lastTradePrice.p) {
        //     this.lastTradePriceChange = 'low';
        //   } else if (existingPrice.p < this.lastTradePrice.p) {
        //     this.lastTradePriceChange = 'high';
        //   } else if (existingPrice.p == this.lastTradePrice.p) {
        //     console.log('lastTradePriceChange', this.lastTradePriceChange);
        //     if (this.lastTradePriceChange == 'low') {
        //       this.lastTradePriceChange = 'stillLow';
        //     } else if (this.lastTradePriceChange == 'high') {
        //       this.lastTradePriceChange = 'stillHigh';
        //     }
        //   }
        // }
        // existingPrice = this.lastTradePrice;
      }
    });

    setTimeout(() => {
      const chatHistory = document.getElementById('messageBody');
      if (chatHistory != null && chatHistory !== undefined) {
        chatHistory.scrollTop = chatHistory.scrollHeight;
      }
    }, 5000);

  }


  getSelectedOrder(data: any) {
    this.apiService.SelectedOrder(data);
  }

  @HostListener('window:resize', ['$event.target'])
  onResize($event) {
    this.resizeWorks();
  }

  private resizeWorks(): void {
    if (document.getElementById('Orderbook') !== undefined && document.getElementById('Orderbook') !== null) {
      this.maxWithOrderbook = document.getElementById('Orderbook').offsetWidth;
    }
  }

  getEditableOrder(st: boolean, orderPrice: number) {
    // this.selectedPriceKey = null;
    // // console.log('--+--*=(o)=*--+--)', st, orderPrice);
    // if (st !== undefined) {
    //   if (orderPrice != null && orderPrice !== undefined) {
    //     // console.log('---------------', st, orderPrice);
    //     this.selectedPriceKey = orderPrice;
    //   } else {
    //     // console.log('---------------', st, orderPrice);
    //     this.selectedPriceKey = orderPrice;
    //   }
    // }

  }

  cancelSelectedOrder(orderValue: any) {
    Object.entries(this.webSocketService.myActiveOrders).forEach(([key, value]) => {
      // valueTemp = value;
      if (orderValue == value['p']) {
        if (value['st'] == false) {
          this.cancel.orderID = value['o'];
          this.apiService.CancelOrder(this.cancel).then((result: any) => {
            this.apiService.ShowToastrAlert('', result.message, 'success');
          }, (err: any) => {
            this.apiService.ShowToastrAlert('', err.message, 'error');
          });
        }
      }
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy(): void {
    this.ltdSub.unsubscribe();
  }

}
