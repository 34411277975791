import {
  Component,
  OnInit,
  ɵConsole,
  ViewChild
} from '@angular/core';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  Router
} from '@angular/router';
import {
  AuthService
} from 'src/app/guard/auth.service';
import {
  CaptchaComponent
} from 'src/app/exchange/shared/captcha/captcha.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  loginDetails = {
    email: '',
    password: '',
    captcha: ''
  };

  persistAuth = {
    isAuthenticated: false,
    authorization: null,
    gAuthEnabled: false,
  };

  @ViewChild(CaptchaComponent)
  private callCaptchaComponent: CaptchaComponent;

  capString = 'purpose=SignIn&h=80&w=370';
  emailValidateError = '';
  passwordValidateError = '';

  captchaValidateError = '';

  reg: RegExp = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
  SignInProcessing = false;
  ImgUrl: any;
  Settings: any;
  captchaEnabled = false;

  constructor(public apiService: ApiService, public router: Router, private auth: AuthService) {}

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/trade']);
    }

    this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.Settings = data;
        if(this.Settings.disable_login === 'true') {
          this.router.navigate(['/feature-disabled']);
        } else if (this.Settings.captcha_mode.toLowerCase() === 'disabled') {
          this.captchaEnabled = false;
        } else if (this.Settings.captcha_mode.toLowerCase() === 'self') {
          this.captchaEnabled = true;
        } else {
          this.captchaEnabled = false;
        }
      }
    });
  }

  emailValidate(): boolean {
    if (this.loginDetails.email === undefined || this.loginDetails.email == null || this.reg.test(this.loginDetails.email) === false) {
      this.emailValidateError = 'Enter a valid email address';
      return false;
    } else {
      this.emailValidateError = '';
      return true;
    }
  }

  passwordValidate(): boolean {
    if (this.loginDetails.password === undefined || this.loginDetails.password == null || this.loginDetails.password.trim() === '') {
      this.passwordValidateError = 'Password can\'t be empty';
      return false;
    } else {
      this.passwordValidateError = '';
      return true;
    }
  }

  // twoFactorAuthValidate(): boolean {
  //   if (this.loginDetails.twoFactorAuth === undefined || this.loginDetails.twoFactorAuth == null
  //      || this.loginDetails.twoFactorAuth.trim() === '') {
  //     this.twoFactorAuthValidateError = '2FA can\'t be empty';
  //     return false;
  //   } else {
  //     this.twoFactorAuthValidateError = '';
  //     return true;
  //   }
  // }

  captchaValidate(): boolean {
    if (this.captchaEnabled !== false) {
      if (this.loginDetails.captcha === undefined || this.loginDetails.captcha == null || this.loginDetails.captcha.trim() === '') {
        this.captchaValidateError = 'Captcha can\'t be empty';
        return false;
      } else {
        this.captchaValidateError = '';
        return true;
      }
    } else {
      return true;
    }
  }

 async loginAccount() {
    if (this.emailValidate() === false || this.passwordValidate() === false || this.captchaValidate() === false) {
      return;
    } else {
      this.SignInProcessing = true;

      let result = await  this.apiService.Login_Account(this.loginDetails).catch(err => {
        if (this.captchaEnabled !== false) {
          this.callCaptcha();
        }
        this.loginDetails.captcha = null;
        this.apiService.ShowToastrAlert('', err.description, 'error');
        this.SignInProcessing = false;
      });
      if(result == undefined || result== null) {
        return
        }
        if (result.data != null && result.data !== undefined) {
          if (result.data.access_token) {
            this.persistAuth.authorization = result.data.access_token;
            this.persistAuth.isAuthenticated = true;
            localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
            this.apiService.Updateisloginstatus();
            this.router.navigate(['/trade']);
            this.apiService.ShowToastrAlert('', 'Login Successful!', 'success');
          } else if (result.data.tempAuthToken) {
            this.apiService.getTempAuth(result.data.tempAuthToken);
            this.apiService.getdevicewhitelist(result.data.requireDeviceWhitelisting);
            if (result.data.twoFAMehtod != null && result.data.twoFAMehtod !== undefined) {
              if (result.data.twoFAMehtod == 'Email') {
                this.apiService.ShowToastrAlert('', 'OTP has been successfully sent to your email address.', 'success');
                this.router.navigate(['/login/email-otp']);
              } else {
                this.router.navigate(['/login/authenticate']);
              }
            } else {
              this.apiService.ShowToastrAlert('', result.data, 'success');
            }
          }
          this.SignInProcessing = false;
        }

    }
  }

  callCaptcha() {
    if (this.callCaptchaComponent != null && this.callCaptchaComponent !== undefined) {
      this.callCaptchaComponent.getCaptchaCode();
    }

  }

}
