<section class="trade-panel">
  <div class="contract-panel-mobile">
    <div class="contract-list-panel">
      <div class="contract-list-panel-inner" *ngFor="let item of contractsList | keyvalue: originalOrder; let i = index">
        <a href="javascript:void(0)" class="contract-group-btn" (click)="selectContracts(item.key)"
          [ngClass]="(this.contractSelector?.contractGroupName == item.key)?'is-active':''">{{item.key}}
        </a>
      </div>
    </div>
    <div class="py-1" style="overflow: auto;">
      <div class="header-contract-details">
        <div class="header-contract-details-item" *ngFor="let item of selectedKeyValue"
          (click)="selectContractsValue(item)">
          <div class="header-contract-details-inner2" [ngClass]="(item.name == contractSelector.contractName)?'active':'any'">
            {{item.name}}
            <!-- {{item.name}} (
              <span class="" *ngIf="item.type.toLowerCase().includes('perpetual')">{{item.type}}</span>
              <span class="" *ngIf="item.type.toLowerCase().includes('future')">{{item.expiryTS | date: 'MMMM d'}}</span>
            ) -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="trade-grid">
    <div class="section1">
      <!-- <div style="width: 100%; height: calc(50vh - 29px);">
          <app-tradingview-chart></app-tradingview-chart>
        </div> -->
      <div class="widget-card">
        <div class="widget-card-header p-0">
          <div class="header-tabs hasAnchor">
            <div class="header-tabs-item">
              <a href="javascript:void(0)" (click)="changeChartType('Mountain')" [ngClass]="{'active': chartType == 'Mountain' , '': (chartType == undefined || chartType == null || chartType != 'Mountain') }">{{'trade.allsections.Mchart' |translate}}</a>
            </div>
            <div class="header-tabs-item">
              <a href="javascript:void(0)" (click)="changeChartType('Depth')" [ngClass]="{'active': chartType == 'Depth' , 'inactive': (chartType == undefined || chartType == null || chartType != 'Depth') }">{{'trade.allsections.Dchart' |translate}}</a>
            </div>
          </div>
        </div>
        <div class="widget-card-body">
          <div *ngIf="chartType == 'Depth'">
            <app-depth-chart></app-depth-chart>
          </div>
          <div *ngIf="chartType == 'Mountain'">
            <app-tradingview-chart></app-tradingview-chart>
          </div>
        </div>
      </div>

      <div style="width: 100%; height: calc(50vh - 29px);">
        <div style="display: flex;align-items: flex-start;justify-content: flex-start;">
          <div style="width: 100%;">
            <app-all-sections></app-all-sections>
          </div>
        </div>
        <div style="display: flex;align-items: flex-start;justify-content: flex-start;">
          <div style="width: 100%;">
            <app-positions-section></app-positions-section>
          </div>
        </div>
      </div>
    </div>
    <div class="section2">
      <div
        style="display: flex;align-items: flex-start;justify-content: flex-start;flex-direction: column; height: calc(100vh - 58px);">
        <div style="height: 50%; width:100%">
          <app-orderbook></app-orderbook>
        </div>
        <div style="height: 50%; width:100%">
          <app-recent-trades></app-recent-trades>
        </div>
      </div>
    </div>
    <div class="section3">
      <app-place-order></app-place-order>
    </div>
  </div>
</section>

<!-- <span class="contentFlex" id="Sellbookheight" #Sellbookheight>
    <div class="dashobard-box" [ngClass]="(placeSidebar == true)?'placeLeft':'placeRight'">
      <div class="fullWidth">
        <div class="">
          <div>
              <div>
                  <app-chart></app-chart>
                </div>
            <app-currency-selector></app-currency-selector>
          </div>
          <div class="gridRow">
            <div class="gridCellOne">
              <app-orderbook></app-orderbook>
            </div>
            <div class="gridCellTwo">

              <div>
                <app-depth-chart></app-depth-chart>
              </div>
            </div>
            <div class="gridCellThree">
              <app-recent-trades></app-recent-trades>
            </div>
          </div>
          <div>
            <app-all-sections></app-all-sections>
          </div>
        </div>
      </div>
    </div>
  </span> -->
<!--
  <div class="modalWrapper verticalAlignFlexParent" *ngIf="ShowSellModel != false">
    <section class="modalBox" style="min-width: 700px;">
      <header>
        <span class="title">Confirm Your Order</span>
        <div class="widget-controls">
          <span class="close" (click)="CloseSellModel()">x</span>
        </div>
      </header>
      <div class="body">
        <h4 style="border-bottom: 1px solid #dee2e6; padding-bottom: 10px; margin-bottom: 5px;">Sell Take Profit Market
        </h4>
        <div style="font-size: 20px;" class="mb-2">
          <p>Sell 1 Contract of XBTUSD at Market.</p>
          <p>Trigger: Last Price @ 8072.5 and above.</p>
        </div>
        <div>
          <div class="fullPositionStatus2">
            <h6 class="underlined">Your Position: XBTUSD</h6>
            <div class=" positionLeverageIndicator"><span class="initialLeverageIcon tooltipWrapper"><i
                  class="fa fa-shield"></i></span>
              <div class=" indicatorGraph">
                <div class="lineIndicator" style="left: 12.5%;"><span class="valueLabel">25.00x</span></div>
              </div><span class="indicatorLabel hideIcon">Leverage</span><span class="maintLeverageIcon tooltipWrapper"><i
                  class="fa fa-exclamation-triangle"></i></span>
            </div>
            <div>This position will be opened in Isolated Margin mode.
              Isolated Margin will use your selected margin preference (25x).
              This will limit your loss. Watch your liquidation price carefully.</div>
          </div>
        </div>
        <div class="grid ">
          <table class="table table-striped ">
            <thead></thead>
            <tbody>
              <tr class="">
                <td class="">Order Value</td>
                <td class="">0.125 mXBT</td>
              </tr>
              <tr class="">
                <td class="">Cost @ 100x</td>
                <td class="">0.001 mXBT</td>
              </tr>
              <tr class="">
                <td class="">Available Balance</td>
                <td class="">10.000 mXBT</td>
              </tr>
              <tr class="">
                <td class="">Position Size After Execution</td>
                <td class="">1</td>
              </tr>
              <tr class="">
                <td class="">Mark Price</td>
                <td class="">7962.5</td>
              </tr>
              <tr class="">
                <td class="">Estimated Liquidation Price</td>
                <td class="">7950.0</td>
              </tr>
              <tr class="">
                <td class="">Mark Price/Est. Liquidation Difference</td>
                <td class=""><span class="lowMarkLiqDif">0.16%</span> (12.5)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="alert alert-info"><b>Note: </b>This Stop order is being submitted with the 'Close on
          Trigger' instruction.
          Close orders can only reduce your position, not increase it. If the account has insufficient Available Balance
          when the Close order is triggered, then other XBTUSD orders will be canceled or amended down. This is desired
          behavior to ensure your stop will trigger as expected.</div>
        <div class="checkbox dontShowAgain"><label><input type="checkbox" name="dontShowAgain" value="false">Don't Show
            Again</label></div>

        <div class="row">
          <div class="col-sm-6 pr-sm-1 mb-2">
            <button type="button" class="btn btn-info btn-block" (click)="CloseSellModel()">Cancel</button>
          </div>
          <div class="col-sm-6 pl-sm-1 mb-2">
            <button type="button" class="btn btn-primary btn-block">Sell</button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="modalWrapper verticalAlignFlexParent" *ngIf="ShowBuyModel != false">
    <section class="modalBox" style="min-width: 700px;">
      <header>
        <span class="title">Confirm Your Order</span>
        <div class="widget-controls">
          <span class="close" (click)="CloseBuyModel()">x</span>
        </div>
      </header>
      <div class="body">
        <h4 style="border-bottom: 1px solid #dee2e6; padding-bottom: 10px; margin-bottom: 5px;">Buy Take Profit Market
        </h4>
        <div style="font-size: 20px;" class="mb-2">
          <p>Buy 1 Contract of XBTUSD at Market.</p>
          <p>Trigger: Last Price @ 7920 and below.</p>
        </div>
        <div>
          <div class="fullPositionStatus2">
            <h6 class="underlined">Your Position: XBTUSD</h6>
            <div class=" positionLeverageIndicator"><span class="initialLeverageIcon tooltipWrapper"><i
                  class="fa fa-shield"></i></span>
              <div class=" indicatorGraph">
                <div class="lineIndicator" style="left: 12.5%;"><span class="valueLabel">25.00x</span></div>
              </div><span class="indicatorLabel hideIcon">Leverage</span><span class="maintLeverageIcon tooltipWrapper"><i
                  class="fa fa-exclamation-triangle"></i></span>
            </div>
            <div>This position will be opened in <b>Isolated Margin</b> mode.<div>Isolated Margin will use your selected
                margin preference (100x).
                This will limit your loss. Watch your liquidation price carefully.</div>
            </div>
          </div>
          <div class="grid ">
            <table class="table table-striped ">
              <thead></thead>
              <tbody>
                <tr class="">
                  <td class="">Order Value</td>
                  <td class="">0.125 mXBT</td>
                </tr>
                <tr class="">
                  <td class="">Cost @ 100x</td>
                  <td class="">0.001 mXBT</td>
                </tr>
                <tr class="">
                  <td class="">Available Balance</td>
                  <td class="">10.000 mXBT</td>
                </tr>
                <tr class="">
                  <td class="">Position Size After Execution</td>
                  <td class="">1</td>
                </tr>
                <tr class="">
                  <td class="">Mark Price</td>
                  <td class="">7962.5</td>
                </tr>
                <tr class="">
                  <td class="">Estimated Liquidation Price</td>
                  <td class="">7950.0</td>
                </tr>
                <tr class="">
                  <td class="">Mark Price/Est. Liquidation Difference</td>
                  <td class=""><span class="lowMarkLiqDif">0.16%</span> (12.5)</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="alert alert-info"><b>Note: </b>This Stop order is being submitted with the 'Close on
            Trigger' instruction.
            Close orders can only reduce your position, not increase it. If the account has insufficient Available Balance
            when the Close order is triggered, then other XBTUSD orders will be canceled or amended down. This is desired
            behavior to ensure your stop will trigger as expected.</div>
          <div class="checkbox dontShowAgain"><label><input type="checkbox" name="dontShowAgain" value="false">Don't Show
              Again</label></div>
        </div>
        <div class="row">
          <div class="col-sm-6 pr-sm-1 mb-2">
            <button type="button" class="btn btn-info btn-block mr-2" (click)="CloseBuyModel()">Cancel</button>
          </div>
          <div class="col-sm-6 pl-sm-1 mb-2">
            <button type="button" class="btn btn-primary btn-block mr-2">Sell</button>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div *ngIf="CalcModel != false" class="drag-boundary calcModal"
    [ngClass]="{ 'top-b': !edge?.top, 'bottom-b': !edge?.bottom, 'left-b': !edge?.left, 'right-b': !edge?.right }"
    #myBounds>
    <div class="left calculatorPopout popout react-draggable react-draggable-dragged drag-block" ngDraggable
      [handle]="dragHandle" (edge)="checkEdge($event)" [bounds]="myBounds" [inBounds]="inBounds">
      <div class="calc-card-tabs">
        <div class="calc-card-tabs-header" #dragHandle>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <div class="tabs-section">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" (click)="calcTabs('Profit/Loss')"
                  [ngClass]="{'active': CalcTabId == 'Profit/Loss' , '': (OrderType != 'Profit/Loss') }">
                  <a class="nav-link active" data-toggle="tab" href="#ProfitLoss">Profit/Loss</a>
                </li>
                <li class="nav-item" (click)="calcTabs('TargetPrice')"
                  [ngClass]="{'active': CalcTabId == 'TargetPrice' , '': (OrderType != 'TargetPrice') }">
                  <a class="nav-link" data-toggle="tab" href="#TargetPrice">Target Price</a>
                </li>
                <li class="nav-item" (click)="calcTabs('LiquidationPrice')"
                  [ngClass]="{'active': CalcTabId == 'LiquidationPrice' , '': (OrderType != 'LiquidationPrice') }">
                  <a class="nav-link" data-toggle="tab" href="#LiquidationPrice">Liquidation Price</a>
                </li>
              </ul>
            </div>
            <div class="widget-controls pr-2">
              <i class="fa fa-fw fa-times linkify" title="Put Into Toolbox" (click)="CloseCalcModel()"></i>
            </div>
          </div>
        </div>
        <section class="calculatorContainer">
          <div class="widget-card-tabs-body">
            <div class="rpl calculator" *ngIf="CalcTabId == 'Profit/Loss'">
              <div class="calculatorSwitches well">
                <div class="labeledSwitch calculatorSwitch">
                  <label class="switchLabel">Side</label>
                  <label class="leftLabel active">Long</label>
                  <label class="u-switch">
                    <div class="toggleWrapper">
                      <input type="checkbox" name="toggle1" class="mobileToggle" id="toggle1" checked>
                      <label for="toggle1"></label>
                    </div>
                  </label>
                  <label class="rightLabel ">Short</label>
                </div>
              </div>
              <div class="flexBody">
                <div class="calculatorInput flexItem">
                  <div class="form-group form-inline sm-input">
                    <label for="quantity">Quantity</label>
                    <input type="number" id="quantity" autocomplete="off" class="form-control valid" min="-Infinity"
                      max="Infinity" name="quantity" placeholder="" step="1"></div>
                  <div class="form-group form-inline sm-input"><label for="entryPrice">Entry Price</label><input
                      type="number" id="entryPrice" autocomplete="off" class="form-control valid" min="-Infinity"
                      max="Infinity" name="entryPrice" placeholder="" step="0.5"></div>
                  <div class="form-group form-inline sm-input"><label for="exitPrice">Exit Price</label><input
                      type="number" id="exitPrice" autocomplete="off" class="form-control valid" min="-Infinity"
                      max="Infinity" name="exitPrice" placeholder="" step="0.5"></div>
                  <div class="form-group form-inline sm-input"><label for="leverage">Leverage</label><input type="number"
                      id="leverage" autocomplete="off" class="form-control valid" min="-Infinity" max="100"
                      name="leverage" placeholder="" step="0.01"></div>
                </div>
                <div class="calculatorOutput flexItem">
                  <div class="grid compact">
                    <table class="table table-striped ">
                      <thead></thead>
                      <tbody>
                        <tr class="">
                          <td class="">Margin</td>
                          <td class="">0.021</td>
                        </tr>
                        <tr class="">
                          <td class="">Entry Value</td>
                          <td class="">-0.105</td>
                        </tr>
                        <tr class="">
                          <td class="">Exit Value</td>
                          <td class="">-0.105</td>
                        </tr>
                        <tr class="">
                          <td class=""><span class="tooltipWrapper">Profit/Loss</span></td>
                          <td class="">0.000</td>
                        </tr>
                        <tr class="">
                          <td class=""><span class="tooltipWrapper horizFlip">Profit/Loss %</span></td>
                          <td class="">0.00%</td>
                        </tr>
                        <tr class="">
                          <td class=""><span class="tooltipWrapper horizFlip">ROE %</span></td>
                          <td class="">0.00%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p class="help-block text-align-center">All currency units denominated in mXBT</p>
                </div>
              </div>
            </div>
            <div class="targetPrice calculator" *ngIf="CalcTabId == 'TargetPrice'">
              <div class="calculatorSwitches well">
                <div class="labeledSwitch calculatorSwitch">
                  <label class="switchLabel">Side</label>
                  <label class="leftLabel active">Long</label>
                  <label class="u-switch">
                    <div class="toggleWrapper">
                      <input type="checkbox" name="toggle2" class="mobileToggle" id="toggle2" checked>
                      <label for="toggle2"></label>
                    </div>
                  </label>
                  <label class="rightLabel ">Short</label>
                </div>
              </div>
              <div class="flexBody">
                <div class="calculatorInput flexItem">
                  <div class="form-group form-inline sm-input"><label for="lvg">Leverage</label><input type="number"
                      id="lvg" autocomplete="off" class="form-control valid" min="-Infinity" max="100" name="lvg"
                      placeholder="" step="1"></div>
                  <div class="form-group form-inline sm-input"><label for="entryPrice">Entry Price</label><input
                      type="number" id="entryPrice" autocomplete="off" class="form-control valid" min="-Infinity"
                      max="Infinity" name="entryPrice" placeholder="" step="0.5"></div>
                  <div class="form-group form-inline sm-input"><label for="pl">ROE %</label><input type="number" id="pl"
                      autocomplete="off" class="form-control valid" min="-Infinity" max="Infinity" name="pl"
                      placeholder="" step="0.5"></div>
                </div>
                <div class="calculatorOutput flexItem">
                  <div class="grid compact">
                    <table class="table table-striped ">
                      <thead></thead>
                      <tbody>
                        <tr class="">
                          <td class="">Entry Price</td>
                          <td class="">10221.5</td>
                        </tr>
                        <tr class="">
                          <td class="">Target Price</td>
                          <td class="">10431.0</td>
                        </tr>
                        <tr class="">
                          <td class="">
                            <div>Profit/Loss %</div>
                          </td>
                          <td class="">2.00%</td>
                        </tr>
                        <tr class="">
                          <td class="">
                            <div>ROE %</div>
                          </td>
                          <td class="">100.00%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="liqPrice calculator" *ngIf="CalcTabId == 'LiquidationPrice'">
              <div class="calculatorSwitches well">
                <div class="labeledSwitch calculatorSwitch">
                  <label class="switchLabel">Side</label>
                  <label class="leftLabel active">Long</label>
                  <label class="u-switch">
                    <div class="toggleWrapper">
                      <input type="checkbox" name="toggle3" class="mobileToggle" id="toggle3" checked>
                      <label for="toggle3"></label>
                    </div>
                  </label>
                  <label class="rightLabel ">Short</label>
                </div>
                <div class="labeledSwitch calculatorSwitch">
                  <label class="switchLabel">Margin</label>
                  <label class="leftLabel ">Isolated</label>
                  <label class="u-switch is-checked">
                    <div class="toggleWrapper">
                      <input type="checkbox" name="toggle4" class="mobileToggle" id="toggle4">
                      <label for="toggle4"></label>
                    </div>
                  </label>
                  <label class="rightLabel active">Cross</label>
                </div>
              </div>
              <div class="flexBody">
                <div class="calculatorInput flexItem">
                  <div class="form-group form-inline sm-input"><label for="quantity">Quantity</label><input type="number"
                      id="quantity" autocomplete="off" class="form-control valid" min="-Infinity" max="100000000"
                      name="quantity" placeholder="" step="1"></div>
                  <div class="form-group form-inline sm-input"><label for="entryPrice">Entry Price</label><input
                      type="number" id="entryPrice" autocomplete="off" class="form-control valid" min="-Infinity"
                      max="100000000" name="entryPrice" placeholder="" step="0.5"></div>
                  <div class="form-group form-inline sm-input"><label for="margin">Wallet</label><input type="number"
                      id="margin" autocomplete="off" class="form-control valid" min="-Infinity" max="Infinity"
                      name="margin" placeholder="" step="0.001"></div>
                </div>
                <div class="calculatorOutput flexItem">
                  <div class="grid compact">
                    <table class="table table-striped ">
                      <thead></thead>
                      <tbody>
                        <tr class="">
                          <td class="">Current Qty</td>
                          <td class="">0</td>
                        </tr>
                        <tr class="">
                          <td class="">Resulting Qty</td>
                          <td class="">1</td>
                        </tr>
                        <tr class="">
                          <td class="">Liquidation Price</td>
                          <td class="">99.5</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="widget-footer">Position: ---<span class="pull-right">Contract: XBTUSD</span></div>
      </div>
    </div>
  </div>

  <div class="modalWrapper verticalAlignFlexParent" *ngIf="AdjustRiskLimit">
    <section class="modalBox">
      <header>
        <span class="title">Adjust XBTUSD Risk Limit</span>
        <div class="widget-controls">
          <span class="close" (click)="CloseAdjustRiskLimit()">x</span>
        </div>
      </header>
      <div class="body">
        <div class="bodyText">
          <div class="form form-inline">
            <div class="posDetails v-offset-xs-1">
              <p>Goesoteric employs a sliding-scale Risk Limit function. In XBTUSD, the base risk limit is 200 XBT.
                For every <b>100 XBT</b> increase in position size, the maintenance &amp; initial margin requirements rise
                by <b>0.50%</b>.</p>
              <p>Please view the <a href="javascript:void(0)">Risk Limits Documentation</a> for more information.</p>
              <hr>
              <h4 style="font-size: 1rem;">Change Risk Limit (XBT)</h4>
              <div class="riskLimitSlider well well-dark mb-3">
                <div class="rangeBox">
                  <div style="position: relative;margin: 0px 10px;z-index: 10;">
                    <input type="range" value="0" step="11.1111111111" class="rangeSlider" id="myRange">
                    <div class="rangeSlider-step">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <div class="rangeSlider-mark">
                    <div>200</div>
                    <div>300</div>
                    <div>400</div>
                    <div>500</div>
                    <div>600</div>
                    <div>700</div>
                    <div>800</div>
                    <div>900</div>
                    <div>1000</div>
                    <div>1100</div>
                  </div>
                </div>
              </div>
              <div class="grid">
                <table class="table table-striped">
                  <thead></thead>
                  <tbody>
                    <tr class="">
                      <td class=""></td>
                      <td class="">Risk Limit</td>
                      <td class="">Maintenance Margin</td>
                      <td class="">Initial Margin</td>
                    </tr>
                    <tr class="">
                      <td class="">Current</td>
                      <td class="">200 XBT</td>
                      <td class="">0.5%</td>
                      <td class="">1%</td>
                    </tr>
                    <tr class="">
                      <td class="">New</td>
                      <td class="">200 XBT</td>
                      <td class="">0.5%</td>
                      <td class="">1%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="row">
          <div class="col-sm-6 pr-sm-1 mb-2">
            <button type="button" class="btn btn-info btn-block" (click)="CloseAdjustRiskLimit()">Cancel</button>
          </div>
          <div class="col-sm-6 pl-sm-1 mb-2">
            <button type="button" class="btn btn-primary btn-block">Please Select New Limit</button>
          </div>
        </div>
      </div>
    </section>
  </div> -->
