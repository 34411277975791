import { Component, OnInit, OnDestroy, ɵConsole } from '@angular/core';
import { Subscription } from 'rxjs';
// tslint:disable-next-line:import-blacklist
import { Observable } from 'rxjs/Rx';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/guard/auth.service';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-email-otp',
  templateUrl: './email-otp.component.html',
  styleUrls: ['./email-otp.component.scss']
})
export class EmailOtpComponent implements OnInit, OnDestroy {

  userDetails = {
    password: '',
    username: '',
    otp: ''
  };

  persistAuth = {
    isAuthenticated: false,
    authorization: null,
    gAuthEnabled: false,
  };

  SignInProcessing = false;
  otpProcessing = false;

  subscription: Subscription;

  passwordValidateError = '';
  otpValidateError = '';
  resendOtpData = {
    verificationLink: '',
    verificationLinkType: 'SignIn'
  };

  timerinterval: any;
  intervalTime: any;
  countDown;
  counter = 300;
  tick = 1000;
  device: any;
  showbtnresend = false;

  constructor(public apiService: ApiService, public router: Router, private auth: AuthService) {
    this.apiService.tempAuthToken.subscribe((data: any) => {
      if (data === undefined || data == null  || data.length == 0) {
        this.router.navigate(['/login']);
      } else {
        this.userDetails.username = data;
      }
    });
    this.apiService.requireDeviceWhitelisting.subscribe((data: any) => {
        this.device = data;
    });
   }

  ngOnInit() {
    this.countDown = Observable.timer(0, this.tick)
    .take(this.counter)
    .map(() => --this.counter);

    this.intervalTime = this.counter * 1000;
    // console.log('interval time', this.intervalTime);
    this.timerinterval = setTimeout(() => {
      this.showbtnresend = true;
    }, this.intervalTime);
  }

  passwordValidate(): boolean {
    if (this.userDetails.password.trim() == '') {
      this.passwordValidateError = 'This field is required.';
      return false;
    } else if (this.userDetails.password.length < 6) {
      this.passwordValidateError = 'Minimum length of 6 required.';
      return false;
    } else {
      this.passwordValidateError = '';
      return true;
    }
  }

  otpValidate(): boolean {
    if (this.userDetails.otp.trim() == '') {
      this.otpValidateError = 'This field is required.';
      return false;
    } else if (this.userDetails.otp.length < 6) {
      this.otpValidateError = 'Minimum length of 6 required.';
      return false;
    } else {
      this.otpValidateError = '';
      return true;
    }
  }


 async generateBearerToken() {
    if (this.passwordValidate() === false) {
      return;
    }

    if (this.device === true && this.otpValidate() === false){
      return;
    }
      this.SignInProcessing = true;

      let result = await  this.apiService.tokenGenerate(this.userDetails).catch(err => {
        this.SignInProcessing = false;
        // this.router.navigate(['/login']);
        this.apiService.ShowToastrAlert('oops!', err.message, 'error');
      });
      if(result == undefined || result== null) {
        return
        }

        if (result.data.access_token != null && result.data.access_token !== undefined && result.data.access_token.length !== 0) {
          this.apiService.ShowToastrAlert('', result.message, 'success');
          this.SignInProcessing = false;
          this.persistAuth.authorization = result.data.access_token;
          this.persistAuth.isAuthenticated = true;
          localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
          this.apiService.Updateisloginstatus();
          this.router.navigate(['/trade']);
        }
}

onKeyPress(event: any) {
  if (event.key === 'Enter') {
    this.generateBearerToken();
  }
}


submitAuth(event: any) {
  if ( event.key === 'Control') {
    return;
  }
  if (this.userDetails.password.length == 6) {
    this.generateBearerToken();
  }
}

async ResendOtp() {
  this.showbtnresend = false;
  this.resendOtpData.verificationLink = this.userDetails.username;

  let result = await this.apiService.requestResendOTP(this.resendOtpData).catch(err => {
    this.showbtnresend = true;
    this.apiService.ShowToastrAlert('oops!', err.message, 'error');
  });
  if(result == undefined || result== null) {
    return
    }
    this.apiService.ShowToastrAlert('', result.data, 'success');
    this.counter = 300;
    this.countDown = Observable.timer(0, this.tick)
    .take(this.counter)
    .map(() => --this.counter);
    this.intervalTime = this.counter * 1000;
    // console.log('interval time', this.intervalTime);
    this.timerinterval = setTimeout(() => {
      this.showbtnresend = true;
    }, this.intervalTime);

}

async sendOtp() {
  this.otpProcessing = true;
  let result = await  this.apiService.requestsendOTP().catch(err => {
    this.otpProcessing = false;
    this.apiService.ShowToastrAlert('oops!', err.message, 'error');
  });
  if(result == undefined || result== null) {
    return
    }
    this.otpProcessing = false;
    this.apiService.ShowToastrAlert('', result.data, 'success');

}

ngOnDestroy() {
  if (this.timerinterval) {
    clearInterval(this.timerinterval);
  }
}

}

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}
