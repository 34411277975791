import {
  Injectable
} from '@angular/core';
import axios from 'axios';
import * as moment from 'moment';
import * as qs from 'qs';
import {
  ApiService
} from '../api.service';
import { WebsocketService } from '../websocket.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryProviderService {
  volume: any;

  constructor(public apiService: ApiService, public webSocketService: WebsocketService) {}

  chartData: any;

  history = {};


  lastBar: any;

  resolutions = {
    1: '1M',
    5: '5M',
    15: '15M',
    30: '15M',
    60: '1H',
    180: '3H',
    240: '4H',
    360: '6H',
    720: '12H',
    '1D': '1D',
  };

  historyProvider = {

    history: this.history,

    getBars: async (symbolInfo, resolution, from, to, first, limit) => {
      // console.log('resolutions', resolution);
      // const resolutions = resolution === '1D' ? '1D' : this.resolutions[resolution];
      const resolutions = this.resolutions[resolution];
      this.webSocketService.selectedResolution(resolution);
      // console.log('+(O_o)+', resolutions, '+($_$)+', resolution);

      const queryString = qs.stringify({
        limit: limit ? limit : 1000,
        ts: to ? to : '',
        // aggregate: 1//resolution
      });

      // tslint:disable-next-line:max-line-length
      // console.log('chart history URL', this.apiService.BaseUrl + 'public/' + symbolInfo.chart + '/' + symbolInfo.name + '/' + resolutions + '?' + queryString);

      const { data } = await axios({
        url: `${this.apiService.BaseUrl}public/${symbolInfo.chart}/${symbolInfo.name}/${resolutions}?${queryString}`,
        method: 'GET',
      });

      if (data != null && data !== undefined) {
        this.chartData = data.success.data;
      }

      // this.apiService.getResolutions(this.BackendDesolutions[this.resolutions[resolution]]);

      if (this.chartData == null || this.chartData === undefined || this.chartData.length === 0) {
        return [];
      }

      if (this.chartData.length) {
        const bars = this.chartData.map((bar: any) => {
          return {
            // time: moment(bar[0]).valueOf(), // TradingView requires bar time in ms
            time: bar[0],
            open: bar[1],
            high: bar[2],
            low: bar[3],
            close: bar[4],
            volume: this.volume = (bar[5] !== undefined) ? bar[5] : 0
          };
        });

        // if (first) {
        //   this.lastBar = bars[bars.length - 1];
        //   history[symbolInfo.name] = {
        //     lastBar: this.lastBar
        //   };
        // }
        if (first) {
          const lastBar = bars[0];
          history[symbolInfo.name] = { lastBar };
        }
        return bars.reverse();
      } else {
        return [];
      }
    }
  };
}
