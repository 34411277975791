<div class="widget-card">
  <div class="widget-card-header" *ngIf="isLogin">
    <div class="place-order-header">
      <div>{{'trade.placeorder.placeodr' |translate}}</div>
      <div><a (click)="openCalc()" style="cursor: pointer;"><i class="fas fa-calculator"></i></a></div>
    </div>
  </div>
  <div class="widget-card-body p-2" style="height: calc(100vh - 97px); overflow: auto;">
    <div class="widget-card">
      <div class="widget-card-header p-0">
        <div class="header-tabs hasAnchor">
          <div class="header-tabs-item">
            <a href="javascript:void(0)" (click)="ChangeOrderType('Limit')"
              [ngClass]="{'active': OrderType == 'Limit' , '': (OrderType == undefined || OrderType == null || OrderType != 'Limit') }">{{'trade.placeorder.Tab1' | translate}}</a>
          </div>
          <div class="header-tabs-item">
            <a href="javascript:void(0)" (click)="ChangeOrderType('Market')"
              [ngClass]="{'active': OrderType == 'Market' , 'inactive': (OrderType == undefined || OrderType == null || OrderType != 'Market') }">{{'trade.placeorder.Tab2' | translate}}</a>
          </div>
          <div class="header-tabs-item">
            <a href="javascript:void(0)" (click)="ChangeOrderType('Stop Market')"
              [ngClass]="{'active': OrderType == 'Stop Limit' || OrderType == 'Stop Market' || OrderType == 'Trailing Stop Market' || OrderType == 'Take Profit Limit' || OrderType == 'Take Profit Market' , 'inactive': (OrderType == undefined || OrderType == null || (OrderType != 'Stop Limit' && OrderType != 'Stop Market' && OrderType != 'Trailing Stop Market' && OrderType != 'Take Profit Limit' && OrderType != 'Take Profit Market')) }">
              {{'trade.placeorder.Tab3' | translate}}</a>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div *ngIf="isLogin">
        <div class="dropdown Go-select-container mb-2"
          *ngIf="OrderType == 'Stop Limit' || OrderType == 'Stop Market' || OrderType == 'Trailing Stop Market' || OrderType == 'Take Profit Limit' || OrderType == 'Take Profit Market'">
          <div class="dropdown-toggle noAfter" data-toggle="dropdown" style='position: relative;'>
            <input type="text" name="currencySearch" readonly class="css-bg1rzq-control react-select__control"
              value="{{otherOrderType}}" style="width: 100%;display: bock;">
            <div class="select_addon">
              <span class="css-bgvzuu-indicatorSeparator react-select__indicator-separator"></span>
              <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" class="css-19bqh2r"
                style="width: 100%;">
                <path
                  d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                </path>
              </svg>
            </div>
          </div>
          <div class="dropdown-menu Go-dropdown-menu">
            <a class="dropdown-item" href="javascript:void(0)" (click)="ChangeOrderType('Stop Limit')"
              *ngIf="otherOrderType != 'Stop Limit'">{{'trade.placeorder.Tab4' | translate}}</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="ChangeOrderType('Stop Market')"
              *ngIf="otherOrderType != 'Stop Market'">{{'trade.placeorder.Tab5' | translate}}</a>
            <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="ChangeOrderType('Trailing Stop Market')"
              *ngIf="otherOrderType != 'Trailing Stop Market'">Trailing Stop Market</a> -->
            <a class="dropdown-item" href="javascript:void(0)" (click)="ChangeOrderType('Take Profit Limit')"
              *ngIf="otherOrderType != 'Take Profit Limit'">{{'trade.placeorder.Tab6' | translate}}</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="ChangeOrderType('Take Profit Market')"
              *ngIf="otherOrderType != 'Take Profit Market'">{{'trade.placeorder.Tab7' | translate}}</a>
          </div>
        </div>

        <div style="display: flex;align-items: center;justify-content: space-between;" class="my-1">
          <div>{{'trade.placeorder.Avlbal' | translate}}</div>
          <div>{{walletbal?.available}} {{walletbal?.currency}}</div>
        </div>
        <div class="placeOrder-panel flexDiv">
          <div class="buyOrder-panel">
            <div class="flexGroup"
              *ngIf="OrderType == 'Stop Limit' || OrderType == 'Limit' || OrderType == 'Take Profit Limit'">
              <div class="label">{{'trade.placeorder.Lpr' | translate}}</div>
              <div>
                <div class="Go-input-group">
                  <input type="number" [(ngModel)]="orderpayload.price" class="" placeholder=""
                    step="{{currContractData?.minPriceINC}}" (keyup)="singleDecimal($event); calculateCostTrigger()">
                  <div class="Go-input-group-append">
                    <div class="Go-input-group-label">
                      <span class="Go-input-group-text"
                        id="basic-addon1">{{currContractData?.underlyingQuoteCurrency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flexGroup"
              *ngIf="OrderType == 'Stop Limit' || OrderType == 'Limit' || OrderType == 'Market' || OrderType == 'Stop Market' || OrderType == 'Trailing Stop Market' || OrderType == 'Take Profit Limit' || OrderType == 'Take Profit Market'">
              <div class="label">{{'trade.placeorder.Qty' | translate}}</div>
              <div>
                <div class="Go-input-group">
                  <input type="number" class="" placeholder="" step="1" min="0" [(ngModel)]="orderpayload.orderQty"
                    (keypress)="noDecimal($event)" (keyup)="calculateCostTrigger()" (change)="calculateCostTrigger()">
                  <div class="Go-input-group-append">
                    <div class="Go-input-group-label" *ngIf="currContractData">
                      <span class="Go-input-group-text" id="basic-addon1"
                        *ngIf="currContractData.type == 'InversePerpetual' || currContractData.type == 'InverseFuture'">{{currContractData?.underlyingQuoteCurrency}}</span>
                      <span class="Go-input-group-text" id="basic-addon1"
                        *ngIf="currContractData.type == 'Perpetual' || currContractData.type == 'Future'">{{currContractData?.underlyingBaseCurrency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flexGroup" *ngIf="OrderType == 'Stop Market' || OrderType == 'Stop Limit'">
              <div class="label">{{'trade.placeorder.Spr' | translate}}</div>
              <div>
                <div class="Go-input-group">
                  <input type="number" class="" placeholder="" [(ngModel)]="orderpayload.stopPrice"
                    (keyup)="checkStopPrice(orderpayload.stopPrice)" step="{{currContractData?.minPriceINC}}">
                  <div class="Go-input-group-append">
                    <div class="Go-input-group-label">
                      <span class="Go-input-group-text"
                        id="basic-addon1">{{currContractData?.underlyingQuoteCurrency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flexGroup" *ngIf="OrderType == 'Trailing Stop Market' ">
              <div class="label">{{'trade.placeorder.Tvl' | translate}}</div>
              <div>
                <div class="Go-input-group">
                  <input type="number" class="" placeholder="" [(ngModel)]="orderpayload.stopPrice"
                    (keyup)="checkStopPrice(orderpayload.stopPrice)" step="{{currContractData?.minPriceINC}}">
                  <div class="Go-input-group-append">
                    <div class="Go-input-group-label">
                      <span class="Go-input-group-text"
                        id="basic-addon1">{{currContractData?.underlyingQuoteCurrency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flexGroup" *ngIf="OrderType == 'Take Profit Limit' || OrderType == 'Take Profit Market'">
              <div class="label">{{'trade.placeorder.Trpr' | translate}}</div>
              <div>
                <div class="Go-input-group">
                  <input type="number" class="" placeholder="" [(ngModel)]="orderpayload.stopPrice"
                    (keyup)="checkStopPrice(orderpayload.stopPrice)" step="{{currContractData?.minPriceINC}}">
                  <div class="Go-input-group-append">
                    <div class="Go-input-group-label">
                      <span class="Go-input-group-text"
                        id="basic-addon1">{{currContractData?.underlyingQuoteCurrency}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="orderPercentBtn-panel my-2">
          <div class="orderPercentBtn-shell">
            <div class="orderPercentBtn">25%</div>
          </div>
          <div class="orderPercentBtn-shell">
            <div class="orderPercentBtn">50%</div>
          </div>
          <div class="orderPercentBtn-shell">
            <div class="orderPercentBtn">75%</div>
          </div>
          <div class="orderPercentBtn-shell">
            <div class="orderPercentBtn">100%</div>
          </div>
        </div> -->
            <!-- <div style="display: flex;align-items: center;justify-content: space-between;">
              <div style="width: 50%; padding: 5px;">
                <button type="button" class="btn btn-success btn-block"
                  (click)="requestBuyOrderPayload('Buy')" [disabled]="IsDisabledBuy == true">{{'trade.placeorder.Buy' | translate}}</button>
              </div>
              <div style="width: 50%; padding: 5px;">
                <button type="button" class="btn btn-danger btn-block"
                  (click)="requestSellOrderPayload('Sell')" [disabled]="IsDisabledSell == true">{{'trade.placeorder.Sell' | translate}}</button>
              </div>
            </div> -->
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div style="width: 50%; padding: 5px;">
                <button type="button" class="btn btn-success btn-block" (click)="requestBuyOrderPayload('Buy')"
                  [disabled]="IsDisabledBuy == true || IsDisabledBuyCost == true">{{'trade.placeorder.Buy' | translate}}</button>
              </div>
              <div style="width: 50%; padding: 5px;">
                <button type="button" class="btn btn-danger btn-block" (click)="requestSellOrderPayload('Sell')"
                  [disabled]="IsDisabledSell == true || IsDisabledSellCost == true">{{'trade.placeorder.Sell' | translate}}</button>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;">
              <div>
                <div class="mb-1">{{'trade.placeorder.Modal.cost' |translate}}</div>
                <div class="mb-1" tooltip="{{buyCost}}">{{buyCostToolTip | number:'1.0-8'}}</div>
              </div>
              <div>
                <div class="mb-1">{{'trade.placeorder.Modal.cost' |translate}}</div>
                <div class="mb-1" tooltip="{{sellCost}}">{{sellCostToolTip| number:'1.0-8'}}</div>
              </div>
            </div>
            <!-- <div style="display: flex;align-items: center;justify-content: space-between;">
              <div class="mb-1">{{'trade.placeorder.Ovl' | translate}}</div>
              <div class="mb-1">0.0260 BTC</div>
            </div> -->
            <!-- <div style="display: flex;align-items: center;justify-content: space-between;">
          <div>Available Balance</div>
          <div>52.68268000 BTC</div>
        </div> -->
            <!-- <div class="flexGroup">
            <div class="label">Total</div>
            <div>
              <div class="Go-input-group">
                <input type="text" class="" placeholder="Username">
                <div class="Go-input-group-append">
                  <span class="Go-input-group-text" id="basic-addon1">BTH</span>
                </div>
              </div>
            </div>
          </div> -->

            <div class="orderControlsAdvanced">
              <div class="controls-section">
                <div class="mb-1"
                  *ngIf="OrderType == 'Stop Market' || OrderType == 'Stop Limit' || OrderType == 'Trailing Stop Market' || OrderType == 'Take Profit Limit' || OrderType == 'Take Profit Market'">
                  <select class="placeOrder-select" [(ngModel)]="orderpayload.triggerType"
                    (change)="changeTriggerType()">
                    <option value="MarkPrice">{{'trade.placeorder.mark' |translate}}</option>
                    <option value="LastPrice" selected>{{'trade.placeorder.last' |translate}}</option>
                    <option value="IndexPrice">{{'trade.placeorder.index' |translate}}</option>
                  </select>
                  <span class="pl-2">{{'trade.placeorder.trgr' |translate}} </span>
                </div>
                <div class="checkbox-control mb-1"
                  *ngIf="OrderType == 'Limit' || OrderType == 'Market' || OrderType == 'Stop Limit' || OrderType == 'Take Profit Limit'">
                  <div class="check-label-left"
                    [ngClass]="(orderpayload.timeinForce == 'IOC' || orderpayload.timeinForce == 'FOK')?'disabled':''">
                    <input class="" type="checkbox" (change)="checkPostOnly()" [(ngModel)]="orderpayload.IsPostOnly" [disabled]="OrderType == 'Market'"
                      [disabled]="orderpayload.timeinForce == 'IOC' || orderpayload.timeinForce == 'FOK'">{{'trade.placeorder.pstonly' |translate}}
                  </div>
                </div>
                <div class="mb-1"
                  *ngIf="OrderType == 'Limit' || OrderType == 'Market' || OrderType == 'Stop Limit' || OrderType == 'Take Profit Limit'">
                  <div *ngIf="orderpayload.IsPostOnly == true"
                    [ngClass]="(orderpayload.IsPostOnly == true)?'disabled':''" class="timeinforce-section">
                    {{'trade.placeorder.GTC' | translate}}</div>
                  <select class="placeOrder-select selectEclipse" *ngIf="orderpayload.IsPostOnly == false"
                    [(ngModel)]="orderpayload.timeinForce" [disabled]="OrderType == 'Market'">
                    <option value="GTC" selected>{{'trade.placeorder.GTC' | translate}}</option>
                    <option value="IOC">{{'trade.placeorder.IOC' | translate}}</option>
                    <option value="FOK">{{'trade.placeorder.FOK' | translate}}</option>
                    <!-- <option value="DO">{{'trade.placeorder.DO' | translate}}</option> -->
                  </select>
                </div>
              </div>

              <div class="controls-section">
                <div class="checkbox-control mb-1"
                  *ngIf="OrderType == 'Stop Market' || OrderType == 'Stop Limit' || OrderType == 'Trailing Stop Market' || OrderType == 'Take Profit Limit' || OrderType == 'Take Profit Market'">
                  <div class="check-label-right"><input class="" type="checkbox"
                      [(ngModel)]="orderpayload.IsCloseOnTrigger">{{'trade.placeorder.clstrgr' |translate}} </div>
                </div>
                <!-- <div class="checkbox-control mb-1"
                  *ngIf="OrderType == 'Limit' || OrderType == 'Market' || OrderType == 'Stop Limit' || OrderType == 'Take Profit Limit'">
                  <div class="check-label-right"
                    [ngClass]="(orderpayload.IsPostOnly == true || IshiddenDisabled == true)?'disabled':''"><input
                      class="" type="checkbox" [disabled]="orderpayload.IsPostOnly == true || IshiddenDisabled == true"
                      [(ngModel)]="orderpayload.IsHiddenOrder" [disabled]="OrderType == 'Market'">{{'trade.placeorder.hiden' |translate}}</div>
                </div> -->
                <div class="checkbox-control mb-1" *ngIf="OrderType == 'Limit' || OrderType == 'Market'">
                  <div class="check-label-right"><input class="" type="checkbox"
                      [(ngModel)]="orderpayload.isReduceOnly">{{'trade.placeorder.rdconly' |translate}}</div>
                </div>
              </div>
            </div>

            <!-- <div class="IfnotloggedIn">
            <div>
              <a href="javascript:void(0)">Log In</a> or <a href="javascript:void(0)">Register</a> to trade
            </div>
          </div> -->
          </div>
        </div>
        <div class="mt-2 pt-3" style="border-top: 1px solid #42404f;">
          <div class="LeverageIndicator" *ngIf="showvalue >= 0">
            <span class="initialLeverageIcon" *ngIf="IsShieldHide == true">
              <i class="fas fa-shield-alt"></i>
            </span>
            <div class="lineIndicator" [ngStyle]="{'left': rangeSliderValue}">
              <span class="valueLabel" [ngClass]="{'textOnLeft': showvalue > 50}">{{showvalue}}x</span>
            </div>
            <span class="indicatorLabel" *ngIf="HideLeverage == false">{{'trade.placeorder.Lv' | translate}}</span>
            <span class="maintLeverageIcon">
              <i class="fa fa-exclamation-triangle"></i>
            </span>
          </div>

          <div class="form form-inline" [ngClass]="(ShowLeverage == false)?'hidelevg' : 'showlevg'">
            <div class="Leverageinput">
              <div> <label for="leverage">{{'trade.placeorder.Lv' | translate}}</label></div>
              <div>
                <input type="number" id="leverage" autocomplete="off" class=" form-control" min="0" max="100"
                  name="leverage" [(ngModel)]="leveragenumber" placeholder="" step="0.01">
                <button class="btn btn-sm btn-danger" type="button" (click)="cancel()"><i
                    class="fas fa-times"></i></button>
                <button class="btn btn-sm btn-success" type="submit" (click)="confirm()"><i
                    class="fas fa-check"></i></button>
              </div>

            </div>
          </div>

          <div class="mb-4 mt-1 custom-slider sld" *ngIf="showdata && usrrsklmtkey"
            [ngClass]="(ShowLeverage == false && leveragevalue >= 0)?'showlevg' : 'hidelevg'">
            <ng5-slider [(value)]="valuez" [options]="lv" (click)="getvalue(valuez)"></ng5-slider>
            <div class="leverageBtn pointer" (click)="LeverageToggle()">
              <svg viewBox="0 0 512 512.00004" xmlns="http://www.w3.org/2000/svg" style=" width: 28px; height: 15px;">
                <path
                  d="m511.132812 79.929688c-.019531-21.390626-8.367187-41.488282-23.507812-56.59375-31.226562-31.15625-81.992188-31.113282-113.183594.117187l-322.207031 323.503906c-10.480469 10.472657-18.480469 23.4375-23.136719 37.496094l-.300781.914063-28.796875 126.632812 126.984375-28.429688.945313-.3125c14.0625-4.65625 27.035156-12.648437 37.542968-23.152343l322.25-323.542969c15.113282-15.132812 23.429688-35.246094 23.410156-56.632812zm-440.714843 375.34375-13.464844-13.472657 9.722656-42.765625 46.613281 46.640625zm389.003906-346.9375-312.847656 314.105468-56.652344-56.6875 214.300781-215.160156 32.632813 32.632812 28.261719-28.261718-32.691407-32.691406 30.402344-30.519532 32.75 32.75 28.261719-28.261718-32.808594-32.808594 11.707031-11.753906c15.605469-15.625 41.023438-15.648438 56.65625-.050782 7.578125 7.5625 11.757813 17.625 11.769531 28.332032.007813 10.710937-4.152343 20.777343-11.742187 28.375zm-249.164063 363.261718h300.875v39.96875h-340.707031zm0 0" />
              </svg>
            </div>
          </div>
          <div style="display: flex;align-items: center;justify-content: space-between;">
            <div>{{'trade.placeorder.Rl' | translate}}</div>
            <div style="display: flex;" *ngIf="usrrsklmtkey" [ngClass]="(usrrsklmtkey)?'showlevg' : 'hidelevg'">0.0000 /
              {{usrrsklmtkey}} {{settledInCurrency}}
              <div class="leverageBtn pointer" (click)="openleverageModal()">
                <svg viewBox="0 0 512 512.00004" xmlns="http://www.w3.org/2000/svg" style=" width: 28px; height: 15px;">
                  <path
                    d="m511.132812 79.929688c-.019531-21.390626-8.367187-41.488282-23.507812-56.59375-31.226562-31.15625-81.992188-31.113282-113.183594.117187l-322.207031 323.503906c-10.480469 10.472657-18.480469 23.4375-23.136719 37.496094l-.300781.914063-28.796875 126.632812 126.984375-28.429688.945313-.3125c14.0625-4.65625 27.035156-12.648437 37.542968-23.152343l322.25-323.542969c15.113282-15.132812 23.429688-35.246094 23.410156-56.632812zm-440.714843 375.34375-13.464844-13.472657 9.722656-42.765625 46.613281 46.640625zm389.003906-346.9375-312.847656 314.105468-56.652344-56.6875 214.300781-215.160156 32.632813 32.632812 28.261719-28.261718-32.691407-32.691406 30.402344-30.519532 32.75 32.75 28.261719-28.261718-32.808594-32.808594 11.707031-11.753906c15.605469-15.625 41.023438-15.648438 56.65625-.050782 7.578125 7.5625 11.757813 17.625 11.769531 28.332032.007813 10.710937-4.152343 20.777343-11.742187 28.375zm-249.164063 363.261718h300.875v39.96875h-340.707031zm0 0" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 pt-3" *ngIf="!isLogin">
        <div class="widget welcome">
          <div class="staticContent welcome disabledHeaderAnchors">
            <h5 class="mb-2" style="color: #51cbad;">{{'trade.placeorder.Wlcm' | translate}}
              <span>{{Settings?.exchange_name}}</span></h5>
            <p>{{'trade.placeorder.WlcmMsg' | translate}}</p>
            <p *ngIf="Settings?.disable_login != 'true'">{{'trade.placeorder.WlcmMsg2' | translate}} <a href="javascript:void(0)"
                [routerLink]="['/register']">{{'trade.placeorder.Create' | translate}}</a></p>
          </div>
          <button *ngIf="Settings?.disable_login != 'true'" [routerLink]="['/login']" class="Go-btn-block iZOvud mt-2"> {{'Login' | translate}}</button>
          <button *ngIf="Settings?.disable_login != 'true'" [routerLink]="['/register']" class="GoBtn hoCLhT2 "> {{'Register' | translate}}</button>
        </div>
      </div>
      <div class="mt-2 pt-3" style="border-top: 1px solid #42404f;">
        <div>
          <h6 class="mb-2">{{'trade.placeorder.Contract.CtrtDetail' |translate}}</h6>
          <div class="robotrix">
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.CtrtDetail' |translate}}
              </div>
              <div class="robotrix-item">
                {{currContract?.contractName}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.Ltp' |translate}}
              </div>
              <div class="robotrix-item">
                {{lastTradePrice}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.Mpr' |translate}}
              </div>
              <div class="robotrix-item">
                {{markPriceTicker?.P}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.Idxp' |translate}}
              </div>
              <div class="robotrix-item">
                {{markPriceTicker?.IP}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
               {{'trade.placeorder.Contract.high' |translate}}
              </div>
              <div class="robotrix-item">
                {{stats?.max}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
               {{'trade.placeorder.Contract.low' |translate}}
              </div>
              <div class="robotrix-item">
                {{stats?.min}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.change' |translate}}
              </div>
              <div class="robotrix-item">
                {{stats?.change | number:'1.1-2'}}%
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.Oi' |translate}}
              </div>
              <div class="robotrix-item">
                {{stats?.open_interest}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.24htkovr' |translate}}
              </div>
              <div class="robotrix-item">
                {{stats?.turn_over | number:'1.1-4'}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.24hvlm' |translate}}
              </div>
              <div class="robotrix-item">
                {{stats?.volume}}
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.CtrtVl' |translate}}
              </div>
              <div class="robotrix-item">
                USD
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.FndRt' |translate}}
              </div>
              <div class="robotrix-item">
                {{fundingRateTicker| number:'1.1-4'}} %
              </div>
            </div>
            <div class="robotrix-row">
              <div class="robotrix-item">
                {{'trade.placeorder.Contract.prdfrate' |translate}}
              </div>
              <div class="robotrix-item">
                {{fundingRatepredictionTicker}}
              </div>
            </div>

            <!-- <div class="robotrix-row">
            <div class="robotrix-item">
              More...
            </div>
            <div class="robotrix-item">
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="Go-modal-container">
    <div class="Go-modal" [ngClass]="(IsModalOpen == true) ? 'IsActive' : ''">
      <div class="Go-modal-background" (click)="closeleverageModal()"></div>
      <div class="Go-modal-content">
        <div class="Go-modal-body">
          <div class="form-modal" *ngIf="allrsklmt">
            <h2 class="mb-3">
              <span class="title">{{'trade.placeorder.Modal.Header1' | translate}} {{currContract?.contractName}}
                {{'trade.placeorder.Rl' | translate}}</span>
            </h2>
            <div class="body">
              <div class="bodyText">
                <div class="">
                  <div class="posDetails v-offset-xs-1">
                    <p>{{Settings?.exchange_name}} {{'trade.placeorder.Modal.p1' | translate}}
                      {{currContract?.contractName}},
                      {{'trade.placeorder.Modal.p2' | translate}} <b>100 {{settledInCurrency}}</b>
                      {{'trade.placeorder.Modal.p3' | translate}}<b>0.50%</b>.</p>
                    <p>{{'trade.placeorder.Modal.p4' | translate}}<a
                        href="">{{'trade.placeorder.Modal.a' | translate}}</a>
                      {{'trade.placeorder.Modal.p5' | translate}}</p>
                    <hr>
                    <h4>{{'trade.placeorder.Modal.Header2' | translate}} ({{settledInCurrency}})</h4>
                    <div class="mb-3 mt-3" *ngIf="options.stepsArray && risklimitdata">
                      <ng5-slider [(value)]="value" [options]="options" (click)="slideer()" ></ng5-slider>
                    </div>
                    <div class="grid ">
                      <table class="table table-striped ">
                        <thead></thead>
                        <tbody>
                          <tr class="gridRow">
                            <td class="gridCell"></td>
                            <td class="gridCell">{{'trade.placeorder.Modal.T1' | translate}}</td>
                            <td class="gridCell">{{'trade.placeorder.Modal.T2' | translate}}</td>
                            <td class="gridCell">{{'trade.placeorder.Modal.T3' | translate}}</td>
                          </tr>
                          <tr class="gridRow" *ngIf="usrrsklmt">
                            <td class="gridCell">{{'trade.placeorder.Modal.T4' | translate}}</td>
                            <td class="gridCell">{{usrrsklmtkey}} {{settledInCurrency}}</td>
                            <td class="gridCell">{{usrrsklmt.mm}}%</td>
                            <td class="gridCell">{{usrrsklmt.im}}%</td>
                          </tr>
                          <tr class="gridRow" *ngIf="newrisklimitdata">
                            <td class="gridCell">{{'trade.placeorder.Modal.T5' | translate}}</td>
                            <td class="gridCell">{{value}} {{settledInCurrency}}</td>
                            <td class="gridCell">{{newrisklimitdata.mm}}%</td>
                            <td class="gridCell">{{newrisklimitdata.im}}%</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div> <i class="fa fa-close cornerClose"></i>
              <div class="buttons pin-bottom">
                <button class="btn btn-danger" (click)="closeleverageModal()"><i class="fa fa-close"></i>
                  {{'trade.placeorder.Modal.CnlBtn' | translate}}</button>
                <button class="btn btn-primary ml-md-3" *ngIf="isselectnewElementdisable == false"
                  disabled>{{'trade.placeorder.Modal.BtnDis' | translate}}</button>
                <button class="btn btn-primary ml-md-3" (click)="Updaterisklimit(value)"
                  *ngIf="isselectnewElementdisable == true">{{'trade.placeorder.Modal.Btn' | translate}} {{value}} XBT
                  {{'trade.placeorder.Tab1' | translate}}</button>
              </div>
            </div>
          </div>
          <div class="form-modal" *ngIf="!allrsklmt && !newrisklimitdata">
            <svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
              <polygon fill="transparent" stroke="#51cbad" stroke-width="1" points="16,0 32,32 0,32"></polygon>
            </svg>
          </div>
        </div>

        <button type="button" class="modal-close is-large" aria-label="close" (click)="closeleverageModal()"></button>
      </div>
    </div>
  </div>

  <div class="Go-modal-container">
    <div class="Go-modal" [ngClass]="(placeOrderModalStatus == true) ? 'IsActive' : ''">
      <div class="Go-modal-background" (click)="closePlaceOrderModal()"></div>
      <div class="Go-modal-content" style="width: 580px">
        <div class="Go-modal-header text-center">
          <strong>{{'trade.placeorder.Modal.cnfmordr' |translate}}</strong>
        </div>
        <div class="Go-modal-body" style="padding: 1rem 1rem;">
          <div class="mb-2">
            <h5><span
                [ngClass]="(placeOrderMode == 'Buy')?'Go-green' : 'Go-red'">{{(placeOrderMode == 'Buy') ? 'Buy': 'Sell'}}</span>
              {{OrderType}}</h5>
            <hr class="my-2" />
            <div class="mb-2">
              <h6 style="font-weight: normal; margin-bottom: 5px; line-height: 1.2;"><span
                  [ngClass]="(placeOrderMode == 'Buy')?'Go-green' : 'Go-red'">{{(placeOrderMode == 'Buy') ? 'Buy': 'Sell'}}</span>&nbsp;{{orderpayload.orderQty}}
                Contract of
                {{currContract?.contractName}} at
                <span *ngIf="OrderType == 'Market' || OrderType == 'Stop Market'">Market</span>
                <span *ngIf="OrderType == 'Limit' || OrderType == 'Stop Limit'">{{orderpayload.price}}</span>.
              </h6>
              <h6 style="font-weight: normal; margin-bottom: 5px; line-height: 1.2;"
                *ngIf="OrderType == 'Stop Limit' || OrderType == 'Stop Market' || OrderType == 'Take Profit Limit' || OrderType == 'Take Profit Market'">
                {{'trade.placeorder.trgr' |translate}}: {{triggerMode}} @
                {{orderpayload.stopPrice}} and
                {{(isBelow == true) ? 'below': 'above'}}.
              </h6>
            </div>
            <div class="palceOdPopLv-Panel mb-2">
              <div class="mb-2">{{'trade.placeorder.Modal.urpstion' |translate}}: {{currContract?.contractName}}</div>
              <div class="LeverageIndicator" *ngIf="showvalue >= 0">
                <span class="initialLeverageIcon" *ngIf="IsShieldHide == true">
                  <i class="fas fa-shield-alt"></i>
                </span>
                <div class="lineIndicator" [ngStyle]="{'left': rangeSliderValue}">
                  <span class="valueLabel" [ngClass]="{'textOnLeft': showvalue > 50}">{{showvalue}}x</span>
                </div>
                <span class="indicatorLabel" *ngIf="HideLeverage == false">{{'trade.placeorder.Lv' | translate}}</span>
                <span class="maintLeverageIcon">
                  <i class="fa fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <div class="wallet-bal-table">
              <div class="wallet-bal-table-row">
                <div class="wallet-bal-table-cell">{{'trade.placeorder.Ovl' |translate}}</div>
                <div class="wallet-bal-table-cell">{{estimateMargin?.orderSize}} {{currContractData?.settledIn}}</div>
              </div>
              <div class="wallet-bal-table-row">
                <div class="wallet-bal-table-cell">{{'trade.placeorder.Modal.cost' |translate}} @ {{showvalue}}x</div>
                <div class="wallet-bal-table-cell">{{estimateMargin?.costOfPlacingNewOrder}}
                  {{currContractData?.settledIn}}</div>
              </div>
              <div class="wallet-bal-table-row">
                <div class="wallet-bal-table-cell">{{'trade.placeorder.Avlbal' |translate}}</div>
                <div class="wallet-bal-table-cell">{{walletbal?.available}} {{currContractData?.settledIn}}</div>
              </div>
              <div class="wallet-bal-table-row">
                <div class="wallet-bal-table-cell">{{'trade.placeorder.Modal.pstaftrexecut' |translate}}</div>
                <div class="wallet-bal-table-cell">{{PosSizeAfterExecution}}</div>
              </div>
              <div class="wallet-bal-table-row">
                <div class="wallet-bal-table-cell">{{'trade.placeorder.Contract.Mpr' |translate}}</div>
                <div class="wallet-bal-table-cell">{{markPriceTicker?.P}} {{currContractData?.underlyingQuoteCurrency}}
                </div>
              </div>
              <div class="wallet-bal-table-row">
                <div class="wallet-bal-table-cell">{{'trade.placeorder.Modal.estlqdprice' |translate}}</div>
                <div class="wallet-bal-table-cell">{{estimateMargin?.liquidationPrice}}
                  {{currContractData?.underlyingQuoteCurrency}}</div>
              </div>
              <div class="wallet-bal-table-row">
                <div class="wallet-bal-table-cell">{{'trade.placeorder.Contract.Mpr' |translate}}/{{'trade.placeorder.Modal.estlqddiff' |translate}}</div>
                <div class="wallet-bal-table-cell">{{estMarginDiff.percentage}}%&nbsp;({{estMarginDiff.calculaed}})
                </div>
              </div>
              <div class="wallet-bal-table-row"
                *ngIf="orderpayload.timeinForce != null && orderpayload.timeinForce !== undefined && orderpayload.timeinForce !== ''">
                <div class="wallet-bal-table-cell">{{'trade.placeorder.Modal.tif' |translate}}</div>
                <div class="wallet-bal-table-cell">
                  <span *ngIf="orderpayload.timeinForce == 'GTC'">{{'trade.placeorder.GTC' | translate}}</span>
                  <span *ngIf="orderpayload.timeinForce == 'IOC'">{{'trade.placeorder.IOC' | translate}}</span>
                  <span *ngIf="orderpayload.timeinForce == 'FOK'">{{'trade.placeorder.FOK' | translate}}</span>
                  <span *ngIf="orderpayload.timeinForce == 'DO'">{{'trade.placeorder.DO' | translate}}</span>
                </div>
              </div>
            </div>

            <div class="form-group form-check">
              <label class="form-check-label">
                <input class="form-check-input" type="checkbox" name="remember" [(ngModel)]="DSACheckBoxModel">
                {{'trade.placeorder.Modal.txt' |translate}}
              </label>
            </div>
            <div class="row">
              <div class="col-sm-6 pr-md-1 mb-1">
                <button class="btn btn-danger btn-block" (click)="closePlaceOrderModal()">{{'trade.activeorders.Btndang' |translate}}</button>
              </div>
              <div class="col-sm-6 pl-md-1 mb-1">
                <button class="btn btn-primary btn-block" *ngIf="placeOrderMode != 'Sell'"
                  (click)="placeBuyOrder()">{{'trade.placeorder.Buy' |translate}}</button>
                <button class="btn btn-primary btn-block" *ngIf="placeOrderMode != 'Buy'"
                  (click)="placeSellOrder()">{{'trade.placeorder.Sell' |translate}}</button>
              </div>
            </div>
          </div>
          <button type="button" class="modal-close is-large" aria-label="close"
            (click)="closePlaceOrderModal()"></button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="IsCalcShow == true">
    <div [ngDraggable]="draggable" [position]="position" [zIndex]="zIndex" [zIndexMoving]="zIndexMoving"
      [handle]="myHandle" [preventDefaultEvent]="preventDefaultEvent" [trackPosition]="trackPosition"
      class="drag-block-lg calc-modal" *ngIf="IsCalcShow == true">
      <div class="calc-modal-content">
        <div class="widget-card-header p-0 drag-block-handle" #myHandle>
          <ul class="nav nav-pills header-tabs hasAnchor">
            <li class="header-tabs-item">
              <a data-toggle="pill" (click)="changeCalcType('profitLoss')"
                [ngClass]="{'active': calcType == 'profitLoss' , '': (calcType == undefined || calcType == null || calcType != 'profitLoss') }">
                {{'trade.placeorder.Modal.tab1' |translate}}
              </a>
            </li>
            <li class="header-tabs-item">
              <a data-toggle="pill" (click)="changeCalcType('targetPrice')"
                [ngClass]="{'active': calcType == 'targetPrice' , 'inactive': (calcType == undefined || calcType == null || calcType != 'targetPrice') }">
                {{'trade.placeorder.Modal.tab2' |translate}}
              </a>
            </li>
            <li class="header-tabs-item">
              <a data-toggle="pill" (click)="changeCalcType('liquidationPrice')"
                [ngClass]="{'active': calcType == 'liquidationPrice' , 'inactive': (calcType == undefined || calcType == null || calcType != 'liquidationPrice') }">
                {{'trade.placeorder.Modal.tab3' |translate}}
              </a>
            </li>
          </ul>
          <div class="calc-cancelBtn" (click)="closeCalc()">
            <i class="fas fa-times"></i>
          </div>
        </div>
        <div class="calc-modal-body p-2">
          <div class="tab-content">
            <div class="calc-side-panel">
              <div style="display: flex;align-items: center;">
                <div style="width: 30%">{{'trade.placeorder.Modal.side' |translate}}:</div>
                <div style="width: 70%">
                  <div style="display: flex;align-items: center;justify-content: center;">
                    <div>{{'trade.placeorder.Modal.long' |translate}}</div>
                    <div style="margin: 0px 15px;">
                      <label class="switch" for="checkboxSide">
                        <input type="checkbox" id="checkboxSide" [(ngModel)]="sideSwitch"
                          (change)="sideSwitchfun(sideSwitch)" />
                        <div class="slider round"></div>
                      </label>
                    </div>
                    <div>{{'trade.placeorder.Modal.short' |translate}}</div>
                  </div>
                </div>
              </div>
              <div style="display: flex;align-items: center;" *ngIf="calcType == 'liquidationPrice'">
                <div style="width: 30%">{{'trade.positions.TH5' |translate}}:</div>
                <div style="width: 70%">
                  <div style="display: flex;align-items: center;justify-content: center;">
                    <div>{{'trade.placeorder.Modal.isolate' |translate}}</div>
                    <div style="margin: 0px 15px;">
                      <label class="switch" for="checkboxMargin">
                        <input type="checkbox" id="checkboxMargin" [(ngModel)]="marginSwitch"
                          (change)="marginSwitchfun(marginSwitch)" />
                        <div class="slider round"></div>
                      </label>
                    </div>
                    <div>{{'trade.placeorder.Modal.cross' |translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="calc-box-panel-container">
              <div class="calc-left-side">
                <div class="calc-form-group" *ngIf="calcType == 'profitLoss'">
                  <label for="Quantity">{{'trade.placeorder.Qty' |translate}}:</label>
                  <input type="number" class="form-control" id="Quantity" step="1" [(ngModel)]="calcQuantity"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()" (keypress)="noDecimal($event)">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'liquidationPrice'">
                  <label for="Quantity">{{'trade.placeorder.Qty' |translate}}:</label>
                  <input type="number" class="form-control" id="Quantity" step="1" [(ngModel)]="calcLPQuantity"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()" (keypress)="noDecimal($event)">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'profitLoss'">
                  <label for="EntryPrice">{{'trade.positions.TH2' |translate}}:</label>
                  <input type="number" class="form-control" id="EntryPrice" step="{{currContractData?.minPriceINC}}" [(ngModel)]="calcEntryPrice"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()" (keyup)="checkIncrement($event)">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'targetPrice'">
                  <label for="EntryPrice">{{'trade.positions.TH2' |translate}}:</label>
                  <input type="number" class="form-control" id="EntryPrice" step="{{currContractData?.minPriceINC}}" [(ngModel)]="calcTPEntryPrice"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()" (keyup)="checkIncrement($event)">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'liquidationPrice'">
                  <label for="EntryPrice">{{'trade.positions.TH2' |translate}}:</label>
                  <input type="number" class="form-control" id="EntryPrice" step="{{currContractData?.minPriceINC}}" [(ngModel)]="calcLPEntryPrice"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()" (keyup)="checkIncrement($event)">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'profitLoss'">
                  <label for="ExitPrice">{{'trade.placeorder.Modal.cross' |translate}}:</label>
                  <input type="number" class="form-control" id="ExitPrice" step="{{currContractData?.minPriceINC}}" [(ngModel)]="calcExitPrice"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()" (keyup)="checkIncrement($event)">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'profitLoss'">
                  <label for="Leverage">{{'trade.placeorder.Lv' |translate}}:</label>
                  <input type="number" class="form-control" id="Leverage" step="0.01" [(ngModel)]="calcLeverage"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'targetPrice'">
                  <label for="Leverage">{{'trade.placeorder.Lv' |translate}}:</label>
                  <input type="number" class="form-control" id="Leverage" step="0.01" [(ngModel)]="calcTPLeverage"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'liquidationPrice' && calcMargin == 'Isolated'">
                  <label for="Leverage">{{'trade.placeorder.Lv' |translate}}:</label>
                  <input type="number" class="form-control" id="Leverage" step="0.01" [(ngModel)]="calcLPLeverage"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'targetPrice'">
                  <label for="ROE">ROE %:</label>
                  <input type="number" class="form-control" id="ROE" step="0.5" [(ngModel)]="calcROE"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()">
                </div>
                <div class="calc-form-group" *ngIf="calcType == 'liquidationPrice' && calcMargin == 'Cross'">
                  <label for="Wallet">{{'Menu4' |translate}}:</label>
                  <!-- <input type="number" class="form-control" id="Wallet" value="{{walletbal.available}}" (focus)="selectAvailableBalance('true')"
                  (change)="selectAvailableBalance('true')" *ngIf="IsCalcWalltInput == false">
                 (blur)="selectAvailableBalance('false')" *ngIf="IsCalcWalltInput == true"-->
                  <input type="number" class="form-control" id="Wallet" [(ngModel)]="calcWallet"
                    (keyup)="calculateEquations()" (blur)="calculateEquations()" (change)="calculateEquations()">
                </div>
              </div>
              <div class="calc-right-side">
                <div class="wallet-bal-table">
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'profitLoss'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.positions.TH5' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedMargin)}}</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'profitLoss'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.EtryVlu' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedEntryValue)}}</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'targetPrice'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.positions.TH2' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedEntryPrice)}}</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'targetPrice'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.tab2' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedTargetPrice)}}</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'profitLoss'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.extvlu' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedExitPrice)}}</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'profitLoss'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.tab1' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedProfitLoss)}}</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'profitLoss'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.tab1' |translate}} %</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedProfitLossPercentage)}}%</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'targetPrice'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.tab1' |translate}} %</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedTPProfitLossPercentage)}}%</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'profitLoss'">
                    <div class="wallet-bal-table-cell text-left">ROE %</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedROEPercentage)}}%</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'targetPrice'">
                    <div class="wallet-bal-table-cell text-left">ROE %</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedTPROEPercentage)}}%</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'liquidationPrice'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.Currqty' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedCurrentQty)}}</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'liquidationPrice'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.RsltQty' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedResultingQty)}}</div>
                  </div>
                  <div class="wallet-bal-table-row" *ngIf="calcType == 'liquidationPrice'">
                    <div class="wallet-bal-table-cell text-left">{{'trade.placeorder.Modal.tab3' |translate}}</div>
                    <div class="wallet-bal-table-cell text-right">{{checkIsNaN(calculatedLiquidationPrice)}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="calc-bottom-panel">
              <div>
                {{'trade.placeorder.Modal.postion' |translate}}:
                <span *ngIf="currentContractPosSize == null && currentContractPosSize === undefined">
                  {{0}}
                </span>
                <span *ngIf="currentContractPosSize != null && currentContractPosSize !== undefined">
                  {{currentContractPosSize}}
                </span>
              </div>
              <div>
                {{'trade.placeorder.Modal.contract' |translate}}: {{currContract?.contractName}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
