<div class="widget-card orderBook">
  <div class="widget-card-header">
    <div>{{'trade.orderheader' | translate}}</div>
  </div>
  <div class="widget-card-body">
    <div class="asks">
      <div class="Go-table-sm animsOn">
        <div class="Go-table-sm-header">
          <div class="Go-table-sm-row">
            <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
              {{'trade.orderbook.Head1' | translate}}
            </div>
            <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
              {{'trade.orderbook.Head2' | translate}}
            </div>
            <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
              {{'trade.orderbook.Head3' | translate}}
            </div>
          </div>
        </div>
        <div class="Go-table-sm-body" style="display: table-cell; vertical-align: bottom; height: calc(25vh - 68px); width: 100vh;">
          <div id="messageBody" style="max-height: calc(25vh - 68px); overflow: auto;">
            <div *ngFor="let item of sellOrdersToBeShown | keyvalue| orderbyNumber: 'key':'number':'desc'; index as i;" class="tbl-striped">
              <div class="Go-table-sm-row" (click)="getSelectedOrder(item)"
              [ngClass]="(webSocketService.activePricePoint[item?.key] !== undefined) ? 'hasCancelBtn':''">
                <div class="Go-table-sm-item Go-red right-aligned-item" style="width:33.333%">
                  {{item?.key | addDecimal}}
                </div>
                <div class="Go-table-sm-item right-aligned-item" style="width:33.333%" id="sellBlink{{item?.key}}"
                [ngClass]="{'blinker_danger': SellTickerMinus == item?.key, 'blinker_success': SellTickerPlus == item?.key}">
                  {{item?.value?.q}}
                </div>
                <div class="Go-table-sm-item right-aligned-item" style="width:33.333%;">
                  <div style="position: relative; padding: 1px 0px; width:100%; text-align: right; z-index: 1" (window:resize)="onResize($event)"
                    id="Orderbook">
                    <div class="sell">
                      {{ item?.value?.cs}}
                    </div>
                    <div class="mx_1d45d high-color"
                      style="height:18px;background-color:rgba(255, 0, 0, 0.25); position: absolute; top:0px; z-index: -1"
                      [style.width.px]="(maxWithOrderbook/100) * ((item?.value?.cs / this.maxValue) * 100)">
                    </div>
                  </div>
                  <div class="orderCancelBtn" *ngIf="webSocketService.activePricePoint[item?.key] !== undefined" (click)="cancelSelectedOrder(item.key)"><i class="fas fa-times cancelOrderBookOrder"></i></div>
                  <!-- <div *ngIf="selectedPriceKey == item?.key" (click)="cancelSelectedOrder(item.key)"><i class="fas fa-times cancelOrderBookOrder"></i></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lastPriceWidget text-center" style="height: 50px;display: flex;
    align-items: center;
    justify-content: center;">
      <div class="limits nowrap">
        <div *ngIf="lastTradePrice" [ngClass]="(lastTradePriceChange == 'low' || lastTradePriceChange == 'stillLow')?'Go-red' : 'Go-green'" style="font-size: 17px; font-weight: bold;">{{lastTradePrice?.p}}
          <i class="fas" [ngClass]="{'fa-sort-down': lastTradePriceChange == 'stillLow', 'fa-sort-up': lastTradePriceChange == 'stillHigh'}"  *ngIf="(lastTradePriceChange == 'stillLow' || lastTradePriceChange == 'stillHigh')"></i>
          <i class="fas" [ngClass]="(lastTradePriceChange == 'low')?'fa-arrow-down' : 'fa-arrow-up'" *ngIf="lastTradePriceChange != 'stillLow' && lastTradePriceChange != 'stillHigh' && lastTradePriceChange != ''"></i>
        </div>
        <div>
          <a href="" class="text-muted" tooltip="Index Price: The price of the underlyiing asset. This is the .BXBT price.click the view historical data.">
            <span>
              <i class="fas fa-globe-asia"></i> {{markPriceTicker?.IP}}</span></a> /
              <span tooltip="Mark Price: The is the current market price. Click for more details.">
                <a >{{markPriceTicker?.P}}</a>
              </span>

        </div>
      </div>
    </div>
    <div class="bids">
      <div class="Go-table-sm animsOn">
        <div class="Go-table-sm-body" style="height: calc(25vh - 68px); overflow: auto; position: relative;">
          <div *ngFor="let item of buyOrdersToBeShown | keyvalue | orderbyNumber: 'key':'number':'desc'" class="tbl-striped">
            <div class="Go-table-sm-row animsOn-row" (click)="getSelectedOrder(item)"
            [ngClass]="(webSocketService.activePricePoint[item?.key] !== undefined) ? 'hasCancelBtn':''">
              <div class="Go-table-sm-item Go-green animsOn-item right-aligned-item" style="width:33.333%">
                {{item?.key | addDecimal}}
              </div>
              <div class="Go-table-sm-item animsOn-item right-aligned-item" style="width:33.333%" id="buyBlink{{item?.key}}"
              [ngClass]="{'blinker_danger': BuyTickerMinus == item?.key, 'blinker_success': BuyTickerPlus == item?.key }">
                {{item?.value.q}}
              </div>
              <div class="Go-table-sm-item animsOn-item right-aligned-item" style="width:33.333%">
                <div style="position: relative; padding: 1px 0px; width:100%; text-align: right; z-index: 1;" (window:resize)="onResize($event)"
                  id="Orderbook">
                  <div>
                    {{ item?.value?.cs}}
                  </div>
                  <div class="mx_1d45d high-color"
                    style="height:18px;background-color:rgba(40, 167, 69, 0.25); position: absolute; top:0px; z-index: -1"
                    [style.width.px]="(maxWithOrderbook/100) * ((item?.value?.cs / this.maxValue) * 100)">
                  </div>
                </div>
                <div class="orderCancelBtn" *ngIf="webSocketService.activePricePoint[item?.key] !== undefined" (click)="cancelSelectedOrder(item.key)"><i class="fas fa-times cancelOrderBookOrder"></i></div>
                <!-- <div *ngIf="selectedPriceKey == item?.key" (click)="cancelSelectedOrder(item.key)"><i class="fas fa-times cancelOrderBookOrder"></i></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
