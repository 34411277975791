import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(public apiService: ApiService) { }
  Aboutus: any;
  ngOnInit() {
    this.aboutus();
  }
async aboutus(){
  let result = await this.apiService.getaboutus().catch(err => {
    this.apiService.ShowToastrAlert('', err.data, 'error');
  });
  if(result == undefined || result== null) {
    return
    }
    if (result.data !== undefined && result.data != null) {
      this.Aboutus = result.data;
    }
}
}
