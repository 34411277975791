import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpModule } from '@angular/http';
import { OrderbookComponent } from './exchange/trade/orderbook/orderbook.component';
import { PlaceOrderComponent } from './exchange/trade/place-order/place-order.component';
import { RecentTradesComponent } from './exchange/trade/recent-trades/recent-trades.component';
import { DepthChartComponent } from './exchange/trade/depth-chart/depth-chart.component';
import { AllSectionsComponent } from './exchange/trade/all-sections/all-sections.component';
import { FormsModule } from '@angular/forms';
import { TradeComponent } from './exchange/trade/trade/trade.component';
import { PositionsComponent } from './exchange/trade/positions/positions.component';
import { ClosedPositionsComponent } from './exchange/trade/closed-positions/closed-positions.component';
import { ActiveOrdersComponent } from './exchange/trade/active-orders/active-orders.component';
import { StopsComponent } from './exchange/trade/stops/stops.component';
import { FillsComponent } from './exchange/trade/fills/fills.component';
import { TradeOrderHistoryComponent } from './exchange/trade/trade-order-history/trade-order-history.component';
import { RegisterComponent } from './exchange/user-authentications/register/register.component';
import { VerifyComponent } from './exchange/user-authentications/verify/verify.component';
import { SigninComponent } from './exchange/user-authentications/login/signin/signin.component';
import { AuthenticateComponent } from './exchange/user-authentications/login/authenticate/authenticate.component';
import { EmailOtpComponent, FormatTimePipe } from './exchange/user-authentications/login/email-otp/email-otp.component';
import { HeaderComponent } from './exchange/shared/header/header.component';
import { FooterComponent } from './exchange/shared/footer/footer.component';
import { ResetComponent } from './exchange/user-authentications/reset-password/reset/reset.component';
import { ConfirmComponent } from './exchange/user-authentications/reset-password/confirm/confirm.component';
import { TradingviewChartComponent } from './exchange/shared/tradingview-chart/tradingview-chart.component';
import { AboutUsComponent } from './exchange/legal/about-us/about-us.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { MarkdownModule } from 'ngx-markdown';
import { AngularDraggableModule } from 'angular2-draggable';
import { ContactComponent } from './exchange/legal/contact/contact.component';
import { FeesComponent } from './exchange/legal/fees/fees.component';
import { PrivacyPolicyComponent } from './exchange/legal/privacy-policy/privacy-policy.component';
import { RiskDisclosureComponent } from './exchange/legal/risk-disclosure/risk-disclosure.component';
import { CookiePolicyComponent } from './exchange/legal/cookie-policy/cookie-policy.component';
import { TermsComponent } from './exchange/legal/terms/terms.component';
import { TradingRulesComponent } from './exchange/legal/trading-rules/trading-rules.component';
import { ExchangeTradingFeesComponent } from './exchange/legal/fees/exchange-trading-fees/exchange-trading-fees.component';
import { DepositWithdrawalFeesComponent } from './exchange/legal/fees/deposit-withdrawal-fees/deposit-withdrawal-fees.component';
import { SearchFilterPipeModule } from './pipe/search-filter.pipe';
import { CurrconverterPipeModule } from './pipe/currconverter.pipe';
import { CaptchaComponent } from './exchange/shared/captcha/captcha.component';
import { SettingsService } from './services/settings.service';
import { ApiService } from './services/api.service';
import { ReferralComponent } from './exchange/user-authentications/referral/referral.component';
import { IndicesComponent } from './exchange/contracts/indices/indices.component';
import { ContractsComponent } from './exchange/contracts/contracts/contracts.component';
import { ContractsListComponent } from './exchange/contracts/contracts-list/contracts-list.component';
import { IndicesListComponent } from './exchange/contracts/indices-list/indices-list.component';
import { ContractsDetailsComponent } from './exchange/contracts/contracts-details/contracts-details.component';
import { IndicesDetailsComponent } from './exchange/contracts/indices-details/indices-details.component';
import { WebsocketService } from './services/websocket.service';
import { OrderByPipeModule } from './pipe/orderby.pipe';
import { Ng5SliderModule } from 'ng5-slider';
import { ApiDocumentationComponent } from './exchange/legal/api-documentation/api-documentation.component';
import { WebsocketApiComponent } from './exchange/legal/websocket-api/websocket-api.component';
import { PositionsSectionComponent } from './exchange/trade/positions-section/positions-section.component';
import { BlockComponent } from './exchange/user-authentications/block/block.component';
import { DecimalAllowedDirective } from './directive/decimal-allowed.directive';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OrderbyNumberPipe } from './pipe/orderby-number.pipe';
import { SidebarComponent } from './exchange/shared/sidebar/sidebar.component';
import { ContentLayoutComponent } from './exchange/shared/content-layout/content-layout.component';
import { TradingChartComponent } from './exchange/shared/trading-chart/trading-chart.component';
import { AddDecimalPipeModule } from './pipe/addDecimal.pipe';
import { RedirectCustomerComponent } from './exchange/user-authentications/redirect-customer/redirect-customer.component';
import { FeatureDisabledComponent } from './exchange/user-authentications/feature-disabled/feature-disabled.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    OrderbookComponent,
    PlaceOrderComponent,
    RecentTradesComponent,
    DepthChartComponent,
    AllSectionsComponent,
    TradeComponent,
    PositionsComponent,
    ClosedPositionsComponent,
    ActiveOrdersComponent,
    StopsComponent,
    FillsComponent,
    TradeOrderHistoryComponent,
    RegisterComponent,
    VerifyComponent,
    SigninComponent,
    AuthenticateComponent,
    EmailOtpComponent,
    ResetComponent,
    ConfirmComponent,
    TradingviewChartComponent,
    AboutUsComponent,
    FormatTimePipe,
    ContactComponent,
    FeesComponent,
    PrivacyPolicyComponent,
    RiskDisclosureComponent,
    CookiePolicyComponent,
    TermsComponent,
    TradingRulesComponent,
    ExchangeTradingFeesComponent,
    DepositWithdrawalFeesComponent,
    CaptchaComponent,
    ReferralComponent,
    ContractsComponent,
    IndicesComponent,
    ContractsListComponent,
    IndicesListComponent,
    ContractsDetailsComponent,
    IndicesDetailsComponent,
    ApiDocumentationComponent,
    WebsocketApiComponent,
    PositionsSectionComponent,
    BlockComponent,
    DecimalAllowedDirective,
    OrderbyNumberPipe,
    SidebarComponent,
    ContentLayoutComponent,
    TradingChartComponent,
    RedirectCustomerComponent,
    FeatureDisabledComponent
  ],
  imports: [
    BrowserModule,
    Ng5SliderModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      progressBar: true,
      positionClass: 'toast-bottom-right',
      closeButton: true,
      preventDuplicates: false,
      extendedTimeOut: 5000,
      maxOpened: 0
    }),
    ScrollToModule.forRoot(),
    MarkdownModule.forRoot(),
    AngularDraggableModule,
    TooltipModule,
    SearchFilterPipeModule,
    CurrconverterPipeModule,
    OrderByPipeModule,
    AddDecimalPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [ApiService, SettingsService, WebsocketService],
  bootstrap: [AppComponent]
})
export class AppModule { }
