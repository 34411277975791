<div style="overflow: auto;">
  <div class="Go-table scrollable">
    <div class="Go-table-header sm-header">
      <div class="header-item" style="width:7%" (click)="manualSorting('sym')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH1' |translate}}</span>
      </div>
      <div class="header-item" style="width:7%" (click)="manualSorting('q')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH2' | translate}}</span>
        </div>
      <div class="header-item" style="width:13%" (click)="manualSorting('p')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH3' | translate}}</span>

      </div>
      <div class="header-item" style="width:7%" (click)="manualSorting('fill')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH4' | translate}}</span>

      </div>
      <div class="header-item" style="width:7%" (click)="manualSorting('r')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH5' | translate}}</span>

      </div>
      <div class="header-item" style="width:13%" (click)="manualSorting('v')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH6' | translate}}</span>
        </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('fp')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH7' | translate}}</span>
        </div>
      <div class="header-item" style="width:8%" (click)="manualSorting('t')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH8' | translate}}</span>
        </div>
      <div class="header-item" style="width:9%" (click)="manualSorting('odst')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH9' | translate}}</span>
       </div>
      <div class="header-item" style="width:9%" (click)="manualSorting('ts')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH10' | translate}}</span>
       </div>
      <div class="header-item" style="width:7%">
        <button class="btn erqrW1" style="line-height: 10px;" (click)="cancelAllActive()"
          *ngIf="activeOrders.length !== 0 && this.cancelAllHide !== true">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="Go-table-body all-section-height" *ngIf="activeOrders">
      <div class="body-row tbl-striped" *ngFor="let item of activeOrders | OrderBy: orderbyKey:orderByType: sortOrder; let i = index;">
        <div class="body-item" style="width:7%"
          [ngClass]="(item?.s == 'S')?'Go-red-border-left' : 'Go-green-border-left'">{{item.sym}}</div>
        <div class="body-item hoverable" style="width:7%">
          <span class="editable" *ngIf="selectedOrderIds !== item.o"
            (click)="openEditOrder(item, 'size')"  [ngClass]="(item?.s == 'S')?'Go-red' : 'Go-green'"><span *ngIf="item?.s == 'S'">-</span>{{item.q}}</span>
          <span *ngIf="selectedOrderIds == item.o" class="orderBtnSection">
            <button class="closeBtn" (click)="closeEditOrder(item, 'size')"><i class="fas fa-times"></i></button>
            <input placeholder="" step="1" min="0" type="number" [(ngModel)]="updateOrderObj.size"
              style="width: 60px; text-align: right;">
            <button class="checkBtn" (click)="confirmEditOrder(item, 'size')"><i class="fas fa-check"></i></button>
          </span>
        </div>
        <div class="body-item hoverable" style="width:13%">
          <span class="editable" *ngIf="selectedOrderIdp !== item.o"
            (click)="openEditOrder(item, 'price')">{{item.p}}</span>
          <span *ngIf="selectedOrderIdp == item.o" class="orderBtnSection">
            <button class="closeBtn" (click)="closeEditOrder(item, 'price')"><i class="fas fa-times"></i></button>
            <input placeholder="" step=".5" type="number" [(ngModel)]="updateOrderObj.price"
              style="width: 60px; text-align: right;">
            <button class="checkBtn" (click)="confirmEditOrder(item, 'price')"><i class="fas fa-check"></i></button>
          </span>
        </div>
        <div class="body-item" style="width:7%">
          <span *ngIf="(item.fill) != 0">
            {{item.fill}}
          </span>
          <span *ngIf="(item.fill) == 0">-</span>
        </div>
        <div class="body-item" style="width:7%">{{item.r}}</div>
        <div class="body-item" style="width:13%">{{item.v}}</div>
        <div class="body-item" style="width:10%">
          <span *ngIf="(item.fp) != 0">
            {{item.fp}}
          </span>
          <span *ngIf="(item.fp) == 0">-</span>
        </div>
        <div class="body-item" style="width:8%">
          <span *ngIf="item.t == 'Limit'">Limit</span>
          <span *ngIf="item.t == 'Market'">Market</span>
          <span *ngIf="item.t == 'StopLimit'">Stop Limit</span>
          <span *ngIf="item.t == 'StopMarket'">Stop Market</span>
          <span *ngIf="item.t == 'TakeProfitLimit'">Take Profit Limit</span>
          <span *ngIf="item.t == 'TakeProfitMarket'">Take Profit Market</span>
        </div>
        <div class="body-item" style="width:9%">
          <span *ngIf="item.odst == 'Accepted'">New</span>
          <span *ngIf="item.odst == 'PartiallyFilled'">Partially Filled</span>
          <span *ngIf="item.odst == 'Filled'">Filled</span>
          <span *ngIf="item.odst == 'Cancelled'">Cancelled</span>
          <span *ngIf="item.odst == 'Rejected'">Rejected</span>
          <span *ngIf="item.odst == 'Expired'">Expired</span>
        </div>
        <div class="body-item" style="width:9%" tooltip="{{item.ts | date: 'MMMM d, y, h:mm:ss a'}}">
          {{item.ts | date:'h:mm a'}}
        </div>
        <div class="body-item" style="width:7%">
          <button class="btn erqrW1" *ngIf="item.odst == 'Accepted'"
            (click)="cancelOD(item.o)">{{'trade.activeorders.Btndang' | translate}}</button>
            <button class="btn bluern" *ngIf="item.odst == 'Cancelled'" (click)="clear(item.o)">clear</button>
          <button class="btn erqrW1" *ngIf="item.odst == 'PartiallyFilled'"
            (click)="cancelOD(item.o)">{{'trade.activeorders.Btndang' | translate}}</button>
            <button class="btn bluern" *ngIf="item.odst == 'Filled'" (click)="clear(item.o)">clear</button>
            <button class="btn bluern" *ngIf="item.odst == 'Rejected'" (click)="clear(item.o)">clear</button>
            <button class="btn bluern" *ngIf="item.odst == 'Expired'" (click)="clear(item.o)">clear</button>
        </div>
      </div>
    </div>
  </div>
</div>
