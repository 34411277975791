import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(public apiService: ApiService) { }
  contact: any;
  ngOnInit() {
  this.contactUS();
  }
 async contactUS() {
  let result = await this.apiService.getcontactus().catch(err => {
    this.apiService.ShowToastrAlert('', err.data, 'error');
  });
  if(result == undefined || result== null) {
    return
    }
    if (result.data !== undefined && result.data != null) {
      this.contact = result.data;
    }
  }

}
