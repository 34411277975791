<div class="widget-card">
    <div class="widget-card-header p-0">
      <ul class="nav nav-pills header-tabs hasAnchor">
        <li class="header-tabs-item">
          <a data-toggle="pill" href="#Positions" class="active">
            {{'trade.positionsection.Tab1' |translate}}
          </a>
        </li>
        <li class="header-tabs-item">
          <a data-toggle="pill" href="#ClosedPositions">
            {{'trade.positionsection.Tab2' |translate}}
          </a>
        </li>
      </ul>
    </div>
    <div class="widget-card-body p-2">
      <div class="tab-content">
        <div id="Positions" class="tab-pane active">
          <app-positions></app-positions>
        </div>
        <div id="ClosedPositions" class="tab-pane fade">
          <app-closed-positions></app-closed-positions>
        </div>
      </div>
    </div>
  </div>

