import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss']
})
export class CaptchaComponent implements OnInit {

  ImgUrl: any;
  @Input() captchaString: string;

  Settings: any;
  captchaEnabled = false;

  constructor(private apiService: ApiService) { }

  ngOnInit() {

    this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.Settings = data;
        if (this.Settings.captcha_mode.toLowerCase() === 'disabled') {
          this.captchaEnabled = false;
        } else if (this.Settings.captcha_mode.toLowerCase() === 'self') {
          this.captchaEnabled = true;
          this.getCaptchaCode();
        } else {
          this.captchaEnabled = false;
        }
      }
    });
  }

 async getCaptchaCode() {
    let result = await this.apiService.RequestCaptcha(this.captchaString).catch(err => {
      this.apiService.ShowToastrAlert('oops!', err.message, 'error');
     });
     if(result == undefined || result== null) {
       return
       }
       if (result.data != null && result.data !== undefined) {
        this.ImgUrl = 'data:image/png;base64,' + result.data;
      }
  }

}
