import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.scss']
})
export class BlockComponent implements OnInit {

  constructor(public apiService: ApiService, private route: ActivatedRoute, public router: Router) { }

  Id: any;
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.Id = params.id;
   });
  }
async block() {
  let result = await this.apiService.Blockaccount(this.Id).catch(err => {
    this.apiService.ShowToastrAlert('', err.description, 'error');
  });
  if(result == undefined || result== null) {
    return
    }
    // console.log('block', result);
    this.apiService.ShowToastrAlert('', result.message, 'success');
    this.router.navigate(['/trade']);
}

cancel() {
  this.router.navigate(['/trade']);
}
}
