import { Injectable } from '@angular/core';
import { StreamProviderService } from './stream-provider.service';
import { HistoryProviderService } from './history-provider.service';
import { Subscription } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class DatafeedService {

  datafeed: any;
  subscription: Subscription;
  chartName: any;
  supportedResolutions = ['1', '5', '15', '30', '60', '180', '240', '360', '720', 'D', '7D', '15D', '30D'];

  constructor(
    public apiService: ApiService,
    public streamProviderService: StreamProviderService,
    public histroyProviderService: HistoryProviderService
    ) {

    this.subscription = this.apiService.sendCurrentCurr.subscribe((data: any) => {
      if (data != null && data !== undefined) {
        if (data.chartName != null && data.chartName !== undefined) {
          this.chartName = data.chartName;
          // console.log('this.chartName', this.chartName);
        }
      }
    });
   }

  getChartData() {

    const config = {
      supported_resolutions: this.supportedResolutions,
      };

    const datafeed = {

      onReady: cb => {
        // console.log('=====onReady running');
        setTimeout(() => cb(config), 0);
      },

      searchSymbols: (userInput: any, exchange: any, symbolType: any, onResultReadyCallback: any) => {
        // console.log('====Search Symbols running');
      },

      resolveSymbol: (symbolName: any, onSymbolResolvedCallback: any, onResolveErrorCallback: any) => {

        // expects a symbolInfo object in response
       // const splitData = symbolName.split(/[:/]/);
        const symbolStub = {
          name: symbolName,
          chart: this.chartName,
          description: '',
          type: 'crypto',
          session: '24x7',
          timezone: 'Etc/UTC',
          ticker: symbolName,
          exchange: symbolName,
          minmov: 1,
          pricescale: 100000000,
          has_intraday: true,
          intraday_multipliers: ['1', '5', '15', '30', '60', '180', '240', '360', '720', '1440', '10080', '21600', '43200'],
          supported_resolution: this.supportedResolutions,
          volume_precision: 8,
          data_status: 'streaming',
          // has_empty_bars: true,
        };
        // console.log('%%%%%%%%%%%%%%%', symbolStub );
        // if (split_data[2].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY/)) {
        //   symbolStub.pricescale = 100
        // }

        setTimeout(() => {
          onSymbolResolvedCallback(symbolStub);
          // console.log('Resolving that symbol....', symbolStub);
        }, 0);

        // onResolveErrorCallback('Not feeling it today')
      },

      getBars: async (symbolInfo, resolution, from, to, onHistoryCallback, onErrorCallback, firstDataRequest, limit) => {
        // console.log('--+-+-==(o)==+-+--');

        // console.log('function args',arguments)
        this.histroyProviderService.historyProvider.getBars(symbolInfo, resolution, from, to, firstDataRequest, limit)
        .then(bars => {
          if (bars.length) {
            onHistoryCallback(bars, {noData: false});
          } else {
            onHistoryCallback(bars, {noData: true});
          }
        }).catch(err => {
          // console.log({err});
          onErrorCallback(err);
        });
      },

      subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
        // console.log('=====subscribeBars runnning');
        this.streamProviderService.streamProvider.subscribeBars(
          symbolInfo,
          resolution,
          onRealtimeCallback,
          subscribeUID,
          onResetCacheNeededCallback,
        );
      },

      unsubscribeBars: subscriberUID => {
        // console.log('=====unsubscribeBars running');
        this.streamProviderService.streamProvider.unsubscribeBars(subscriberUID);
      },

      calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
        // optional
        // console.log('=====calculateHistoryDepth running');
        // while optional, this makes sure we request 24 hours of minute data at a time
        // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
        // return resolution < 60 ? {resolutionBack: 'D', intervalBack: '1'} : undefined;
      },

      getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
        // optional
        // console.log('=====getMarks running');
      },

      getTimeScaleMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
        // optional
        // console.log('=====getTimeScaleMarks running');
      },

      getServerTime: cb => {
        // console.log('=====getServerTime running');
      }

    };

    this.datafeed = datafeed;

    return this.datafeed;
  }

}
