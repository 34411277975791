import { Component, OnInit, NgZone } from '@angular/core';
import { WebsocketService } from 'src/app/services/websocket.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-fills',
  templateUrl: './fills.component.html',
  styleUrls: ['./fills.component.scss']
})
export class FillsComponent implements OnInit {
  orderFills = [];
  orderbyKey: any;

  sortOrder: string = '';
  orderByType: any;
  constructor(public webSocketService: WebsocketService, public zone: NgZone, public apiService: ApiService) { }

  ngOnInit() {
    this.webSocketService.myTradesSub.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
          data.forEach((element: any) => {
            this.orderFills.unshift(element);
          });
        }
      });
    });
  }
  manualSorting(data: any) {
    this.orderbyKey = data;

    if (this.sortOrder == 'desc') {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = 'desc';
    }
  }
}
