import {
  Component,
  OnInit,
  VERSION,
  ɵConsole,
  ViewChild
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  CaptchaComponent
} from '../../shared/captcha/captcha.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerationDetails = {
    email: '',
    password: '',
    country: 'DC',
    // mobile: '',
    // firstName: '',
    // middleName: '',
    // lastName: '',
    referral: '',
    captcha: ''
  };
  ResendDetail = {
    email: ''
  };

  tempPassword: string;
  tempPasswordValidateError = '';

  emailValidateError = '';
  passwordValidateError = '';
  countryValidateError = '';
  mobileValidateError = '';
  firstNameValidateError = '';
  lastNameValidateError = '';
  captchaValidateError = '';
  acceptTermsError = '';

  @ViewChild(CaptchaComponent)
  private callCaptchaComponent: CaptchaComponent;

  capString = 'purpose=SignUp&h=80&w=370';

  IsRegistered = false;
  SignUpProcessing = false;
  resendProcessing = false;
  ImgUrl: any;
  countryList: any;
  referralId: any;

  countryDetails: any;

  countryCode: any;

  reg: RegExp = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);

  regexPassword: RegExp = new RegExp(/^((?=.*\d)(?=.*[A-Z]).{6,20})$/);

  objectKeys = Object.keys;
  Settings: any;
  captchaEnabled = false;

  sentEmail = false;
  constructor(public apiService: ApiService, public router: Router) {}

  ngOnInit() {
    this.GetCountryList();
    if (localStorage.getItem('referral:ID') != null) {
      this.registerationDetails.referral = localStorage.getItem('referral:ID');
    }

    this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.Settings = data;
        if(this.Settings.disable_login === 'true') {
          this.router.navigate(['/feature-disabled']);
        } else if (this.Settings.captcha_mode.toLowerCase() === 'disabled') {
          this.captchaEnabled = false;
        } else if (this.Settings.captcha_mode.toLowerCase() === 'self') {
          this.captchaEnabled = true;
        } else {
          this.captchaEnabled = false;
        }
      }
    });

  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  emailValidate(): boolean {
    if (this.registerationDetails.email === undefined || this.registerationDetails.email == null ||
      this.reg.test(this.registerationDetails.email) === false) {
      this.emailValidateError = 'Enter a valid email address.';
      return false;
    } else {
      this.emailValidateError = '';
      return true;
    }
  }

  tempPasswordValidate(): boolean {
    if (this.tempPassword === undefined || this.tempPassword == null || this.tempPassword.trim() === '') {
      this.tempPasswordValidateError = 'Field can\'t be empty.';
      return false;
    } else if (this.regexPassword.test(this.tempPassword) == false) {
      this.tempPasswordValidateError = 'Password must have min 6 characters, one uppercase and one numaric character.';
      return false;
    } else if (this.registerationDetails.password !== this.tempPassword) {
      this.tempPasswordValidateError = '';
      this.passwordValidateError = 'Password does not match.';
      return false;
    } else {
      this.passwordValidateError = '';
      this.tempPasswordValidateError = '';
      return true;
    }
  }

  passwordValidate(): boolean {
    if (this.registerationDetails.password === undefined || this.registerationDetails.password == null ||
      this.registerationDetails.password.trim() === '') {
      this.passwordValidateError = 'Field can\'t be empty.';
      return false;
    } else if (this.registerationDetails.password !== this.tempPassword) {
      this.passwordValidateError = 'Password does not match.';
      return false;
    } else {
      this.passwordValidateError = '';
      return true;
    }
  }


  countryValidate(): boolean {
    if (this.registerationDetails.country === undefined || this.registerationDetails.country == null ||
      this.registerationDetails.country.trim() === '') {
      this.countryValidateError = 'Country can\'t be empty.';
      return false;
    } else if (this.registerationDetails.country == 'DC') {
      this.countryValidateError = 'Please select valid country.';
      return false;
    } else {
      this.countryValidateError = '';
      this.GetCountryCode();
      return true;
    }
  }

  // mobileValidate(): boolean {
  //   if (this.registerationDetails.mobile === undefined || this.registerationDetails.mobile == null ||
  //     this.registerationDetails.mobile.trim() === '') {
  //     this.mobileValidateError = 'Moblie number can\'t be empty';
  //     return false;
  //   } else {
  //     this.mobileValidateError = '';
  //     return true;
  //   }
  // }

  // firstNameValidate(): boolean {
  //   if (this.registerationDetails.firstName === undefined || this.registerationDetails.firstName == null ||
  //     this.registerationDetails.firstName.trim() === '') {
  //     this.firstNameValidateError = 'First name can\'t be empty';
  //     return false;
  //   } else {
  //     this.firstNameValidateError = '';
  //     return true;
  //   }
  // }

  // lastNameValidate(): boolean {
  //   if (this.registerationDetails.lastName === undefined || this.registerationDetails.lastName == null ||
  //     this.registerationDetails.lastName.trim() === '') {
  //     this.lastNameValidateError = 'Last name can\'t be empty';
  //     return false;
  //   } else {
  //     this.lastNameValidateError = '';
  //     return true;
  //   }
  // }

  captchaValidate(): boolean {
    if (this.captchaEnabled !== false) {
      if (this.registerationDetails.captcha === undefined || this.registerationDetails.captcha == null ||
        this.registerationDetails.captcha.trim() === '') {
        this.captchaValidateError = 'Captcha can\'t be empty';
        return false;
      } else {
        this.captchaValidateError = '';
        return true;
      }
    } else {
      return true;
    }
  }

  IfWarnChecked(): boolean {
    const element = document.getElementById('IfWarnChecked') as HTMLInputElement;
    if (element.checked === false) {
      this.acceptTermsError = 'Please accept first our terms of services and policies.';
      return false;
    } else {
      this.acceptTermsError = '';
      return true;
    }
  }

 async RegisterNow() {
    //   if (this.firstNameValidate() === false || this.lastNameValidate() === false ||
    //   this.emailValidate() === false || this.countryValidate() === false ||
    //   this.mobileValidate() === false || this.tempPasswordValidate() === false || this.passwordValidate() === false ||
    //   this.captchaValidate() === false) {
    //   return;
    // }
    if (this.emailValidate() === false || this.countryValidate() === false ||
      this.tempPasswordValidate() === false || this.passwordValidate() === false ||
      this.captchaValidate() === false) {
      return;
    } else if (this.IfWarnChecked() === false) {
      return;
    } else {
      this.SignUpProcessing = true;
      this.registerationDetails.country = this.registerationDetails.country.toUpperCase();
      sessionStorage.setItem('Email', this.registerationDetails.email);

      let result = await this.apiService.Register_Account(this.registerationDetails).catch(err => {
        this.apiService.ShowToastrAlert('', err.description, 'error');
        this.IsRegistered = false;
        this.SignUpProcessing = false;
        if (this.captchaEnabled !== false) {
          this.callCaptcha();
        }
        this.registerationDetails.captcha = null;
      });
      if(result == undefined || result== null) {
        return
        }
        this.apiService.ShowToastrAlert('', result.data, 'success');
        this.IsRegistered = true;
        this.SignUpProcessing = false;
    }
  }

  GetCountryList() {
    const list = '';
    this.apiService.RequestCountryList(list).then((result: any) => {
      if (result.message.toLowerCase() == 'success') {
        this.countryList = result.data;
      }
    }, (err: any) => {
      console.error('result', err);
    });
  }

  callCaptcha() {
    if (this.callCaptchaComponent != null && this.callCaptchaComponent !== undefined) {
      this.callCaptchaComponent.getCaptchaCode();
    }

  }

  GetCountryCode() {
    this.countryCode = this.registerationDetails.country;
    Object.entries(this.countryList).forEach(([key, value]) => {
      if (this.countryCode == key) {
        this.countryDetails = value;
      }
    });
    // this.countryCode = '/' + this.registerationDetails.country;
    // this.apiService.RequestCountryList(this.countryCode).then((result: any) => {
    //   this.countryDetails = result.data;
    //   console.log('---', this.countryDetails);
    // });
  }

 async resendemailVer() {
    this.ResendDetail.email = sessionStorage.getItem('Email');
    this.resendProcessing = true;
    let result = await this.apiService.ResendEmail(this.ResendDetail).catch(err => {
      this.sentEmail = false;
      this.apiService.ShowToastrAlert('', err.description, 'error');
      this.resendProcessing = false;
    });
    if(result == undefined || result== null) {
      return
      }
      this.apiService.ShowToastrAlert('', 'Email Sent', 'success');
      this.sentEmail = true;
      this.resendProcessing = false;
  }
}
