<div class="Go-table scrollable">
        <div class="Go-table-header sm-header">
          <div class="header-item" style="width:10%" (click)="manualSorting('sym')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH1' |translate}}</span>
          </div>
          <div class="header-item" style="width:10%" (click)="manualSorting('q')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH2' |translate}}</span>
           </div>
          <div class="header-item" style="width:12%" (click)="manualSorting('eq')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH4' |translate}}/{{'trade.activeorders.TH5' |translate}}</span>
            </div>
          <div class="header-item" style="width:10%" (click)="manualSorting('ep')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.fills.TH2' |translate}}</span>
           </div>
          <div class="header-item" style="width:10%" (click)="manualSorting('p')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH3' |translate}}</span>
           </div>
          <div class="header-item" style="width:8%" (click)="manualSorting('v')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.fills.TH3' |translate}}</span>
            </div>
          <div class="header-item" style="width:10%" (click)="manualSorting('t')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH8' |translate}}</span>
            </div>
            <div class="header-item" style="width:10%" (click)="manualSorting('fee')">
              <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'Wallet.Th2' |translate}}</span>
              </div>
              <div class="header-item" style="width:12%" (click)="manualSorting('rpnl')">
                <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.positions.TH7' |translate}}</span>
                </div>
          <div class="header-item" style="width:8%" (click)="manualSorting('ts')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH10' |translate}}</span>
            </div>
        </div>
        <div class="Go-table-body all-section-height" *ngIf="orderFills">
          <div class="body-row tbl-striped" *ngFor="let item of orderFills | OrderBy: orderbyKey:orderByType: sortOrder; let i = index;">
            <div class="body-item" style="width:10%" [ngClass]="(item?.s == 'S')?'Go-red-border-left' : 'Go-green-border-left'">{{item.sym}}</div>
            <div class="body-item" style="width:10%" [ngClass]="(item?.s == 'S')?'Go-red' : 'Go-green'"><span *ngIf="item.s == 'S'">-{{item.q}}</span>
              <span *ngIf="item.s != 'S'">{{item.q}}</span>
            </div>
            <div class="body-item" style="width:12%">{{item.eq}}/{{item.r}}</div>
            <div class="body-item" style="width:10%">{{item.ep}}</div>
            <div class="body-item" style="width:10%">{{item.p}}</div>
            <div class="body-item" style="width:8%">{{item.v}}</div>
            <div class="body-item" style="width:10%">
              <span *ngIf="item.lqid === ''">{{item.t}}</span>
              <span *ngIf="item.lqid !== ''">Liquidation</span>
            </div>
            <div class="body-item" style="width:10%">
              <span *ngIf="item.lqid === ''">{{item.fee}}</span>
              <span *ngIf="item.lqid !== ''">0</span>
            </div>
            <div class="body-item" style="width:12%">
              <span *ngIf="item.lqid === ''">{{item.rpnl}}</span>
              <span *ngIf="item.lqid !== ''">0</span>
            </div>
            <div class="body-item" style="width:8%"  tooltip="{{item.ts * 1000 | date: 'MMMM d, y, h:mm:ss a'}}">{{item.ts * 1000 | date: 'h:mm a'}}</div>
          </div>
        </div>
      </div>
