import {
  Component,
  OnInit,
  OnDestroy,
  NgZone
} from '@angular/core';
import {
  Subscription
} from 'rxjs';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  AuthService
} from 'src/app/guard/auth.service';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import {
  SankeyNodeObject
} from 'highcharts';
import {
  Options
} from 'ng5-slider';
import {
  element,
  Key
} from 'protractor';
// import { ConsoleReporter } from 'jasmine';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit, OnDestroy {
  value: any;
  options: Options = {
    showTicksValues: true,
    stepsArray: [
      // {value: 2},
      // {value: 3},
      // {value: 4},
      // {value: 4},
      // {value: 5},
      // {value: 6},
      // {value: 7},
      // {value: 8},
      // {value: 9}
    ]
  };
  valuez: any;
  lv: Options = {
    showTicksValues: true,
    stepsArray: [
      // {value: 0, legend: 'Very poor'},
      // {value: 1},
      // {value: 2},
      // {value: 3},
      // {value: 5},
    ]
  };


  showvalue = 0;
  allSettingsSub: Subscription;
  ShowLeverage = false;
  crosslimit = false;
  allSettings: any;
  IsModalOpen = false;
  rangeValue: any;
  rangeSliderValue = '0%';
  valueLabel = '0.00x';

  HideLeverage = false;
  IsShieldHide = false;
  showDisplayQty = false;

  OrderType: string;
  otherOrderType: string;
  currWalletBal: any;
  allrsklmt: any;

  SelectedTM = 'Good till Cancelled';


  leveragenumber: any;
  walletbal: any;
  settledInCurrency: any;
  currentContractDetails: any;

  fundingRateTicker: any;
  fundingRatepredictionTicker: any;
  currContract: any;
  // contractSelector: any;
  contractsList: any;
  usrrsklmt: any;
  isselectnewElementdisable = false;
  newrisklimitdata: any;
  updateddata: any;
  Settings: any;
  usrrsklmtkey: any;
  isLogin = false;
  lastTradePrice = 0;
  mlcalcx3: number;
  mlfloor: any;
  mlceil: any;
  cross: any;
  currslidevalue: any;
  showdata = false;
  leveragevalue: any;

  orderpayload = {
    symbol: '',
    side: '',
    orderQty: 1,
    price: 0,
    stopPrice: 0,
    orderType: '',
    timeinForce: 'GTC',
    triggerType: 'LastPrice',
    isReduceOnly: false,
    IsCloseOnTrigger: false,
    IsHiddenOrder: false,
    IsPostOnly: false,

  };
  orderselected: any;
  selectedorder: Subscription;
  sendCintractsub: Subscription;
  currContractData: any;
  timer: any;
  currContDetailsSub: Subscription;
  placeOrderModalStatus = false;
  placeOrderMode: any;
  LTP: any;
  estimateMargin: any;
  currentContractPosSize: any;
  PosSizeAfterExecution: any;
  estMarginDiff = {
    percentage: 0,
    calculaed: 0,
  };
  triggerMode: string;
  postOnlyStatus = false;
  IshiddenDisabled = false;
  DSACheckBoxModel = false;
  DSACheckBoxStatus = 'Shown';
  IsDisabledBuy = false;
  IsDisabledSell = false;

  isBelow = false;
  markPriceTicker: any;

  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  IsCalcShow = false;


  draggable = true;
  useHandle = false;
  zIndex;
  zIndexMoving;
  preventDefaultEvent = false;
  trackPosition = true;
  position = {
    x: 10,
    y: 10
  };

  calcType = 'profitLoss';

  calcQuantity: number;
  calcLPQuantity: number;
  calcEntryPrice: number;
  calcTPEntryPrice: number;
  calcLPEntryPrice: number;
  calcExitPrice: number;
  calcLeverage: number;
  calcTPLeverage: number;
  calcLPLeverage: number;
  calcROE: number;
  calcWallet: number;

  calcSide = 'Long';
  calcMargin = 'Isolated';

  calculatedMargin = 0.00000000;
  calculatedEntryValue = 0.00000000;
  calculatedExitPrice = 0.00000000;
  calculatedProfitLoss = 0.00000000;
  calculatedProfitLossPercentage = 0.0000;
  calculatedTPProfitLossPercentage = 0.0000;
  calculatedROEPercentage = 0.0000;
  calculatedTPROEPercentage = 0.0000;
  calculatedEntryPrice = 0.00000000;
  calculatedTargetPrice = 0.00000000;
  calculatedCurrentQty = 0;
  calculatedResultingQty = 0;
  calculatedLiquidationPrice = 0.00000000;

  sideSwitch: any;
  marginSwitch: any;
  maintenanceMargin: number;
  fundingRate: number;
  IsCalcWalltInput = false;
  stats: any;
  userRiskLimit: any;
  buyCost: number;
  sellCost: number;
  buyCostToolTip: number;
  sellCostToolTip: number;
  willOrderLiquidateInstantlySell = false;
  willOrderLiquidateInstantlyBuy = false;
  allOpenSellOrder = [];
  allOpenBuyOrder = [];
  orderMargin: number;

  IsDisabledSellCost = false;
  IsDisabledBuyCost = false;
  userProfile: any;
  risklimitdata = false;
  constructor(public apiService: ApiService, private auth: AuthService, public webSocketService: WebsocketService, private zone: NgZone) {

  }

  ngOnInit() {
    this.IshiddenDisabled = true;

    this.sendCintractsub = this.apiService.sendSelectorContract.subscribe((contractData: any) => {
      if (contractData != null && contractData !== undefined && contractData.length !== 0) {
        this.currContract = contractData;
        if (this.isLogin == true) {
          this.Getallrisks();
          this.getProfile();
        }
      }
    });

    this.apiService.userAuthStatus.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        if (data.authorization != null && data.authorization !== undefined) {
          this.isLogin = true;
          this.timer = setInterval(() => {
            this.Getallrisks();
            // console.warn('Risk Limit Settings Updated', );
            // this.apiService.ShowToastrAlert('', 'Risk Limit Settings Updated', 'success');
          }, 60000);
        } else {
          this.isLogin = false;
        }
      }
    });

    this.webSocketService.markPrice.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.markPriceTicker = data;
        this.checkPlaceOrder();
      }
    });

    this.webSocketService.statsTicker.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.stats = data;
      }
    });

    this.webSocketService.RisklimitTicker.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.leveragevalue = data.cl;
        this.apiService.getCurrentLeverage(this.leveragevalue);
        this.valuez = this.leveragevalue;
        this.showvalue = this.leveragevalue;
        this.usrrsklmtkey = parseInt(data.rl);
        this.mlcalcx3 = parseInt(((data.ml * 2) / 3).toFixed(2));
        this.mlfloor = parseInt((((data.ml / 2) / 5) * 5).toFixed(2));
        this.mlceil = (this.mlfloor + 5);
        if (this.mlceil > this.mlcalcx3) {
          this.showdata = false;
          this.lv.stepsArray = [];
          const datadd = ['Cross', 1, 2, 3, 5, this.mlfloor, this.mlceil, data.ml];
          // console.log('s', datadd);
          datadd.forEach(element => {
            let templv = {
              value: element,
              legend: element
            };
            this.lv.stepsArray.push(templv);
          });

        } else {
          this.lv.stepsArray = [];
          const datadd = ['Cross', 1, 2, 3, 5, this.mlfloor, this.mlceil, this.mlcalcx3, data.ml];
          // console.log('s', datadd);
          datadd.forEach(element => {
            let templv = {
              value: element,
              legend: element
            };
            this.lv.stepsArray.push(templv);
          });

        }
        this.lv.stepsArray.forEach(elmt => {
          if (elmt.legend == 'Cross') {
            elmt.value = 0;
          }
        });
        // console.log('socket final meter data', this.lv.stepsArray);
        this.showdata = true;

      }
    });

    this.selectedorder = this.apiService.currSelectedOrder.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.orderselected = data;
        this.orderpayload.price = parseFloat(this.orderselected.key);
        this.orderpayload.stopPrice = parseFloat(this.orderselected.key);
        this.orderpayload.orderQty = this.orderselected.value.q;
        setTimeout(() => {
          if (this.userProfile != null && this.userProfile !== undefined) {
            if (this.userProfile.checkOrderMargin == true) {
              this.calculateCost();
            } else {
              this.buyCost = 0;
              this.sellCost = 0;
            }
          }
        }, 3000);
      }
    });

    this.allSettingsSub = this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.Settings = data;
      }
    });

    this.currContDetailsSub = this.apiService.sendSelectedContractDetail.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.currContractData = data;
        if(data.type == "InversePerpetual"){
          this.settledInCurrency = data.underlyingBaseCurrency
        } else {
          this.settledInCurrency = data.underlyingQuoteCurrency;
        }
        // if (this.isLogin == true) {
        //   this.GetBalance();
        // }
      }
    });

    this.webSocketService.FundingRate.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.fundingRateTicker = data.R;
        // Object.entries(data).forEach(([key, value]) => {
        //   if (key == 'R') {
        //     this.singleFundingRate = value;
        //   }
        // });
      }
    });

    this.webSocketService.FundingRatePrediction.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.fundingRatepredictionTicker = data.R;
        // Object.entries(data).forEach(([key, value]) => {
        //   if (key == 'R') {
        //     this.singleFundingRate = value;
        //   }
        // });
      }
    });

    this.apiService.lastTradeDetails.subscribe((lastPrice: any) => {
      if (lastPrice != null && lastPrice !== undefined && lastPrice.length !== 0) {
        if (this.lastTradePrice == 0) {
          this.LTP = lastPrice[0].p;
          this.orderpayload.price = lastPrice[0].p;
          this.orderpayload.stopPrice = lastPrice[0].p;
          setTimeout(() => {
            if (this.userProfile != null && this.userProfile !== undefined) {
              if (this.userProfile.checkOrderMargin == true) {
                this.calculateCost();
              } else {
                this.buyCost = 0;
                this.sellCost = 0;
              }
            }
          }, 3000);
        }
        this.lastTradePrice = lastPrice[0].p;
        this.checkPlaceOrder();
      }
    });

    this.webSocketService.BalanceTicker.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data !== undefined && data != null && data.length !== 0) {
          this.walletbal = data;
          // this.calcWallet = data.available;
          // this.calculateEquations();
        }
      });
    });

    this.apiService.CurrPostionSize.subscribe((posSize: any) => {
      if (posSize != null && posSize !== undefined) {
        this.currentContractPosSize = posSize.size;
      }
    });


    this.webSocketService.myOrdersSub.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
          data.forEach((el: any) => {
            if (el.sym == this.currContract.contractName) {
              if (el.s == 'S') {
                this.allOpenSellOrder.unshift(el);
              } else {
                this.allOpenBuyOrder.unshift(el);
              }
            }
          });
          if (this.userProfile != null && this.userProfile !== undefined) {
            if (this.userProfile.checkOrderMargin == true) {
              this.calculateCost();
            } else {
              this.buyCost = 0;
              this.sellCost = 0;
            }
          }
        }
      });
    });

    this.otherOrderType = 'Stop Market';
    this.ChangeOrderType('Limit');

  }

  async getProfile() {
    let result = await this.apiService.requestUserProfile().catch(err => {
      // this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined && result.data !== '') {
        this.userProfile = result.data;
      }

  }

  slideer() {
    this.newrisklimitdata = this.allrsklmt[this.value];


    if (this.value !== this.usrrsklmtkey) {
      this.isselectnewElementdisable = true;
    } else {
      this.isselectnewElementdisable = false;
    }
  }

  // GetBalance() {
  //   this.apiService.getwalletbalance(this.settledInCurrency).then((result: any) => {
  //     if (result.data != null && result.data !== undefined) {
  //       this.walletbal = result.data[0];
  //     }
  //   });
  // }

  GetTimeInforce(data: any) {
    this.SelectedTM = data;
  }

  LeverageToggle() {
    this.ShowLeverage = true;
  }

  cancel() {
    this.ShowLeverage = false;
  }

  async confirm() {

    let result = await this.apiService.Upadteleverage(this.currContract.contractName + '/' + this.leveragenumber).catch(err => {
      this.apiService.ShowToastrAlert('', err.description, 'error');
      this.ShowLeverage = false;
    });
    if(result == undefined || result== null) {
      return
      }
  // console.log('RisklimitTicker', result.data);
  this.leveragevalue = result.data.cl;
  this.apiService.getCurrentLeverage(this.leveragevalue);
  this.valuez = this.leveragevalue;
  this.showvalue = this.leveragevalue;
  if (this.userProfile != null && this.userProfile !== undefined) {
    if (this.userProfile.checkOrderMargin == true) {
      if (this.userProfile != null && this.userProfile !== undefined) {
        if (this.userProfile.checkOrderMargin == true) {
          this.calculateCost();
        } else {
          this.buyCost = 0;
          this.sellCost = 0;
        }
      }
    } else {
      this.buyCost = 0;
      this.sellCost = 0;
    }
  }

  if (this.showvalue >= 100) {
    this.rangeSliderValue = '73%';
  } else {
    this.rangeSliderValue = this.leveragevalue + '%';
  }
  if (this.showvalue > 10) {
    this.IsShieldHide = true;
    this.HideLeverage = true;
  } else {
    this.IsShieldHide = false;
    this.HideLeverage = false;
  }
  this.ShowLeverage = false;
  }

  ChangeOrderType(DataOrderType: string) {
    this.showDisplayQty = false;
    this.orderpayload.IsCloseOnTrigger = false;
    this.orderpayload.IsHiddenOrder = false;
    this.orderpayload.IsPostOnly = false;
    this.orderpayload.isReduceOnly = false;
    // this.RangeVal = this.RangeValmedian;
    // this.RangeVal2 = this.RangeValmedian;
    this.OrderType = DataOrderType;
    if (DataOrderType !== 'Limit' && DataOrderType !== 'Market') {
      this.otherOrderType = DataOrderType;

    }

    if (DataOrderType == 'Limit' || DataOrderType == 'Stop Limit' || DataOrderType == 'Take Profit Limit') {
      this.orderpayload.timeinForce = 'GTC';
    }

    this.checkPlaceOrder();
  }

  noDecimal(event: any) {
    if (event.which < 48 || event.which > 57) {
      return (false); // stop processing
    }
  }

  singleDecimal(event: any) {
    const regex = /\d*\.?\d?/g;

    if (this.currContractData != null && this.currContractData !== undefined) {
      let lastTradePrice = Math.round(event.target.value * 10) / 10;
      lastTradePrice = this.currContractData.minPriceINC * (Math.floor(lastTradePrice / this.currContractData.minPriceINC));
      event.target.value = lastTradePrice;
      event.target.value = regex.exec(event.target.value);
    }
  }

  openleverageModal() {
    this.IsModalOpen = true;
  }

  closeleverageModal() {
    this.IsModalOpen = false;
  }

  changeTriggerType() {
    this.checkPlaceOrder();
  }

  checkPlaceOrder() {
    if (this.OrderType == 'Stop Limit' || this.OrderType == 'Stop Market') {
      if (this.orderpayload.triggerType == 'MarkPrice') {
        if (this.markPriceTicker != null && this.markPriceTicker !== undefined) {
          this.CheckLimitMarketPlaceOrder(this.markPriceTicker.P);
        }
      } else if (this.orderpayload.triggerType == 'LastPrice') {
        if (this.lastTradePrice != null && this.lastTradePrice !== undefined) {
          this.CheckLimitMarketPlaceOrder(this.lastTradePrice);
        }
      } else if (this.orderpayload.triggerType == 'IndexPrice') {
        if (this.markPriceTicker != null && this.markPriceTicker !== undefined && this.markPriceTicker.length !== 0) {
          const indexPrice = this.markPriceTicker.IP;
          this.CheckLimitMarketPlaceOrder(indexPrice);
        }
      }
    } else if (this.OrderType == 'Take Profit Limit' || this.OrderType == 'Take Profit Market') {
      if (this.orderpayload.triggerType == 'MarkPrice') {
        if (this.markPriceTicker != null && this.markPriceTicker !== undefined) {
          this.checkTakeProfitPlaceOrder(this.markPriceTicker.P);
        }
      } else if (this.orderpayload.triggerType == 'LastPrice') {
        if (this.lastTradePrice != null && this.lastTradePrice !== undefined) {
          this.checkTakeProfitPlaceOrder(this.lastTradePrice);
        }
      } else if (this.orderpayload.triggerType == 'IndexPrice') {
        if (this.markPriceTicker != null && this.markPriceTicker !== undefined && this.markPriceTicker.length !== 0) {
          const indexPrice = this.markPriceTicker.IP;
          this.checkTakeProfitPlaceOrder(indexPrice);
        }
      }
    } else if (this.OrderType == 'Limit' || this.OrderType == 'Market') {
      this.IsDisabledBuy = false;
      this.IsDisabledSell = false;
    }
  }

  CheckLimitMarketPlaceOrder(price: number) {
    if (this.orderpayload.stopPrice < price) {
      this.IsDisabledBuy = true;
      this.IsDisabledSell = false;
    } else if (this.orderpayload.stopPrice > price) {
      this.IsDisabledSell = true;
      this.IsDisabledBuy = false;
    } else if (this.orderpayload.stopPrice == price) {
      this.IsDisabledSell = true;
      this.IsDisabledBuy = true;
    }
  }

  checkTakeProfitPlaceOrder(price: number) {
    if (this.orderpayload.stopPrice > price) {
      this.IsDisabledBuy = true;
      this.IsDisabledSell = false;
    } else if (this.orderpayload.stopPrice < price) {
      this.IsDisabledSell = true;
      this.IsDisabledBuy = false;
    } else if (this.orderpayload.stopPrice == price) {
      this.IsDisabledSell = true;
      this.IsDisabledBuy = true;
    }
  }

  checkStopPrice(stopPrice: any) {
    if (this.OrderType == 'Stop Limit' || this.OrderType == 'Stop Market') {
      if (this.orderpayload.triggerType == 'MarkPrice') {
        if (this.markPriceTicker != null && this.markPriceTicker !== undefined) {
          this.checkLimitMarket(this.markPriceTicker.P, stopPrice);
        }
      } else if (this.orderpayload.triggerType == 'LastPrice') {
        if (this.lastTradePrice != null && this.lastTradePrice !== undefined) {
          this.checkLimitMarket(this.lastTradePrice, stopPrice);
        }
      } else if (this.orderpayload.triggerType == 'IndexPrice') {
        if (this.markPriceTicker != null && this.markPriceTicker !== undefined && this.markPriceTicker.length !== 0) {
          const indexPrice = this.markPriceTicker.IP;
          this.checkLimitMarket(indexPrice, stopPrice);
        }
      }
    } else if (this.OrderType == 'Take Profit Limit' || this.OrderType == 'Take Profit Market') {
      if (this.orderpayload.triggerType == 'MarkPrice') {
        if (this.markPriceTicker != null && this.markPriceTicker !== undefined) {
          this.checkTakeProfit(this.markPriceTicker.P, stopPrice);
        }
      } else if (this.orderpayload.triggerType == 'LastPrice') {
        if (this.lastTradePrice != null && this.lastTradePrice !== undefined) {
          this.checkTakeProfit(this.lastTradePrice, stopPrice);
        }
      } else if (this.orderpayload.triggerType == 'IndexPrice') {
        if (this.markPriceTicker != null && this.markPriceTicker !== undefined && this.markPriceTicker.length !== 0) {
          const indexPrice = this.markPriceTicker.IP;
          this.checkTakeProfit(indexPrice, stopPrice);
        }
      }
    } else if (this.OrderType == 'Limit' || this.OrderType == 'Market') {
      this.IsDisabledBuy = false;
      this.IsDisabledSell = false;
    }
  }

  checkLimitMarket(price: number, stopPrice: number) {
    if (stopPrice < price) {
      this.IsDisabledBuy = true;
      this.IsDisabledSell = false;
    } else if (stopPrice > price) {
      this.IsDisabledSell = true;
      this.IsDisabledBuy = false;
    } else if (stopPrice == price) {
      this.IsDisabledSell = true;
      this.IsDisabledBuy = true;
    }
  }

  checkTakeProfit(price: number, stopPrice: number) {
    if (stopPrice > price) {
      this.IsDisabledBuy = true;
      this.IsDisabledSell = false;
    } else if (stopPrice < price) {
      this.IsDisabledSell = true;
      this.IsDisabledBuy = false;
    } else if (stopPrice == price) {
      this.IsDisabledBuy = true;
      this.IsDisabledSell = true;
    }
  }

  GetDisplayQty() {
    const xelement = document.getElementById('showDisplayQty') as HTMLInputElement;
    const Ischecked = xelement.checked;
    if (Ischecked !== false) {
      this.showDisplayQty = true;
    } else {
      this.showDisplayQty = false;
    }
  }

  async Getallrisks() {
    this.risklimitdata = false;
    let result = await this.apiService.all_risk_limit(this.currContract.contractName).catch(err => {
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined) {
        this.allrsklmt = result.data;
        // console.log(' this.allrsklmt', this.allrsklmt);
        this.Getuserrisks();
        this.options.stepsArray = [];
        Object.keys(this.allrsklmt).forEach((el, index) => {
          if (index == 0) {
            this.value = parseInt(el);
          }
          let temprisklimit = {
            value: parseInt(el),
          };

          this.options.stepsArray.push(temprisklimit);

        });
        this.risklimitdata = true;
      }
  }

  async Getuserrisks() {

    let result = await this.apiService.user_risk_limit(this.currContract.contractName).catch(err => {
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined && Object.keys(result.data).length > 0) {
        if(this.allrsklmt != null && this.allrsklmt !== undefined && Object.keys(this.allrsklmt).length > 0) {
          let isFound = false;
          Object.entries(this.allrsklmt).forEach(([key, value]) => {
            if (key == result.data.rl) {
              isFound = true;
              let temp = {
                rl: parseFloat(key),
                im: value['im'],
                mm: value['mm'],
                ml: value['ml'],
              };
              this.usrrsklmt = temp;
            }
          });
          if (!isFound) {

            let temp = {
              rl: parseFloat(Object.keys(this.allrsklmt)[0]),
              im: Object.values(this.allrsklmt)[0]['im'],
              mm: Object.values(this.allrsklmt)[0]['mm'],
              ml: Object.values(this.allrsklmt)[0]['ml'],
            };
            this.usrrsklmt = temp;
            this.Updaterisklimit(Object.keys(this.allrsklmt)[0])
          }
        }
        this.userRiskLimit = result.data;

        if (this.userProfile != null && this.userProfile !== undefined) {
          if (this.userProfile.checkOrderMargin == true) {
            this.calculateCost();
          } else {
            this.buyCost = 0;
            this.sellCost = 0;
          }
        }
        // console.log('Getuserrisks', result.data);
        this.leveragevalue = result.data.cl;

        this.apiService.getCurrentLeverage(this.leveragevalue);
        this.valuez = this.leveragevalue;
        this.showvalue = this.leveragevalue;
        this.leveragenumber = this.showvalue;
        if (this.showvalue >= 100) {
          this.rangeSliderValue = '73%';
        } else {
          this.rangeSliderValue = this.leveragevalue + '%';
        }
        if (this.showvalue > 10) {
          this.IsShieldHide = true;
          this.HideLeverage = true;
        } else {
          this.IsShieldHide = false;
          this.HideLeverage = false;
        }
        // this.usrrsklmt = result.data;
        this.usrrsklmtkey = this.usrrsklmt.rl;
        this.mlcalcx3 = parseInt(((this.usrrsklmt.ml * 2) / 3).toFixed(2));
        this.mlfloor = parseInt((((this.usrrsklmt.ml / 2) / 5) * 5).toFixed(2));
        this.mlceil = (this.mlfloor + 5);
        if (this.mlceil > this.mlcalcx3) {
          this.lv.stepsArray = [];
          const datadd = ['Cross', 1, 2, 3, 5, this.mlfloor, this.mlceil, this.usrrsklmt.ml];
          // console.log('slider meter data', datadd);
          datadd.forEach(element => {

            let templv = {
              value: element,
              legend: element
            };
            this.lv.stepsArray.push(templv);
          });

          this.lv.stepsArray.forEach(elmt => {
            if (elmt.legend == 'Cross') {
              elmt.value = 0;
            }
          });
        } else {
          this.lv.stepsArray = [];
          const datadd = ['Cross', 1, 2, 3, 5, this.mlfloor, this.mlceil, this.mlcalcx3, this.usrrsklmt.ml];
          // console.log('slider meter data', datadd);
          datadd.forEach(element => {

            let templv = {
              value: element,
              legend: element
            };
            this.lv.stepsArray.push(templv);
          });

          this.lv.stepsArray.forEach(elmt => {
            if (elmt.legend == 'Cross') {
              elmt.value = 0;
            }
          });
        }

        // console.log('slider meter final data', this.lv.stepsArray);
        this.showdata = true;

        Object.keys(this.allrsklmt).forEach((element, index) => {
          if (index == 0) {
            this.value = parseInt(element)
          }
        });
        this.value = this.usrrsklmtkey;

        this.slideer();
      } else {
        this.Updaterisklimit(Object.keys(this.allrsklmt)[0]);
        this.usrrsklmtkey = Object.keys(this.allrsklmt)[0];
        this.usrrsklmt = Object.values(this.allrsklmt)[0];
        if (this.usrrsklmt != null && this.usrrsklmt !== undefined) {
          this.mlcalcx3 = parseInt(((this.usrrsklmt.ml * 2) / 3).toFixed(2));
          this.mlfloor = parseInt((((this.usrrsklmt.ml / 2) / 5) * 5).toFixed(2));
          this.mlceil = (this.mlfloor + 5);
          if (this.mlceil > this.mlcalcx3) {
            const datadd = ['Cross', 1, 2, 3, 5, this.mlfloor, this.mlceil, this.usrrsklmt.ml];
            datadd.forEach(element2 => {
              const templv = {
                value: element2,
                legend: element2
              };
              this.lv.stepsArray.push(templv);
            });
          } else {
            const datadd = ['Cross', 1, 2, 3, 5, this.mlfloor, this.mlceil, this.mlcalcx3, this.usrrsklmt.ml];
            datadd.forEach(element2 => {
              const templv = {
                value: element2,
                legend: element2
              };
              this.lv.stepsArray.push(templv);
            });
          }
        }

        this.lv.stepsArray.forEach(elmt => {
          if (elmt.legend == 'Cross') {
            elmt.value = 0;
          }
        });
        this.showdata = true;
        this.slideer();
      }
  }

 async Updaterisklimit(key: any) {
    this.showdata = false;
    let result = await this.apiService.SendUpadteRisklimit(this.currContract.contractName + '/' + key).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
         // tslint:disable-next-line:max-line-length
      // this.apiService.ShowToastrAlert('Successfully set risk limit', 'Your new ' + this.currContract.contractName + ' risk limit is ' + key + ' ' + this.settledInCurrency + '.', 'success');
      this.usrrsklmt = result.data;
      this.usrrsklmtkey = this.usrrsklmt.rl;
      this.mlcalcx3 = parseInt(((this.usrrsklmt.ml * 2) / 3).toFixed(2));
      this.mlfloor = parseInt((((this.usrrsklmt.ml / 2) / 5) * 5).toFixed(2));
      this.mlceil = (this.mlfloor + 5);
      // console.log('slider meter data', datadd);
      if (this.mlceil > this.mlcalcx3) {
        this.lv.stepsArray = [];
        const datadd = ['Cross', 1, 2, 3, 5, this.mlfloor, this.mlceil, this.usrrsklmt.ml];
        datadd.forEach(element => {

          let templv = {
            value: element,
            legend: element
          };
          this.lv.stepsArray.push(templv);
        });

        this.lv.stepsArray.forEach(elmt => {
          if (elmt.legend == 'Cross') {
            elmt.value = 0;
          }
        });
      } else {
        this.lv.stepsArray = [];


        const datadd = ['Cross', 1, 2, 3, 5, this.mlfloor, this.mlceil, this.mlcalcx3, this.usrrsklmt.ml];
        datadd.forEach(element => {

          let templv = {
            value: element,
            legend: element
          };
          this.lv.stepsArray.push(templv);
        });

        this.lv.stepsArray.forEach(elmt => {
          if (elmt.legend == 'Cross') {
            elmt.value = 0;
          }
        });
      }


      // console.log('slider meter final data', this.lv.stepsArray);
      this.showdata = true;
      this.slideer();
      this.IsModalOpen = false;
      this.Getallrisks();
  }

  getvalue(data: any) {
    if (data == 'cross') {
      this.leveragenumber = 0;
    } else {
      this.leveragenumber = data;
    }
    this.confirm();
  }

  requestBuyOrderPayload(mode: any) {
    if (mode === 'Buy') {
      this.placeOrderMode = mode;
    }
    if (this.OrderType === 'Stop Limit') {
      this.orderpayload.orderType = 'STOPLIMIT';
      this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.isBelow = false;
    } else if (this.OrderType === 'Stop Market') {
      this.orderpayload.orderType = 'STOPMARKET';
      this.orderpayload.timeinForce = '';
      this.orderpayload.price = this.LTP;
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.isBelow = false;
    } else if (this.OrderType === 'Trailing Stop Market') {
      this.orderpayload.orderType = 'Trailing Stop Market';
      this.orderpayload.timeinForce = '';
      this.orderpayload.triggerType = this.orderpayload.triggerType;
    } else if (this.OrderType === 'Take Profit Limit') {
      this.orderpayload.orderType = 'TAKEPROFITLIMIT';
      this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.isBelow = true;
    } else if (this.OrderType === 'Take Profit Market') {
      this.orderpayload.orderType = 'TAKEPROFITMARKET';
      this.orderpayload.timeinForce = '';
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.isBelow = true;
    } else if (this.OrderType === 'Market') {
      this.orderpayload.orderType = 'MARKET';
      this.orderpayload.timeinForce = '';
      this.orderpayload.stopPrice = 0;
      this.orderpayload.price = this.LTP;
    } else if (this.OrderType === 'Limit') {
      this.orderpayload.orderType = 'LIMIT';
      this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      this.orderpayload.stopPrice = 0;
    }
    this.orderpayload.symbol = this.currContract.contractName;
    this.orderpayload.side = 'BUY';

    if (this.currentContractPosSize != null && this.currentContractPosSize !== undefined) {
      this.PosSizeAfterExecution = this.currentContractPosSize + this.orderpayload.orderQty;
    } else {
      this.PosSizeAfterExecution = this.orderpayload.orderQty;
    }
    this.getEstimateMargin(this.orderpayload, this.placeOrderMode);
  }

  requestSellOrderPayload(mode: any) {
    if (mode === 'Sell') {
      this.placeOrderMode = mode;
    }
    if (this.OrderType === 'Stop Limit') {
      this.orderpayload.orderType = 'STOPLIMIT';
      this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.isBelow = true;
    } else if (this.OrderType === 'Stop Market') {
      this.orderpayload.orderType = 'STOPMARKET';
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.orderpayload.timeinForce = '';
      this.orderpayload.price = this.LTP;
      this.isBelow = true;
    } else if (this.OrderType === 'Trailing Stop Market') {
      this.orderpayload.orderType = 'Trailing Stop Market';
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.orderpayload.timeinForce = '';
    } else if (this.OrderType === 'Take Profit Limit') {
      this.orderpayload.orderType = 'TAKEPROFITLIMIT';
      this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.isBelow = false;
    } else if (this.OrderType === 'Take Profit Market') {
      this.orderpayload.orderType = 'TAKEPROFITMARKET';
      this.orderpayload.triggerType = this.orderpayload.triggerType;
      this.orderpayload.timeinForce = '';
      this.isBelow = false;
    } else if (this.OrderType === 'Market') {
      this.orderpayload.orderType = 'MARKET';
      this.orderpayload.timeinForce = '';
      this.orderpayload.stopPrice = 0;
      this.orderpayload.price = this.LTP;
    } else if (this.OrderType === 'Limit') {
      this.orderpayload.orderType = 'LIMIT';
      this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      this.orderpayload.stopPrice = 0;
    }
    this.orderpayload.symbol = this.currContract.contractName;
    this.orderpayload.side = 'SELL';
    if (this.currentContractPosSize != null && this.currentContractPosSize !== undefined) {
      this.PosSizeAfterExecution = this.currentContractPosSize - this.orderpayload.orderQty;
    } else {
      this.PosSizeAfterExecution = this.orderpayload.orderQty;
    }

    this.getEstimateMargin(this.orderpayload, this.placeOrderMode);
  }

  // openPlaceOrderModal(mode: string) {
  //   if (mode === 'Buy') {
  //     this.placeOrderMode = mode;
  //     this.placeOrderModalStatus = true;
  //   } else {
  //     this.placeOrderMode = mode;
  //     this.placeOrderModalStatus = true;
  //   }
  // }

  async getEstimateMargin(orderpayload: any, mode: any) {
    let result = await this.apiService.requestEstimateMargin(orderpayload).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      this.estimateMargin = result.data;
      // console.log('estimateMargin', this.estimateMargin);
      const roundedliqPrice = Math.round(result.data.liquidationPrice * 10) / 10;
      this.estimateMargin.liquidationPrice = roundedliqPrice;
      if (this.markPriceTicker != null && this.markPriceTicker !== undefined && this.markPriceTicker.length !== 0) {
        const Diff = this.estimateMargin.liquidationPrice - this.markPriceTicker.P;
        const DiffiqPrice = Math.round(Diff * 10) / 10;
        this.estMarginDiff.calculaed = parseFloat(DiffiqPrice.toFixed(1));
        // tslint:disable-next-line:max-line-length
        const estMarginDiffper = (this.estimateMargin.liquidationPrice - this.markPriceTicker.P) / this.estimateMargin.liquidationPrice * 100;
        const DiffiqPr = Math.round(estMarginDiffper * 10) / 10;
        this.estMarginDiff.percentage = DiffiqPr;
      }
      if (this.orderpayload.triggerType == 'LastPrice') {
        this.triggerMode = 'Last Price';
      } else if (this.orderpayload.triggerType == 'MarkPrice') {
        this.triggerMode = 'Mark Price';
      } else if (this.orderpayload.triggerType == 'IndexPrice') {
        this.triggerMode = 'Index Price';
      }

      // if (this.markPriceTicker.P > this.orderpayload.stopPrice) {
      //   this.isBelow = true;
      // } else if (this.markPriceTicker.P < this.orderpayload.stopPrice) {
      //   this.isBelow = false;
      // }

      if (localStorage.getItem('DSAStatus') != null && localStorage.getItem('DSAStatus') !== undefined) {
        this.DSACheckBoxStatus = localStorage.getItem('DSAStatus');
        if (this.DSACheckBoxStatus == 'Hidden') {
          if (mode == 'Sell') {
            this.placeSellOrder();
          } else if (mode == 'Buy') {
            this.placeBuyOrder();
          }
        } else {
          this.DSACheckBoxModel = false;
          this.placeOrderModalStatus = true;
        }
      } else {
        this.DSACheckBoxModel = false;
        this.placeOrderModalStatus = true;
      }
  }

  async placeBuyOrder() {
    if (this.orderpayload.orderType == 'MARKET' || this.orderpayload.orderType == 'STOPMARKET'
      || this.orderpayload.orderType == 'TAKEPROFITMARKET') {
        this.orderpayload.price = 0;
    }
    // console.log('placeBuyOrder', this.orderpayload);
    this.placeOrderModalStatus = false;

    let result = await this.apiService.NewOrder(this.orderpayload).catch(err => {
      this.apiService.ShowToastrAlert('', err.description, 'error');
      console.error('Buy Order Error', err.description);
    });
    if(result == undefined || result== null) {
      return
      }
      console.warn('Buy Order Success', result.data);
      if (this.OrderType === 'Stop Limit') {
        this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      } else if (this.OrderType === 'Take Profit Limit') {
        this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      } else if (this.OrderType === 'Limit') {
        this.orderpayload.timeinForce = this.orderpayload.timeinForce;
      }

      if (this.DSACheckBoxModel == true) {
        localStorage.setItem('DSAStatus', 'Hidden');
      }
  }

 async placeSellOrder() {
    // console.log('placeSellOrder', this.orderpayload);
    this.placeOrderModalStatus = false;
    let result = await this.apiService.NewOrder(this.orderpayload).catch(err => {
      this.apiService.ShowToastrAlert('', err.description, 'error');
      console.error('Sell Order Error', err.description);
    });
    if(result == undefined || result== null) {
      return
      }
 // this.apiService.ShowToastrAlert('', result.message, 'success');
 console.warn('Sell Order Success', result.data);
 if (this.OrderType === 'Stop Limit') {
   this.orderpayload.timeinForce = this.orderpayload.timeinForce;
 } else if (this.OrderType === 'Take Profit Limit') {
   this.orderpayload.timeinForce = this.orderpayload.timeinForce;
 } else if (this.OrderType === 'Limit') {
   this.orderpayload.timeinForce = this.orderpayload.timeinForce;
 }

 if (this.DSACheckBoxModel == true) {
   localStorage.setItem('DSAStatus', 'Hidden');
 }
  }

  closePlaceOrderModal() {
    this.placeOrderModalStatus = false;
  }

  checkPostOnly() {
    this.orderpayload.timeinForce = 'GTC';
    if (this.orderpayload.IsPostOnly !== false) {
      this.postOnlyStatus = true;
    } else {
      this.postOnlyStatus = false;
    }
  }

  checkEdge(event) {
    this.edge = event;
    // console.log('edge:', event);
  }

  changeCalcType(calcType: any) {
    this.calcType = calcType;
  }

  openCalc() {
    this.IsCalcShow = !this.IsCalcShow;
    if (this.walletbal != null && this.walletbal !== undefined) {
      this.calcWallet = this.walletbal.available;
    }
    this.changeCalcType('profitLoss');
  }

  closeCalc() {
    this.IsCalcShow = false;
  }

  checkIsNaN(input: any) {
    if (isNaN(input)) {
      return 0;
    } else {
      return input;
    }
  }

  roundNumber(input: number, precision: number, multiplier: number) {
    let roundedValue = 0;
    roundedValue = Math.round(input * Math.pow(10, precision)) / Math.pow(10, precision);
    if (multiplier > 0) {
      if (roundedValue >= 0) {
        roundedValue = multiplier * (Math.floor(roundedValue / multiplier) + (roundedValue % multiplier === 0 ? 0 : 1));
      } else {
        roundedValue = multiplier * (Math.floor(roundedValue / multiplier));
      }
    }
    return roundedValue;
  }

  checkIncrement(event: any) {
    const regex = /\d*\.?\d?/g;

    if (this.currContractData != null && this.currContractData !== undefined) {
      let inputPrice = Math.round(event.target.value * 10) / 10;
      // tslint:disable-next-line:max-line-length
      inputPrice = this.currContractData.minPriceINC * (Math.floor(inputPrice / this.currContractData.minPriceINC));
      event.target.value = inputPrice;
      event.target.value = regex.exec(event.target.value);
    }
  }

  calculateEquations() {
    let calculatedMargin: number;
    let calculatedEntryValue: number;
    let calculatedExitPrice: number;
    let calculatedProfitLoss: number;
    let calculatedProfitLossPercentage: number;
    let calculatedROEPercentage: number;
    let calculatedEntryPrice: number;
    let calculatedTPROEPercentage: number;
    let calculatedTPProfitLossPercentage: number;
    let calculatedTargetPrice: number;

    if (this.usrrsklmt != null && this.usrrsklmt !== undefined) {
      this.maintenanceMargin = this.usrrsklmt.mm;
    }
    this.fundingRate = this.fundingRateTicker;
    if (this.calcType === 'profitLoss') {
      if (this.currContractData != null && this.currContractData !== undefined) {
        // tslint:disable-next-line:max-line-length
        calculatedMargin = this.calcQuantity / this.calcEntryPrice / this.calcLeverage + this.calcQuantity / this.calcEntryPrice * this.currContractData.takerFee / 100 * 2;
        this.calculatedMargin = this.roundNumber(calculatedMargin, 8, 0);
        // this.calculatedMargin = this.roundNumber(calculatedMargin, 8 , this.currContractData.minPriceINC);
      }
      if (this.calcSide == 'Long') {
        calculatedEntryValue = (-this.calcQuantity / this.calcEntryPrice);
        this.calculatedEntryValue = this.roundNumber(calculatedEntryValue, 8, 0);

        calculatedExitPrice = (-this.calcQuantity / this.calcExitPrice);
        this.calculatedExitPrice = this.roundNumber(calculatedExitPrice, 8, 0);

      } else if (this.calcSide == 'Short') {
        calculatedEntryValue = (this.calcQuantity / this.calcEntryPrice);
        this.calculatedEntryValue = this.roundNumber(calculatedEntryValue, 8, 0);

        calculatedExitPrice = (this.calcQuantity / this.calcExitPrice);
        this.calculatedExitPrice = this.roundNumber(calculatedExitPrice, 8, 0);
      }

      calculatedProfitLoss = calculatedExitPrice - calculatedEntryValue;
      this.calculatedProfitLoss = this.roundNumber(calculatedProfitLoss, 8, 0);

      // tslint:disable-next-line:max-line-length
      calculatedProfitLossPercentage = calculatedProfitLoss / Math.abs(calculatedEntryValue) * 100;
      this.calculatedProfitLossPercentage = this.roundNumber(calculatedProfitLossPercentage, 4, 0);

      calculatedROEPercentage = calculatedProfitLossPercentage * this.calcLeverage;
      this.calculatedROEPercentage = this.roundNumber(calculatedROEPercentage, 4, 0);

    } else if (this.calcType === 'targetPrice') {
      calculatedEntryPrice = this.calcTPEntryPrice;
      this.calculatedEntryPrice = this.roundNumber(calculatedEntryPrice, 8, 0);

      calculatedTPROEPercentage = this.calcROE;
      this.calculatedTPROEPercentage = this.roundNumber(calculatedTPROEPercentage, 4, 0);

      calculatedTPProfitLossPercentage = calculatedTPROEPercentage / this.calcTPLeverage;
      this.calculatedTPProfitLossPercentage = this.roundNumber(calculatedTPProfitLossPercentage, 4, 0);
      if (this.calcSide == 'Long') {
        // tslint:disable-next-line:max-line-length
        calculatedTargetPrice = calculatedEntryPrice / (1 - calculatedTPProfitLossPercentage / 100);
        this.calculatedTargetPrice = this.roundNumber(calculatedTargetPrice, 8, this.currContractData.minPriceINC);
      } else if (this.calcSide == 'Short') {
        // tslint:disable-next-line:max-line-length
        calculatedTargetPrice = calculatedEntryPrice / (1 + calculatedTPProfitLossPercentage / 100);
        this.calculatedTargetPrice = this.roundNumber(calculatedTargetPrice, 8, this.currContractData.minPriceINC);
      }

    } else if (this.calcType === 'liquidationPrice') {
      if (this.currentContractPosSize != null && this.currentContractPosSize !== undefined) {
        this.calculatedCurrentQty = this.currentContractPosSize;
      } else {
        this.calculatedCurrentQty = 0;
      }
      if (this.calcSide == 'Long') {
        this.calculatedResultingQty = this.calculatedCurrentQty + (this.calcLPQuantity);
      } else if (this.calcSide == 'Short') {
        this.calculatedResultingQty = this.calculatedCurrentQty + (-this.calcLPQuantity);
        // console.log('Resulting Qty', this.calculatedResultingQty, this.calcLPQuantity);
      }

      if (this.calcMargin == 'Isolated') {
        if (this.calcSide == 'Long') {
          // tslint:disable-next-line:max-line-length
          const calculatedLiquidationPrice = this.calcLPEntryPrice / (1 - (this.maintenanceMargin / 100 - (1 / this.calcLPLeverage) + Math.max(this.fundingRate / 100, 0)));
          const calculatedLiquidationPricefinal = this.roundNumber(calculatedLiquidationPrice, 8, this.currContractData.minPriceINC);
          if (calculatedLiquidationPricefinal < 0 || calculatedLiquidationPricefinal > this.currContractData.maxPrice) {
            if (this.currContractData != null && this.currContractData !== undefined) {
              this.calculatedLiquidationPrice = this.currContractData.maxPrice;
            }
          } else if (calculatedLiquidationPricefinal < this.currContractData.minPriceINC) {
            this.calculatedLiquidationPrice = this.currContractData.minPriceINC;
          } else {
            this.calculatedLiquidationPrice = calculatedLiquidationPricefinal;
          }
        } else if (this.calcSide == 'Short') {
          // tslint:disable-next-line:max-line-length
          const calculatedLiquidationPrice = this.calcLPEntryPrice / (1 + (this.maintenanceMargin / 100 - (1 / this.calcLPLeverage) - Math.min(this.fundingRate / 100, 0)));
          const calculatedLiquidationPricefinal = this.roundNumber(calculatedLiquidationPrice, 8, this.currContractData.minPriceINC);
          if (calculatedLiquidationPricefinal < 0 || calculatedLiquidationPricefinal > this.currContractData.maxPrice) {
            if (this.currContractData != null && this.currContractData !== undefined) {
              this.calculatedLiquidationPrice = this.currContractData.maxPrice;
            }
          } else if (calculatedLiquidationPricefinal < this.currContractData.minPriceINC) {
            this.calculatedLiquidationPrice = this.currContractData.minPriceINC;
          } else {
            this.calculatedLiquidationPrice = calculatedLiquidationPricefinal;
          }
        }
      } else if (this.calcMargin == 'Cross') {
        if (this.calcSide == 'Long') {
          // tslint:disable-next-line:max-line-length
          const calculatedLiquidationPrice = this.calcLPEntryPrice / (1 - (this.maintenanceMargin / 100 - (this.calcWallet * this.calcLPEntryPrice / this.calcLPQuantity) + this.currContractData.takerFee / 100 + Math.max(this.fundingRate / 100, 0)));
          const calculatedLiquidationPricefinal = this.roundNumber(calculatedLiquidationPrice, 8, this.currContractData.minPriceINC);
          if (calculatedLiquidationPricefinal < 0 || calculatedLiquidationPricefinal > this.currContractData.maxPrice) {
            if (this.currContractData != null && this.currContractData !== undefined) {
              this.calculatedLiquidationPrice = this.currContractData.maxPrice;
            }
          } else if (calculatedLiquidationPricefinal < this.currContractData.minPriceINC) {
            this.calculatedLiquidationPrice = this.currContractData.minPriceINC;
          } else {
            this.calculatedLiquidationPrice = calculatedLiquidationPricefinal;
          }

        } else if (this.calcSide == 'Short') {
          // tslint:disable-next-line:max-line-length
          const calculatedLiquidationPrice = this.calcLPEntryPrice / (1 + (this.maintenanceMargin / 100 - (this.calcWallet * this.calcLPEntryPrice / this.calcLPQuantity) + this.currContractData.takerFee / 100 - Math.min(this.fundingRate / 100, 0)));
          const calculatedLiquidationPricefinal = this.roundNumber(calculatedLiquidationPrice, 8, this.currContractData.minPriceINC);
          if (calculatedLiquidationPricefinal < 0 || calculatedLiquidationPricefinal > this.currContractData.maxPrice) {
            if (this.currContractData != null && this.currContractData !== undefined) {
              this.calculatedLiquidationPrice = this.currContractData.maxPrice;
            }
          } else if (calculatedLiquidationPricefinal < this.currContractData.minPriceINC) {
            this.calculatedLiquidationPrice = this.currContractData.minPriceINC;
          } else {
            this.calculatedLiquidationPrice = calculatedLiquidationPricefinal;
          }
        }
      }
    }

  }

  sideSwitchfun(sideMode: any) {
    if (sideMode == true) {
      this.calcSide = 'Short';
      this.calculateEquations();
    } else {
      this.calcSide = 'Long';
      this.calculateEquations();
    }
  }

  marginSwitchfun(marginMode: any) {
    if (marginMode == true) {
      this.calcMargin = 'Cross';
      this.calcWallet = this.walletbal.available;
      this.calculateEquations();
    } else {
      this.calcMargin = 'Isolated';
      this.calculateEquations();
    }
  }

  calculateCost() {
    this.willOrderLiquidateInstantlyBuy = false;
    this.willOrderLiquidateInstantlySell = false;
    const tempOrderBuy = {
      p: this.orderpayload.price,
      q: this.orderpayload.orderQty,
      r: this.orderpayload.orderQty,
      s: 'B',
    };

    const tempOrderSell = {
      p: this.orderpayload.price,
      q: this.orderpayload.orderQty,
      r: this.orderpayload.orderQty,
      s: 'S',
    };


    let liquidationPriceBuy = 0;
    if (this.leveragevalue <= 0) {
      if (this.userRiskLimit != null && this.userRiskLimit !== undefined ) {
      // cross
      const initialMargin = 1 / this.userRiskLimit.ml * 100;
       if (this.walletbal !== undefined && this.walletbal != null){
          // tslint:disable-next-line:max-line-length
        liquidationPriceBuy = this.orderpayload.price / (1 - (this.userRiskLimit.mm / 100 - (initialMargin / 100 + (this.walletbal.available * this.orderpayload.price / this.orderpayload.orderQty)) + this.currContractData.takerFee / 100 + Math.max(this.fundingRateTicker / 100, 0)));
     }
      }
    } else {
      if (this.userRiskLimit != null && this.userRiskLimit !== undefined) {
      // isolated
      const initialMargin = 1 / this.leveragevalue * 100;
      // tslint:disable-next-line:max-line-length
      liquidationPriceBuy = this.orderpayload.price / (1 - (this.userRiskLimit.mm / 100 - initialMargin / 100 + Math.max(this.fundingRateTicker / 100, 0)));
      }
    }

    if (liquidationPriceBuy < 0 || liquidationPriceBuy > this.currContractData.maxPrice) {
      if (this.currContractData != null && this.currContractData !== undefined) {
        liquidationPriceBuy = this.currContractData.maxPrice;
      }
    } else if (liquidationPriceBuy < this.currContractData.minPriceINC) {
      liquidationPriceBuy = this.currContractData.minPriceINC;
    } else {
      liquidationPriceBuy = liquidationPriceBuy;
    }


    if (this.markPriceTicker != null && this.markPriceTicker !== undefined) {
      if (liquidationPriceBuy >= this.markPriceTicker.P) {
        this.willOrderLiquidateInstantlyBuy = true;
      }
    }

    if(this.userProfile != null && this.userProfile !== undefined) {
      if (this.userProfile.checkLiquidationPrice == true) {
        if (this.willOrderLiquidateInstantlyBuy == true) {
          this.IsDisabledBuyCost = true;
          this.buyCost = 0;
          this.buyCostToolTip = 0;
        } else {
          this.IsDisabledBuyCost = false;
          this.buyCost = this.OrderMarginAsync(tempOrderBuy);
          this.buyCostToolTip = Math.round(this.buyCost * Math.pow(10, 8)) / Math.pow(10, 8);
        }
      }
    }



    // if (this.buyCost == 0 ) {
    //   this.IsDisabledBuyCost = true;
    // } else {
    //   this.IsDisabledBuyCost = false;
    // }


    let liquidationPriceSell = 0;
    if (this.leveragevalue <= 0) {
      if (this.userRiskLimit != null && this.userRiskLimit !== undefined) {
      // cross
      const initialMargin = 1 / this.userRiskLimit.ml * 100;
      if (this.walletbal !== undefined && this.walletbal != null) {
         // tslint:disable-next-line:max-line-length
      liquidationPriceSell = this.orderpayload.price / (1 + (this.userRiskLimit.mm / 100 - (initialMargin / 100 + (this.walletbal.available * this.orderpayload.price / this.orderpayload.orderQty)) + this.currContractData.takerFee / 100 - Math.min(this.fundingRateTicker / 100, 0)));

      }
     }
    } else {
      if (this.userRiskLimit != null && this.userRiskLimit !== undefined) {
      // isolated
      const initialMargin = 1 / this.leveragevalue * 100;
      // tslint:disable-next-line:max-line-length
      liquidationPriceSell = this.orderpayload.price / (1 + (this.userRiskLimit.mm / 100 - initialMargin / 100 - Math.min(this.fundingRateTicker / 100, 0)));
      }
    }

    if (liquidationPriceSell < 0 || liquidationPriceSell > this.currContractData.maxPrice) {
      if (this.currContractData != null && this.currContractData !== undefined) {
        liquidationPriceSell = this.currContractData.maxPrice;
      }
    } else if (liquidationPriceSell < this.currContractData.minPriceINC) {
      liquidationPriceSell = this.currContractData.minPriceINC;
    } else {
      liquidationPriceSell = liquidationPriceSell;
    }

    if (this.markPriceTicker != null && this.markPriceTicker !== undefined) {
      if (liquidationPriceSell <= this.markPriceTicker.P) {
        this.willOrderLiquidateInstantlySell = true;
      }
    }

    if(this.userProfile != null && this.userProfile !== undefined) {
      if (this.userProfile.checkLiquidationPrice == true) {
      }

    if (this.willOrderLiquidateInstantlySell == true) {
      this.IsDisabledSellCost = true;
      this.sellCost = 0;
      this.sellCostToolTip = 0;
    } else {
      this.IsDisabledSellCost = false;
      this.sellCost = this.OrderMarginAsync(tempOrderSell);
      this.sellCostToolTip = Math.round(this.sellCost * Math.pow(10, 8)) / Math.pow(10, 8);
    }
    }


    // if (this.sellCost == 0 ) {
    //   this.IsDisabledSellCost = true;
    // } else {
    //   this.IsDisabledSellCost = false;
    // }
  }

  public OrderMarginAsync(currentOrderPaylaod = null) {
    let size = this.currentContractPosSize;
    let orderedAllOpenOrder = [];
    let allOpenSellOrder = [];
    let orderedAllOpenSellOrder = [];
    let allOpenBuyOrder = [];
    let orderedAllOpenBuyOrder = [];

    // tslint:disable-next-line:max-line-length
    allOpenSellOrder = JSON.parse(JSON.stringify(this.allOpenSellOrder.filter((item) => item.st === false))); // all sell orders;

    if (currentOrderPaylaod != null && currentOrderPaylaod.s == 'S') {
      allOpenSellOrder.push(currentOrderPaylaod);
    }
    // console.log('sell', JSON.parse(JSON.stringify(allOpenSellOrder)));
    orderedAllOpenSellOrder = allOpenSellOrder.sort((a, b) => {
      return a.p - b.p;
    });

    allOpenBuyOrder = JSON.parse(JSON.stringify(this.allOpenBuyOrder.filter((item) => item.st === false))); // all buy orders;
    if (currentOrderPaylaod != null && currentOrderPaylaod.s == 'B') {
      allOpenBuyOrder.push(currentOrderPaylaod);
    }
    // console.log('buy', JSON.parse(JSON.stringify(allOpenBuyOrder)));
    orderedAllOpenBuyOrder = allOpenBuyOrder.sort((a, b) => {
      return a.p - b.p;
    });

    // If Position is Long
    if (size > 0 && allOpenSellOrder.length > 0) {
      // Take out most pricey 1st
      for (let i = allOpenSellOrder.length - 1; i >= 0; i--) {
        const sellOrder = orderedAllOpenSellOrder[i];
        // Not Sufficient to offset the position
        if (size > sellOrder.r) {
          size -= sellOrder.r;
          orderedAllOpenSellOrder.splice(i, 1);
          // Sufficient to offset the position
        } else if (size <= sellOrder.r) {
          sellOrder.r -= size;
          size = 0;
          // Fully offset the position
        } else if (size == sellOrder.r) {
          orderedAllOpenSellOrder.splice(i, 1);
          size = 0;
        }
        if (size == 0) {
          break;
        }
      }
    }

    // If Position is Short
    if (size < 0 && orderedAllOpenBuyOrder.length > 0) {
      // Take out most pricey 1st
      for (let i = orderedAllOpenBuyOrder.length - 1; i >= 0; i--) {
        const buyOrder = orderedAllOpenBuyOrder[i];
        // Not Sufficient to offset the position
        if (Math.abs(size) > buyOrder.r) {
          size += buyOrder.r;
          orderedAllOpenBuyOrder.splice(i, 1);
          // Sufficient to offset the position
        } else if (Math.abs(size) < buyOrder.r) {
          buyOrder.r += size;
          size = 0;
          // Fully offset the position
        } else if (Math.abs(size) == buyOrder.r) {
          orderedAllOpenBuyOrder.splice(i, 1);
          size = 0;
        }
        if (size == 0) {
          break;
        }
      }
    }

    const grossPotentialShortPosition = orderedAllOpenSellOrder.reduce((initial, obj) => {
      return initial + obj.r;
    }, 0);
    const grossPotentialLongPosition = orderedAllOpenBuyOrder.reduce((initial, obj) => {
      return initial + obj.r;
    }, 0);

    let maxGrossPosition = Math.max(grossPotentialShortPosition, grossPotentialLongPosition);
    // console.log('maxGrossPosition', maxGrossPosition);
    orderedAllOpenOrder = orderedAllOpenSellOrder.concat(orderedAllOpenBuyOrder);
    orderedAllOpenOrder.sort((a, b) => {
      return b.p - a.p;
    });
    // users cl;
    let currentLeverage = this.leveragevalue;
    // console.log('currentLeverage', currentLeverage);
    let orderMargin = 0;
    if (orderedAllOpenOrder.length > 0) {
      for (let i = orderedAllOpenOrder.length - 1; i >= 0; i--) {
        const openOrder = orderedAllOpenOrder[i];
        // This order can't offset the position.So Take whole size for margin calc.
        if (maxGrossPosition > openOrder.r) {
          maxGrossPosition -= openOrder.r;
          // This order can offset the position.So Take remaining position size for margin calc.
        } else if (maxGrossPosition <= openOrder.r) {
          openOrder.r = maxGrossPosition;
          maxGrossPosition = 0;
        }
        const sizeInBTCPerContract = 1 / openOrder.p;
        if (currentLeverage <= 0) {
          // ml of minimum risk limit
          currentLeverage = this.userRiskLimit.ml;
        }
        const collateral = (sizeInBTCPerContract / currentLeverage) * openOrder.r;
        const takerFee = 2 * (sizeInBTCPerContract * openOrder.r * 0.01 * this.currContractData.takerFee);
        orderMargin += (collateral + takerFee);
        if (maxGrossPosition == 0) {
          break;
        }
      }
    }

    return Math.round(orderMargin * Math.pow(10, 10)) / Math.pow(10, 10);
  }

  calculateCostTrigger() {
    if (this.userProfile != null && this.userProfile !== undefined) {
      if (this.userProfile.checkOrderMargin == true) {
        this.calculateCost();
      } else {
        this.buyCost = 0;
        this.sellCost = 0;
      }
    }
  }

  // selectAvailableBalance(data: any) {
  //   if (data == 'true') {
  //     this.IsCalcWalltInput = true;
  //     this.calcWallet = this.walletbal.available;
  //     this.calculateEquations();
  //   } else {
  //     this.IsCalcWalltInput = false;
  //     this.calcWallet = this.walletbal.available;
  //     this.calculateEquations();
  //   }
  // }


  // calculateCost() {
  //   this.buyCost = 0;
  //   this.sellCost = 0;
  //   if (this.leveragevalue <= 0) {
  //     this.buyCost = this.testingMethod(this.userRiskLimit.ml, 'buy');
  //     this.sellCost = this.testingMethod(this.userRiskLimit.ml, 'sell');
  //     this.buyCost = Math.round(this.buyCost * 10000) / 10000;
  //     this.sellCost = Math.round(this.sellCost * 10000) / 10000;
  //   } else {
  //     this.buyCost = this.testingMethod(this.leveragevalue, 'buy');
  //     this.sellCost = this.testingMethod(this.leveragevalue, 'sell');
  //     this.buyCost = Math.round(this.buyCost * 10000) / 10000;
  //     this.sellCost = Math.round(this.sellCost * 10000) / 10000;
  //   }
  // }

  // testingMethod(leverage: any, side: string) {
  //   this.orderMargin = 0;
  //   let allOpenSellOrder = [];
  //   let allOpenBuyOrder = [];
  //   let size = this.currentContractPosSize;
  //   let orderedAllOpenSellOrder = [];
  //   let orderedAllOpenBuyOrder = [];


  //   allOpenSellOrder = this.allOpenSellOrder;
  //   allOpenBuyOrder = this.allOpenBuyOrder;

  //   if (side == 'sell') {
  //     const tempOrder = {
  //       p: this.orderpayload.price,
  //       q: this.orderpayload.orderQty,
  //       r: this.orderpayload.orderQty,
  //     };
  //     allOpenSellOrder.push(tempOrder);
  //   }

  //   if (side == 'buy') {
  //     const tempOrder = {
  //       p: this.orderpayload.price,
  //       q: this.orderpayload.orderQty,
  //       r: this.orderpayload.orderQty,
  //     };
  //     allOpenBuyOrder.push(tempOrder);
  //   }

  //   orderedAllOpenSellOrder = allOpenSellOrder.sort((a, b) => {
  //     return a.p - b.p;
  //   });

  //   if (size < 0) {
  //     orderedAllOpenBuyOrder = allOpenBuyOrder.sort((a, b) => {
  //       return a.p - b.p;
  //     });
  //   } else {
  //     orderedAllOpenBuyOrder = allOpenBuyOrder;
  //   }


  //   // let orderedAllOpenOrder: any;

  //   // If Position is Long
  //   if (size > 0 && allOpenSellOrder.length > 0) {
  //     let i: number;
  //     for (i = allOpenSellOrder.length - 1; i >= 0; i--) {
  //       const order2 = orderedAllOpenSellOrder[i];
  //       if (size > order2.r) {
  //         size -= order2.r;
  //         orderedAllOpenSellOrder.forEach((el: any, index) => {
  //           if (el.o == order2.o) {
  //             orderedAllOpenSellOrder.splice(index, 1);
  //           }
  //         });
  //         // orderedAllOpenSellOrder.remove(order2);
  //       } else if (size <= order2.r) {
  //         order2.r -= size;
  //         size = 0;
  //       } else if (size == order2.r) {
  //         orderedAllOpenSellOrder.forEach((el: any, index) => {
  //           if (el.o == order2.o) {
  //             orderedAllOpenSellOrder.splice(index, 1);
  //           }
  //         });
  //         // orderedAllOpenSellOrder.Remove(order2);
  //         size = 0;
  //       }
  //       if (size == 0) {
  //         break;
  //       }
  //     }
  //   }

  //   // If Position is Short
  //   if (size < 0 && orderedAllOpenBuyOrder.length > 0) {
  //     let i: number;
  //     for (i = orderedAllOpenBuyOrder.length - 1; i >= 0; i--) {
  //       const order2 = orderedAllOpenBuyOrder[i];
  //       if (Math.abs(size) > order2.r) {
  //         size += order2.r;
  //         orderedAllOpenBuyOrder.forEach((el: any, index) => {
  //           if (el.o == order2.o) {
  //             orderedAllOpenBuyOrder.splice(index, 1);
  //           }
  //         });
  //         // orderedAllOpenBuyOrder.Remove(order2);
  //       } else if (Math.abs(size) < order2.r) {
  //         order2.r += size;
  //         size = 0;
  //       } else if (Math.abs(size) == order2.r) {
  //         orderedAllOpenBuyOrder.forEach((el: any, index) => {
  //           if (el.o == order2.o) {
  //             orderedAllOpenBuyOrder.splice(index, 1);
  //           }
  //         });
  //         // orderedAllOpenBuyOrder.Remove(order2);
  //         size = 0;
  //       }
  //       if (size == 0) {
  //         break;
  //       }
  //     }
  //   }

  //   let grossPotentialShortPosition: any;
  //   orderedAllOpenSellOrder.forEach((el: any) => {
  //     grossPotentialShortPosition += el.r;
  //     // console.log('order id matched', el.o, order2.o);
  //   });

  //   let grossPotentialLongPosition: any;
  //   orderedAllOpenBuyOrder.forEach((el: any) => {
  //     grossPotentialLongPosition += el.r;
  //   });

  //   let maxGrossPosition = Math.max(grossPotentialShortPosition, grossPotentialLongPosition);

  //   // We can remove sorting from here if needed. Because sell and buy prices can't cross each other.
  //   const orderedAllOpenOrder = orderedAllOpenSellOrder.concat(orderedAllOpenBuyOrder).sort((a, b) => {
  //     return a.p - b.p;
  //   });
  //   // console.log('A+ orderedAllOpenOrder', orderedAllOpenOrder );
  //   let currentLeverage = leverage;
  //   // console.log('currentLeverage', currentLeverage );
  //   let orderMargin = 0;
  //   let collateral = 0;
  //   let takerFee = 0;
  //   let openOrder: any;
  //   let sizeInBTCPerContract = 0;
  //   if (orderedAllOpenOrder.length > 0) {
  //     let i: number;
  //     for (i = orderedAllOpenOrder.length - 1; i >= 0; i--) {
  //       openOrder = orderedAllOpenOrder[i];
  //       // console.log('openOrder', openOrder);
  //       // This order can't offset the position.So Take whole size for margin calc.
  //       if (maxGrossPosition > openOrder.r) {
  //         maxGrossPosition -= openOrder.r;
  //         // This order can offset the position.So Take remaining position size for margin calc.
  //       } else if (maxGrossPosition <= openOrder.r) {
  //         openOrder.r = maxGrossPosition;
  //         maxGrossPosition = 0;
  //       }
  //       sizeInBTCPerContract = 1 / openOrder.p;
  //       if (currentLeverage <= 0) {
  //         currentLeverage = this.userRiskLimit.ml;
  //       }

  //       collateral = (sizeInBTCPerContract / currentLeverage) * openOrder.r;
  //       takerFee = 2 * (sizeInBTCPerContract * openOrder.r * 0.01 * this.currContractData.takerFee / 100);
  //       orderMargin += (collateral + takerFee);

  //       if (maxGrossPosition == 0) {
  //         break;
  //       }
  //     }
  //   }


  //   if (this.leveragevalue <= 0) {
  //     if (this.walletbal != null && this.walletbal !== undefined) {
  //       // tslint:disable-next-line:max-line-length
  //       const liquidationPriceBuy = this.orderpayload.price / (1 - (this.userRiskLimit.mm / 100 - (this.walletbal.available * this.orderpayload.price / this.orderpayload.orderQty) + this.currContractData.takerFee / 100 + Math.max(this.fundingRateTicker / 100, 0)));
  //       // tslint:disable-next-line:max-line-length
  //       const liquidationPriceSell = this.orderpayload.price / (1 + (this.userRiskLimit.mm / 100 - (this.walletbal.available * this.orderpayload.price / this.orderpayload.orderQty) + this.currContractData.takerFee / 100 - Math.min(this.fundingRateTicker / 100, 0)));

  //       this.calculateCostBuy(this.userRiskLimit.ml, this.userRiskLimit.mm, liquidationPriceBuy);
  //       this.calculateCostSell(this.userRiskLimit.ml, this.userRiskLimit.mm, liquidationPriceSell);
  //     }

  //   } else {
  //     const initialMargin = 1 / this.leveragevalue * 100;
  //     // tslint:disable-next-line:max-line-length
  //     const liquidationPriceBuy = this.orderpayload.price / (1 - (this.userRiskLimit.mm / 100 - initialMargin / 100 + Math.max(this.fundingRateTicker / 100, 0)));
  //     // tslint:disable-next-line:max-line-length
  //     const liquidationPriceSell = this.orderpayload.price / (1 + (this.userRiskLimit.mm / 100 - initialMargin / 100 - Math.min(this.fundingRateTicker / 100, 0)));

  //     this.calculateCostBuy(this.leveragevalue, this.userRiskLimit.mm, liquidationPriceBuy);
  //     this.calculateCostSell(this.leveragevalue, this.userRiskLimit.mm, liquidationPriceSell);

  //   }

  //   return orderMargin;

  // }

  // calculateCostBuy(leverage: any, maintenanceMargin: any, liquidationPrice: number) {
  //   let buyCostTemp = 0;
  //   let costAbnormal = 0;
  //   let costNormal = 0;
  //   let markPrice = null;
  //   let sizeInBTC = 0;
  //   let initialCollateral = 0;
  //   let transactionFee = 0;
  //   let twoWayFee = 0;
  //   let markPricePNL = 0;
  //   let maintenanceMarginTemp = 0;
  //   let newMargin = 0;

  //   if (this.markPriceTicker != null && this.markPriceTicker !== undefined && this.markPriceTicker.length !== 0) {
  //     markPrice = this.markPriceTicker;
  //     sizeInBTC = this.orderpayload.orderQty / this.orderpayload.price;
  //     initialCollateral = sizeInBTC / leverage;
  //     transactionFee = -sizeInBTC * this.currContractData.takerFee / 100;
  //     twoWayFee = -transactionFee * 2;
  //     costNormal = twoWayFee + initialCollateral;
  //     markPricePNL = this.orderpayload.orderQty * (1 / this.orderpayload.price - 1 / markPrice.P);
  //     maintenanceMarginTemp = maintenanceMargin / 100 * sizeInBTC;
  //     costAbnormal = twoWayFee + maintenanceMarginTemp - markPricePNL;
  //     const buyCost = Math.max(costNormal, costAbnormal);
  //     // this.buyCost = Math.round(buyCost * 10000) / 10000;

  //     if (liquidationPrice < 0 || liquidationPrice > this.currContractData.maxPrice) {
  //       if (this.currContractData != null && this.currContractData !== undefined) {
  //         liquidationPrice = this.currContractData.maxPrice;
  //       }
  //     } else if (liquidationPrice < this.currContractData.minPriceINC) {
  //       liquidationPrice = this.currContractData.minPriceINC;
  //     } else {
  //       liquidationPrice = liquidationPrice;
  //     }
  //     console.log('liquidationPrice buy', Math.round(liquidationPrice * 10) / 10);
  //     if (liquidationPrice >= markPrice) {
  //       this.willOrderLiquidateInstantly = true;
  //     }

  //     newMargin = this.orderMargin;

  //     if (this.walletbal != null && this.walletbal !== undefined && this.walletbal.length !== 0) {
  //       let tempValue: any;
  //       Object.entries(this.walletbal.breakdown).forEach(([key, value]) => {
  //         tempValue = value;
  //         if (this.currContract.contractName == key) {
  //           buyCostTemp = Math.max((newMargin - tempValue.order), 0);
  //         }
  //       });
  //     }

  //     // this.buyCost = buyCostTemp;
  //     // console.log('buyCostTemp', costNormal, costAbnormal);
  //     // if (costNormal >= costAbnormal) {
  //     //   this.buyCost = buyCostTemp;
  //     // } else if (costNormal < costAbnormal) {
  //     //   this.buyCost = costAbnormal;
  //     // }
  //     // console.log('this.buyCost', this.buyCost, newMargin);
  //   }

  // }

  // calculateCostSell(leverage: any, maintenanceMargin: any, liquidationPrice: number) {
  //   let sellCostTemp = 0;
  //   let costAbnormal = 0;
  //   let costNormal = 0;
  //   let markPrice = null;
  //   let sizeInBTC = 0;
  //   let initialCollateral = 0;
  //   let transactionFee = 0;
  //   let twoWayFee = 0;
  //   let markPricePNL = 0;
  //   let maintenanceMarginTemp = 0;
  //   let newMargin = 0;

  //   if (this.markPriceTicker != null && this.markPriceTicker !== undefined && this.markPriceTicker.length !== 0) {
  //     markPrice = this.markPriceTicker;
  //     sizeInBTC = this.orderpayload.orderQty / this.orderpayload.price;
  //     initialCollateral = sizeInBTC / leverage;
  //     transactionFee = -sizeInBTC * this.currContractData.takerFee / 100;
  //     twoWayFee = -transactionFee * 2;
  //     costNormal = twoWayFee + initialCollateral;
  //     markPricePNL = -this.orderpayload.orderQty * (1 / this.orderpayload.price - 1 / markPrice.P);
  //     maintenanceMarginTemp = maintenanceMargin / 100 * sizeInBTC;
  //     costAbnormal = twoWayFee + maintenanceMarginTemp - markPricePNL;
  //     const sellCost = Math.max(costNormal, costAbnormal);
  //     // this.sellCost = Math.round(sellCost * 10000) / 10000;

  //     if (liquidationPrice < 0 || liquidationPrice > this.currContractData.maxPrice) {
  //       if (this.currContractData != null && this.currContractData !== undefined) {
  //         liquidationPrice = this.currContractData.maxPrice;
  //       }
  //     } else if (liquidationPrice < this.currContractData.minPriceINC) {
  //       liquidationPrice = this.currContractData.minPriceINC;
  //     } else {
  //       liquidationPrice = liquidationPrice;
  //     }
  //     console.log('liquidationPrice sell', Math.round(liquidationPrice * 10) / 10);
  //     if (liquidationPrice <= markPrice) {
  //       this.willOrderLiquidateInstantly = true;
  //     }

  //     newMargin = this.orderMargin;
  //     if (this.walletbal != null && this.walletbal !== undefined && this.walletbal.length !== 0) {
  //       let tempValue: any;
  //       Object.entries(this.walletbal.breakdown).forEach(([key, value]) => {
  //         tempValue = value;
  //         if (this.currContract.contractName == key) {
  //           sellCostTemp = Math.max((newMargin - tempValue.order), 0);
  //           // console.log('sellCostTemp', sellCostTemp, tempValue.order);
  //         }
  //       });
  //     }

  //     // this.sellCost = sellCostTemp;
  //     // console.log('sellCostTemp', costNormal, costAbnormal);
  //     // if (costNormal >= costAbnormal) {
  //     //   this.sellCost = sellCostTemp;
  //     // } else if (costNormal < costAbnormal) {
  //     //   this.sellCost = costAbnormal;
  //     // }

  //     // console.log('this.sellCost', this.sellCost, newMargin);
  //   }
  // }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    if (this.currContDetailsSub) {
      this.currContDetailsSub.unsubscribe();
    }
    this.sendCintractsub.unsubscribe();
  }

}
