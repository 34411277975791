import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-indices',
  templateUrl: './indices.component.html',
  styleUrls: ['./indices.component.scss']
})
export class IndicesComponent implements OnInit {

  indicesItem = {
    chartName: 'indices-charts',
    currencyName: null,
  };

  indicesList: any;
  indicesItemDetails: any;
  selectedindicesKey: any;
  indicesItemDetailedBreakdown: any;
  tempValues: any;
  currentKey: any;
  innerWidth: number;
  hideSideTabs = false;

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1199) {
      this.hideSideTabs = true;
    } else {
      this.hideSideTabs = false;
    }
  }

  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  if (this.innerWidth <= 1199) {
    this.hideSideTabs = true;
  } else {
    this.hideSideTabs = false;
  }
}

}
