import {
  Component,
  OnInit,
  NgZone,
  EventEmitter,
  Output
} from '@angular/core';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import {
  ApiService
} from 'src/app/services/api.service';

@Component({
  selector: 'app-positions',
  templateUrl: './positions.component.html',
  styleUrls: ['./positions.component.scss']
})
export class PositionsComponent implements OnInit {

  userPositions = [];
  closedPositions = [];

  PMBStatus = false;
  CPMBStatus = false;
  selectedRowData = {
    size: 0,
    margin: 0
  };


  updatePositionMargin = {
    Positionid: '',
    operation: '',
    new_margine: null,
    Symbol: ''
  };

  markPriceValue: any;

  marginMode = 'add';
  currContractData: any;
  currSelectedPosition: any;
  isDisabled: boolean;

  closePositionMode = 'Limit';

  DataToClosePosition = {
    Positionid: '',
    Symbol: '',
    MarketPrice: 0,
    orderType: ''
  };

  lastTradePrice = 0;
  LTP: any;
  posSide: string;
  currContract: any;
  walletbal: any;
  inputMarketPrice = 0;
  orderbyKey: any;

  sortOrder: string = '';
  orderByType: any;
  markPriceTicker: any;
  allMarkPriceTicker: any;
  contractsList: any;
  selectedContractGroupList: any;
  selectOrderSym = null;

  constructor(public webSocketService: WebsocketService, public zone: NgZone, public apiService: ApiService) {}

  ngOnInit() {

    this.apiService.sendSelectorContract.subscribe((contractData: any) => {
      if (contractData != null && contractData !== undefined && contractData.length !== 0) {
        this.currContract = contractData;
      }
    });

    this.apiService.sendSelectedContractDetail.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.currContractData = data;
      }
    });

    this.apiService.sendContractsDetails.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.contractsList = data;
        Object.entries(this.contractsList).forEach(([key, value]) => {
          if (this.currContract != null && this.currContract !== undefined) {
            if (key == this.currContract.contractGroupName) {
              this.selectedContractGroupList = value;
            }
          }
        });
      }
    });

    this.webSocketService.BalanceTicker.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data !== undefined && data != null && data.length !== 0) {
          this.walletbal = data;
        }
      });
    });

    this.webSocketService.positions.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
          this.userPositions = [];
          this.closedPositions = [];
          if (data.open == null || data.open == undefined || data.open.length == 0) {
            const avi = [];
            this.apiService.getCurrentPostionSize(avi);
          }

          data.open.forEach((element: any, index: any) => {

            if (this.userPositions != null && this.userPositions !== undefined && this.userPositions.length > 0) {
              let found = false;
              this.userPositions.forEach((element2: any) => {
                if (element2.sym == element.sym) {
                  element2.sym = element.sym;
                  element2.size = element.size;
                  element2.value = element.value;
                  element2.collateral_currency = element.collateral_currency;
                  element2.entry_price = element.entry_price;
                  if (this.selectedContractGroupList != null && this.selectedContractGroupList !== undefined
                    && this.selectedContractGroupList.length !== 0) {
                      this.selectedContractGroupList.forEach((contList: any) => {
                        if (element2.sym == contList.name) {
                          if (element.size >= 0) {
                            // sell case round up
                            let newMarkPrice = Math.round(element.mark_price);
                            if (newMarkPrice < element.mark_price) {
                              newMarkPrice = newMarkPrice + contList.minPriceINC;
                            }
                            element2.mark_price = newMarkPrice;
                            element2.minPriceINC = contList.minPriceINC;
                          } else {
                            // buy case round down
                            let newMarkPrice = Math.round(element.mark_price);
                            if (newMarkPrice > element.mark_price) {
                              newMarkPrice = newMarkPrice - contList.minPriceINC;
                            }
                            element2.mark_price = newMarkPrice;
                            element2.minPriceINC = contList.minPriceINC;
                          }

                          if (element.size >= 0) {
                            // sell case round up
                            let newliqPrice = Math.round(element.liq_price);
                            if (newliqPrice < element.liq_price) {
                              newliqPrice = newliqPrice + contList.minPriceINC;
                            }
                            element2.liq_price = newliqPrice;
                          } else {
                            // buy case round down
                            let newliqPrice = Math.round(element.liq_price);
                            if (newliqPrice > element.liq_price) {
                              newliqPrice = newliqPrice - contList.minPriceINC;
                            }
                            element2.liq_price = newliqPrice;
                          }
                        }
                      });
                  }

                  element2.liq_price = element.liq_price;
                  element2.margin = Math.round(element.margin * Math.pow(10, 8)) / Math.pow(10, 8);
                  element2.leverage_x = element.leverage_x;
                  element2.uPNL = Math.round(element.uPNL * Math.pow(10, 2)) / Math.pow(10, 2);
                  element2.uPNL_ROE = element.uPNL_ROE;
                  element2.rPNL = element.rPNL;
                  found = true;
                }
              });
              if (!found) {
                const tempUserPos = {
                  pid: element.pid,
                  sym: element.sym,
                  size: element.size,
                  value: element.value,
                  collateral_currency: element.collateral_currency,
                  entry_price: element.entry_price,
                  mark_price: element.mark_price,
                  liq_price: element.liq_price,
                  margin: Math.round(element.margin * Math.pow(10, 8)) / Math.pow(10, 8),
                  leverage_x: element.leverage_x,
                  uPNL: Math.round(element.uPNL * Math.pow(10, 2)) / Math.pow(10, 2),
                  uPNL_ROE: element.uPNL_ROE,
                  rPNL: element.rPNL,
                  minPriceINC: 0,
                };

                if (this.selectedContractGroupList != null && this.selectedContractGroupList !== undefined
                  && this.selectedContractGroupList.length !== 0) {
                    this.selectedContractGroupList.forEach((contList: any) => {
                      if (element.sym == contList.name) {
                        tempUserPos.minPriceINC = contList.minPriceINC;

                        if (element.size >= 0) {
                          // sell case round up
                          let newMarkPrice = Math.round(element.mark_price);
                          if (newMarkPrice < element.mark_price) {
                            newMarkPrice = newMarkPrice + contList.minPriceINC;
                          }
                          tempUserPos.mark_price = newMarkPrice;
                        } else {
                          // buy case round down
                          let newMarkPrice = Math.round(element.mark_price);
                          if (newMarkPrice > element.mark_price) {
                            newMarkPrice = newMarkPrice - contList.minPriceINC;
                          }
                          tempUserPos.mark_price = newMarkPrice;
                        }

                        if (element.size >= 0) {
                          // sell case round up
                          let newliqPrice = Math.round(element.liq_price);
                          if (newliqPrice < element.liq_price) {
                            newliqPrice = newliqPrice + contList.minPriceINC;
                          }
                          tempUserPos.liq_price = newliqPrice;
                        } else {
                          // buy case round down
                          let newliqPrice = Math.round(element.liq_price);
                          if (newliqPrice > element.liq_price) {
                            newliqPrice = newliqPrice - contList.minPriceINC;
                          }
                          tempUserPos.liq_price = newliqPrice;
                        }
                      }
                    });
                  }

                this.userPositions.unshift(tempUserPos);
              }
            } else {

              const tempUserPos = {
                pid: element.pid,
                sym: element.sym,
                size: element.size,
                value: element.value,
                collateral_currency: element.collateral_currency,
                entry_price: element.entry_price,
                mark_price: element.mark_price,
                liq_price: element.liq_price,
                margin: Math.round(element.margin * Math.pow(10, 8)) / Math.pow(10, 8),
                leverage_x: element.leverage_x,
                uPNL: Math.round(element.uPNL * Math.pow(10, 2)) / Math.pow(10, 2),
                uPNL_ROE: element.uPNL_ROE,
                rPNL: element.rPNL,
                minPriceINC: 0,
              };

              if (this.selectedContractGroupList != null && this.selectedContractGroupList !== undefined
                && this.selectedContractGroupList.length !== 0) {
                  this.selectedContractGroupList.forEach((contList: any) => {
                    if (element.sym == contList.name) {
                      tempUserPos.minPriceINC = contList.minPriceINC;

                      if (element.size >= 0) {
                        // sell case round up
                        let newMarkPrice = Math.round(element.mark_price);
                        if (newMarkPrice < element.mark_price) {
                          newMarkPrice = newMarkPrice + contList.minPriceINC;
                        }
                        tempUserPos.mark_price = newMarkPrice;
                      } else {
                        // buy case round down
                        let newMarkPrice = Math.round(element.mark_price);
                        if (newMarkPrice > element.mark_price) {
                          newMarkPrice = newMarkPrice - contList.minPriceINC;
                        }
                        tempUserPos.mark_price = newMarkPrice;
                      }

                      if (element.size >= 0) {
                        // sell case round up
                        let newliqPrice = Math.round(element.liq_price);
                        if (newliqPrice < element.liq_price) {
                          newliqPrice = newliqPrice + contList.minPriceINC;
                        }
                        tempUserPos.liq_price = newliqPrice;
                      } else {
                        // buy case round down
                        let newliqPrice = Math.round(element.liq_price);
                        if (newliqPrice > element.liq_price) {
                          newliqPrice = newliqPrice - contList.minPriceINC;
                        }
                        tempUserPos.liq_price = newliqPrice;
                      }
                    }
                  });
                }

              this.userPositions.unshift(tempUserPos);
            }
          });

          data.closed.forEach((element2: any) => {
            this.closedPositions.unshift(element2);
            if (this.userPositions != null && this.userPositions !== undefined && this.userPositions.length !== 0) {
              this.userPositions.forEach((openPos: any, index: any) => {
                if (this.closedPositions != null && this.closedPositions !== undefined && this.closedPositions.length !== 0) {
                  this.closedPositions.forEach((closedPos: any) => {
                    if (closedPos.pid === openPos.pid) {
                      this.userPositions.splice(index, 1);
                    }
                  });
                }
              });
            }
          });

          this.userPositions.forEach((element: any) => {
            if (this.currContract != null && this.currContract !== undefined) {
              if (element.sym == this.currContract.contractName) {
                this.apiService.getCurrentPostionSize(element);
              }
            }
          });

          // console.log('this.userPositions', this.userPositions);
        }
      });
    });

    // this.webSocketService.markPrice.subscribe((data: any) => {
    //   if (data != null && data !== undefined && data.length !== 0) {
    //     this.markPriceTicker = data;
    //     if (this.userPositions != null && this.userPositions !== undefined) {
    //       this.userPositions.forEach((element: any) => {
    //         if (this.markPriceTicker.C == element.sym) {
    //           if (element.size >= 0) {
    //             // sell case round up
    //             let inputMarkPrice = Math.round(this.markPriceTicker.P);
    //             if (inputMarkPrice < this.markPriceTicker.P) {
    //               inputMarkPrice = inputMarkPrice + 0.5;
    //             }
    //             element.mark_price = inputMarkPrice;
    //           } else {
    //            // buy case round down
    //            let inputMarkPrice = Math.round(this.markPriceTicker.P);
    //            if (inputMarkPrice > this.markPriceTicker.P) {
    //              inputMarkPrice = inputMarkPrice - 0.5;
    //            }
    //            element.mark_price = inputMarkPrice;
    //           }
    //         }
    //       });
    //     }
    //   }
    // });

    // this.webSocketService.allMarkPrice.subscribe((data: any) => {
    //   if (data != null && data !== undefined && data.length !== 0) {
    //     this.allMarkPriceTicker = data;

    //     if (this.userPositions != null && this.userPositions !== undefined) {
    //       this.userPositions.forEach((element: any) => {
    //         if (this.allMarkPriceTicker.C == element.sym) {

    //           if (element.size >= 0) {
    //             // sell case round up
    //             let inputMarkPrice = Math.round(this.allMarkPriceTicker.P);
    //             if (inputMarkPrice < this.allMarkPriceTicker.P) {
    //               inputMarkPrice = inputMarkPrice + 0.5;
    //             }
    //             element.mark_price = inputMarkPrice;

    //           } else {
    //            // buy case round down
    //            let inputMarkPrice = Math.round(this.allMarkPriceTicker.P);
    //            if (inputMarkPrice > this.allMarkPriceTicker.P) {
    //              inputMarkPrice = inputMarkPrice - 0.5;
    //            }
    //            element.mark_price = inputMarkPrice;

    //           }
    //         }
    //       });
    //     }
    //   }
    // });

    this.apiService.lastTradeDetails.subscribe((lastPrice: any) => {
      if (lastPrice != null && lastPrice !== undefined && lastPrice.length !== 0) {
        if (this.lastTradePrice == 0) {
          this.LTP = lastPrice[0].p;
        }
        this.lastTradePrice = lastPrice[0].p;
      }
    });
  }

  OpenPostionMarginBox(size: any, margin: any, item: any) {
    this.selectedRowData.size = size;
    this.selectedRowData.margin = margin;
    // this.currSelectedPosition = item;
    this.updatePositionMargin.Positionid = item.pid;
    this.updatePositionMargin.Symbol = item.sym;
    this.PMBStatus = true;
  }

  closePostionMarginBox() {
    this.PMBStatus = false;
  }

  selectMarkPrice(data: any) {
    this.selectOrderSym = data.sym;
    if (this.selectOrderSym == data.sym) {
      this.inputMarketPrice = data.mark_price;
    }
  }

  async updatePosMargin() {
    if (this.marginMode == 'remove') {
      this.updatePositionMargin.operation = 'update';
    } else {
      this.updatePositionMargin.operation = this.marginMode;
    }
    this.PMBStatus = false;
    let result = await this.apiService.positionUpdateMargine(this.updatePositionMargin).catch(err => {
      this.apiService.ShowToastrAlert('', err.description, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      this.apiService.ShowToastrAlert('', result.message, 'success');
  }

  async closeYourPosition() {
    this.CPMBStatus = false;
    let result = await this.apiService.requestPosCloseOrderMarkitPrice(this.DataToClosePosition).catch(err => {
      this.apiService.ShowToastrAlert('', err.description, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      this.apiService.ShowToastrAlert('', result.message, 'success');

  }

  openCloseMarketPosition(data: any, posData: any, mk: any) {

    this.currSelectedPosition = posData;
    this.DataToClosePosition.Symbol = posData.sym;
    this.DataToClosePosition.Positionid = posData.pid;
    this.DataToClosePosition.orderType = data;
    this.closePositionMode = data;


    if (this.selectedContractGroupList != null && this.selectedContractGroupList !== undefined
      && this.selectedContractGroupList.length !== 0) {
        this.selectedContractGroupList.forEach((contList: any) => {
          if (posData.sym == contList.name) {
            if (posData.size >= 0) {
              // sell case round up
              this.posSide = 'Sell';
              if (this.closePositionMode == 'Market') {
                if (this.lastTradePrice != null && this.lastTradePrice !== undefined) {
                  let ltp = Math.round(this.lastTradePrice);
                  if (ltp < this.lastTradePrice) {
                    ltp = ltp + contList.minPriceINC;
                  }
                  this.DataToClosePosition.MarketPrice = ltp;
                }
              } else {
                if (this.selectOrderSym == posData.sym) {
                  // console.log('edited Sell');
                  if (this.inputMarketPrice == null || this.inputMarketPrice == undefined || this.inputMarketPrice == 0) {
                    let markPrice = Math.round(parseFloat(mk));
                    if (markPrice < parseFloat(mk)) {
                      markPrice = markPrice + contList.minPriceINC;
                    }
                    this.DataToClosePosition.MarketPrice = markPrice;
                  } else {
                    let inputMarkPrice = Math.round(this.inputMarketPrice);
                    if (inputMarkPrice < this.inputMarketPrice) {
                      inputMarkPrice = inputMarkPrice + contList.minPriceINC;
                    }
                    this.DataToClosePosition.MarketPrice = inputMarkPrice;
                  }
                } else {
                  // console.log('nothing edited Sell');
                  let markPrice = Math.round(parseFloat(mk));
                  if (markPrice < parseFloat(mk)) {
                    markPrice = markPrice + contList.minPriceINC;
                  }
                  this.DataToClosePosition.MarketPrice = markPrice;
                }

              }
            } else {
              // buy case round down
              this.posSide = 'Buy';
              if (this.closePositionMode == 'Market') {
                if (this.lastTradePrice != null && this.lastTradePrice !== undefined) {
                  let lastTradePrice = Math.round(this.lastTradePrice);
                  if (lastTradePrice > this.lastTradePrice) {
                    lastTradePrice = lastTradePrice - contList.minPriceINC;
                  }
                  this.DataToClosePosition.MarketPrice = lastTradePrice;
                }
              } else {
                if (this.selectOrderSym == posData.sym) {
                // console.log('edited Buy');
                if (this.inputMarketPrice == null || this.inputMarketPrice == undefined || this.inputMarketPrice == 0) {
                  let markPrice = Math.round(parseFloat(mk));
                  if (markPrice > parseFloat(mk)) {
                    markPrice = markPrice - contList.minPriceINC;
                  }
                  this.DataToClosePosition.MarketPrice = markPrice;
                } else {
                  let inputMarkPrice = Math.round(this.inputMarketPrice);
                  if (inputMarkPrice > this.inputMarketPrice) {
                    inputMarkPrice = inputMarkPrice - contList.minPriceINC;
                  }
                  this.DataToClosePosition.MarketPrice = inputMarkPrice;
                }
              } else {
                // console.log('nothing edited Buy');
                let markPrice = Math.round(parseFloat(mk));
                if (markPrice > parseFloat(mk)) {
                  markPrice = markPrice - contList.minPriceINC;
                }
                this.DataToClosePosition.MarketPrice = markPrice;
              }
              }
            }
          }
        });
    }



    // if (posData.size >= 0) {
    //   this.posSide = 'Sell';
    //   if (this.closePositionMode == 'Market') {
    //     if (this.lastTradePrice != null && this.lastTradePrice !== undefined) {
    //       let ltp = Math.round(this.lastTradePrice * 10) / 10;
    //       ltp = 0.5 * (Math.floor(ltp / 0.5) + (ltp % 0.5 === 0 ? 0 : 1));
    //       this.DataToClosePosition.MarketPrice = ltp;
    //     }
    //   } else {
    //     if (this.inputMarketPrice == null || this.inputMarketPrice == undefined || this.inputMarketPrice == 0) {
    //       let markPrice = Math.round(parseFloat(mk) * 10) / 10;
    //       markPrice = 0.5 * (Math.floor(markPrice / 0.5) + (markPrice % 0.5 === 0 ? 0 : 1));
    //       this.DataToClosePosition.MarketPrice = markPrice;
    //     } else {
    //       let inputMarkPrice = Math.round(this.inputMarketPrice * 10) / 10;
    //       inputMarkPrice = 0.5 * (Math.floor(inputMarkPrice / 0.5) + (inputMarkPrice % 0.5 === 0 ? 0 : 1));
    //       this.DataToClosePosition.MarketPrice = inputMarkPrice;
    //     }
    //   }
    // } else {
    //   this.posSide = 'Buy';
    //   if (this.closePositionMode == 'Market') {
    //     if (this.lastTradePrice != null && this.lastTradePrice !== undefined) {
    //       let lastTradePrice = Math.round(this.lastTradePrice * 10) / 10;
    //       lastTradePrice = 0.5 * (Math.floor(lastTradePrice / 0.5));
    //       this.DataToClosePosition.MarketPrice = lastTradePrice;
    //     }
    //   } else {
    //     if (this.inputMarketPrice == null || this.inputMarketPrice == undefined || this.inputMarketPrice == 0) {
    //       let markPrice = Math.round(parseFloat(mk) * 10) / 10;
    //       markPrice = 0.5 * (Math.floor(markPrice / 0.5));
    //       this.DataToClosePosition.MarketPrice = markPrice;
    //     } else {
    //       let inputMarkPrice = Math.round(this.inputMarketPrice * 10) / 10;
    //       inputMarkPrice = 0.5 * (Math.floor(inputMarkPrice / 0.5));
    //       this.DataToClosePosition.MarketPrice = inputMarkPrice;
    //     }
    //   }
    // }
    this.CPMBStatus = true;
  }

  closeCloseMarketPosition() {
    this.CPMBStatus = false;
  }
  manualSorting(data: any) {
    this.orderbyKey = data;

    if (this.sortOrder == 'desc') {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = 'desc';
    }
  }

  // getContractsMenu() {
  //   this.apiService.requestContractsMenu().then((result: any) => {
  //     if (result.data != null && result.data !== undefined && result.data.length !== 0) {
  //       console.log('result', result.data);
  //     }
  //   });
  // }

  // getUserCurrPos() {
  //   this.userPositions.forEach((element: any) => {
  //     if (this.currContract != null && this.currContract !== undefined) {
  //       if (element.sym == this.currContract.contractName) {
  //         console.log('position size ****', element.size);
  //         this.apiService.getCurrentPostionSize(element);
  //       }
  //     }
  //   });
  // }

}
