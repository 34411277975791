declare var baseUrl;
import { Component, OnInit } from '@angular/core';
import { SwaggerUIBundle} from 'swagger-ui-dist';

@Component({
  selector: 'app-api-documentation',
  templateUrl: './api-documentation.component.html',
  styleUrls: ['./api-documentation.component.scss']
})
export class ApiDocumentationComponent implements OnInit {
  BaseUrl = baseUrl;
  constructor() { }

  ngOnInit() {
    const ui = SwaggerUIBundle({
      dom_id: '#swagger-ui',
      layout: 'BaseLayout',
      presets: [
        SwaggerUIBundle.presets.apis,
        SwaggerUIBundle.SwaggerUIStandalonePreset
      ],
      url:'./assets/Derivativesapi.json',
      operationsSorter: 'alpha'
    });
  }

}
