<ul class="lhYOwQ list-view">
    <li class="lhYOwQ" *ngFor="let item of contractsList | keyvalue: originalOrder; let i = index" style="position: relative;">
      <a href="javascript:void(0)" class="ibTopk mb-0 flex-nav" data-toggle="collapse" (click)="selectContracts(item.key)" [ngClass]="(selectedContractKey == item.key)?'is-active':''">
        {{item.key}} <i class="fas" [ngClass]="(selectedContractKey == item.key)?'fa-angle-up':'fa-angle-down'"></i></a>
      <div id="item.key" class="collapse triangle" [ngClass]="(selectedContractKey == item.key)?'show':''">
        <ul class="lhYOwQ mb-0">
          <li class="lhYOwQ" *ngFor="let item of item.value">
            <a href="javascript:void(0)" title="{{item.aliasName}}"
              [ngClass]="(contractItem.currencyName == item.name)?'is-active':''"
              (click)="GetContractDetails(item)">{{item.name}}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="lhYOwQ" style="position: relative;" *ngIf="!contractsList">
      <div id="loaderContainer" style="min-width: 100%;">
        <svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
          <polygon fill="transparent" stroke="#51cbad" stroke-width="1" points="16,0 32,32 0,32"></polygon>
        </svg>
      </div>
    </li>
  </ul>
