import {
  Component,
  OnInit,
  NgZone,
  OnDestroy
} from '@angular/core';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import {
  ApiService
} from 'src/app/services/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-recent-trades',
  templateUrl: './recent-trades.component.html',
  styleUrls: ['./recent-trades.component.scss']
})
export class RecentTradesComponent implements OnInit, OnDestroy {

  recentTrades = [];
  lastTradeTimeStamp = 0;
  recentTradeSub: Subscription;

  constructor(private webSocketService: WebsocketService, private apiService: ApiService, private zone: NgZone) {}

  ngOnInit() {

    this.webSocketService.changedContractDetails.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.recentTrades = [];
        this.lastTradeTimeStamp = 0;
        const emptyValue = null;
        this.apiService.getLastTradeDetails(emptyValue);
      }
    });

    this.recentTradeSub = this.webSocketService.tradeData.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
          const tradeData = data;

          if (tradeData != null && tradeData !== undefined && tradeData.length !== 0) {
            tradeData.forEach((element: any) => {
              if (this.recentTrades.length === 0) {
                const tempTrades = {
                  p: element.p,
                  q: element.q,
                  ts: element.ts,
                  s: element.s
                };
                this.recentTrades.push(tempTrades);
              } else if (this.lastTradeTimeStamp < element.ts) {
                const tempTrades = {
                  p: element.p,
                  q: element.q,
                  ts: element.ts,
                  s: element.s
                };
                this.recentTrades.push(tempTrades);
                this.recentTrades.sort((a, b) => b.ts - a.ts);
              }
            });
          }

          if (this.lastTradeTimeStamp === 0 && tradeData.length !== 0) {
            this.lastTradeTimeStamp = tradeData[0].ts;
          }

          if (this.recentTrades.length > 110) {
            this.recentTrades.length = 100;
          }

          this.apiService.getLastTradeDetails(this.recentTrades);

        }
      });
    });
  }

  ngOnDestroy() {
    this.recentTradeSub.unsubscribe();
  }

}
