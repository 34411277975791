import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  Router
} from '@angular/router';
import {
  AuthService
} from 'src/app/guard/auth.service';
import {
  CaptchaComponent
} from 'src/app/exchange/shared/captcha/captcha.component';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  resetPassDetails = {
    email: '',
    captcha: ''
  };

  @ViewChild(CaptchaComponent)
  private callCaptchaComponent: CaptchaComponent;

  emailValidateError = '';
  captchaValidateError = '';

  capString = 'purpose=ForgotPassword&h=80&w=370';

  IsResetComplete = false;

  ResetProcessing = false;

  reg: RegExp = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);

  ImgUrl: string;
  Settings: any;
  captchaEnabled = false;
  constructor(public apiService: ApiService, public router: Router, private auth: AuthService) {}

  ngOnInit() {
    this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.Settings = data;
        if(this.Settings.disable_login === 'true') {
          this.router.navigate(['/feature-disabled']);
        } else if (this.Settings.captcha_mode.toLowerCase() === 'disabled') {
          this.captchaEnabled = false;
        } else if (this.Settings.captcha_mode.toLowerCase() === 'self') {
          this.captchaEnabled = true;
        } else {
          this.captchaEnabled = false;
        }
      }
    });
  }

  emailValidate(): boolean {
    if (this.resetPassDetails.email === undefined || this.resetPassDetails.email == null ||
      this.reg.test(this.resetPassDetails.email) === false) {
      this.emailValidateError = 'Enter a valid email address';
      return false;
    } else {
      this.emailValidateError = '';
      return true;
    }
  }

  captchaValidate(): boolean {
    if (this.captchaEnabled !== false) {
      if (this.resetPassDetails.captcha === undefined || this.resetPassDetails.captcha == null ||
        this.resetPassDetails.captcha.trim() === '') {
        this.captchaValidateError = 'Captcha can\'t be empty';
        return false;
      } else {
        this.captchaValidateError = '';
        return true;
      }
    } else {
      return true;
    }
  }

  async resetPassNow() {
    if (this.emailValidate() === false || this.captchaValidate() === false) {
      return;
    } else {
      this.ResetProcessing = true;
      let result = await this.apiService.Request_ForgotPassword(this.resetPassDetails).catch(err => {
        if (this.captchaEnabled !== false) {
          this.callCaptcha();
        }
        this.apiService.ShowToastrAlert('', err.description, 'error');
        this.IsResetComplete = false;
        this.ResetProcessing = false;
      });
      if(result == undefined || result== null) {
        return
        }
        this.IsResetComplete = true;
        this.ResetProcessing = false;
        this.apiService.ShowToastrAlert('', result.data, 'success');
        this.router.navigate(['/login']);
    }
  }

  callCaptcha() {
    if (this.callCaptchaComponent != null && this.callCaptchaComponent !== undefined) {
      this.callCaptchaComponent.getCaptchaCode();
    }

  }

}
