import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { AuthService } from '../guard/auth.service';


@Injectable()
export class ContractsListResolver implements Resolve<any> {
   constructor(public apiService: ApiService, private authService: AuthService) { }

   resolve(route: ActivatedRouteSnapshot) {
      this.apiService.getLoadingStatus(true);
      return this.apiService.requestContractsList().pipe(
         catchError((error) => {
            this.apiService.ShowToastrAlert('', error, 'error');
            this.authService.logout('/login');
            this.apiService.getLoadingStatus(false);
            return of('No data');
         })
      );
   }
}
