import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {

  verifyData = {
    verificationLink: '',
    verificationLinkType: '',
  };

  result: any;
  Id: any;
  otp: any;
  Msg: any;
  Msgs: any;
  Msg2: any;
  isSignUpMsgHidden = true;
  constructor(public apiService: ApiService, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params != null) {
        this.verifyData.verificationLinkType =  params.type;
        this.verifyData.verificationLink = params.id;
      }
   });
   this.verifyaccount();

  }

  async verifyaccount(){
    let result = await this.apiService.Verify_Account(this.verifyData).catch(err => {
      if (this.verifyData.verificationLinkType !== 'signup') {
        this.Msg = 'Verification link is invalid or has expired.';
        this.isSignUpMsgHidden = true;
      } else {
        // tslint:disable-next-line:max-line-length
        this.Msg = 'It looks like the email verification link you\'ve used has expired or has been used already. If you believe it has expired, you can ';
        this.Msg2 = ' now which will automatically complete email verification for your account.';
        this.isSignUpMsgHidden = false;
      }
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data !== undefined && result.data != null && result.data.length !== 0 ) {
        this.Msgs = result.data;
        this.apiService.ShowToastrAlert('', this.Msgs, 'success');
        if (this.verifyData.verificationLinkType !== 'signup') {
          this.isSignUpMsgHidden = true;
          this.router.navigate(['/trade']);
        } else {
          this.isSignUpMsgHidden = false;
          this.router.navigate(['/login']);
        }
      }
  }
}
