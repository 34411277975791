<section class="auth-container" *ngIf="Settings?.disable_login != 'true'">
  <section class="login-panel">
    <header class="text-align-center">
      <!-- <div class="logLogo">
        <img src="../../../../assets/images/logo.png">
      </div> -->
      <h3 class="text-center my-3">{{'Signin.Header' | translate}}</h3>
    </header>
    <div class="body">
      <form class="no-margin">
        <input type="email" name="email" autofocus="" style="opacity: 0;position: absolute;z-index: -1">
        <input style="opacity: 0;position: absolute;z-index: -1">
        <input type="password" style="opacity: 0;position: absolute;z-index: -1;">
        <fieldset>
          <div class="floating-form">
            <div class="floating-label">
              <input class="floating-input" [(ngModel)]="loginDetails.email" type="email" name="email" placeholder=" "
                autocomplete="off" (blur)="emailValidate()" (keyup)="emailValidate()">
              <label>{{'Signin.Label1' | translate}}</label>
              <div class="ErrorMSG text-center" *ngIf="emailValidateError != ''">{{emailValidateError}}</div>
            </div>
          </div>
          <div class="floating-form">
            <div class="floating-label">
              <input class="floating-input" type="password" name="password" placeholder=" " autocomplete="off" [(ngModel)]="loginDetails.password" type="password"
              autocomplete="off" (blur)="passwordValidate()" (keyup)="passwordValidate()">
              <label>{{'Signin.Label2' | translate}}</label>
              <div class="ErrorMSG text-center" *ngIf="passwordValidateError != ''">{{passwordValidateError}}</div>
            </div>
          </div>

          <div class="" *ngIf="captchaEnabled !== false">
            <div class="row">
              <div class="col-sm-10 pr-sm-1 mb-2">
                <app-captcha [captchaString]="capString"></app-captcha>
                <div class="floating-form">
                    <div class="floating-label">
                      <input class="floating-input" [(ngModel)]="loginDetails.captcha" type="text" name="captcha" placeholder=" "
                        autocomplete="off" (blur)="captchaValidate()" (keyup)="captchaValidate()">
                      <label>{{'Signin.Label3' | translate}}</label>
                      <div class="ErrorMSG text-center" *ngIf="captchaValidateError != ''">{{captchaValidateError}}</div>
                    </div>
                  </div>
              </div>
              <div class="col-sm-2 pl-sm-1 mb-2">
                <button type="button" class="hoCLhT XWHA" (click)="callCaptcha()"><i
                    class="fas fa-redo"></i></button>
              </div>
            </div>
          </div>

        </fieldset>
        <div class="form-actions">
          <button class="GoBtn hoCLhT mt-2" type="submit" (click)="loginAccount()"
            [disabled]="SignInProcessing">{{(SignInProcessing? 'Signin.Process' : 'Login')|translate}}</button>
          <div class="row my-3">
            <div class="col-sm-6 text-center">
              <a class="forgot" href="javascript:void(0)" [routerLink]="['/register']">{{'Signup' | translate}}</a>
            </div>
            <div class="col-sm-6 text-center">
              <a class="forgot" href="javascript:void(0)" [routerLink]="['/reset-password']">{{'FgtPass' | translate}}</a>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</section>
