import {
  Component,
  OnInit,
  NgZone,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import * as Highcharts from 'highcharts';
import * as Exporting from 'highcharts/modules/exporting';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import {
  Subscription
} from 'rxjs';
import {
  ApiService
} from 'src/app/services/api.service';

@Component({
  selector: 'app-depth-chart',
  templateUrl: './depth-chart.component.html',
  styleUrls: ['./depth-chart.component.scss']
})
export class DepthChartComponent implements OnInit, OnDestroy {
  interval: any;
  subscription: Subscription;

  sellOrders = {}; // main value
  buyOrders = {};
  sellOrdersToBeShown = {}; // filtered value
  buyOrdersToBeShown = {}; // filtered value

  BuyTickerPlus: any;
  BuyTickerMinus: any;
  BuyTickerChange: any;

  SellTickerPlus: any;
  SellTickerMinus: any;
  SellTickerChange: any;

  snapshotBuy: any;
  changedBuyTicker: any;
  deletedBuyTicker: any;
  snapshotBuyOrder: any;

  changedSellTicker: any;
  deletedSellTicker: any;
  snapshotSellOrder: any;

  SellMaxVolume: any;
  BuyMaxVolume: any;
  maxValue: any;
  sellMaxValue: any;
  sellMinValue: any;
  buyMaxValue: any;
  SellBookWidth: number;
  BuyBookWidth: number;
  maxWithOrderbook: number;

  Asks = [];
  Bids = [];
  AsksBack = [];
  BidsBack = [];

  chartcolorSubscription: Subscription;
  themeMode: any;
  gridcolor: any;
  themeColor: any;
  colchartColor: any;

  constructor(public webSocketService: WebsocketService, public zone: NgZone, public apiService: ApiService) {}

  ngOnInit() {

    this.subscription = this.apiService.sendCurrentCurr.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.chartcolorSubscription = this.apiService.ChartColor.subscribe((mode: any) => {
          if (mode == 'Light') {
            this.themeMode = '#ffffff';
            this.themeColor = '#9ea1a9';
            this.colchartColor = '#cacaca';
            this.createchart();
          } else if (mode == 'Dark') {
            this.themeMode = '#131722';
            this.themeColor = '#d9d9d9';
            this.colchartColor = '#000000';
            this.createchart();
          }
        });
      }
    });

    this.webSocketService.BuyTicker.subscribe((dataBuyTicker: any) => {
      this.zone.run(() => { // <== added
        if (dataBuyTicker != null && dataBuyTicker !== undefined) {
          if (dataBuyTicker.type == 'snapshot') {
            this.buyOrders = {};
            this.buyOrdersToBeShown = [];
            const buySnapshot = dataBuyTicker.buy;
            Object.entries(buySnapshot).forEach(([key, value]) => {
              const temp = {};
              temp[key] = value;
              this.snapshotBuyOrder = temp;

              let Price: any;
              let Quantity: any;
              Price = parseFloat(Object.keys(this.snapshotBuyOrder)[0]);
              Quantity = Object.values(this.snapshotBuyOrder)[0];
              const tempBuyOrders = {
                q: Quantity,
                cs: Quantity,
              };
              this.buyOrders[Price] = tempBuyOrders;
            });
          } else if (dataBuyTicker.type == 'ticker') {
            const buyTicker = dataBuyTicker.buy;
            // console.group('buy ticker', buyTicker);
            this.BuyTickerPlus = null;
            this.BuyTickerMinus = null;
            const updated = {...buyTicker['inserted'], ...buyTicker['changed']};
            Object.entries(updated).forEach(([key, value]) => {
              let Price: any;
              let Quantity: any;
              Price = parseFloat(key);
              Quantity = value;
              const tempBuyOrders = {
                q: Quantity,
                cs: Quantity,
              };
              const existingVALUE = this.buyOrders[Price];
              const existingKEY = Price;

              if (existingVALUE == undefined || existingKEY == undefined) {
                // New value
                const getSpace = document.getElementById('buyBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_danger');
                  getSpace.classList.remove('blinker_success');
                  this.BuyTickerPlus = existingKEY;
                }

              } else if (existingVALUE.q > Quantity) {
                // Decreased
                const getSpace = document.getElementById('buyBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_danger');
                  getSpace.classList.remove('blinker_success');
                  this.BuyTickerMinus = existingKEY;
                }

              } else if (existingVALUE.q < Quantity) {
                // Increased
                const getSpace = document.getElementById('buyBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_success');
                  getSpace.classList.remove('blinker_danger');
                  this.BuyTickerPlus = existingKEY;
                }

              } else {
                // No change
              }
              this.buyOrders[Price] = tempBuyOrders;
            });

            Object.entries(buyTicker['deleted']).forEach(([key, value]) => {
              delete this.buyOrders[parseFloat(key)];
            });
          }

          let arrayKeys = [];
          const arrayKeysFinal = [];
          arrayKeys = Object.keys(this.buyOrders);
          arrayKeys.forEach((k2) => {
            arrayKeysFinal.push(parseFloat(k2));
          });

          arrayKeysFinal.sort((a, b) => b - a);

          let previousdata = undefined;
          this.buyOrdersToBeShown = [];
          arrayKeysFinal.forEach((element: any, index: any) => {

            // if (index >= 7) {
            //   return;
            // }
            if (index === 0) {
              this.buyMaxValue = parseFloat(element);
            }

            let existingdata = this.buyOrders[element];

            if (previousdata !== undefined) {
              existingdata['cs'] = previousdata['cs'] + existingdata['q'];
            }
            this.buyOrdersToBeShown[element] = existingdata;
            previousdata = existingdata;

            this.BuyMaxVolume = parseFloat(this.buyOrdersToBeShown[element].cs);
            if (this.SellMaxVolume == null && this.SellMaxVolume == undefined) {
              this.maxValue = parseFloat(this.BuyMaxVolume);
            } else if (this.BuyMaxVolume > this.SellMaxVolume) {
              this.maxValue = parseFloat(this.BuyMaxVolume);
            }

          });

          if (this.buyOrdersToBeShown != null && this.buyOrdersToBeShown !== undefined && Object.keys(this.buyOrdersToBeShown).length > 0) {
            if (document.getElementById('Orderbook') !== undefined && document.getElementById('Orderbook') !== null) {
              this.maxWithOrderbook = document.getElementById('Orderbook').offsetWidth;
            }
          }

          //   console.log('buy data', this.buyOrdersToBeShown);
          this.buyDataConverter();
        }
      });
    });

    this.webSocketService.SellTicker.subscribe((dataSellTicker: any) => {
      this.zone.run(() => { // <== added
        if (dataSellTicker != null && dataSellTicker !== undefined) {

          if (dataSellTicker.type == 'snapshot') {
            this.sellOrders = {};
            this.sellOrdersToBeShown = [];
            const sellSnapshot = dataSellTicker.sell;
            Object.entries(sellSnapshot).forEach(([key, value]) => {
              const temp = {};
              temp[key] = value;
              this.snapshotSellOrder = temp;

              let Price: any;
              let Quantity: any;
              Price = parseFloat(Object.keys(this.snapshotSellOrder)[0]);
              Quantity = Object.values(this.snapshotSellOrder)[0];
              const tempSellOrders = {
                q: Quantity,
                cs: Quantity,
              };
              this.sellOrders[Price] = tempSellOrders;
            });
          } else if (dataSellTicker.type == 'ticker') {
            const sellTicker = dataSellTicker.sell;
            // console.group('sell ticker', sellTicker);
            this.SellTickerPlus = null;
            this.SellTickerMinus = null;
            const updated = {...sellTicker['inserted'], ...sellTicker['changed']};
            Object.entries(updated).forEach(([key, value]) => {
              let Price: any;
              let Quantity: any;
              Price = parseFloat(key);
              Quantity = value;
              const tempSellOrders = {
                q: Quantity,
                cs: Quantity,
              };
              const existingVALUE = this.sellOrders[Price];
              const existingKEY = Price;

              if (existingVALUE == undefined || existingKEY == undefined) {
                // New value
                const getSpace = document.getElementById('sellBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_danger');
                  getSpace.classList.remove('blinker_success');
                  this.SellTickerPlus = existingKEY;
                }

              } else if (existingVALUE.q > Quantity) {
                // Decreased
                const getSpace = document.getElementById('sellBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_danger');
                  getSpace.classList.remove('blinker_success');
                  this.SellTickerMinus = existingKEY;
                }

              } else if (existingVALUE.q < Quantity) {
                // Increased
                const getSpace = document.getElementById('sellBlink' + parseFloat(existingKEY));
                if (getSpace != null && getSpace !== undefined) {
                  getSpace.classList.remove('blinker_success');
                  getSpace.classList.remove('blinker_danger');
                  this.SellTickerPlus = existingKEY;
                }

              } else {
                // No change
              }
              this.sellOrders[Price] = tempSellOrders;
              let messageBody = document.querySelector('#messageBody');
              if (messageBody != null && messageBody !== undefined) {
                messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
              }
            });

            Object.entries(sellTicker['deleted']).forEach(([key, value]) => {
              delete this.sellOrders[parseFloat(key)];
            });

          }

          let arrayKeys = [];
          const arrayKeysFinal = [];
          arrayKeys = Object.keys(this.sellOrders);
          arrayKeys.forEach((k2) => {
            arrayKeysFinal.push(parseFloat(k2));
          });

          arrayKeysFinal.sort((a, b) => a - b);

          let previousdata = undefined;

          this.sellOrdersToBeShown = [];
          arrayKeysFinal.forEach((element: any, index: any) => {
            // if (index >= 7) {
            //   return;
            // }
            this.sellMaxValue = parseFloat(element);

            let existingdata = this.sellOrders[element];
            if (previousdata !== undefined) {
              existingdata['cs'] = previousdata['cs'] + existingdata['q'];
            }

            this.sellOrdersToBeShown[element] = existingdata;
            previousdata = existingdata;

            this.SellMaxVolume = parseFloat(this.sellOrdersToBeShown[element].cs);
            if (this.BuyMaxVolume == null && this.BuyMaxVolume == undefined) {
              this.maxValue = parseFloat(this.SellMaxVolume);
            } else if (this.SellMaxVolume > this.BuyMaxVolume) {
              this.maxValue = parseFloat(this.SellMaxVolume);
            }
          });

          // tslint:disable-next-line:max-line-length
          if (this.sellOrdersToBeShown != null && this.sellOrdersToBeShown !== undefined && Object.keys(this.sellOrdersToBeShown).length > 0) {
            if (document.getElementById('Orderbook') !== undefined && document.getElementById('Orderbook') !== null) {
              this.maxWithOrderbook = document.getElementById('Orderbook').offsetWidth;
            }
          }

          const chatHistory = document.getElementById('messageBody');
          if (chatHistory != null && chatHistory !== undefined) {
            chatHistory.scrollTop = chatHistory.scrollHeight;
          }

          //   console.log('sell data', this.sellOrdersToBeShown);
          this.sellDataConverter();
        }
      });
    });

    // this.interval = setInterval(() => {
    //   this.createchart();
    // }, 2000);

  }

  buyDataConverter() {
    let tempAskArray = [];
    Object.entries(this.buyOrdersToBeShown).forEach(([key, value]) => {
      const tempData = {
        p: parseFloat(key),
        q: value['q'],
      };
      tempAskArray.push(tempData);
    });

    // tempAskArray = tempAskArray.slice(0, 20);

    tempAskArray.sort((a: any, b: any) => {
        return b.p - a.p;
      });

    const tempAsk = [];
    let comulativeVol = 0;
    tempAskArray.forEach((element: any) => {
        comulativeVol += element.q;
        tempAsk.push([element.p, comulativeVol]);
    });

    tempAsk.sort((a: any, b: any) => {
      return a[0] - b[0];
    });
    this.Asks = tempAsk;
    // console.log('this.Asks ', this.Asks);

    const maxVolTot = Math.max(...(this.Asks.map(x => x[1])));
    const maxVol = Math.max(...(tempAskArray.map(x => x.q)));

    const AsksBack = [];
    tempAskArray.forEach(element => {
      AsksBack.push([element.p, element.q * maxVolTot / maxVol]);
    });
    AsksBack.sort((a: any, b: any) => {
      return a[0] - b[0];
    });
    this.AsksBack = AsksBack;
    // console.log('this.AsksBack ', this.AsksBack);

    this.createchart();
  }

  sellDataConverter() {

    let tempBidArray = [];
    Object.entries(this.sellOrdersToBeShown).forEach(([key, value]) => {
      const tempData = {
        p: parseFloat(key),
        q: value['q'],
      };
      tempBidArray.push(tempData);
    });

    // tempBidArray = tempBidArray.slice(0, 20);

    tempBidArray.sort((a: any, b: any) => {
        return a.p - b.p;
      });

    const tempBid = [];
    let comulativeVol = 0;
    tempBidArray.forEach((element: any) => {
        comulativeVol += element.q;
        tempBid.push([element.p, comulativeVol]);
    });

    this.Bids = tempBid;
    // console.log('this.Bids ', this.Bids);

    const maxVolTot = Math.max(...(this.Bids.map(x => x[1])));
    const maxVol = Math.max(...(tempBidArray.map(x => x.q)));

    const BidsBack = [];
    tempBidArray.forEach(element => {
      BidsBack.push([element.p, element.q * maxVolTot / maxVol]);
    });
    this.BidsBack = BidsBack;
    // console.log('this.BidsBack ', this.BidsBack);

    this.createchart();
  }

  createchart() {
    Highcharts.chart('Asks', {
      chart: {
        type: 'area',
        zoomType: 'xy',
        marginRight: 0,
        marginBottom: 50,
        marginLeft: 15,
        animation: {
          duration: 1500,
          easing: 'easeOutBounce'
        },
        backgroundColor: this.themeMode,
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      xAxis: [{
        categories: this.Asks.map(x => x[0]),
        type: 'category',
        tickColor: '#363c4e',
        lineColor: '#363c4e',
        minTickInterval: 2,
        tickInterval: 10,
        gridLineColor: this.themeMode,
        title: {
          text: null,
          style: {
            color: '#000000'
          },
        },
        // Width: '50%',
        labels: {
          style: {
            color: 'green',
          },
        }
      }],
      yAxis: {
        tickColor: '#363c4e',
        lineColor: '#363c4e',
        gridLineColor: this.themeMode,
        lineWidth: 1,
        gridLineWidth: 1,
        title: {
          text: null,
          style: {
            color: this.colchartColor
          }
        },
        labels: {
          // format: '{value}',
          align: 'left',
          x: -4,
          style: {
            color: this.themeColor
          }
        }
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        area: {
          fillOpacity: 0.2,
          step: 'center',
          softThreshold: true,
          marker: {
            radius: 2
          },
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 3
            }
          },
          threshold: null
        },
        series: {
          gapSize: 3,
          animation: false,
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size=10px;">Price: {point.key}</span><br/>',
      },

      series: [{
          type: 'column',
          data: this.AsksBack.map(x => x[1]),
          color: this.colchartColor,
          borderColor: 'false',
        },
        {
          type: 'area',
          name: 'Asks',
          data: this.Asks.map(x => x[1]),
          color: '#28a745',
          fillColor: 'rgba(40, 167, 69, 0.38)',
          xAxis: 0,
        }
      ]
    });

    Highcharts.chart('Bids', {
      chart: {
        type: 'area',
        zoomType: 'xy',
        marginLeft: 0,
        marginBottom: 50,
        marginRight: 15,
        animation: {
          duration: 1500,
          easing: 'easeOutBounce'
        },
        backgroundColor: this.themeMode,
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      xAxis: [{
        categories: this.Bids.map(x => x[0]),
        type: 'category',
        minTickInterval: 2,
        maxPadding: 10,
        tickInterval: 10,
        tickColor: '#363c4e',
        lineColor: '#363c4e',
        gridLineColor: this.themeMode,
        title: {
          text: null,
          style: {
            color: this.colchartColor,
          },
        },
        // Width: '50%',
        labels: {
          style: {
            color: 'red'
          }
        },
        offset: 0,
      }],
      yAxis: {
        opposite: true,
        tickColor: '#363c4e',
        lineColor: '#363c4e',
        gridLineColor: this.themeMode,
        lineWidth: 1,
        gridLineWidth: 1,
        title: {
          text: null,
          style: {
            color: '#000000'
          }
        },
        labels: {
          format: '{value}',
          align: 'right',
          x: -4,
          style: {
            color: this.themeColor
          }
        }
      },
      legend: {
        padding: 3,
        enabled: false
      },
      plotOptions: {
        area: {
          fillOpacity: 0.2,
          step: 'center',
          softThreshold: true,
          marker: {
            radius: 2
          },
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 3
            }
          },
          threshold: null
        },
        series: {
          gapSize: 3,
          animation: false,
        },
      },

      tooltip: {
        headerFormat: '<span style="font-size=10px;">Price: {point.key}</span><br/>',
      },

      series: [{
          type: 'column',
          data: this.BidsBack.map(x => x[1]),
          color: this.colchartColor,
          borderColor: 'false',
        },
        {
          type: 'area',
          name: 'Bids',
          data: this.Bids.map(x => x[1]),
          color: '#c40708',
          fillColor: 'rgba(196, 7, 8, 0.38)',
          xAxis: 0,
        }
      ]
    });
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
