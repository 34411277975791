<div class="Go-table scrollable">
    <div class="Go-table-header sm-header">
      <div class="header-item" style="width:9%" (click)="manualSorting('sym')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH1' |translate}}</span>
      </div>
      <div class="header-item" style="width:9%"  (click)="manualSorting('q')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH2' |translate}}</span>
        </div>
      <div class="header-item" style="width:12%" (click)="manualSorting('p')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH3' |translate}}</span>
        </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('fill')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH4' |translate}}</span>
        </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('sp')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.stops.TH1' |translate}}</span>
        </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('fp')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH7' |translate}}</span>
        </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('t')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH8' |translate}}</span>
        </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('o')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.fills.TH4' |translate}}</span>
        </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('odst')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH9' |translate}}</span>
        </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('ts')">
         <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.activeorders.TH10' |translate}}</span>
        </div>
    </div>
    <div class="Go-table-body all-section-height" *ngIf="myOrders != null && myOrders !== undefined && myOrders.length !== 0">
      <div class="body-row tbl-striped" *ngFor="let item of myOrders | OrderBy: orderbyKey:orderByType: sortOrder; let i = index;" >
        <div class="body-item" style="width:9%" [ngClass]="(item?.s == 'S')?'Go-red-border-left' : 'Go-green-border-left'">{{item.sym}}</div>
        <div class="body-item" style="width:9%" [ngClass]="(item?.s == 'S')?'Go-red' : 'Go-green'"><span *ngIf="item.s == 'S'">-{{item.q}}</span><span *ngIf="item.s != 'S'">{{item.q}}</span></div>
        <div class="body-item" style="width:12%">{{item.p}}</div>
        <div class="body-item" style="width:10%" *ngIf="item.fill != 0" [ngClass]="(item?.s == 'S')?'Go-red' : 'Go-green'">{{item.fill}}</div>
        <div class="body-item" style="width:10%" *ngIf="item.fill == 0">-</div>
        <div class="body-item" style="width:10%" *ngIf="item.sp != 0">{{item.sp}}</div>
        <div class="body-item" style="width:10%"*ngIf="item.sp == 0">-</div>
        <div class="body-item" style="width:10%">{{item.fp}}</div>
        <div class="body-item" style="width:10%">{{item.t}}</div>
        <div class="body-item" style="width:10%">
          <span>{{item.o | slice:0:8}}
          </span> &nbsp;
          <span (click)="copy(item.o)"><i class="far fa-copy"></i></span>
        </div>
        <div class="body-item" style="width:10%">{{item.odst}}</div>
        <div class="body-item" style="width:10%" tooltip="{{item.ts | date: 'MMMM d, y, h:mm:ss a'}}">{{item.ts | date: ' h:mm a'}}</div>
      </div>
    </div>
  </div>

