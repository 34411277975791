import {
  Component,
  OnInit,
  HostListener,
  OnDestroy
} from '@angular/core';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  Router,
  NavigationEnd,
  ActivatedRoute
} from '@angular/router';
import {
  AuthService
} from 'src/app/guard/auth.service';
import {
  Subscription
} from 'rxjs';
import {
  KeyValue
} from '@angular/common';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  DateTime: any;
  siteMode: any = 'Day';
  MainOptions = false;
  IsExpended = false;
  menuSub = 'false';
  LoginStatus = false;
  IstickerBar = false;
  IstickerBarPinned = false;
  subscribelogStatus: Subscription;
  allSettingsSub: Subscription;
  userDetails: any;
  isLogin = false;
  LanguageDropDown = false;
  HideChartMarket = false;
  IsMarketOpen = false;
  enumdetails = [{
      name: 'EN'
    },
    {
      name: '中文'
    },
    {
      name: '한국어'
    },
  ];

  IsOptionModalOpen = false;

  settings: any;
  //  fiatList: any;
  Settings: any;
  // selectedFiatEst: any;

  cookieValue = 'UNKNOWN';
  contractsList: any;
  selectedKeyValue: any;

  contractSelector: any;

  currentContractsURL: any;
  currContractName: any;
  currentUrlName: any;

  CurrentLanguage: string;
  langSelected: any;
  TimeGap: boolean;
  // timeGapModalStatus = false;

  timeGapsubs: Subscription;
  innerWidth: number;
  Hiddenthings = false;

  constructor(
    public apiService: ApiService,
    public router: Router,
    private auth: AuthService,
    public activatedRoute: ActivatedRoute,
    public webSocketService: WebsocketService,
    private translate: TranslateService
  ) {
    let cl = localStorage.getItem('Language');
    if (cl === undefined || cl == null || cl === 'null' || cl.trim() === '') {
      this.langSelected = 'EN';
      localStorage.setItem('Language', this.langSelected);
    } else {
      this.langSelected = cl;
    }
    translate.setDefaultLang(this.langSelected);
    this.apiService.ChangeLang(this.langSelected);
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1199) {
      this.Hiddenthings = true;
    } else {
      this.Hiddenthings = false;
    }
    this.cookieValue = localStorage.getItem('Language');
    this.langSelected = this.cookieValue;
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url != null && event.url !== undefined && event.url !== '/') {
          const currUrl = event.url.split('/');
          this.currentUrlName = currUrl[1];
          if (this.currentUrlName == 'trade') {
            this.HideChartMarket = false;
            this.IsMarketOpen = false;
          } else {
            this.HideChartMarket = true;
            this.IsMarketOpen = false;
          }
        }
      }
    });

    this.apiService.userAuthStatus.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        if (data.authorization != null && data.authorization !== undefined) {
          this.isLogin = true;
       this.profile();
        } else {
          this.isLogin = false;
        }
      }
    });


    // Getting all default seetings from api service
    this.allSettingsSub = this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.Settings = data;
        // this.fiatList = data.fiat_list.split(',');
        // if (localStorage.getItem('currentFiatCurrency') == null) {
        //   this.selectedFiatEst = this.fiatList[0];
        //   localStorage.setItem(
        //     'currentFiatCurrency',
        //     this.selectedFiatEst
        //   );
        // } else {
        //   this.selectedFiatEst = localStorage.getItem(
        //     'currentFiatCurrency'
        //   );
        // }
      }
    });


    this.apiService.sendSelectorContract.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.contractSelector = data;
        // console.log('sendSelectorContract', data);
      }
    });

    this.apiService.sendContractsDetails.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        // console.log('sendContractsDetails', data);
        this.contractsList = data;
        if (!this.HideChartMarket) {
          this.extractKeyValue();
        }
        // console.log('+ contractSelector +', this.contractsList);
      }
    });



    // if (this.auth.isLoggedIn() !== false) {
    //   this.LoginStatus = true;
    //   this.apiService.requestUserProfile().then((result: any) => {
    //     if (result.data != null && result.data !== undefined && result.data !== '') {
    //       this.userDetails = result.data;
    //     }
    //   });
    // }

    const siteMode = localStorage.getItem('siteMode');
    if (
      siteMode === undefined ||
      siteMode == null ||
      siteMode === 'null' ||
      siteMode.trim() === ''
    ) {
      this.siteMode = 'Dark';
      this.apiService.chartcolor(this.siteMode);
      localStorage.setItem('siteMode', this.siteMode);
    }
    if (siteMode !== '' && siteMode != null && siteMode !== undefined) {
      this.CallMode(siteMode);
    }

    // console.log('%c Welcome to goesoteric.com', 'color: red; font-size: .85rem;');

    // this.timeGapsubs = this.apiService.timeGap.subscribe((TimeBetween: any) => {
    //   this.TimeGap = TimeBetween;
    //   if (TimeBetween === true) {
    //     // this.timeGapModalStatus = true;
    //   }
    // });
  }

  // closeTimeGapModal() {
  //   this.timeGapModalStatus = false;
  // }

  getCurrContractDetails() {
    if (this.selectedKeyValue != null  && this.selectedKeyValue !== undefined && this.selectedKeyValue.length !== 0) {
      this.selectedKeyValue.forEach((element: any) => {
        if (element.name == this.contractSelector.contractName) {
          this.apiService.getSelectedContractDetails(element);
        }
      });
    }
  }

  ChooseLanguage() {
    this.LanguageDropDown = !this.LanguageDropDown;
  }

  switchLanguage() {
    localStorage.setItem('Language', this.langSelected);
    this.langSelected = localStorage.getItem('Language');
    this.translate.use(this.langSelected);
    this.apiService.ChangeLang(this.langSelected);
    this.LanguageDropDown = false;
  }

 async logout() {
    let result = await this.apiService.requestLogout().catch(err => {
      this.apiService.ShowToastrAlert('', err.data, 'error');
     });
     if(result == undefined || result== null) {
       return
       }
       this.apiService.ShowToastrAlert('', result.data, 'success');

    if (JSON.parse(localStorage.getItem('persist:auth')) != null) {
      const authDetails = JSON.parse(localStorage.getItem('persist:auth'));
      authDetails.isAuthenticated = false;
      authDetails.authorization = null;
      localStorage.setItem('persist:auth', JSON.stringify(authDetails));
      this.apiService.Updateisloginstatus();
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  OpenMarket() {
    this.IsMarketOpen = !this.IsMarketOpen;
  }

  openOptionModal() {
    this.IsOptionModalOpen = true;
  }

  closeOptionModal() {
    this.IsOptionModalOpen = false;
  }

  // selectFiatEstimation(data: any) {
  //   this.selectedFiatEst = data;
  //   this.apiService.GetselectedFiatEst(this.selectedFiatEst);
  //   localStorage.setItem('currentFiatCurrency', this.selectedFiatEst);
  // }

  CallMode(data: any) {
    if (data == 'Light') {
      document.documentElement.setAttribute('data-theme', 'light');
      this.siteMode = 'Light';
      this.apiService.chartcolor(this.siteMode);
      localStorage.setItem('siteMode', this.siteMode);
    } else {
      this.siteMode = 'Dark';
      document.documentElement.setAttribute('data-theme', 'dark');
      this.apiService.chartcolor(this.siteMode);
      localStorage.setItem('siteMode', this.siteMode);
    }
  }

  // selectContracts(data: any) {
  //   this.contractSelector.contractGroupName = data;
  //   this.extractKeyValue();
  // }

  selectContractsValue(data: any) {
    this.contractSelector.contractName = data.name;
    localStorage.setItem('exchange:selectedContract', JSON.stringify(this.contractSelector));
    this.router.navigate(['/trade', this.contractSelector.contractName]);
    this.apiService.getContractSelector(this.contractSelector);
    this.apiService.sendContractDetails(data);
    this.apiService.getSelectedContractDetails(data);
    this.webSocketService.contractChanged(data);
    this.IsMarketOpen = false;
  }

  extractKeyValue() {
    Object.entries(this.contractsList).forEach(([key, value]) => {
      if (this.contractSelector != null && this.contractSelector !== undefined) {
        if (this.contractSelector.contractGroupName == key) {
          this.selectedKeyValue = value;
          this.getCurrContractDetails();
        }
      }
    });
  }

 async profile() {
    let result = await this.apiService.requestUserProfile().catch(err => {
     });
     if(result == undefined || result== null) {
       return
       }
       if (result.data != null && result.data !== undefined && result.data !== '') {
        this.userDetails = result.data;
      }
  }
  originalOrder = (
    a: KeyValue < number, string > ,
    b: KeyValue < number, string >
  ): number => {
    return 0;
  }

  openSideBar() {
    const sidebarClicked = true;
    this.apiService.getSidebarStatus(sidebarClicked);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1199) {
      this.Hiddenthings = true;
    } else {
      this.Hiddenthings = false;
    }
  }

  ngOnDestroy(): void {
    if (this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
      this.timeGapsubs.unsubscribe();
    }
  }
}
