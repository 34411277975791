<section class="auth-container widget-container">
  <section class="login-panel">
    <header class="text-align-center">
          <h3 class="text-center my-3" *ngIf="device">{{'DeviceVerify.NewDevice' |translate}}</h3>
          <h3 class="text-center my-3" *ngIf="!device">{{'Signin.Otp.Label' |translate}}</h3>
    </header>
    <div class="body p-3">
      <div *ngIf="device" class="row">
        <div class="floating-form col-md-8">
          <div class="floating-label">
            <input class="floating-input" [(ngModel)]="userDetails.otp" type="text" maxlength="6" name="password" placeholder=" " (blur)="otpValidate()" (keyup)="otpValidate();" (keypress)="onKeyPress($event)">
              <label for="otp">{{'DeviceVerify.otp' |translate}}</label>
            <div class="ErrorMSG text-center" *ngIf="otpValidateError != ''">{{otpValidateError}}</div>
          </div>
        </div>
        <div class="form-group text-center col-md-4">
          <button class="btn btn-outline-danger btn-block" type="button" (click)="sendOtp()" [disabled]="otpProcessing">{{'account.ChangePass.Otp' |translate}}</button>
      </div>
      </div>
   <div class="row">
    <div class="floating-form col-md-8">
      <div class="floating-label">
        <input class="floating-input" [(ngModel)]="userDetails.password" type="text" maxlength="6" name="password" placeholder=" " (blur)="passwordValidate()" (keyup)="passwordValidate();" (keypress)="onKeyPress($event)">
          <label for="password">{{'Signin.Otp.Label' | translate}}</label>
        <div class="ErrorMSG text-center" *ngIf="passwordValidateError != ''">{{passwordValidateError}}</div>
      </div>
    </div>
    <div class="form-group col-md-4 text-center">
      <button class="btn btn-outline-danger btn-block disabled" type="button" *ngIf="!showbtnresend"><span>{{countDown | async | formatTime}}</span></button>
      <button class="btn btn-outline-danger btn-block" type="button" (click)="ResendOtp()" *ngIf="showbtnresend">{{'Signin.Otp.BtnMsg' | translate}}</button>
  </div>
   </div>
      <div class="form-group">
        <button class="GoBtn hoCLhT" (click)="generateBearerToken()" [disabled]="SignInProcessing">{{(SignInProcessing? 'Signin.Process' : 'Login')|translate}}</button>
      </div>
    </div>
  </section>
</section>
