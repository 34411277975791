<div class="auth-container">
 <section class="login-panel text-center" *ngIf="isSignUpMsgHidden != true">
    <div class="body text-center" *ngIf="Msg">
      <h3 class="text-center">{{'Usrvrfy' | translate}}</h3>
      <p class="p-3 text-justify">
        {{Msg}}<a routerLink="/reset-password">reset your password</a>{{Msg2}}
      </p>
    </div>
    <div id=" body loaderContainer" *ngIf="!Msg" >
      <svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
        <polygon fill="transparent" stroke="#51cbad" stroke-width="1" points="16,0 32,32 0,32"></polygon>
      </svg>
    </div>
  </section>
  <section class="login-panel text-center" *ngIf="isSignUpMsgHidden != false">
    <div class="body" *ngIf="Msg">
      <p class="p-3 text-center">
        {{Msg}}
      </p>
    </div>
    <div id=" body loaderContainer" *ngIf="!Msg" >
      <svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
        <polygon fill="transparent" stroke="#51cbad" stroke-width="1" points="16,0 32,32 0,32"></polygon>
      </svg>
    </div>
  </section>
</div>
