import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    const referralID = this.activatedRoute.snapshot.paramMap.get('id');
    if (referralID != null) {
      localStorage.setItem('referral:ID', referralID);
      this.router.navigate(['/register']);
    } else {
      this.router.navigate(['/trade']);
    }


  }

}
