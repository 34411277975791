import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SettingsService } from '../services/settings.service';


@Injectable()
export class SettingsResolver implements Resolve<any> {
   constructor(public settingsService: SettingsService) { }

   resolve() {
      return this.settingsService.getSettings().pipe(
         catchError((error) => {
            return of('No data');
         })
      );
   }
}
