<section class="auth-container">
  <section class="login-panel">
    <div class="body">
      <div class="bottom">
        <h2 class="title">{{'Block.Header' | translate}}</h2>
        <p class="subtitle">{{'Block.p' | translate}}</p>
          <div class="buttons">
            <button class="btn block mr-2" (click)="block()">{{'Block.Btn1' | translate}}</button>
            <button class="btn cancel" (click)="cancel()">{{'Block.Btn2' | translate}}</button>
          </div>
      </div>
    </div>
  </section>
</section>
