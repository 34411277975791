<div class="widget-card RecentTrade">
  <div class="widget-card-header">
    <div>{{'trade.recentheader' | translate}}</div>
  </div>
  <div class="widget-card-body">
    <div>
      <div class="Go-table-sm">
        <div class="Go-table-sm-header">
          <div class="Go-table-sm-row">
            <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
              {{'trade.orderbook.Head1' | translate}}
            </div>
            <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
              {{'trade.orderbook.Head2' | translate}}
            </div>
            <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
              {{'trade.recenttrade.Head1' | translate}}
            </div>
          </div>
        </div>
        <div class="Go-table-sm-body" style="height: calc(50vh - 92px); overflow: auto; position: relative;">
          <div *ngFor="let item of recentTrades" class="tbl-striped">
            <div class="Go-table-sm-row" [ngClass]="(item?.s == 'S')?'Go-red' : 'Go-green'">
              <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
                {{item?.p}}
              </div>
              <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
                {{item?.q}}
              </div>
              <div class="Go-table-sm-item right-aligned-item" style="width:33.333%">
                {{item?.ts | date: 'HH:mm:ss'}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
