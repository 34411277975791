import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-redirect-customer',
  templateUrl: './redirect-customer.component.html',
  styleUrls: ['./redirect-customer.component.scss']
})
export class RedirectCustomerComponent implements OnInit {
  persistAuth = {
    isAuthenticated: false,
    authorization: null,
    gAuthEnabled: false,
  };
  constructor(public apiService: ApiService,private activatedRoute: ActivatedRoute, public router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params != null) {
        this.persistAuth.authorization = params.token;
        this.persistAuth.isAuthenticated = true;
        localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
        this.apiService.Updateisloginstatus();
        this.router.navigate(['/account']);
      }
   });
  }

}
