import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  confirmPasswordValidateError = '';
  confirmData = {
    resetPasswordLink: '',
    newPassword: '',
  };

  tempNewPassword = '';
  tempNewPasswordValidateError = '';

  ConfirmProcessing = false;

  regexPassword: RegExp = new RegExp(/^((?=.*\d)(?=.*[A-Z]).{6,20})$/);

  result: any;
  Id: any;
  otp: any;

  constructor(public apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.Id = params.id;
   });
  }

  tempNewPasswordValidate(): boolean {
    if (this.tempNewPassword === undefined || this.tempNewPassword == null || this.tempNewPassword.trim() === '') {
      this.tempNewPasswordValidateError = 'Field can\'t be empty.';
      return false;
    } else if (this.regexPassword.test(this.tempNewPassword) == false) {
      this.tempNewPasswordValidateError = 'Password must have min 6 characters, one uppercase and one numaric character.';
      return false;
    } else if (this.confirmData.newPassword !== this.tempNewPassword) {
      this.tempNewPasswordValidateError = '';
      this.confirmPasswordValidateError = 'Password does not match.';
      return false;
    } else {
      this.confirmPasswordValidateError = '';
      this.tempNewPasswordValidateError = '';
      return true;
    }
  }

  confirmPasswordValidate(): boolean {
    if (this.confirmData.newPassword === undefined || this.confirmData.newPassword == null ||
      this.confirmData.newPassword.trim() === '') {
      this.confirmPasswordValidateError = 'Field can\'t be empty.';
      return false;
    } else if (this.confirmData.newPassword !== this.tempNewPassword) {
      this.confirmPasswordValidateError = 'Password does not match.';
      return false;
    } else {
      this.confirmPasswordValidateError = '';
      return true;
    }
  }

 async ConfirmPassword() {
    if (this.tempNewPasswordValidate() === false || this.confirmPasswordValidate() === false) {
      return;
    } else {
      this.ConfirmProcessing = true;
      this.confirmData.resetPasswordLink = this.Id;
      let result = await this.apiService.ResetPasword(this.confirmData).catch(err => {
        this.apiService.ShowToastrAlert('oops!', err.description, 'error');
        this.ConfirmProcessing = false;
      });
      if(result == undefined || result== null) {
        return
        }
        this.apiService.ShowToastrAlert('', result.data, 'success');
        this.ConfirmProcessing = false;
        this.router.navigate(['/login']);
    }
  }
}

