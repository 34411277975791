<section class="page-content">
    <div class="contentFlex row">
      <div class="col-xl-3" *ngIf="!hideSideTabs">
        <div class="consde">
          <div>
            <ul class="nav nav-pills" role="tablist" style="margin: 0px -2px;">
              <li class="nav-item" style="width: 50%; padding: 0px 2px;">
                <a class="nav-link contract-tab-item" [routerLink]="['/contracts']">{{'Contract.Tab1' | translate}}</a>
              </li>
              <li class="nav-item" style="width: 50%; padding: 0px 2px;">
                <a class="nav-link contract-tab-item active" [routerLink]="['/indices']">{{'Contract.Tab2' | translate}}</a>
              </li>
            </ul>

            <div style="height: calc(100vh - 150px); overflow: auto;">
                <app-indices-list></app-indices-list>
              </div>
          </div>
        </div>
      </div>
      <div class="col-xl-9 col-lg-12 col-md-12">
        <app-indices-details></app-indices-details>
      </div>
    </div>
  </section>
