import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trading-rules',
  templateUrl: './trading-rules.component.html',
  styleUrls: ['./trading-rules.component.scss']
})
export class TradingRulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
