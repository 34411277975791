import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { Subscription } from 'rxjs';
// import { SignalrService } from '../_httpservices/signalr.service';
import { ApiService } from '../services/api.service';

@Pipe({
  name: 'currconverter'
})
export class CurrconverterPipe implements PipeTransform {

  subscription: Subscription;
  FiatUSDPrice: any;
  CryptoUSDPrice: any;

  CryptoUSD: any;
  FiatUSD: any;
  CrrBTCRate: any;
  CurrFiatRate: any;
  CurrRate: any;
  IsCrypto = false;
  IsFiat = false;

  constructor(public apiService: ApiService) {

    this.apiService.requestCryptoCurrenciesPrice();
    this.subscription = this.apiService.CryptoPrice.subscribe((result: any) => {
      if (result.data != null && result.data  !== undefined) {
        this.CryptoUSDPrice = result.data.rateList;
      }
    });

    this.apiService.requestFiatCurrenciesPrice();
    this.subscription = this.apiService.FiatPrice.subscribe((result: any) => {
      if (result.data != null && result.data  !== undefined) {
        this.FiatUSDPrice = result.data.rateList;
      }
    });

  }

  transform(rate: any, currencyName?: any, CurrentConvertCurr?: any): any {
    this.IsCrypto = false;
    this.IsFiat = false;
    if (this.CryptoUSDPrice != null && this.CryptoUSDPrice  !== undefined && this.CryptoUSDPrice.length !== 0) {
    this.CryptoUSDPrice.forEach((data: any) => {
        if (data.currency == currencyName) {
          this.IsCrypto = true;
          this.CryptoUSD = rate * data.rate;
        }
        if (data.currency === 'BTC') {
          this.CrrBTCRate = data.rate;
        }
      });
    }
    if (this.FiatUSDPrice != null && this.FiatUSDPrice  !== undefined && this.FiatUSDPrice.length !== 0) {
    this.FiatUSDPrice.forEach((data: any) => {
      this.IsFiat = true;
      if (data.currency == currencyName) {
        this.FiatUSD = rate * data.rate;
      }
      if (data.currency === CurrentConvertCurr) {
        this.CurrFiatRate = data.rate;
      }
    });
  }

    if (this.IsCrypto !== false) {
      this.CurrRate = this.CryptoUSD * this.CurrFiatRate;
    } else if (this.IsFiat !== false) {
      this.CurrRate = this.FiatUSD * this.CurrFiatRate;
    }

    return this.CurrRate;
  }

}

@NgModule({
  imports: [],
  declarations: [CurrconverterPipe],
  exports: [CurrconverterPipe]
})
export class CurrconverterPipeModule { }
