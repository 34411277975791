import {
  Component,
  OnInit,
  NgZone
} from '@angular/core';
import {
  WebsocketService
} from 'src/app/services/websocket.service';

@Component({
  selector: 'app-positions-section',
  templateUrl: './positions-section.component.html',
  styleUrls: ['./positions-section.component.scss']
})
export class PositionsSectionComponent implements OnInit {
  userPositions = [];
  closedPositions = [];
  constructor(public webSocketService: WebsocketService, public zone: NgZone) {}

  ngOnInit() {

  }

}
