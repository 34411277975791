import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  persistAuth = {
    isAuthenticated: false,
    authorization: null,
    gAuthEnabled: false,
  };

  constructor(private myRoute: Router) { }

  sendToken(token: string) {
    localStorage.setItem('Authorization', token);
  }

  getToken() {
    if (JSON.parse(localStorage.getItem('persist:auth')) != null) {
      const authDetails = JSON.parse(localStorage.getItem('persist:auth'));
      return authDetails.isAuthenticated;
    }
  }

  isLoggedIn() {
    return this.getToken() !== false;
  }

  logout(path= '/login') {
    if (JSON.parse(localStorage.getItem('persist:auth')) != null) {
      const authDetails = JSON.parse(localStorage.getItem('persist:auth'));
      authDetails.isAuthenticated = false;
      authDetails.authorization = null;
      localStorage.setItem('persist:auth', JSON.stringify(authDetails));
    } else {
      localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
    }
    this.myRoute.navigate([path]);
  }
}

