<div class="tbl-responsive-active-position" style="overflow: auto;">
  <div class="Go-table scrollable">
    <div class="Go-table-header sm-header">
      <div class="header-row">
        <div class="header-item alignItemLeft" style="width:5%" (click)="manualSorting('sym')">
          <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH1' |translate}}</span>
        </div>
      <div class="header-item alignItemRight" style="width:5%" (click)="manualSorting('size')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.positions.TH1' | translate}}</span>
        </div>
      <div class="header-item alignItemRight" style="width:8%" (click)="manualSorting('value')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.fills.TH3' | translate}}</span>
        </div>
      <div class="header-item alignItemRight" style="width:8%" (click)="manualSorting('entry_price')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.positions.TH2' | translate}}</span>
        </div>
      <div class="header-item alignItemRight" style="width:8%" (click)="manualSorting('mark_price')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.positions.TH3' | translate}}</span>
        </div>
      <div class="header-item alignItemRight" style="width:8%" (click)="manualSorting('liq_price')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.positions.TH4' | translate}}</span>
       </div>
      <div class="header-item alignItemRight" style="width:12%" (click)="manualSorting('leverage_x')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.positions.TH5' | translate}}</span>
        </div>
      <div class="header-item alignItemRight" style="width:15%" (click)="manualSorting('uPNL')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.positions.TH6' | translate}}</span>
        </div>
      <div class="header-item alignItemRight" style="width:8%" (click)="manualSorting('rPNL')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.positions.TH7' | translate}}</span>
        </div>
      <div class="header-item alignItemRight" style="width:20%">{{'trade.positions.TH8' | translate}}</div>
    </div>
    </div>
    <div class="Go-table-body all-section-height" *ngIf="userPositions">
      <div class="body-row tbl-striped" *ngFor="let item of userPositions | OrderBy: orderbyKey:orderByType: sortOrder; let i = index;">
        <div class="body-item alignItemLeft" style="width:5%" [ngClass]="(item.size >= 0)?'Go-green-border-left' : 'Go-red-border-left'">{{item.sym}}</div>
        <div class="body-item alignItemRight" style="width:5%" [ngClass]="(item.size >= 0)?'Go-green' : 'Go-red'">{{item.size}}</div>
        <div class="body-item alignItemRight" style="width:8%">{{item.value}}</div>
        <div class="body-item alignItemRight" style="width:8%">{{item.entry_price}}</div>
        <div class="body-item alignItemRight" style="width:8%">{{item.mark_price}}</div>
        <div class="body-item alignItemRight" style="width:8%">{{item.liq_price}}</div>
        <div class="body-item alignItemRight" style="width:12%;">
          <div *ngIf="item.leverage_x == 0.00">{{item.margin}}&nbsp;(Cross)</div>
          <div class="tooltipSpot" style="padding: 4px;"
            tooltip="Click to add or remove margin assigned to this position."
            (click)="OpenPostionMarginBox(item.size, item.margin, item)" *ngIf="item.leverage_x != 0.00">
            {{item.margin}}&nbsp;({{item.leverage_x}}x)</div>
        </div>
        <div class="body-item alignItemRight" style="width:15%">{{item.uPNL}} ({{item.uPNL_ROE}}%)</div>
        <div class="body-item alignItemRight" style="width:8%">{{item.rPNL}}</div>
        <div class="body-item alignItemRight" style="width:20%">
          <div class="positionOptions">
            <input placeholder="" step="{{item.minPriceINC}}" type="number" value="{{item.mark_price}}"
              (focus)="selectMarkPrice(item)"
              (change)="selectMarkPrice(item)" style="width: 75px; text-align: right;" *ngIf="selectOrderSym !== item.sym">
            <input placeholder="" *ngIf="selectOrderSym == item.sym" step="{{item.minPriceINC}}" type="number" style="width: 75px; text-align: right;" [(ngModel)]="inputMarketPrice">
            <button class="btns closebtn"
              (click)="openCloseMarketPosition('Limit', item, item.mark_price)">{{'trade.positions.cbtn' |translate}}</button>
            <button class="btns marketbtn"
              (click)="openCloseMarketPosition('Market', item, item.mark_price)">{{'trade.placeorder.Tab2' |translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="Go-modal-container">
  <div class="Go-modal" [ngClass]="(PMBStatus == true) ? 'IsActive' : ''">
    <div class="Go-modal-background" (click)="closePostionMarginBox()"></div>
    <div class="Go-modal-content" style="width: 420px">
      <div class="Go-modal-header text-center">
        <strong>{{'trade.positions.head' |translate}}</strong>
      </div>
      <div class="Go-modal-body">
        <div class="mb-2">
          <div class="form-check-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" value="add" [(ngModel)]="marginMode" name="optradio">{{'trade.positions.addmrg' |translate}}
            </label>
          </div>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input type="radio" class="form-check-input" value="remove" [(ngModel)]="marginMode"
                name="optradio">{{'trade.positions.rmvmrg' |translate}}
            </label>
          </div>
          <div class="text-center">
            <div><span>{{'trade.positions.yrcrntpst' |translate}}:</span>&nbsp;&nbsp;<span><strong>{{selectedRowData?.size}}</strong>
                {{'Menu2' |translate}} (0.00x)</span></div>
            <div><span>{{'trade.positions.crntasgmrg' |translate}}:</span>&nbsp;&nbsp;<span><strong>{{selectedRowData?.margin}}&nbsp;{{currContractData?.settledIn}}</strong></span>
            </div>
            <div *ngIf="marginMode != 'add'"><span>{{'trade.positions.maxrmve' |translate}}:</span>&nbsp;&nbsp;<span><strong>0.0000&nbsp;{{currContractData?.settledIn}}.</strong></span>
            </div>
            <div *ngIf="marginMode != 'remove'"><span>{{'trade.positions.avlmrgn' |translate}}:</span>&nbsp;&nbsp;<span><strong>{{walletbal?.available}}&nbsp;{{currContractData?.settledIn}}.</strong></span>
            </div>
          </div>
          <hr class="my-3" />
          <div class="mb-3">
            <div class="mb-2">{{((marginMode != 'remove') ? 'trade.positions.addmrg': 'trade.positions.rmvmrg') |translate}}</div>
            <div class="Go-input-group">
              <input class="ng-pristine ng-valid ng-touched" placeholder="" step=".00000001" type="number"
                [(ngModel)]="updatePositionMargin.new_margine">
              <div class="Go-input-group-append">
                <div class="Go-input-group-label">
                  <span class="Go-input-group-text" id="basic-addon1">{{currContractData?.settledIn}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 pr-1">
              <button class="btn btn-danger btn-block" (click)="closePostionMarginBox()">{{'trade.activeorders.Btndang' |translate}}</button>
            </div>
            <div class="col-sm-6 pl-1">
              <button class="btn btn-primary btn-block" *ngIf="marginMode != 'remove'" (click)="updatePosMargin()"
                [disabled]="updatePositionMargin.new_margine < 0.00000001">{{'trade.positions.addmrg' |translate}}</button>
              <button class="btn btn-primary btn-block" *ngIf="marginMode != 'add'" (click)="updatePosMargin()">{{'trade.positions.rmvmrg' |translate}}</button>
            </div>
          </div>
        </div>
        <button type="button" class="modal-close is-large" aria-label="close"
          (click)="closePostionMarginBox()"></button>
      </div>
    </div>
  </div>
</div>

<div class="Go-modal-container">
  <div class="Go-modal" [ngClass]="(CPMBStatus == true) ? 'IsActive' : ''">
    <div class="Go-modal-background" (click)="closeCloseMarketPosition()"></div>
    <div class="Go-modal-content" style="width: 450px">
      <div class="Go-modal-header text-center">
        <strong>{{closePositionMode}} {{'trade.activeorders.clspst' |translate}}?</strong>
      </div>
      <div class="Go-modal-body">
        <div class="mb-2">
          <h5><span [ngClass]="(posSide == 'Buy') ? 'Go-green' : 'Go-red'">{{posSide}} {{closePositionMode}}
              {{'trade.positions.cbtn' |translate}}</span></h5>
          <hr class="my-3" />
          <h6 class="mb-2"><span
              [ngClass]="(posSide == 'Buy') ? 'Go-green' : 'Go-red'">{{posSide}}&nbsp;</span>{{currSelectedPosition?.size}}
            Contracts of {{currSelectedPosition?.sym}} at <span
              *ngIf="closePositionMode != 'Limit'">{{closePositionMode}}</span>
            <span *ngIf="closePositionMode != 'Market'">{{DataToClosePosition.MarketPrice}}</span>.</h6>
          <p class="mb-3" *ngIf="closePositionMode != 'Market'">{{'trade.positions.line1' |translate}}</p>
          <p class="mb-3" *ngIf="closePositionMode != 'Limit'">{{'trade.positions.line2' |translate}}</p>
          <div class="row">
            <div class="col-sm-6 pr-1">
              <button class="btn btn-danger btn-block" (click)="closeCloseMarketPosition()">{{'trade.activeorders.Btndang' |translate}}</button>
            </div>
            <div class="col-sm-6 pl-1">
              <button class="btn btn-primary btn-block" *ngIf="closePositionMode != 'Limit'"
                (click)="closeYourPosition()">{{closePositionMode}} {{'trade.positions.TH8' |translate}}</button>
              <button class="btn btn-primary btn-block" *ngIf="closePositionMode != 'Market'"
                (click)="closeYourPosition()">{{closePositionMode}} {{'trade.positions.TH8' |translate}}</button>
            </div>
          </div>
        </div>
        <button type="button" class="modal-close is-large" aria-label="close"
          (click)="closeCloseMarketPosition()"></button>
      </div>
    </div>
  </div>
</div>
