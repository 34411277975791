import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'orderbyNumber'
})
export class OrderbyNumberPipe implements PipeTransform {

  transform(array: any, fieldName: string, fieldType: string = 'string', sortType: string = 'asc'): any[] {
    if (!Array.isArray(array)) {
      return;
    }

    array.sort((a: any, b: any) => {

      if (fieldType === 'date') {
        // console.log('success sorting by', fieldType, a.[fieldName]);
        return sortType === 'asc' ? (new Date(a[fieldName]).getTime() - new Date(b[fieldName]).getTime()) :
          (new Date(b[fieldName]).getTime() - new Date(a[fieldName]).getTime());

      } else if (fieldType === 'number') {
        // console.log('success sorting by', fieldType);
        return sortType === 'asc' ? (parseFloat(a[fieldName]) - parseFloat(b[fieldName])) :
          (parseFloat(b[fieldName]) - parseFloat(a[fieldName]));

      } else {
        // console.log('success sorting by', fieldType);
        return sortType === 'asc' ? (a[fieldName] - b[fieldName]) : (b[fieldName] - a[fieldName]);
      }

    });

    return array;
  }

}
