<div style="overflow: auto;">
  <div class="Go-table scrollable">
    <div class="Go-table-header sm-header">
      <div class="header-item" style="width:7%" (click)="manualSorting('sym')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH1' |translate}}</span>
      </div>
      <div class="header-item" style="width:10%" (click)="manualSorting('q')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH2' |translate}}</span>
        </div>
      <div class="header-item" style="width:13%" (click)="manualSorting('t')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH3' |translate}}</span>
        </div>
      <div class="header-item" style="width:7%" (click)="manualSorting('q')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH4' |translate}}</span>
        </div>
      <div class="header-item" style="width:11%" (click)="manualSorting('sp')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'"> {{'trade.stops.TH1' |translate}}</span>
       </div>
      <div class="header-item" style="width:13%" (click)="manualSorting('trgtyp')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.fills.TH5' |translate}}</span>
        </div>
      <div class="header-item" style="width:9%" (click)="manualSorting('fp')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH7' |translate}}</span>
        </div>
      <div class="header-item" style="width:8%" (click)="manualSorting('t')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH8' |translate}}</span>
        </div>
      <div class="header-item" style="width:7%" (click)="manualSorting('trg')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH9' |translate}}</span>
        </div>
      <div class="header-item" style="width:7%" (click)="manualSorting('ts')">
        <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH10' |translate}}</span>
        </div>
      <div class="header-item" style="width:8%">
        <button class="btn erqrW1" style="line-height: 10px;" (click)="cancelAllStops()"
          *ngIf="stopOrders.length !== 0 && this.cancelAllHide !== true"><i class="fas fa-times"></i></button>
      </div>
    </div>
    <div class="Go-table-body all-section-height" *ngIf="stopOrders">
      <div class="body-row tbl-striped" *ngFor="let item of stopOrders| OrderBy: orderbyKey:orderByType: sortOrder; let i = index;">
        <div class="body-item" style="width:7%"
          [ngClass]="(item?.s == 'S')?'Go-red-border-left' : 'Go-green-border-left'">{{item?.sym}}</div>
        <div class="body-item" style="width:10%" [ngClass]="(item?.q >= 0)?'Go-green':'Go-red'">
          <span class="editable" *ngIf="selectedOrderIds !== item.o"
            (click)="openEditOrder(item, 'size')" >{{item.q}}</span>
          <span *ngIf="selectedOrderIds == item.o" class="orderBtnSection">
            <button class="closeBtn" (click)="closeEditOrder(item,'size')"><i class="fas fa-times"></i></button>
            <input placeholder="" step="1" min="0" type="number" [(ngModel)]="updateOrderObj.size"
              style="width: 60px; text-align: right;">
            <button class="checkBtn" (click)="confirmEditOrder(item, 'size')"><i class="fas fa-check"></i></button>
          </span>
        </div>
        <div class="body-item" style="width:13%" [ngClass]="(item?.t == 'StopLimit')?'hoverable' : ''">
          <div *ngIf="item?.t == 'StopMarket'">Market</div>
          <div *ngIf="item?.t == 'StopLimit'">
            <span class="editable" *ngIf="selectedOrderIdp !== item.o"
              (click)="openEditOrder(item, 'price')">{{item?.p}}</span>
            <span *ngIf="selectedOrderIdp == item.o" class="orderBtnSection">
              <button class="closeBtn" (click)="closeEditOrder(item, 'price')"><i class="fas fa-times"></i></button>
              <input placeholder="" step=".5" type="number" [(ngModel)]="updateOrderObj.price"
                style="width: 60px; text-align: right;">
              <button class="checkBtn" (click)="confirmEditOrder(item, 'price')"><i class="fas fa-check"></i></button>
            </span>
          </div>
          <div *ngIf="item?.t !== 'StopMarket' && item?.t !== 'StopLimit'">
            {{item?.p}}
          </div>
        </div>
        <div class="body-item" style="width:7%">
          <span *ngIf="item?.trg == true">{{item?.q}}</span>
          <span *ngIf="item?.trg == false">-</span>
          <!-- <span *ngIf="item?.q - item?.r != 0" [ngClass]="(item?.s == 'S')?'Go-red' : 'Go-green'">{{item?.q - item?.r}}</span>
            <span *ngIf="item?.q - item?.r == 0">-</span> -->
        </div>
        <div class="body-item hoverable" style="width:11%">
          <span class="editable" *ngIf="selectedOrderIdsp !== item.o"
            (click)="openEditOrder(item, 'stopPrice')">{{item?.sp}}</span>
          <span *ngIf="selectedOrderIdsp == item.o" class="orderBtnSection">
            <button class="closeBtn" (click)="closeEditOrder(item, 'stopPrice')"><i class="fas fa-times"></i></button>
            <input placeholder="" step=".5" type="number" [(ngModel)]="updateOrderObj.stopPrice"
              style="width: 60px; text-align: right;">
            <button class="checkBtn" (click)="confirmEditOrder(item, 'stopPrice')"><i class="fas fa-check"></i></button>
          </span>
        </div>
        <div class="body-item" style="width:13%">
          <span *ngIf="item?.trgtyp == 0">{{lastTradePrice}}
            <span class=""
              [ngClass]="(lastTradePrice - item?.sp >= 0)?'Go-green':'Go-red'">({{lastTradePrice - item?.sp | number: '1.0-1'}})</span>
          </span>
          <span *ngIf="item?.trgtyp == 1">{{markPriceTicker?.P}}
            <span class=""
              [ngClass]="(markPriceTicker?.P - item?.sp >= 0)?'Go-green':'Go-red'">({{markPriceTicker?.P - item?.sp | number: '1.0-1'}})</span>
          </span>
          <span *ngIf="item?.trgtyp == 2">{{markPriceTicker?.IP}}
            <span class=""
              [ngClass]="(markPriceTicker?.IP - item?.sp >= 0)?'Go-green':'Go-red'">({{markPriceTicker?.IP - item?.sp | number: '1.0-1'}})</span>
          </span>

        </div>
        <div class="body-item" style="width:9%">
          <span *ngIf="item?.fp != 0">{{item?.fp}}</span>
          <span *ngIf="item?.fp == 0">-</span>
        </div>
        <div class="body-item" style="width:8%">
          <span *ngIf="item.t == 'StopLimit'">Stop Limit</span>
          <span *ngIf="item.t == 'StopMarket'">Stop Market</span>
          <span *ngIf="item.t == 'TakeProfitLimit'">Take Profit Limit</span>
          <span *ngIf="item.t == 'TakeProfitMarket'">Take Profit Market</span>
        </div>
        <div class="body-item" style="width:7%">{{(item?.trg == true) ? 'Triggered': 'Untriggered'}}</div>

        <div class="body-item" style="width:7%" tooltip="{{item.ts | date: 'MMMM d, y, h:mm:ss a'}}">
          {{item?.ts | date: 'h:mm a'}}
        </div>
        <div class="body-item" style="width:8%">
          <button class="btn erqrW1" *ngIf="item?.odst == 'Accepted'"
            (click)="cancelOD(item?.o)">{{'trade.activeorders.Btndang' | translate}}</button>
          <span *ngIf="item?.odst == 'Cancelled'"></span>
          <button class="btn erqrW1" *ngIf="item?.odst == 'PartiallyFilled'"
            (click)="cancelOD(item?.o)">{{'trade.activeorders.Btndang' | translate}}</button>
          <span *ngIf="item?.odst == 'Filled'"></span>
          <span *ngIf="item?.odst == 'Rejected'"></span>
          <span *ngIf="item?.odst == 'Expired'"></span>
        </div>
      </div>
    </div>
  </div>
</div>
