import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  Subscription
} from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  allSettingsSub: Subscription;
  footerHidden = false;
  content: any;
  allSettings: any;
  currentUrlName: string;

  constructor(public apiService: ApiService, public router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url != null && event.url !== undefined && event.url !== '/') {
          const currUrl = event.url.split('/');
          this.currentUrlName = currUrl[1];
          if (this.currentUrlName == 'trade') {
            this.footerHidden = true;
          } else {
            this.footerHidden = false;
          }
        }
      }
    });
  }

  ngOnInit() {
    this.allSettingsSub = this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.content = data;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
    }
  }
}
