import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {

  contractsList: any;
  selectedContractKey: any;

  contractItemDetails: any;
  contractItem = {
    chartName: 'charts',
    currencyName: null
  };
  tempValues: any;
  currentKey: string;
  innerWidth: number;
  hideSideTabs = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 1199) {
      this.hideSideTabs = true;
    } else {
      this.hideSideTabs = false;
    }
  }

  @HostListener('window:resize', ['$event'])
onResize(event) {
  this.innerWidth = window.innerWidth;
  if (this.innerWidth <= 1199) {
    this.hideSideTabs = true;
  } else {
    this.hideSideTabs = false;
  }
}
}
