import {
  Pipe,
  PipeTransform,
  NgModule
} from '@angular/core';
import {
  Subscription
} from 'rxjs';
import {
  ApiService
} from '../services/api.service';

@Pipe({
  name: 'addDecimal'
})

export class AddDecimalPipe implements PipeTransform {

  currContDetailsSub: Subscription;
  contractDecimalCount: any;

  constructor(public apiService: ApiService) {

    this.currContDetailsSub = this.apiService.sendSelectedContractDetail.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.contractDecimalCount = this.countDecimals(data.minPriceINC);
      }
    });

  }

  roundN(num, n) {
    return (Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n);
  }

  countDecimals(value) {
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  }

  transform(price: any): any {
    if (this.contractDecimalCount != 0) {
      const rounded = this.roundN(price, this.contractDecimalCount);
      return rounded;
    }
  }

}

@NgModule({
  imports: [],
  declarations: [AddDecimalPipe],
  exports: [AddDecimalPipe]
})
export class AddDecimalPipeModule {}
