import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  KeyValue
} from '@angular/common';

@Component({
  selector: 'app-indices-list',
  templateUrl: './indices-list.component.html',
  styleUrls: ['./indices-list.component.scss']
})
export class IndicesListComponent implements OnInit {

  indicesItem = {
    chartName: 'indices-charts',
    currencyName: null,
  };

  indicesList: any;
  indicesItemDetails: any;
  selectedindicesKey: any;
  indicesItemDetailedBreakdown: any;
  tempValues: any;
  currentKey: any;
  currentKeyValue: any;

  currentIndicesSelector = {
    indicesGroupName: '',
    indicesIndex: ''
  };

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private router: Router) {}

  ngOnInit() {

    this.requestIndices();

    // this.activatedRoute.data.subscribe(data => {
    //   this.indicesList = JSON.parse(data.indicesListResolver._body).success.data;

    //   if (this.activatedRoute.snapshot.paramMap.get('indicesName') != null) {
    //       this.indicesItem.currencyName = this.activatedRoute.snapshot.paramMap.get('indicesName');
    //       let isFound = false;
    //       Object.entries(this.indicesList).forEach(([key, value]) => {
    //         this.tempValues = null;
    //         this.currentKey = null;
    //         if (key != null) {
    //           this.currentKey = key;
    //           this.tempValues = value;
    //           if (value != null && value !== undefined) {
    //             this.tempValues.forEach((element: any) => {
    //               if (this.indicesItem.currencyName == element.ticker) {
    //                 isFound = true;
    //                 this.currentIndicesSelector.indicesIndex = element.ticker;
    //                 this.currentIndicesSelector.indicesGroupName = this.currentKey;
    //                 this.selectedindicesKey = this.currentKey;
    //                 this.apiService.getCurrentCurr(this.indicesItem);
    //                 this.checkIfNotComposit();
    //               }
    //             });
    //           }
    //         }
    //       });
    //       if (!isFound) {
    //         this.setDefaultIndices();
    //       }

    //     } else {
    //       this.setDefaultIndices();
    //     }
    // });
  }

  async requestIndices(){
    let result = await this.apiService.requestIndicesMenu().catch(err => {
    });
    if(result == undefined || result== null) {
      return
      }
      this.indicesList = result.data;

      if (this.activatedRoute.snapshot.paramMap.get('indicesName') != null) {
          this.indicesItem.currencyName = this.activatedRoute.snapshot.paramMap.get('indicesName');
          let isFound = false;
          Object.entries(this.indicesList).forEach(([key, value]) => {
            this.tempValues = null;
            this.currentKey = null;
            if (key != null) {
              this.currentKey = key;
              this.tempValues = value;
              if (value != null && value !== undefined) {
                this.tempValues.forEach((element: any) => {
                  if (this.indicesItem.currencyName == element.ticker) {
                    isFound = true;
                    this.currentIndicesSelector.indicesIndex = element.ticker;
                    this.currentIndicesSelector.indicesGroupName = this.currentKey;
                    this.selectedindicesKey = this.currentKey;
                    this.apiService.getCurrentCurr(this.indicesItem);
                    this.checkIfNotComposit();
                  }
                });
              }
            }
          });
          if (!isFound) {
            this.setDefaultIndices();
          }

        } else {
          this.setDefaultIndices();
        }
  }

  setDefaultIndices() {
    this.selectedindicesKey = Object.keys(this.indicesList)[0];
    const xyz = Object.values(this.indicesList)[0];
    this.indicesItem.currencyName = xyz[0].ticker;
    this.currentIndicesSelector.indicesGroupName = this.selectedindicesKey;
    this.currentIndicesSelector.indicesIndex = this.indicesItem.currencyName;
    this.router.navigate(['/indices/', this.indicesItem.currencyName]);
    this.apiService.getCurrentCurr(this.indicesItem);
    this.checkIfNotComposit();
  }


  checkIfNotComposit() {
    if (this.indicesList != null && this.indicesList !== undefined) {
      Object.entries(this.indicesList).forEach(([key, value]) => {

        if (this.currentIndicesSelector.indicesGroupName == key) {
          this.currentKeyValue = value;

          if (this.currentKeyValue != null && this.currentKeyValue !== undefined) {
            this.currentKeyValue.forEach((element: any) => {
              if (this.currentIndicesSelector.indicesIndex == element.ticker) {
                const tempValue = element;
                this.apiService.indicesListToView(tempValue);
                this.indices();
                this.indicesDetailedBreakdown();
              }
          });
        }
        }
      });
    }



  }

  selectindices(data: any) {
    this.selectedindicesKey = data;
  }

  GetIndicesDetails(data: any) {
    if (data != null && data !== undefined) {
      this.router.navigate(['/indices/', data]);
      this.indicesItem.currencyName = data;
      this.apiService.getCurrentCurr(this.indicesItem);
      // this.menudetailstoggle = true;
      this.checkIfNotComposit();
    }
  }

  async indices() {
    const indicesItem = this.indicesItem.currencyName + '/1?limit=100&ts=' + new Date().getTime();
    let result = await this.apiService.requestIndices(indicesItem).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined) {
        this.apiService.getIndicesDetails(result.data);
      }
  }

 async indicesDetailedBreakdown() {
    const indicesItemDetailsBreakdown = this.indicesItem.currencyName + '/1';
    let result = await this.apiService.requestIndicesDetailedBreakdown(indicesItemDetailsBreakdown).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined) {
        this.apiService.getIndicesDetailsBreakdown(result.data);
      }
  }

  originalOrder = (a: KeyValue < number, string > , b: KeyValue < number, string > ): number => {
    return 0;
  }

}
