import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-deposit-withdrawal-fees',
  templateUrl: './deposit-withdrawal-fees.component.html',
  styleUrls: ['./deposit-withdrawal-fees.component.scss']
})
export class DepositWithdrawalFeesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
