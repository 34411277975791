import {
  Component,
  OnInit,
  Input,
  ViewChild
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  KeyValue
} from '@angular/common';
import {
  ApiService
} from 'src/app/services/api.service';
import { ContractsDetailsComponent } from '../contracts-details/contracts-details.component';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-contracts-list',
  templateUrl: './contracts-list.component.html',
  styleUrls: ['./contracts-list.component.scss']
})
export class ContractsListComponent implements OnInit {
  contractsList: any;
  selectedContractKey: any;
  contractItem = {
    chartName: 'charts',
    currencyName: null
  };
  tempValues: any;
  currentKey: string;



  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private webSocketService: WebsocketService
  ) {}

  ngOnInit() {


this.requestContract();

    // this.activatedRoute.data.subscribe(data => {
    //   this.contractsList = JSON.parse(
    //     data.contractsListResolver._body
    //   ).success.data;
    //   if (this.activatedRoute.snapshot.paramMap.get('contractName') != null) {
    //     this.contractItem.currencyName = this.activatedRoute.snapshot.paramMap.get('contractName');

    //     let isFound = false;
    //     Object.entries(this.contractsList).forEach(([key, value]) => {
    //       this.tempValues = null;
    //       this.currentKey = null;
    //       if (key != null) {
    //         this.currentKey = key;
    //         this.tempValues = value;
    //         if (value != null && value !== undefined) {
    //           this.tempValues.forEach((element: any) => {
    //             if (this.contractItem.currencyName == element.name) {
    //               isFound = true;
    //               this.selectedContractKey = this.currentKey;
    //               this.apiService.getCurrentCurr(this.contractItem);
    //             }
    //           });
    //         }
    //       }
    //     });
    //     if (!isFound) {
    //       this.setDefaultContract();
    //     }
    //   } else {
    //     this.setDefaultContract();
    //   }
    //   this.contractDetails();
    // });
  }

async requestContract(){

  let result = await this.apiService.requestContractsMenu().catch(err => {

  });
  if(result == undefined || result== null) {
    return
    }
// console.log('result.data', result.data)

    this.contractsList = result.data;

    if (this.activatedRoute.snapshot.paramMap.get('contractName') != null) {
      this.contractItem.currencyName = this.activatedRoute.snapshot.paramMap.get('contractName');

      let isFound = false;
      Object.entries(this.contractsList).forEach(([key, value]) => {
        this.tempValues = null;
        this.currentKey = null;
        if (key != null) {
          this.currentKey = key;
          this.tempValues = value;
          if (value != null && value !== undefined) {
            this.tempValues.forEach((element: any) => {
              if (this.contractItem.currencyName == element.name) {
                isFound = true;
                this.selectedContractKey = this.currentKey;
                this.apiService.getCurrentCurr(this.contractItem);
              }
            });
          }
        }
      });
      if (!isFound) {
        this.setDefaultContract();
      }
    } else {
      this.setDefaultContract();
    }
    this.contractDetails();
}


  setDefaultContract() {
    this.selectedContractKey = Object.keys(this.contractsList)[0];
    const xyz = Object.values(this.contractsList)[0];
    this.contractItem.currencyName = xyz[0].name;
    this.router.navigate(['/contracts/', this.contractItem.currencyName]);
    this.apiService.getCurrentCurr(this.contractItem);
  }

  selectContracts(data: any) {
    this.selectedContractKey = data;
  }

  GetContractDetails(data: any) {
    if (data != null && data !== undefined) {
      this.router.navigate(['/contracts/', data.name]);
      this.contractItem.currencyName = data.name;

      this.apiService.getCurrentCurr(this.contractItem);
      // await this.contractsChart(data);
      // this.menudetailstoggle = false;
      this.contractDetails();
      this.webSocketService.contractChanged(data);
    }
  }

  async contractDetails() {
    let result = await this.apiService.requestContractDetails(this.contractItem.currencyName).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined) {
        this.apiService.getContractDetails(result);
      }
  }

  originalOrder = (
    a: KeyValue < number, string > ,
    b: KeyValue < number, string >
  ): number => {
    return 0;
  }
}
