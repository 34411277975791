import {
  Component,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  widget,
  IChartingLibraryWidget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
} from '../../../../assets/charting_library/charting_library.min';
import {
  Subscription
} from 'rxjs';
import {
  DatafeedService
} from 'src/app/services/tradingview-chart/datafeed.service';
import {
  ApiService
} from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-trading-chart',
  templateUrl: './trading-chart.component.html',
  styleUrls: ['./trading-chart.component.scss']
})
export class TradingChartComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  chartcolorSubscription: Subscription;
  socketConSub: Subscription;
  currencySymbol: any;
  themeMode: any;
  gridcolor: any;

  constructor(public datafeedService: DatafeedService, public apiService: ApiService, private activatedRoute : ActivatedRoute, public webSocketService: WebsocketService) {
    activatedRoute
  }

  // private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'AAPL';
  private _interval: ChartingLibraryWidgetOptions['interval'] = '1h';
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = 'https://demo_feed.tradingview.com';

  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '../../../../assets/charting_library/';
  private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] = 'https://saveload.tradingview.com';
  private _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] = '1.1';
  private _clientId: ChartingLibraryWidgetOptions['client_id'] = 'tradingview.com';
  private _userId: ChartingLibraryWidgetOptions['user_id'] = 'public_user_id';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
  private _tvWidget: IChartingLibraryWidget | null = null;



  // @Input()
  // set symbol(symbol: ChartingLibraryWidgetOptions['symbol']) {
  //   this._symbol = symbol || this._symbol;
  // }

  @Input()
  set interval(interval: ChartingLibraryWidgetOptions['interval']) {
    this._interval = interval || this._interval;
  }

  @Input()
  set datafeedUrl(datafeedUrl: string) {
    this._datafeedUrl = datafeedUrl || this._datafeedUrl;
  }

  @Input()
  set libraryPath(libraryPath: ChartingLibraryWidgetOptions['library_path']) {
    this._libraryPath = libraryPath || this._libraryPath;
  }

  @Input()
  set chartsStorageUrl(chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']) {
    this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
  }

  @Input()
  set chartsStorageApiVersion(chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']) {
    this._chartsStorageApiVersion = chartsStorageApiVersion || this._chartsStorageApiVersion;
  }

  @Input()
  set clientId(clientId: ChartingLibraryWidgetOptions['client_id']) {
    this._clientId = clientId || this._clientId;
  }

  @Input()
  set userId(userId: ChartingLibraryWidgetOptions['user_id']) {
    this._userId = userId || this._userId;
  }

  @Input()
  set fullscreen(fullscreen: ChartingLibraryWidgetOptions['fullscreen']) {
    this._fullscreen = fullscreen || this._fullscreen;
  }

  @Input()
  set autosize(autosize: ChartingLibraryWidgetOptions['autosize']) {
    this._autosize = autosize || this._autosize;
  }

  @Input()
  set containerId(containerId: ChartingLibraryWidgetOptions['container_id']) {
    this._containerId = containerId || this._containerId;
  }

  ngOnInit() {

    // this.subscription = this.apiService.sendCurrentCurr.subscribe((data: any) => {
    //   if (data != null && data !== undefined && data.length !== 0) {
    //     this.currencySymbol = data;
    //     console.log('currencySymbol', this.currencySymbol);
    //     this.chartcolorSubscription = this.apiService.ChartColor.subscribe((mode: any) => {
    //         this.themeMode = 'Dark';
    //         this.gridcolor = '#131722';
    //         this.loadChart(this.currencySymbol);
    //         console.log('mode', mode);
    //     });
    //   }
    // });
    this.socketConSub = this.webSocketService.socketConnectionAlert.subscribe((result: any) => {
      if (result == true) {
        // this.webSocketService.otherSettledIn(this.selectedCurrName);
        if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
          let tempId = this.activatedRoute.snapshot.paramMap.get('id');
          if (tempId != null && tempId !== undefined) {
            if (tempId.includes("&")) {
              let currName = tempId.split('&')[0];
              this.webSocketService.socketSubscribe(currName);
              let currencySymbol = {
                chartName: "charts",
                currencyName: currName
              }
              if (tempId.split('&')[1] == 'Dark') {
                this.themeMode = 'Dark';
              } else if (tempId.split('&')[1] == 'Light') {
                this.themeMode = 'Light';
              }

              this.loadChart(currencySymbol);
            } else {
              this.webSocketService.socketSubscribe(tempId);
              let currencySymbol = {
                chartName: "charts",
                currencyName: tempId
              }
              this.loadChart(currencySymbol);
            }
          }
        }
      }
    });


  }

  loadChart(currencySymbol: any) {
    function getLanguageFromURL(): LanguageCode | null {
      const regex = new RegExp('[\\?&]lang=([^&#]*)');
      const results = regex.exec(location.search);
      if (results != null && results !== undefined) {
        return decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
      } else {
        return null;
      }
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: currencySymbol.currencyName,
      //  datafeed:  new (window as any).Datafeeds.UDFCompatibleDatafeed(this._datafeedUrl),
      datafeed: this.datafeedService.getChartData(),
      interval: '60',
      container_id: this._containerId,
      library_path: this._libraryPath,
      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings', 'widget_logo', 'study_templates'],
      enabled_features: [],
      charts_storage_url: this._chartsStorageUrl,
      charts_storage_api_version: this._chartsStorageApiVersion,
      client_id: this._clientId,
      user_id: this._userId,
      fullscreen: this._fullscreen,
      autosize: this._autosize,
      theme: this.themeMode
    };
    const tvWidget = new widget(widgetOptions);
    this._tvWidget = tvWidget;

    tvWidget.onChartReady(() => {

      const button = tvWidget.createButton()
        .attr('title', 'Click to show a notification popup')
        .addClass('apply-common-tooltip')
        .on('click', () => tvWidget.showNoticeDialog({
          title: 'Notification',
          body: 'TradingView Charting Library API works correctly',
          callback: () => {
            // console.log('Noticed!');
          },
        }));

      button[0].innerHTML = 'Check API';
    });

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.chartcolorSubscription) {
      this.chartcolorSubscription.unsubscribe();
    }
    // if (this._tvWidget !== null) {
    //       this._tvWidget.remove();
    //       this._tvWidget = null;
    //   }
  }
}
