import { Component, OnInit, NgZone } from '@angular/core';
import { WebsocketService } from 'src/app/services/websocket.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-trade-order-history',
  templateUrl: './trade-order-history.component.html',
  styleUrls: ['./trade-order-history.component.scss']
})
export class TradeOrderHistoryComponent implements OnInit {
  myOrders = [];
  isAscendic = true
  orderbyKey: any;

  sortOrder: string = '';
  orderByType: any;
  constructor(public webSocketService: WebsocketService, public zone: NgZone, public apiService: ApiService) { }

  ngOnInit() {
    this.webSocketService.myOrdersSub.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
          data.forEach((element: any) => {
            const tempMyOrders = {
              sym: element.sym,
              ts: element.ts,
              o: element.o,
              u: element.u,
              sp: element.sp,
              p: element.p,
              r: element.r,
              s: element.s,
              q: element.q,
              st: element.st,
              tif: element.tif,
              t: element.t,
              odst: element.odst,
              v: element.v,
              fp: element.fp,
              trg: element.trg,
              trgtyp: element.trgtyp,
              fill: element.q - element.r
            };


            this.myOrders.unshift(tempMyOrders);
          });
        }
      });
    });
  }

  manualSorting(data: any) {
    this.orderbyKey = data;

    if (this.sortOrder == 'desc') {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = 'desc';
    }
  }

  copy(copyorderid: any) {
    var input = document.createElement('input');
    input.type = 'text';
    input.setAttribute('value', copyorderid);

    document.body.appendChild(input);
    // input.focus();
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
    document.body.removeChild(input);
    this.apiService.ShowToastrAlert('', 'Copied to clipboard', 'success');
  }

}
