        <div class="Go-card">
          <div id="loaderContainer" *ngIf="!contractItemDetails" >
            <svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
              <polygon fill="transparent" stroke="#51cbad" stroke-width="1" points="16,0 32,32 0,32"></polygon>
            </svg>
          </div>
          <div class="Go-card-header" *ngIf="contractItemDetails">
            <div class="gNtjxq cYBjDx">{{contractItemDetails?.name}} : {{'Contract.ContractDetail.Header1' | translate}}</div>
          </div>
          <div class="Go-card-body">
              <div class="mb-3">
                  <app-tradingview-chart></app-tradingview-chart>
              </div>
            <div>
              <h3 class="mb-3">{{'Contract.ContractDetail.Header2' | translate}}</h3>
              <table class="contractDetailstable table table-striped table-sm without-border">
                <tbody>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th1' | translate}}</td>
                    <td>{{contractItemDetails?.name}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th34' | translate}}</td>
                    <td>{{contractItemDetails?.description}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th6' | translate}}</td>
                    <td>
                      <span *ngIf="contractItemDetails?.type.toLowerCase() == 'perpetual'">Perpetual</span>
                      <span *ngIf="contractItemDetails?.type.toLowerCase() == 'future'">Future</span>
                      <span *ngIf="contractItemDetails?.type.toLowerCase() == 'inverseperpetual'">Inverse Perpetual</span>
                      <span *ngIf="contractItemDetails?.type.toLowerCase() == 'inversefuture'">Inverse Future</span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th35' | translate}}</td>
                    <td>{{contractItemDetails?.expiryTS}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th36' | translate}}</td>
                    <td>{{contractItemDetails?.settledIn}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th37' | translate}}</td>
                    <td>{{contractItemDetails?.dependentOnIndex}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th5' | translate}}</td>
                    <td>{{contractItemDetails?.underlyingBaseCurrency}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th4' | translate}}</td>
                    <td>{{contractItemDetails?.underlyingQuoteCurrency}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th19' | translate}}</td>
                    <td>{{contractItemDetails?.minPrice}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th18' | translate}}</td>
                    <td>{{contractItemDetails?.maxPrice}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th13' | translate}}</td>
                    <td>{{contractItemDetails?.makerFee}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th14' | translate}}</td>
                    <td>{{contractItemDetails?.takerFee}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th38' | translate}}</td>
                    <td>{{contractItemDetails?.minSize}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th39' | translate}}</td>
                    <td>{{contractItemDetails?.maxSize}}</td>
                  </tr>


                  <!-- <tr>
                    <td>{{'Contract.ContractDetail.Th2' | translate}}</td>
                    <td>{{contractItemDetails?.aliasName}}</td>
                  </tr> -->
                  <!-- <tr>
                    <td>{{'Contract.ContractDetail.Th3' | translate}}</td>
                    <td>{{contractItemDetails?.groupName}}</td>
                  </tr> -->
                  <!-- <tr>
                    <td>{{'Contract.ContractDetail.Th7' | translate}}</td>
                    <td>{{contractItemDetails?.currentPrice}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th8' | translate}}</td>
                    <td>{{contractItemDetails?.fairPrice}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th9' | translate}}</td>
                    <td>{{contractItemDetails?.changePerc}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th10' | translate}}</td>
                    <td>{{contractItemDetails?.initialMargin}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th11' | translate}}</td>
                    <td>{{contractItemDetails?.maintainceMargin}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th12' | translate}}</td>
                    <td>{{contractItemDetails?.maxLeverage}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th13' | translate}}</td>
                    <td>{{contractItemDetails?.makerFee}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th14' | translate}}</td>
                    <td>{{contractItemDetails?.takerFee}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th15' | translate}}</td>
                    <td>{{contractItemDetails?.settlementFee}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th16' | translate}}</td>
                    <td>{{contractItemDetails?.maxOrderQuantity}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th17' | translate}}</td>
                    <td>{{contractItemDetails?.minOrderQuantity}}</td>
                  </tr>

                  <tr>
                    <td>{{'Contract.ContractDetail.Th20' | translate}}</td>
                    <td>{{contractItemDetails?.dailyTurnOver}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th21' | translate}}</td>
                    <td>{{contractItemDetails?.totalVolume}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th22' | translate}}</td>
                    <td>{{contractItemDetails?.refrenceUrl}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th23' | translate}}</td>
                    <td>{{nextFundingTS | date: 'MMM d, y, h:mm:ss'}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th24' | translate}}</td>
                    <td>{{contractItemDetails?.interestBase}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th25' | translate}}</td>
                    <td>{{contractItemDetails?.interestQuote}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th26' | translate}}</td>
                    <td>{{contractItemDetails?.premium}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th27' | translate}}</td>
                    <td>{{contractItemDetails?.longFundingRate}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th28' | translate}}</td>
                    <td>{{contractItemDetails?.shortFundingRate}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th29' | translate}}</td>
                    <td>{{contractItemDetails?.fundingInterval}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th30' | translate}}</td>
                    <td>{{contractItemDetails?.addedOnTS | date: 'MMM d, y, h:mm:ss'}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th31' | translate}}</td>
                    <td>{{contractItemDetails?.validTillTS}}</td>
                  </tr> -->
                  <!-- <tr>
                    <td>{{'Contract.ContractDetail.Th32' | translate}}</td>
                    <td>{{contractItemDetails?.status}}</td>
                  </tr> -->
                  <!-- <tr>
                    <td>{{'Contract.ContractDetail.Th4' | translate}}</td>
                    <td>{{contractItemDetails?.tbl_CurrencySetting_UnderlyingQuoteCurrency}}</td>
                  </tr>
                  <tr>
                    <td>{{'Contract.ContractDetail.Th5' | translate}}</td>
                    <td>{{contractItemDetails?.tbl_CurrencySetting_UnderlyingBaseCurrency}}</td>
                  </tr> -->
                  <!-- <tr>
                    <td>{{'Contract.ContractDetail.Th33' | translate}}</td>
                    <td>{{contractItemDetails?.tbl_Index_Settings}}</td>
                  </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>

