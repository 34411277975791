<div class="auth-container" *ngIf="Settings?.disable_login != 'true'">
  <section class="login-panel" *ngIf="IsRegistered != true">
    <header class="text-center">
      <h3 class="my-3">{{'Signup' | translate}}</h3>
    </header>
    <div class="body">
      <form>
        <input id="email" type="email" name="email" autofocus="" style="opacity: 0;position: absolute;z-index: -1">
        <input style="opacity: 0;position: absolute;z-index: -1">
        <input type="password" style="opacity: 0;position: absolute;z-index: -1;">

        <!-- <div class="floating-form">
          <div class="floating-label">
            <input class="floating-input" [(ngModel)]="registerationDetails.firstName" type="email" name="firstName" placeholder=" "
              autocomplete="off" (blur)="firstNameValidate()" (keyup)="firstNameValidate()">
            <label>Enter your first name</label>
            <div class="ErrorMSG text-center" *ngIf="firstNameValidateError != ''">{{firstNameValidateError}}</div>
          </div>
        </div>
        <div class="floating-form">
          <div class="floating-label">
            <input class="floating-input" [(ngModel)]="registerationDetails.middleName" type="text" name="middleName" placeholder=" ">
            <label>Enter your middle name</label>
          </div>
        </div>

        <div class="floating-form">
          <div class="floating-label">
            <input class="floating-input" [(ngModel)]="registerationDetails.lastName" type="lastname" name="lastName" placeholder=" "
              autocomplete="off" (blur)="lastNameValidate()" (keyup)="lastNameValidate()">
            <label>Enter your last name</label>
            <div class="ErrorMSG text-center" *ngIf="lastNameValidateError != ''">{{lastNameValidateError}}</div>
          </div>
        </div> -->
        <div class="floating-form">
          <div class="floating-label">
            <input class="floating-input" [(ngModel)]="registerationDetails.email" type="email" name="email" placeholder=" "
              autocomplete="off" (blur)="emailValidate()" (keyup)="emailValidate()">
            <label>{{'CreateAcc.Label1' | translate}}</label>
            <div class="ErrorMSG text-center" *ngIf="emailValidateError != ''">{{emailValidateError}}</div>
          </div>
        </div>
        <div class="floating-form">
          <div class="floating-label">
            <select name="country" id="countrySelector" class="floating-input" [(ngModel)]="registerationDetails.country" (change)="countryValidate()">
              <option value="DC">{{'CreateAcc.Label2' | translate}}</option>
              <option *ngFor="let item of countryList | keyvalue" value="{{item.key}}">{{item.value.fullName}}</option>
            </select>
            <label>{{'CreateAcc.Label3' | translate}}</label>
            <div class="ErrorMSG text-center" *ngIf="countryValidateError != ''">{{countryValidateError}}</div>
          </div>
        </div>
        <!-- <div class="floating-form floating-input-group">
          <div class="floating-input-group-label">
            <div class="input-append">
              <span *ngIf="countryDetails != null">
                <span>+{{countryDetails?.isdCode}}</span>
              </span>
            </div>
          </div>
          <div class="floating-label" style="flex-grow: 1;">
            <input class="floating-input" [(ngModel)]="registerationDetails.mobile" (keypress)="numberOnly($event)"
              type="text" name="mobile" placeholder=" " autocomplete="off" (blur)="mobileValidate()" (keyup)="mobileValidate()">
            <label>Enter your mobile phone number</label>
            <div class="ErrorMSG text-center" *ngIf="mobileValidateError != ''">{{mobileValidateError}}</div>
          </div>
        </div> -->
        <div class="floating-form">
          <div class="floating-label">
            <input class="floating-input" [(ngModel)]="tempPassword" type="password" name="tempPassword" placeholder=" " autocomplete="off"
              (blur)="tempPasswordValidate()" (keyup)="tempPasswordValidate()">
            <label>{{'CreateAcc.Label4' | translate}}</label>
            <div class="ErrorMSG text-center" *ngIf="tempPasswordValidateError != ''">{{tempPasswordValidateError}}
            </div>
          </div>
        </div>
        <div class="floating-form">
          <div class="floating-label">
            <input class="floating-input" [(ngModel)]="registerationDetails.password" type="password" name="password" placeholder=" "
              autocomplete="off" (blur)="passwordValidate()" (keyup)="passwordValidate()">
            <label>{{'CreateAcc.Label5' | translate}}</label>
            <div class="ErrorMSG text-center" *ngIf="passwordValidateError != ''">{{passwordValidateError}}</div>
          </div>
        </div>
        <div class="floating-form">
          <div class="floating-label">
            <input class="floating-input" [(ngModel)]="registerationDetails.referral" type="text" name="text" placeholder=" "
              autocomplete="off" disabled>
            <label>{{'CreateAcc.Label6' | translate}}</label>
          </div>
        </div>
        <div class="" *ngIf="captchaEnabled !== false">
          <div class="row">
            <div class="col-sm-10 pr-sm-1 mb-2">
              <app-captcha [captchaString]="capString"></app-captcha>
              <div class="floating-form">
                <div class="floating-label">
                  <input class="floating-input" [(ngModel)]="registerationDetails.captcha" type="text" name="captcha" name="token"
                    autocomplete="off" placeholder=" " (blur)="captchaValidate()" (keyup)="captchaValidate()">
                  <div class="ErrorMSG text-center" *ngIf="captchaValidateError != ''">{{captchaValidateError}}</div>
                </div>
              </div>
            </div>
            <div class="col-sm-2 pl-sm-1 mb-2">
              <button type="button" class="hoCLhT XWHA" (click)="callCaptcha()"><i class="fas fa-redo"></i></button>
            </div>
          </div>
        </div>
        <div class="form-actions">
          <div class="form-group tos text-left">
            <div class="checkbox">
              <label>
                <input type="checkbox" name="acceptsTOS" required="" id="IfWarnChecked" name="check" (change)="IfWarnChecked()">
                {{'CreateAcc.Check' | translate}} {{apiService.data?.exchange_name}}.
              </label>
              <div class="ErrorMSG text-left" *ngIf="acceptTermsError != ''">{{acceptTermsError}}</div>
            </div>
          </div>
          <button class="GoBtn hoCLhT" type="submit" (click)="RegisterNow()"
            [disabled]="SignUpProcessing">{{(SignUpProcessing? 'Signin.Process' : 'Register') | translate}}</button>
        </div>
      </form>
    </div>
  </section>

  <section class="login-panel text-center p-4" *ngIf="IsRegistered != false">
    <h2 class="mb-2">Registration successful!</h2>
    <div class="text-center">A verification link has been sent to your email.</div>
    <div class="mt-3">
      <button class="btn GoBtn hoCLhT" (click)="resendemailVer()" [disabled]="resendProcessing">
        {{(resendProcessing? 'Processing' : (sentEmail ? 'Sent' : 'Resend Email'))}}
      </button>
    </div>
  </section>
</div>
