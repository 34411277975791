import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {

  constructor(public apiService: ApiService) { }

  cookies: any;

  ngOnInit() {
this.cookiess();
  }

  async cookiess() {
    let result = await this.apiService.getcookiepolicy().catch(err => {
      this.apiService.ShowToastrAlert('', err.data, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data !== undefined && result.data != null) {
        this.cookies = result.data;
      }
  }

}
