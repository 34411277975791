<section class="auth-container">
  <section class="login-panel">
    <div class="body">
      <div class="bottom">
        <h2 class="title">{{'featuredisable' | translate}}</h2>
          <div class="buttons">
            <button class="btn block" (click)="back()">{{'back' | translate}}</button>
          </div>
      </div>
    </div>
  </section>
</section>
