import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  Subscription
} from 'rxjs';
import { WebsocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-contracts-details',
  templateUrl: './contracts-details.component.html',
  styleUrls: ['./contracts-details.component.scss']
})
export class ContractsDetailsComponent implements OnInit, OnDestroy {

  contractItemDetails: any;
  sendContractsDetailsSub: Subscription;
  fundingRateSub: Subscription;
  nextFundingTS: any;

  constructor(private apiService: ApiService, public webSocketService: WebsocketService) {}

  ngOnInit() {
    this.sendContractsDetailsSub = this.apiService.sendContractsDetails.subscribe((result: any) => {
      this.contractItemDetails = result.data;
    });

    this.fundingRateSub = this.webSocketService.FundingRate.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.nextFundingTS = data.NT;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sendContractsDetailsSub) {
      this.sendContractsDetailsSub.unsubscribe();
    }
    if (this.fundingRateSub) {
      this.fundingRateSub.unsubscribe();
    }
  }

}
