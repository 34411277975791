import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exchange-trading-fees',
  templateUrl: './exchange-trading-fees.component.html',
  styleUrls: ['./exchange-trading-fees.component.scss']
})
export class ExchangeTradingFeesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
