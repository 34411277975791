<div class="auth-container widget-container" *ngIf="Settings?.disable_login != 'true'">
  <section class="login-panel">
    <header class="text-align-center mb-4">
      <h3 class="text-center my-3">{{'RstPass' | translate}}</h3>
    </header>
    <div class="body">
      <form>
        <fieldset>
          <div class="floating-form">
            <div class="floating-label">
              <input class="floating-input" [(ngModel)]="resetPassDetails.email" type="email" name="email" placeholder=" "
                autocomplete="off" (blur)="emailValidate()" (keyup)="emailValidate()">
              <label>{{'forgot.Label1' |translate}}</label>
              <div class="ErrorMSG text-center" *ngIf="emailValidateError != ''">{{emailValidateError}}</div>
            </div>
          </div>
          <div class="" *ngIf="captchaEnabled !== false">
            <div class="row">
              <div class="col-sm-10 pr-sm-1 mb-2">
                <app-captcha [captchaString]="capString"></app-captcha>
                <div class="floating-form">
                    <div class="floating-label">
                <input class="floating-input" [(ngModel)]="resetPassDetails.captcha" type="text" name="captcha"
                  autocomplete="off" placeholder=" " (blur)="captchaValidate()" (keyup)="captchaValidate()">
                  <label for="token">{{'Signin.Label3' |translate}}</label>
                <div class="ErrorMSG" *ngIf="captchaValidateError != ''">{{captchaValidateError}}</div>
              </div>
            </div>
              </div>
              <div class="col-sm-2 pl-sm-1 mb-2">
                <button type="button" class="hoCLhT XWHA" (click)="callCaptcha()"><i class="fas fa-redo"></i></button>
              </div>
            </div>
          </div>
        </fieldset>
        <div class="form-actions">
          <button class="GoBtn hoCLhT" type="submit" (click)="resetPassNow()"
            [disabled]="ResetProcessing">{{(ResetProcessing? 'Signin.Process' : 'RstPass') |translate}}
          </button>
        </div>
      </form>
    </div>
  </section>
</div>
