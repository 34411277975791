import {
  Component,
  OnInit,
  HostListener
} from '@angular/core';
import {
  Subscription
} from 'rxjs';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  Router,
  ActivatedRoute
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  KeyValue
} from '@angular/common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  Settings: any;
  allSettingsSub: Subscription;
  sideBarStatus = false;
  userDetails: any;
  isLogin = false;
  siteMode: any = 'Day';
  IsLanguageModalOpen = false;
  langSelected: any;
  enumdetails = [{
      name: 'EN'
    },
    {
      name: '中文'
    },
    {
      name: '한국어'
    },
  ];
  cookieValue = 'UNKNOWN';

  innerWidth: number;
  hideSideTabs = false;

  contractsList: any;
  selectedContractKey: any;
  contractItem = {
    chartName: 'charts',
    currencyName: null
  };
  tempValues: any;
  currentKey: string;

  indicesItem = {
    chartName: 'indices-charts',
    currencyName: null,
  };

  indicesList: any;
  indicesItemDetails: any;
  selectedindicesKey: any;
  indicesItemDetailedBreakdown: any;
  tempValues2: any;
  currentKey2: any;
  currentKeyValue: any;

  currentIndicesSelector = {
    indicesGroupName: '',
    indicesIndex: ''
  };

  constructor(
    public apiService: ApiService,
    public router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit() {

    let cl = localStorage.getItem('Language');
    if (cl === undefined || cl == null || cl === 'null' || cl.trim() === '') {
      this.langSelected = 'EN';
      localStorage.setItem('Language', this.langSelected);
    } else {
      this.langSelected = cl;
    }
    this.translate.setDefaultLang(this.langSelected);
    this.apiService.ChangeLang(this.langSelected);

    this.cookieValue = localStorage.getItem('Language');
    this.langSelected = this.cookieValue;

    const siteMode = localStorage.getItem('siteMode');
    if (
      siteMode === undefined ||
      siteMode == null ||
      siteMode === 'null' ||
      siteMode.trim() === ''
    ) {
      this.siteMode = 'Dark';
      this.apiService.chartcolor(this.siteMode);
      localStorage.setItem('siteMode', this.siteMode);
    }
    if (siteMode !== '' && siteMode != null && siteMode !== undefined) {
      this.CallMode(siteMode);
    }

    this.allSettingsSub = this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.Settings = data;
      }
    });

    this.allSettingsSub = this.apiService.sendSideBarStatus.subscribe((data: any) => {
      if (data == true) {
        this.sideBarStatus = true;
      }
    });

    this.apiService.userAuthStatus.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        if (data.authorization != null && data.authorization !== undefined) {
          this.isLogin = true;
        this.profile();
        } else {
          this.isLogin = false;
        }
      }
    });

    this.router.events.subscribe((val) => {
      this.sideBarStatus = false;
    });
  }
  async profile() {
    let result = await this.apiService.requestUserProfile().catch(err => {
     });
     if(result == undefined || result== null) {
       return
       }
       if (result.data != null && result.data !== undefined && result.data !== '') {
        this.userDetails = result.data;
      }
  }

 async getContractList() {
    let result = await this.apiService.requestContractsMenu().catch(err => {
    });
    if(result == undefined || result== null) {
      return
      }
      this.contractsList = result.data;
      if (this.activatedRoute.snapshot.paramMap.get('contractName') != null) {
        this.contractItem.currencyName = this.activatedRoute.snapshot.paramMap.get('contractName');

        let isFound = false;
        Object.entries(this.contractsList).forEach(([key, value]) => {
          this.tempValues = null;
          this.currentKey = null;
          if (key != null) {
            this.currentKey = key;
            this.tempValues = value;
            if (value != null && value !== undefined) {
              this.tempValues.forEach((element: any) => {
                if (this.contractItem.currencyName == element.name) {
                  isFound = true;
                  this.selectedContractKey = this.currentKey;
                  this.apiService.getCurrentCurr(this.contractItem);
                }
              });
            }
          }
        });
        if (!isFound) {
          this.setDefaultContract();
        }
      } else {
        this.setDefaultContract();
      }
      this.contractDetails();
  }

  async getIndicesList() {
    let result = await this.apiService.requestIndicesMenu().catch(err => {
    });
    if(result == undefined || result== null) {
      return
      }
      this.indicesList = result.data;

      if (this.activatedRoute.snapshot.paramMap.get('indicesName') != null) {
        this.indicesItem.currencyName = this.activatedRoute.snapshot.paramMap.get('indicesName');
        let isFound = false;
        Object.entries(this.indicesList).forEach(([key, value]) => {
          this.tempValues2 = null;
          this.currentKey2 = null;
          if (key != null) {
            this.currentKey2 = key;
            this.tempValues2 = value;
            if (value != null && value !== undefined) {
              this.tempValues2.forEach((element: any) => {
                if (this.indicesItem.currencyName == element.ticker) {
                  isFound = true;
                  this.currentIndicesSelector.indicesIndex = element.ticker;
                  this.currentIndicesSelector.indicesGroupName = this.currentKey2;
                  this.selectedindicesKey = this.currentKey2;
                  this.apiService.getCurrentCurr(this.indicesItem);
                  this.checkIfNotComposit();
                }
              });
            }
          }
        });
        if (!isFound) {
          this.setDefaultIndices();
        }

      } else {
        this.setDefaultIndices();
      }
  }


  closeSideBar() {
    this.sideBarStatus = false;
  }

 async logout() {
    let result = await this.apiService.requestLogout().catch(err => {
      this.apiService.ShowToastrAlert('', err.data, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      this.apiService.ShowToastrAlert('', result.data, 'success');

    if (JSON.parse(localStorage.getItem('persist:auth')) != null) {
      const authDetails = JSON.parse(localStorage.getItem('persist:auth'));
      authDetails.isAuthenticated = false;
      authDetails.authorization = null;
      localStorage.setItem('persist:auth', JSON.stringify(authDetails));
      this.apiService.Updateisloginstatus();
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  CallMode(data: any) {
    if (data == 'Light') {
      document.documentElement.setAttribute('data-theme', 'light');
      this.siteMode = 'Light';
      this.apiService.chartcolor(this.siteMode);
      localStorage.setItem('siteMode', this.siteMode);
    } else {
      this.siteMode = 'Dark';
      document.documentElement.setAttribute('data-theme', 'dark');
      this.apiService.chartcolor(this.siteMode);
      localStorage.setItem('siteMode', this.siteMode);
    }
  }

  openLanguageModal() {
    this.IsLanguageModalOpen = true;
  }

  closeLanguageModal() {
    this.IsLanguageModalOpen = false;
  }

  switchLanguage() {
    localStorage.setItem('Language', this.langSelected);
    this.langSelected = localStorage.getItem('Language');
    this.translate.use(this.langSelected);
    this.apiService.ChangeLang(this.langSelected);
    this.closeLanguageModal();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1199) {
      this.sideBarStatus = false;
    }
  }


  setDefaultContract() {
    this.selectedContractKey = Object.keys(this.contractsList)[0];
    const xyz = Object.values(this.contractsList)[0];
    this.contractItem.currencyName = xyz[0].name;
    this.router.navigate(['/contracts/', this.contractItem.currencyName]);
    this.apiService.getCurrentCurr(this.contractItem);
  }

  selectContracts(data: any) {
    this.selectedContractKey = data;
  }

  GetContractDetails(data: any) {
    if (data != null && data !== undefined) {
      this.router.navigate(['/contracts/', data]);
      this.contractItem.currencyName = data;

      this.apiService.getCurrentCurr(this.contractItem);
      // await this.contractsChart(data);
      // this.menudetailstoggle = false;
      this.contractDetails();
    }
  }

 async contractDetails() {
    let result = await this.apiService.requestContractDetails(this.contractItem.currencyName).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined) {
        this.apiService.getContractDetails(result);
      }
  }

  originalOrder = (
    a: KeyValue < number, string > ,
    b: KeyValue < number, string >
  ): number => {
    return 0;
  }

  setDefaultIndices() {
    this.selectedindicesKey = Object.keys(this.indicesList)[0];
    const xyz = Object.values(this.indicesList)[0];
    this.indicesItem.currencyName = xyz[0].ticker;
    this.currentIndicesSelector.indicesGroupName = this.selectedindicesKey;
    this.currentIndicesSelector.indicesIndex = this.indicesItem.currencyName;
    this.router.navigate(['/indices/', this.indicesItem.currencyName]);
    this.apiService.getCurrentCurr(this.indicesItem);
    this.checkIfNotComposit();
  }


  checkIfNotComposit() {
    if (this.indicesList != null && this.indicesList !== undefined) {
      Object.entries(this.indicesList).forEach(([key, value]) => {

        if (this.currentIndicesSelector.indicesGroupName == key) {
          this.currentKeyValue = value;

          if (this.currentKeyValue != null && this.currentKeyValue !== undefined) {
            this.currentKeyValue.forEach((element: any) => {
              if (this.currentIndicesSelector.indicesIndex == element.ticker) {
                const tempValue = element;
                this.apiService.indicesListToView(tempValue);
                this.indices();
                this.indicesDetailedBreakdown();
              }
            });
          }
        }
      });
    }



  }

  selectindices(data: any) {
    this.selectedindicesKey = data;
  }

  GetIndicesDetails(data: any) {
    if (data != null && data !== undefined) {
      this.router.navigate(['/indices/', data]);
      this.indicesItem.currencyName = data;
      this.apiService.getCurrentCurr(this.indicesItem);
      // this.menudetailstoggle = true;
      this.checkIfNotComposit();
    }
  }

 async indices() {
    const indicesItem = this.indicesItem.currencyName + '/1?limit=100&ts=' + new Date().getTime();
    let result = await this.apiService.requestIndices(indicesItem).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined) {
        this.apiService.getIndicesDetails(result.data);
      }
  }

 async indicesDetailedBreakdown() {
    const indicesItemDetailsBreakdown = this.indicesItem.currencyName + '/1';
    let result = await this.apiService.requestIndicesDetailedBreakdown(indicesItemDetailsBreakdown).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data != null && result.data !== undefined) {
        this.apiService.getIndicesDetailsBreakdown(result.data);
      }
  }

  // originalOrder = (a: KeyValue < number, string > , b: KeyValue < number, string > ): number => {
  //   return 0;
  // }

}
