import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-risk-disclosure',
  templateUrl: './risk-disclosure.component.html',
  styleUrls: ['./risk-disclosure.component.scss']
})
export class RiskDisclosureComponent implements OnInit {

  constructor(public apiService: ApiService) { }

  risks: any;
  ngOnInit() {
this.getrisk();
  }

 async getrisk(){
    let result = await this.apiService.getrisksdisclouser().catch(err => {
      this.apiService.ShowToastrAlert('', err.data, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data !== undefined && result.data != null) {
        this.risks = result.data;
      }
  }

}
