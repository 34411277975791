declare var baseUrl;
import {
  Injectable
} from '@angular/core';
import {
  environment
} from 'src/environments/environment';
import {
  ApiService
} from './api.service';
import {
  BehaviorSubject
} from 'rxjs';
import {
  Router,
  NavigationEnd
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  ChartUpdates = [];

  PremiumIndexUpdates = [];
  MarkPriceUpdates = [];
  FundingRateUpdates = [];
  FundingBasisUpdates = [];

  serverUrl: any;
  chartinterval = '1M';
  socket: any;
  // tslint:disable-next-line:max-line-length
  token: any;
  contract: any;
  Balances = {};
  contractSelector: any;
  persistAuth: any;
  MarkPrice: any;

  markPrice = new BehaviorSubject < any > ([]);
  allMarkPrice = new BehaviorSubject < any > ([]);
  FundingRate = new BehaviorSubject < any > ([]);
  FundingRatePrediction = new BehaviorSubject < any > ([]);
  OrderBook = new BehaviorSubject < any > ([]);
  OrderBookTicker: any;
  tradeData = new BehaviorSubject < any > ([]);
  BalanceTicker = new BehaviorSubject < any > ([]);
  BuyTicker = new BehaviorSubject < any > ([]);
  SellTicker = new BehaviorSubject < any > ([]);
  RisklimitTicker = new BehaviorSubject < any > ([]);
  LeverageTicker = new BehaviorSubject < any > ([]);
  chartTicker = new BehaviorSubject < any > ([]);

  snapshotBuy = new BehaviorSubject < any > ([]);
  snapshotSell = new BehaviorSubject < any > ([]);
  positions = new BehaviorSubject < any > ([]);
  myOrdersSub = new BehaviorSubject < any > ([]);
  myTradesSub = new BehaviorSubject < any > ([]);
  statsTicker = new BehaviorSubject < any > ([]);
  changedContractDetails = new BehaviorSubject < any > ([]);
  socketConnectionAlert = new BehaviorSubject < any > ([]);

  contractItem = {
    chartName: 'charts',
    currencyName: null
  };
  RiskLimit: any;
  Leverage: any;
  currContractData: any;
  walletCurrency: any;
  contractData: any;
  settledInCurrency: any;
  pageloaded = false;
  currContractDetails: any;
  allContractsList: any;
  Settings: any;
  otherSettledInCurr: any;

  myActiveOrders = {};
  activePricePoint = {};
  existingTime: any;

  supportedEvents: any;

  tempContractName: any;
  tempSettledIn: any;
  tempResoluion: any;

  resolutions = {
    1: 'chart_1m',
    5: 'chart_5m',
    15: 'chart_15m',
    30: 'chart_30m',
    60: 'chart_1h',
    180: 'chart_3h',
    240: 'chart_4h',
    360: 'chart_6h',
    720: 'chart_12h',
    '1D': 'chart_1d',
  };
  activeCountInSec = 1;
  timeOutInMin: number;

  isSocketConnected = false;
  currentSettledIn: any;

  constructor(private apiService: ApiService, private router: Router) {

    this.serverUrl = baseUrl.replace('https://', 'wss://') .replace('http://', 'ws://');

    this.apiService.AllSettings.subscribe((settingsData: any) => {
      if (settingsData != null && settingsData !== undefined && settingsData.length !== 0) {
        this.Settings = settingsData;
        this.timeOutInMin = parseFloat(settingsData.ws_idle_timeout_in_min);
        if (this.isSocketConnected == false) {
          this.connect();
          this.socketComponentCheck();
        }
      }
    });

    // this.apiService.AllSettings.subscribe((data: any) => {
    //   if (data != null && data !== undefined && data.length !== 0) {
    //     this.serverUrl = data.exchange_url_bff.replace('https://', '');
    //     // console.log('* getting exchange url');
    //     this.connect();
    //   }
    // });

    this.apiService.sendSelectorContract.subscribe((contractData: any) => {
      if (contractData != null && contractData !== undefined && contractData.length !== 0) {
        this.contractData = contractData;
        this.contractItem.currencyName = this.contractData.contractName;
        this.apiService.getCurrentCurr(this.contractItem);
      }
    });

    this.apiService.sendSelectedContractDetail.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.currContractDetails = data;
      }
    });

    this.apiService.userAuthStatus.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.processMyActiveOrders();
        if (data.authorization != null && data.authorization !== undefined) {
          this.token = data.authorization;
          // console.log('* token getting', this.token);
          if (this.pageloaded == true) {
            // console.log('* login');
            this.changeSubscriber();
          }
        } else {
          const authDetails = JSON.parse(localStorage.getItem('persist:auth'));
          authDetails.isAuthenticated = false;
          authDetails.authorization = null;
          localStorage.setItem('persist:auth', JSON.stringify(authDetails));
          this.token = null;
          this.authunSubscribe();
          const emptyValue = null;
          this.myOrdersSub.next(emptyValue);
          this.myTradesSub.next(emptyValue);
          this.positions.next(emptyValue);

        }
      }
    });

  }


  checkTabFocus() {
    setInterval(() => {
      if (document.hasFocus() == false) {
        let minutes = 0;
        this.activeCountInSec ++ ;
        minutes = Math.floor(this.activeCountInSec / 60);
        if (minutes >= this.timeOutInMin) {
          if (this.isSocketConnected == true) {
            this.socket.close();
            this.isSocketConnected = false;
          }
        }
      } else if (document.hasFocus() == true) {
        this.activeCountInSec = 0;
        if (this.isSocketConnected == false) {
          this.isSocketConnected = true;
          this.connect();
        }
      }
     }, 1000);
  }

  socketComponentCheck() {
    const currentRoute = this.router.url.split('/')[1];
    if (currentRoute !== 'trade') {
      if (JSON.parse(localStorage.getItem('exchange:selectedContract')) != null) {
        this.contractData = JSON.parse(localStorage.getItem('exchange:selectedContract'));
        this.contractItem.currencyName = this.contractData.contractName;
        this.apiService.getCurrentCurr(this.contractItem);
        this.getAllContractsList(this.contractData.contractGroupName, this.contractData.contractName);
      } else {
        if (this.Settings != null && this.Settings !== undefined) {
          const contractGroupName = this.Settings.default_pair.split('#')[0];
          const contractName = this.Settings.default_pair.split('#')[1];
          const tempObj = {
            contractGroupName,
            contractName,
          };

          tempObj.contractGroupName = contractGroupName;
          tempObj.contractName = contractName;
          this.contractData = tempObj;
          // console.log('this.contractData', this.contractData);
          this.contractItem.currencyName = this.contractData.contractName;
          this.apiService.getCurrentCurr(this.contractItem);
          this.getAllContractsList(contractGroupName, contractName);
          localStorage.setItem('exchange:selectedContract', this.contractData);
        }
      }
    }
  }

  getAllContractsList(contractGroupName: any, contractName: any) {
    this.apiService.requestContractsMenu().then((result: any) => {
      this.allContractsList = result.data;
      Object.entries(this.allContractsList).forEach(([key, value]) => {
        let tempValues = null;
        if (key == contractGroupName) {
          tempValues = value;
          if (value != null && value !== undefined) {
            tempValues.forEach((element: any) => {
              if (contractName == element.name) {
                this.currContractDetails = element;
                // this.changeSubscriber();
              }
            });
          }
        }
      });
    });
  }

  connect() {

    if (!this.serverUrl) {
      alert('invalid server Url');
      return;
    }

    // const url = this.Scheme + this.serverUrl + 'updates';
    const url = this.serverUrl + 'updatesV2';

    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }

    this.socket = new WebSocket(url);

    this.socket.onopen = (event: any) => {
      console.log('%c opened connection to ' + url, 'color: green;');
      this.pageloaded = true;
      this.isSocketConnected = true;
      this.socketConnectionAlert.next(true);
      this.checkTabFocus();
      this.changeSubscriber();
      // console.log('* connection opened');
      // this.Login();
    };

    this.socket.onclose = (event: any) => {
      console.log('%c closed connection from ' + url, 'color: red;');
      this.socket = undefined;
      if (this.isSocketConnected !== false) {
        setTimeout(() => {
          this.connect();
        }, 5000);
      }
    };

    this.socket.onmessage = (event: any) => {
      const parsedData = JSON.parse(event.data);
      // console.log('+(o_o)+', event.data);

      if (parsedData.event == 'notifications') {
        const notificationsData = parsedData.data;
        console.log('multiple notifications', notificationsData);
        if (notificationsData != null && notificationsData !== undefined && notificationsData.length !== 0) {
          notificationsData.forEach((element: any) => {
            if (localStorage.getItem('notifySoundSetting') != null && localStorage.getItem('notifySoundSetting') !== undefined &&
              localStorage.getItem('notifySoundSetting').length !== 0) {
              if (localStorage.getItem('notifySoundSetting') == 'On') {
                this.playNotificationSound();
                this.apiService.websoketNotify(element.type, element.title, element.content, element.time);
              } else {
                this.apiService.websoketNotify(element.type, element.title, element.content, element.time);
              }
            } else {
              this.apiService.websoketNotify(element.type, element.title, element.content, element.time);
            }

          });
        }

      } else if (parsedData.event == 'notification') {
        const notification = parsedData.data;
        // console.log('single notification', notification);
        if (notification != null && notification !== undefined) {
          if (localStorage.getItem('notifySoundSetting') != null && localStorage.getItem('notifySoundSetting') !== undefined &&
            localStorage.getItem('notifySoundSetting').length !== 0) {
            if (localStorage.getItem('notifySoundSetting') == 'On') {

              if (this.existingTime == null || this.existingTime === undefined) {
                this.existingTime = notification.time;
                this.playNotificationSound();
                this.apiService.websoketNotify(notification.type, notification.title, notification.content, notification.time);
              } else {
                const timeStart = new Date('01/01/2020 ' + this.existingTime).getSeconds();
                const timeEnd = new Date('01/01/2020 ' + notification.time).getSeconds();
                const secondsDiff = timeEnd - timeStart;
                // console.log('C+', this.existingTime, notification.time, secondsDiff);
                this.existingTime = notification.time;
                if (secondsDiff >= 1) {
                  this.playNotificationSound();
                  this.apiService.websoketNotify(notification.type, notification.title, notification.content, notification.time);
                } else {
                  this.apiService.websoketNotify(notification.type, notification.title, notification.content, notification.time);
                }
              }
            } else {
              this.apiService.websoketNotify(notification.type, notification.title, notification.content, notification.time);
            }
          } else {
            this.apiService.websoketNotify(notification.type, notification.title, notification.content, notification.time);
          }
        }

      } else if (parsedData.event == 'markprice') {
        this.markPrice.next(parsedData.data);

      } else if (parsedData.event == 'stats') {
        this.statsTicker.next(parsedData.data);

      } else if (parsedData.event == 'premiumindex') {
        Object.entries(parsedData.data).forEach(([key, value]) => {
          const temp = {
            ticker: key,
            data: value,
          };
          this.PremiumIndexUpdates.unshift(temp);
        });

      } else if (parsedData.event == 'fundingrate') {
        this.FundingRate.next(parsedData.data);

      } else if (parsedData.event == 'fundingrate_prediction') {
        this.FundingRatePrediction.next(parsedData.data);

      } else if (parsedData.event == 'funding_basis_ticker') {
        const fb = parsedData.data.funding_basis_ticker;
        this.FundingBasisUpdates.unshift(fb);

      } else if (parsedData.event == 'chart_1m') {
        // console.log('chart_1m', parsedData.data);
        this.chartTicker.next(parsedData.data);

      } else if (parsedData.event == 'orderbook') {
        this.BuyTicker.next(parsedData.data);
        this.SellTicker.next(parsedData.data);

      } else if (parsedData.event == 'orderbook_L25') {

      } else if (parsedData.event == 'marginsettings') {
        this.RiskLimit = parsedData.data;
        this.RisklimitTicker.next(this.RiskLimit);

      } else if (parsedData.event == 'trades') {
        this.tradeData.next(parsedData.data);

      } else if (parsedData.event == 'positions') {
        this.positions.next(parsedData.data);

      } else if (parsedData.event == 'myorders') {
        this.myOrdersSub.next(parsedData.data);

      } else if (parsedData.event == 'mytrades') {
        this.myTradesSub.next(parsedData.data);

      } else if (parsedData.event == 'balances') {
        this.Balances = parsedData.data;
        this.BalanceTicker.next(parsedData.data);


      } else {
        // console.warn('unknown', event.data);
      }
      return false;
    };

    this.socket.onerror = (event: any) => {
      // console.log('error: ' + event.data);
    };
  }

  send(obj: any) {
    if (this.isSocketConnected !== false) {
    try {
      this.socket.send(JSON.stringify(obj));
    } catch (e) {
      // console.log('exception while sending', e);
      // this.socket = null;
      // this.connect();
    }
  }
  }


  socketSubscribe(contractName: any) {
    if (this.tempContractName !== null && this.tempContractName !== undefined) {
      const obj2 = {};
      obj2['op'] = 'unsubscribe';
      obj2['args'] = [
        'fundingrate:' + this.tempContractName,
        'fundingrate_prediction:' + this.tempContractName,
        'markprice:' + this.tempContractName,
        'orderbook:' + this.tempContractName,
        'orderbook_L25:' + this.tempContractName,
        'stats:' + this.tempContractName,
        'trades:' + this.tempContractName,
        'premiumindex:' + this.tempContractName,
        'chart_1h:' + this.tempContractName,
      ];
      this.send(obj2);
    }

    // console.log('socket Subscribe', contractName);
    // console.log('testing', this.supportedEvents[fundingrate]);
    const obj = {};
    obj['op'] = 'subscribe';
    obj['args'] = [
      'fundingrate:' + contractName,
      'fundingrate_prediction:' + contractName,
      'markprice:' + contractName,
      'orderbook:' + contractName,
      'orderbook_L25:' + contractName,
      'stats:' + contractName,
      'trades:' + contractName,
      'premiumindex:' + contractName,
      'chart_1h:' + contractName,
      'notification',
      'notifications',
    ];
    this.send(obj);

    this.tempContractName = contractName;
  }

  // socketUnsubscribe(settledIn: any, contractName: any) {
  //   let obj = { "op": "unsubscribe", args: [event_instrument] };
  //   $scope.send(obj);
  // }

  socketSubscribeQuery(contractName: any) {
    const obj = {};
    obj['op'] = 'query';
    obj['args'] = [
      'fundingrate:' + contractName,
      'fundingrate_prediction:' + contractName,
      'orderbook:' + contractName,
      'trades:' + contractName,
    ];
    this.send(obj);
  }

  loginSocket(bearerToken: string) {
    const obj = {};
    obj['op'] = 'authorization:token';
    obj['args'] = [bearerToken];
    this.send(obj);
  }

  authSubscribe(settledIn: any, contractName: any) {
    if (this.tempContractName !== null && this.tempContractName !== undefined) {
      if (this.tempSettledIn != null && this.tempSettledIn !== undefined) {
      const obj2 = {};
      obj2['op'] = 'unsubscribe';
      obj2['args'] = [
        // 'fundingrate:' + this.tempContractName,
        // 'fundingrate_prediction:' + this.tempContractName,
        // 'markprice:' + this.tempContractName,
        // 'orderbook:' + this.tempContractName,
        // 'orderbook_L25:' + this.tempContractName,
        // 'stats:' + this.tempContractName,
        // 'trades:' + this.tempContractName,
        'myorders:' + this.tempContractName,
        'mytrades:' + this.tempContractName,
        'positions:' + this.tempContractName,
        'balances:' + this.tempSettledIn,
        'marginsettings:' + this.tempContractName,
      ];
      this.send(obj2);
      }
    }
    // console.log('auth Subscribe', token, settledIn, contractName);
    const obj = {};
    obj['op'] = 'subscribe';
    obj['args'] = [
      // 'fundingrate:' + contractName,
      // 'fundingrate_prediction:' + contractName,
      // 'markprice:' + contractName,
      // 'orderbook:' + contractName,
      // 'orderbook_L25:' + contractName,
      // 'stats:' + contractName,
      // 'trades:' + contractName,
      'myorders:' + contractName,
      'mytrades:' + contractName,
      'positions:' + contractName,
      'balances:' + settledIn,
      'marginsettings:' + contractName,
    ];
    this.send(obj);

    this.tempSettledIn = settledIn;
    this.tempContractName = contractName;
    const emptyValue = null;
    this.BalanceTicker.next(emptyValue);
    this.authSubQuery(settledIn, contractName);
  }

  authSubQuery(settledIn: any, contractName: any) {
    const obj = {};
    obj['op'] = 'query';
    obj['args'] = [
      // 'fundingrate:' + contractName,
      // 'fundingrate_prediction:' + contractName,
      // 'markprice:' + contractName,
      // 'orderbook:' + contractName,
      // 'stats:' + contractName,
      // 'trades:' + contractName,
      'myorders:' + contractName,
      'mytrades:' + contractName,
      'positions:' + contractName,
      'balances:' + settledIn,
    ];
    this.send(obj);
    const emptyValue = null;
    this.BalanceTicker.next(emptyValue);
  }

  authunSubscribe() {
    const obj = {};
    obj['op'] = 'logout';
    obj['args'] = [];
    this.send(obj);

    this.logOutUnsubscribe();
  }


  logOutUnsubscribe() {
    const obj2 = {};
    obj2['op'] = 'unsubscribe';
    obj2['args'] = [
      'myorders:' + this.tempContractName,
      'mytrades:' + this.tempContractName,
      'positions:' + this.tempContractName,
      'balances:' + this.tempSettledIn,
      'marginsettings:' + this.tempContractName,
    ];
    this.send(obj2);
  }

  changeSubscriber() {
    if (this.currContractDetails != null && this.currContractDetails !== undefined) {
      this.socketSubscribe(this.currContractDetails.name);
      this.socketSubscribeQuery(this.currContractDetails.name);
      if (this.token != null && this.token !== undefined) {
        if (this.router.url.split('/')[1] !== 'trade') {
          if (this.otherSettledInCurr != null && this.otherSettledInCurr !== undefined) {
            this.loginSocket(this.token);
            this.authSubscribe(this.otherSettledInCurr, this.currContractDetails.name);
            localStorage.setItem('tradeSettledInCurrency', this.otherSettledInCurr);
            // this.apiService.CurrentSettledInCurr(this.otherSettledInCurr);
          } else {
            this.loginSocket(this.token);
            this.authSubscribe(this.currContractDetails.settledIn, this.currContractDetails.name);
            localStorage.setItem('tradeSettledInCurrency', this.currContractDetails.settledIn);
          }
        } else {
          this.loginSocket(this.token);
          this.authSubscribe(this.currContractDetails.settledIn, this.currContractDetails.name);
          localStorage.setItem('tradeSettledInCurrency', this.currContractDetails.settledIn);
        }

      }
    }
  }

  contractChanged(data: any) {
    if (data != null && data !== undefined) {
      this.changedContractDetails.next(data);
      this.socketSubscribe(data.name);
      this.socketSubscribeQuery(data.name);
      if (this.token != null && this.token !== undefined) {
        this.loginSocket(this.token);
        this.authSubscribe(data.settledIn, data.name);
      }
    }
  }

  getContractCall(data: boolean, settledIn: string) {
    if (settledIn != null && settledIn !== undefined && settledIn.length !== 0) {
      if (this.token != null && this.token !== undefined) {
        if (data == true) {
          const obj = {};
          obj['op'] = 'authorization:token';
          obj['args'] = [this.token];
          this.send(obj);
        }
        if (this.tempSettledIn != null && this.tempSettledIn !== undefined) {
          const obj1 = {};
          obj1['op'] = 'unsubscribe';
          obj1['args'] = ['balances:' + this.tempSettledIn];
          this.send(obj1);
        }
        this.tempSettledIn = settledIn;

        const obj2 = {};
        obj2['op'] = 'query';
        obj2['args'] = [
          'balances:' + settledIn,
        ];
        this.send(obj2);
        const obj3 = {};
        obj3['op'] = 'subscribe';
        obj3['args'] = ['balances:' + settledIn];
        this.send(obj3);
      }
    }
  }

  socketSuscribeCall(isAuth: boolean) {
    if (JSON.parse(localStorage.getItem('exchange:selectedContract')) != null) {
      this.contractSelector = JSON.parse(localStorage.getItem('exchange:selectedContract'));
      if (this.token != null && this.token !== undefined) {
        if (isAuth == true) {
          const obj = {};
          obj['op'] = 'authorization:token';
          obj['args'] = [this.token];
          this.send(obj);
        }
        if (this.tempContractName != null && this.tempContractName !== undefined) {
          const obj1 = {};
          obj1['op'] = 'unsubscribe';
          obj1['args'] = ['myorders:' + this.contractSelector.contractName];
          this.send(obj1);
        }
        this.tempContractName = this.contractSelector.contractName;

        const obj2 = {};
        obj2['op'] = 'query';
        obj2['args'] = ['myorders:' + this.contractSelector.contractName];
        this.send(obj2);

        const obj3 = {};
        obj3['op'] = 'subscribe';
        obj3['args'] = ['myorders:' + this.contractSelector.contractName];
        this.send(obj3);
      }

    }

  }

  otherSettledIn(currency: any) {
    this.otherSettledInCurr = currency;
  }

  processMyActiveOrders() {
    this.myOrdersSub.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        data.forEach((element: any) => {


          const orderAtExistingPrice = this.activePricePoint[element.p];
          if (orderAtExistingPrice === undefined) {
            if (element.st === false) {
              const temp = {};
              temp[element.o] = '';
              this.activePricePoint[element.p] = temp;
            }
          } else {
            if (element.st === false) {
              orderAtExistingPrice[element.o] = '';
            } else {
              delete orderAtExistingPrice[element.o];
              if (Object.keys(orderAtExistingPrice).length === 0) {
                delete this.activePricePoint[element.p];
              }
            }
          }


          const existingVALUE = this.myActiveOrders[element.o];

          element['fill'] = element.q - element.r;

          if (existingVALUE === undefined) {
            if (element.st == false) {
              this.myActiveOrders[element.o] = element;
            }
          } else {
            existingVALUE.sp = element.sp;
            existingVALUE.p = element.p;
            existingVALUE.r = element.r;
            existingVALUE.q = element.q;
            existingVALUE.st = element.st;
            existingVALUE.t = element.t;
            existingVALUE.odst = element.odst;
            existingVALUE.v = element.v;
            existingVALUE.fp = element.fp;
            existingVALUE.trg = element.trg;
            existingVALUE.trgtyp = element.trgtyp;
            existingVALUE.fill = element.fill;
          }
        });
      }
    });
  }

  playNotificationSound() {
    const audio = new Audio('../../assets/sounds/deduction.mp3');
    audio.play();
  }

  selectedResolution(resoluion: any) {
    if (this.tempResoluion != null && this.tempResoluion !== undefined) {
      const obj = {};
      obj['op'] = 'unsubscribe';
      obj['args'] = [this.tempResoluion + ':' + this.tempContractName];
      this.send(obj);
    }

    this.tempResoluion = this.resolutions[resoluion];
    const obj3 = {};
    obj3['op'] = 'subscribe';
    obj3['args'] = [this.tempResoluion + ':' + this.currContractDetails.contractName];
    this.send(obj3);
  }

}
