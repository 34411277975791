import {
  Component,
  OnInit,
  NgZone
} from '@angular/core';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import {
  ApiService
} from 'src/app/services/api.service';

@Component({
  selector: 'app-active-orders',
  templateUrl: './active-orders.component.html',
  styleUrls: ['./active-orders.component.scss']
})
export class ActiveOrdersComponent implements OnInit {
  activeOrders = [];
  cancel = {
    orderID: ''
  };

  updateOrderObj = {
    OrderID: '',
    size: 0,
    price: '',
    stopPrice: ''
  };
  showOrderQTY = false;
  showOrderPrice = false;
  selectedOrderId = null;
  selectedOrderIdClose: any;
  selectedOrderIdP = null;

  selectedOrderIds = null;
  selectedOrderIdp = null;
  cancelAllHide = false;
  orderbyKey = 'ts';

  sortOrder: string = 'asc';
  orderByType: any;
  constructor(public webSocketService: WebsocketService, public zone: NgZone, public apiService: ApiService) {}

  ngOnInit() {

    this.webSocketService.myOrdersSub.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
          data.forEach((element: any) => {
            // console.log('@#$%', data);
            if (this.activeOrders.length === 0) {
              if (element.st === false) {
                const temp = {
                  sym: element.sym,
                  ts: element.ts,
                  o: element.o,
                  u: element.u,
                  sp: element.sp,
                  p: element.p,
                  r: element.r,
                  s: element.s,
                  q: element.q,
                  st: element.st,
                  tif: element.tif,
                  t: element.t,
                  odst: element.odst,
                  v: element.v,
                  fp: element.fp,
                  trg: element.trg,
                  trgtyp: element.trgtyp,
                  fill: element.q - element.r
                };
                this.activeOrders.unshift(temp);
              }
            } else {
              let isFound = false;

              this.activeOrders.forEach((newEl: any) => {
                if (newEl.o == element.o) {
                  isFound = true;
                  newEl.sym = element.sym;
                  newEl.ts = element.ts;
                  newEl.o = element.o;
                  newEl.u = element.u;
                  newEl.sp = element.sp;
                  newEl.p = element.p;
                  newEl.r = element.r;
                  newEl.s = element.s;
                  newEl.q = element.q;
                  newEl.st = element.st;
                  newEl.tif = element.tif;
                  newEl.t = element.t;
                  newEl.odst = element.odst;
                  newEl.v = element.v;
                  newEl.fp = element.fp;
                  newEl.trg = element.trg;
                  newEl.trgtyp = element.trgtyp;
                  newEl.fill = element.q - element.r;
                }
              });

              if (!isFound) {
                if (element.st === false) {
                  const temp = {
                    sym: element.sym,
                    ts: element.ts,
                    o: element.o,
                    u: element.u,
                    sp: element.sp,
                    p: element.p,
                    r: element.r,
                    s: element.s,
                    q: element.q,
                    st: element.st,
                    tif: element.tif,
                    t: element.t,
                    odst: element.odst,
                    v: element.v,
                    fp: element.fp,
                    trg: element.trg,
                    trgtyp: element.trgtyp,
                    fill: element.q - element.r
                  };
                  this.activeOrders.unshift(temp);
                }
              }
            }
          });
          // console.log('--{ active orders final }--', this.activeOrders);
          this.checkRemaining();
        }
      });
    });

    this.apiService.getNotifyEmptyOrders.subscribe((data: any) => {
      if (data != null && data !== undefined) {
        this.cancelAllHide = data;
      }
    });
  }

  async cancelOD(ID: any) {
    this.cancel.orderID = ID;
    let result = await  this.apiService.CancelOrder(this.cancel).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
    this.apiService.ShowToastrAlert('', result.message, 'success');
  }

 async cancelAllActive() {
   for(let i = 0; i < this.activeOrders.length; i++) {
    if (this.activeOrders[i].st === false) {
      this.cancel.orderID = this.activeOrders[i].o;
      let result = await  this.apiService.CancelOrder(this.cancel).catch(err => {
        this.cancelAllHide = false;
        this.apiService.ShowToastrAlert('', err.message, 'error');
      });
      if(result == undefined || result== null) {
        return
        }
        this.cancelAllHide = true;
        this.apiService.notifyEmpty(this.cancelAllHide);
        this.apiService.ShowToastrAlert('', result.message, 'success');

    }
   }
    // this.activeOrders.forEach((element: any) => {

    // });
  }

  openEditOrder(data: any, type: any) {
    this.selectedOrderIds = null;
    this.selectedOrderIdp = null;
    if (type == 'size') {
      this.updateOrderObj.size = data.q;
      this.selectedOrderIds = data.o;
    } else if (type == 'price') {
      this.updateOrderObj.price = data.p;
      this.selectedOrderIdp = data.o;
    }
  }


  confirmEditOrder(data: any, type: any) {
    if (this.selectedOrderIds == data.o || this.selectedOrderIdp == data.o) {
      if (type == 'size') {
        this.updateOrderObj.price = data.p;
      } else if (type == 'price') {
        this.updateOrderObj.size = data.q;
      } else if (type == 'stopPrice') {
        this.updateOrderObj.price = data.p;
        this.updateOrderObj.size = data.q;
      }
      this.updateOrderObj.stopPrice = data.sp;
      this.updateOrderObj.OrderID = data.o;
      this.updateOrder();
      this.selectedOrderIds = null;
      this.selectedOrderIdp = null;
    }
  }

  closeEditOrder(data: any, type: any) {
    if (this.selectedOrderIds == data.o || this.selectedOrderIdp == data.o) {
      this.selectedOrderIds = null;
      this.selectedOrderIdp = null;
    }
  }

 async updateOrder() {
    // console.log('update active order', this.updateOrderObj);
    let result = await this.apiService.requestUpdateOrder(this.updateOrderObj).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      this.apiService.ShowToastrAlert('', result.message, 'success');
  }

  checkRemaining() {
    this.cancelAllHide = true;
    this.activeOrders.forEach((element: any) => {
      if (element.st == false) {
        this.cancelAllHide = false;
      }
    });
  }

  manualSorting(data: any) {
    this.orderbyKey = data;

    if (this.sortOrder == 'desc') {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = 'desc';
    }
  }
  clear(ID: any) {
    this.activeOrders.forEach((ordrid: any, index: any) => {
          if (ID === ordrid.o) {
            this.activeOrders.splice(index, 1);
          }
    });
  }
}
