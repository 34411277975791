import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { orderBy } from 'lodash';
@Pipe({
  name: 'OrderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(array: any, fieldName: string, fieldType: string = 'string', sortType: string = 'asc'): any[] {
    if (!Array.isArray(array)) {
      return;
    }


    if (!array || sortType === '' || !sortType) { return array; } // no array
    if (array.length <= 1) { return array; } // array with only one item
    if (!fieldName || fieldName === '') {
        if (sortType === 'asc') {
          return array.sort();
        } else {
          return array.sort().reverse();
         }
      } // sort 1d array
    return orderBy(array, [fieldName], [sortType]);

  }
}

@NgModule({
  imports: [],
  declarations: [OrderByPipe],
  exports: [OrderByPipe]
})
export class OrderByPipeModule { }
