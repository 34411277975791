<div class="widget-card">
  <div class="widget-card-header p-0">
    <ul class="nav nav-pills header-tabs hasAnchor">
      <li class="header-tabs-item">
        <a data-toggle="pill" href="#ActiveOrders" class="active">
          {{'trade.allsections.Tab1' |translate}}
        </a>
      </li>
      <li class="header-tabs-item">
        <a data-toggle="pill" href="#Stops">
          {{'trade.allsections.Tab2' |translate}}
        </a>
      </li>
      <li class="header-tabs-item">
        <a data-toggle="pill" href="#Fills">
          {{'trade.allsections.Tab3' |translate}}
        </a>
      </li>
      <li class="header-tabs-item">
        <a data-toggle="pill" href="#OrderHistory">
          {{'trade.allsections.Tab4' |translate}}
        </a>
      </li>
    </ul>
  </div>
  <div class="widget-card-body p-2">
    <div class="tab-content">
      <div id="ActiveOrders" class="tab-pane active">
          <app-active-orders></app-active-orders>
      </div>
      <div id="Stops" class="tab-pane fade">
          <app-stops></app-stops>
      </div>
      <div id="Fills" class="tab-pane fade">
          <app-fills></app-fills>
      </div>
      <div id="OrderHistory" class="tab-pane fade">
          <app-trade-order-history></app-trade-order-history>
      </div>
    </div>
  </div>
</div>
