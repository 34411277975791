<div class="Go-table scrollable">
        <div class="Go-table-header sm-header">
          <div class="header-item left" style="width:50%" (click)="manualSorting('sym')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.activeorders.TH1' |translate}}</span>
          </div>
          <div class="header-item right" style="width:50%" (click)="manualSorting('rPNL')">
            <span [ngClass]="(sortOrder == 'desc')?'down' : 'up'">{{'trade.positions.TH7' | translate}}</span>
          </div>
        </div>
        <div class="Go-table-body all-section-height" *ngIf="userPositions">
          <div class="body-row tbl-striped" *ngFor="let item of userPositions | OrderBy: orderbyKey:orderByType: sortOrder; let i = index;">
            <div class="body-item left" style="width:50%">{{item.sym}}</div>
            <div class="body-item right" style="width:50%">{{item.rPNL}}</div>
          </div>
        </div>
      </div>
