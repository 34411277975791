declare var baseUrl;
import {
  Injectable, ɵConsole
} from '@angular/core';
import {
  Http,
  Headers,
  RequestOptions
} from '@angular/http';
import {
  Observable,
  Subject,
  BehaviorSubject,
  Subscription,
  from,
  of
} from 'rxjs';
// import 'rxjs/add/operator/map';
import {
  Router,
  ActivatedRoute,
  NavigationEnd
} from '@angular/router';
import {
  AuthService
} from '../guard/auth.service';
import {
  ToastrService
} from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
@Injectable({
  providedIn: 'root'
})

export class ApiService {

  // BaseUrl = environment.apiUrl;
  BaseUrl = baseUrl;
  webURL = '';
  tempAuthToken = new BehaviorSubject < any > ([]);
  requireDeviceWhitelisting = new BehaviorSubject < any > ([]);
  SideBarStatus = new BehaviorSubject < any > ([]);
  sendCurrentCurr = new BehaviorSubject < any > ([]);
  sendCurrentResolution = new BehaviorSubject < any > ([]);
  sendListCurrencyDetails = new BehaviorSubject < any > ([]);
  ChartColor = new BehaviorSubject < any > ([]);
  sendSelectorContract = new BehaviorSubject < any > ([]);
  sendSelectedContractDetail = new BehaviorSubject < any > ([]);
  sendIndicesList = new BehaviorSubject < any > ([]);
  myHeaders: any;
  IsLogin = false;
  data22: any;
  data: any;
  AllSettings = new BehaviorSubject < any > ([]);
  uuidValue:string;
  CryptoPrice = new BehaviorSubject < any > ([]);
  FiatPrice = new BehaviorSubject < any > ([]);
  SendselectedFiatEst = new BehaviorSubject < any > ([]);

  sendContractsDetails = new BehaviorSubject < any > ([]);
  sendIndicesDetails = new BehaviorSubject < any > ([]);
  sendIndicesDetailsBreakdown = new BehaviorSubject < any > ([]);
  lastTradeDetails = new BehaviorSubject < any > ([]);

  loadingStatus = new BehaviorSubject < any > ([]);
  currSelectedOrder = new BehaviorSubject < any > ([]);
  userAuthStatus = new BehaviorSubject < any > ([]);
  CurrentLang = new BehaviorSubject < any > (null);
  persistAuth = {
    isAuthenticated: false,
    authorization: null,
    gAuthEnabled: false,
  };
  currentLeverageData = new BehaviorSubject < any > ([]);
  currContractDetails = new BehaviorSubject < any > ([]);

  CurrPostionSize = new BehaviorSubject < any > ([]);
  selectedWalletCurrency = new BehaviorSubject < any > ([]);
  getNotifyEmptyOrders = new BehaviorSubject < any > ([]);

  sendWalletSelectedCurr = new BehaviorSubject < any > ([]);

  SendcurrSettledInCurreny = new BehaviorSubject < any > ([]);
  timeGap = new BehaviorSubject < any > ([]);
  UTCseconds: number;
  APIUTCTime: any;
  TimeMismatch: boolean;

  sendSideBarStatus = new BehaviorSubject < any > ([]);
  tokeninterval = null;

  constructor(private http: Http, public router: Router, private auth: AuthService,
              public activatedRoute: ActivatedRoute, public toastr: ToastrService) {
    // if (JSON.parse(localStorage.getItem('persist:auth')) != null) {
    //   this.persistAuth = JSON.parse(localStorage.getItem('persist:auth'));
    //   this.IsLogin = this.persistAuth.isAuthenticated;
    //   console.log('A+');
    // } else {
    //   this.persistAuth.authorization = null;
    //   this.persistAuth.isAuthenticated = false;
    //   localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
    //   this.auth.logout('/login');
    //   console.log('B+');
    // }

      this.generateUUID();

    this.Updateisloginstatus();

    if(this.IsLogin == true){
      this.renew_access_token();
    }

    // const SBStatus = localStorage.getItem('toggleSidebar');
    // if (SBStatus === undefined || SBStatus == null || SBStatus.trim() == '') {
    //   this.GetSideBarStatus('left');
    // } else {
    //   this.GetSideBarStatus(SBStatus);
    // }

    this.requestCryptoCurrenciesPrice();
    this.requestFiatCurrenciesPrice();
    // this.requestSettings();

    if (localStorage.getItem('currentFiatCurrency') != null) {
      this.GetselectedFiatEst(localStorage.getItem('currentFiatCurrency'));
    }

    this.requestSettings().then((result: any) => {
      this.AllSettings.next(result.data);
      this.data = result.data;
      let link = document.querySelector<HTMLAnchorElement>('link[rel*=\'icon\']') || document.querySelector<HTMLAnchorElement>('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = this.data.favIcon_url;
      document.getElementsByTagName('head')[0].appendChild(link);
      document.title = this.data.exchange_name;

      this.APIUTCTime = result.data.server_time_utc / 1000;
      // console.log(typeof 'result.data.server_time_utc', this.APIUTCTime);
      this.UTCseconds = Math.floor(new Date().getTime() / 1000);
      // console.log(typeof 'this.UTCseconds', this.UTCseconds);
      const timeGap = this.UTCseconds - this.APIUTCTime;
      if (timeGap < -60 || timeGap > 60) {
        this.TimeMismatch = true;
        this.timeGap.next(this.TimeMismatch);
      }

    }, (err: any) => {
    //  console.log('Error while getting website settings from api', data);
      if (err.status == 403 && err.message == 'BrokerNotFound') {
      //  console.log('URL: ', location.href);
      //  this.router.navigate(['/BrokerNotFound']);
      }
    });

  }

  generateUUID(){
    let uuidValue= localStorage.getItem('UUID');
    if(uuidValue)
    return uuidValue;
    uuidValue=uuidv4();
    localStorage.setItem('UUID', uuidValue);
    return uuidValue;
  }
  ShowToastrAlert(heading: string, msg: string, type: string) {
    // Swal(heading, msg , type);
    if (type === 'error') {
      this.toastr.error(msg, heading);
    } else if (type === 'success') {
      this.toastr.success(msg, heading);
    } else if (type === 'info') {
      this.toastr.info(msg, heading);
    } else if (type === 'warning') {
      this.toastr.warning(msg, heading);
    }
  }


  websoketNotify(type: any, title: any, content: any, time: any) {
    // Swal(heading, msg , type);
    if (type === 'Error') {
      this.toastr.error(content, title, time);
    } else if (type === 'Success') {
      this.toastr.success(content, title, time);
    } else if (type === 'Info') {
      this.toastr.info(content, title, time);
    } else if (type === 'Warning') {
      this.toastr.warning(content, title, time);
    }
  }

  Updateisloginstatus() {
    if (JSON.parse(localStorage.getItem('persist:auth')) != null) {
    this.persistAuth = JSON.parse(localStorage.getItem('persist:auth'));
    this.userAuthStatus.next(JSON.parse(localStorage.getItem('persist:auth')));
    this.IsLogin = this.persistAuth.isAuthenticated;
    if(this.IsLogin == true){
       this.tokeninterval = setInterval(() => {
        this.renew_access_token();
        }, 180000);
    }
  } else if (JSON.parse(localStorage.getItem('persist')) != null) {
    this.requestLogout().then((result: any) => {
      this.ShowToastrAlert('', result.data, 'success');
    }, (err: any) => {
      this.ShowToastrAlert('', err.data, 'error');
    });
    this.IsLogin = false;
    this.persistAuth.authorization = null;
    this.persistAuth.isAuthenticated = false;
    localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
    // this.auth.logout('/login');
  } else {
    this.IsLogin = false;
    this.persistAuth.authorization = null;
    this.persistAuth.isAuthenticated = false;
    localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
  }
  }

  renew_access_token(){
    // console.error('hiii');

    //   console.warn('access token', );
      return new Promise((resolve, reject) => {
        this.PostApi('renew-token')
          .then((res: any) => {
            //  console.log('kuch', res);
             this.persistAuth.authorization = null;
             this.persistAuth.authorization = res.data.access_token;
             localStorage.setItem('persist:auth', JSON.stringify(this.persistAuth));
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
      // this.apiService.ShowToastrAlert('', 'Risk Limit Settings Updated', 'success');

  }


  getLoadingStatus(data: boolean) {
    this.loadingStatus.next(data);
  }

  getCurrentCurr(data: any) {
    this.sendCurrentCurr.next(data);
  }

  getResolutions(data: any) {
    this.sendCurrentResolution.next(data);
  }

  listCurrencyDetails(data: any) {
    this.sendListCurrencyDetails.next(data);
  }

  PostApi(route: string, body = null) {
    return new Promise((resolve, reject) => {
      const headers: Headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', this.persistAuth.authorization);
      headers.append('UUID', localStorage.getItem('UUID'));
      headers.append('client', 'web');
      this.http.post(this.BaseUrl + route, body, {
        headers
      }).subscribe((res: any) => {
        if (res._body != null && res._body !== undefined) {
          let data = JSON.parse(res._body);
          if (data == undefined || data == null) {
            data = {};
          }
          data.status = res.status;
          resolve(data.success);
        }
      }, (res: any) => {
        let data = JSON.parse(res._body);
        if (data.error != undefined && data.error != null) {
          if (data.error.code == 5000) {
            this.auth.logout('/login');
            this.Updateisloginstatus();
          }
        }
        if (res.status == 401) {
          this.auth.logout('/login');
          this.Updateisloginstatus();
        }
        // if (res.status) {
        if (res._body !== '') {
            let data = JSON.parse(res._body);
            if (data == undefined || data == null) {
              data = {};
            }
            data.status = res.status;
            reject(data.error);
          }
        // }

      });
    });
  }

  GetApi(route: string, queryString = null) {
    return new Promise((resolve, reject) => {
        const headers: Headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', this.persistAuth.authorization);
        queryString = queryString == null ? '' : '?' + queryString;
        this.http.get(this.BaseUrl + route + queryString, {
          headers
        }).subscribe((res: any) => {
            if (res._body != null && res._body !== undefined) {
              let data = JSON.parse(res._body);
              if (data == undefined || data == null) {
                data = {};
              }
              data.status = res.status;
              resolve(data.success);
            }
            }, (res: any) => {
              let data = JSON.parse(res._body);
              if (data.error != undefined && data.error != null) {
                if (data.error.code == 5000) {
                  this.auth.logout('/login');
                  this.Updateisloginstatus();
                }
              }
              if (res.status == 401) {
              this.auth.logout('/login');
              this.Updateisloginstatus();
            }
              if (res._body !== '') {
              let data = JSON.parse(res._body);
              if (data == undefined || data == null) {
                data = {};
              }
              data.status = res.status;

              reject(data.error);
            }
            });
        });
    }

  // GetSideBarStatus(data: any) {
  //   this.SideBarStatus.next(data);
  // }

    Register_Account(payload: any) {
      // console.log('payload', payload);
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/signup', payload)
          .then((res: any) => {
            // console.log('kuch', res);
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    Verify_Account(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/verify', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    Login_Account(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/signin', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    Request_ForgotPassword(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/forgot-password', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    ResetPasword(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/reset-password', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    tokenGenerate(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('token', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestCryptoWithdrawal(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/add-crypto-withdrawal', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestWithdrawalEmailAgain() {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/crypto_withdrawal_resend_email')
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestChangePassword(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/change-password', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    getTempAuth(data: any) {
      this.tempAuthToken.next(data);
    }
    getdevicewhitelist(data: any) {
      this.requireDeviceWhitelisting.next(data);
    }

    RequestCountryList(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('list-countries' + payload)
          .then((result: any) => {
            resolve(result);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    RequestCaptcha(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('captcha?' + payload)
          .then((result: any) => {
            resolve(result);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestOTP(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/request-otp', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestResendOTP(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/resend-otp', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestUserProfile() {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('user/my-profile')
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestUpdateProfile(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/update-profile', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    check2faStatus() {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('user/check-2fa-status')
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    request2faChangeStatus(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/change-2fa-status', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestContractsMenu() {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('public/contracts-menu')
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestContractsList() {
      const headers: Headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', this.persistAuth.authorization);
      return this.http.get(environment.apiUrl + 'public/contracts-menu', {headers});
    }

    requestContractDetails(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('public/contracts/' + payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestIndicesMenu() {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('public/indices-menu')
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestIndicesList() {
      const headers: Headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', this.persistAuth.authorization);
      return this.http.get(environment.apiUrl + 'public/indices-menu', {headers});
    }

    requestIndices(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('public/indices/' + payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    requestIndicesDetailedBreakdown(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('public/indices-detailed-breakdown/' + payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    getwalletbalance(payload: any) {
      return new Promise((resolve, reject) => {
        this.GetApi('user/wallet-balance/' + payload)
          .then((result: any) => {
            resolve(result);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    // requestIndicesCharts(payload: any) {
    //   return new Promise((resolve, reject) => {
    //     this.GetApi('public/indices-charts/' + payload)
    //       .then((res: any) => {
    //         resolve(res);
    //       }, (res: any) => {
    //         reject(res);
    //       });
    //   });
    // }

    // requestContractsCharts(payload: any) {
    //   return new Promise((resolve, reject) => {
    //     this.GetApi('public/charts/' + payload)
    //       .then((res: any) => {
    //         resolve(res);
    //       }, (res: any) => {
    //         reject(res);
    //       });
    //   });
    // }

    requestListCurrency() {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/list-currency')
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

    currecnyList() {
      const headers: Headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', this.persistAuth.authorization);
      return this.http.post(environment.apiUrl + 'user/list-currency', {}, {headers});
    }

    requestDepositGenerateAddress(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('user/generate-address/' + payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

// --------- list-crypto-deposits ---------

    requestCryptoDepositList(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('user/list-deposits/' + payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

// --------- list-fiat-deposit ---------

requestFiatDepositList(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/list-fiat-deposit/' + payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

// --------- list-fiat-withdrawal ---------

    requestWithdrawalList(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('user/all-withdrawals/' + payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

// --------- AddFiatDeposit ---------

    requestAddFiatDeposit(payload: any) {
      return new Promise<any>((resolve, reject) => {
        this.PostApi('user/add-fiat-deposit', payload)
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }


    _PutApi(_URL: any, file: any, fileType: any) {
      return new Promise<any>((resolve, reject) => {
        try {
          this.myHeaders = new Headers();
          this.myHeaders.append('Content-Type', 'application/json, ' + fileType);
          const options = new RequestOptions({ headers: this.myHeaders });
          // const uploadData = new FormData();
          // uploadData.append(filename, file, filename);
          this.http.put(_URL, file, options)
            .subscribe(result => {
              this.data22 = result;
              resolve(this.data22);
            }, err => {
              if (err.status == 401) {
                window.location.reload();
                this.auth.logout('/Login');
                reject(err);
              }
            });
        } catch (err) {
          reject(err);
        }
      });
    }

    GenerateS3_PutUrl() {
      return new Promise<any>((resolve, reject) => {
        this.GetApi('user/get_s3_upload_url')
          .then((res: any) => {
            resolve(res);
          }, (res: any) => {
            reject(res);
          });
      });
    }

// ---------- user/add-fiat-withdrawal ----------add-fiat-withdrawal

requestFiatWithdrawal(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/add-fiat-withdrawal', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}
// requestUserProfile() {
//   return new Promise((resolve, reject) => {
//     this.GetApi('user/my-profile')
//       .then((res: any) => {
//         resolve(res);
//       }, (res: any) => {
//         reject(res);
//       });
//   });
// }
getapilist() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/apikeys-list/')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}
deleteapikey(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/delete-apikey/', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}
GenerateApiKey(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/generate-apikey/', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}
getprivacypolicy() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/content/PrivacyPolicy')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}
getaboutus() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/content/AboutUs')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}
getcontactus() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/content/ContactUs')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}
getcookiepolicy() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/content/CookiePolicy')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}
gettermscondition() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/content/TermsOfService')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}
getrisksdisclouser() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/content/RiskDisclosure')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestCryptoCurrenciesPrice() {
  return new Promise((resolve, reject) => {
    this.GetApi('user/crypto-price')
      .then((result: any) => {
        resolve(result);
        this.CryptoPrice.next(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestFiatCurrenciesPrice() {
  return new Promise((resolve, reject) => {
    this.GetApi('user/fiat-price')
      .then((result: any) => {
        resolve(result);
        this.FiatPrice.next(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestSettings() {
  return new Promise((resolve, reject) => {
    this.GetApi('user/settings')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

Loginhistory() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/login-history/')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

ReferralCommision(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/affiliate-commission/' + payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

BonusCommission() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/bonus-commission')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

Referrallist() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/affiliates-list/')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

GetKyc() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/GOKYC_Get_Kyc_Form')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

PostKycDetail(payload: any, level: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/GOKYC_Submit_KYC_Form?level=' + level, payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

ResendEmail(payload: any) {
  // console.log('payload', payload);
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/signup_resend_email', payload)
      .then((res: any) => {
        // console.log('kuch', res);
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

all_risk_limit(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/all-risk-limits/' + payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

user_risk_limit(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/user-margin-settings/' + payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

SendUpadteRisklimit(payload: any) {
  // console.log('payload', payload);
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/user-risk-limit-update/' + payload)
      .then((res: any) => {
        // console.log('kuch', res);
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

Upadteleverage(payload: any) {
  // console.log('payload', payload);
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/user-leverage-update/' + payload)
      .then((res: any) => {
        // console.log('kuch', res);
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestOrderBook(payload: any) {
  return new Promise((resolve, reject) => {
    this.GetApi('public/order-book/' + payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

NewOrder(payload: any) {
  // console.log('payload', payload);
  return new Promise<any>((resolve, reject) => {
    this.PostApi('order/new-order', payload)
      .then((res: any) => {
        // console.log('kuch', res);
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

CancelOrder(payload: any) {
  // console.log('payload', payload);
  return new Promise<any>((resolve, reject) => {
    this.PostApi('order/cancel-order', payload)
      .then((res: any) => {
        // console.log('kuch', res);
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

WalletTranscations(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/Wallet_Transcations?' + payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

Blockaccount(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/block-account/' + payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

positionUpdateMargine(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('order/position-update-margine', payload)
      .then((res: any) => {
        // console.log('kuch', res);
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestEstimateMargin(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('order/estimate-margin', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestPosCloseOrderMarkitPrice(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('order/position-close-order-markprice', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestUpdateOrder(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('order/update-order', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}
orderHistory(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('order/my-order-history', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

tradeHistory(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('order/my-trade-history', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestBonusTypes() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/Get_Bonus_Types/')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestBonusForm(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/Get_Bonus_Form/?BonusID=' + payload)
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

sendBonusForm(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/Submit_Bonus_Request', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestBonusHistory() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/Get_Bonus_Request')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

requestLogout() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/logout')
      .then((result: any) => {
        clearInterval(this.tokeninterval);
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}

CancelOrderWithdrawal() {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/crypto_withdrawal_cancel')
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}


getkycWebsdkMode() {
  return new Promise<any>((resolve, reject) => {
      const headers: Headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', this.persistAuth.authorization);
      this.http.get(this.BaseUrl + 'user/kyc-websdk-get-access-token', {
        headers
      }).subscribe((res: any) => {
          if (res._body != null && res._body !== undefined) {
            let data = JSON.parse(res._body);
            if (data == undefined || data == null) {
              data = {};
            }
            data.status = res.status;
            resolve(data.success);
          }
          }, (res: any) => {
            let data = JSON.parse(res._body);
            if (data.error != undefined && data.error != null) {
              if (data.error.code == 5000) {
                this.auth.logout('/login');
                this.Updateisloginstatus();
              }
            }
            if (res.status == 401) {
            this.auth.logout('/login');
            this.Updateisloginstatus();
          }

          if (res.status != 200) {
            if (res._body !== '') {
              let data = JSON.parse(res._body);

              if (data == undefined || data == null) {
                data = {};
              }
              data.status = res.status;

              reject(data.error);
            }
          }
          });
      });
  }

getwhitelisteddevices() {
  return new Promise<any>((resolve, reject) => {
    this.GetApi('user/whitelisted-devices')
      .then((result: any) => {
        resolve(result);
      }, (res: any) => {
        reject(res);
      });
  });
}
deletewhitelisteddevices(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/remove-whitelisted-device/'+ payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}
requestsendOTP() {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('whitelist-device-request-otp')
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}
CancelFiatwithdrawal(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/cancel-fiat-withdrawal', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}
CancelFiatdeposit(payload: any) {
  return new Promise<any>((resolve, reject) => {
    this.PostApi('user/cancel-fiat-deposit', payload)
      .then((res: any) => {
        resolve(res);
      }, (res: any) => {
        reject(res);
      });
  });
}


getwalletSelectedCurrency(curr: any) {
  this.selectedWalletCurrency.next(curr);
}


getCurrentPostionSize(data: any) {
  this.CurrPostionSize.next(data);
}

ChangeLang(lan: any) {
  this.CurrentLang.next(lan);
}
chartcolor(data: any) {
  this.ChartColor.next(data);
}

GetselectedFiatEst(data: any) {
  if (data != null && data !== undefined) {
    this.SendselectedFiatEst.next(data);
  }
}

getContractSelector(data: any) {
  this.sendSelectorContract.next(data);
}

getContractDetails(data: any) {
  this.sendContractsDetails.next(data);
}

indicesListToView(data: any) {
  this.sendIndicesList.next(data);
}

getIndicesDetails(data: any) {
  this.sendIndicesDetails.next(data);
}

getIndicesDetailsBreakdown(data: any) {
  this.sendIndicesDetailsBreakdown.next(data);
}

getSelectedContractDetails(data: any) {
  this.sendSelectedContractDetail.next(data);
}

getLastTradeDetails(data: any) {
  this.lastTradeDetails.next(data);
}

SelectedOrder(data: any) {
  this.currSelectedOrder.next(data);
}

sendContractDetails(data: any) {
  this.currContractDetails.next(data);
}

getCurrentLeverage(lev: any) {
  this.currentLeverageData.next(lev);
}

notifyEmpty(data: any) {
  this.getNotifyEmptyOrders.next(data);
}

requestWSC(data: any) {
  this.sendWalletSelectedCurr.next(data);
}

CurrentSettledInCurr(data: any) {
  this.SendcurrSettledInCurreny.next(data);
}

getSidebarStatus(togglebtnClicked: any) {
  this.sendSideBarStatus.next(togglebtnClicked);
}

}
