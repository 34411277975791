<div class="auth-container widget-container">
    <section class="login-panel">
    <header class="text-align-center">
          <h3 class="text-center my-3">{{'RstPass' | translate}}</h3>
    </header>
    <div class="body p-3">
      <div class="floating-form">
        <div class="floating-label">
          <input class="floating-input" [(ngModel)]="tempNewPassword" type="password" name="password"
            name="password" placeholder=" " (blur)="tempNewPasswordValidate()" (keyup)="tempNewPasswordValidate()">
          <label for="password">{{'forgot.Label2' |translate}}</label>
          <div class="ErrorMSG" *ngIf="tempNewPasswordValidateError != ''">{{tempNewPasswordValidateError}}</div>
        </div>
      </div>
      <div class="floating-form">
        <div class="floating-label">
          <input class="floating-input" [(ngModel)]="confirmData.newPassword" type="password" name="confirmPassword"
            name="password" placeholder=" " (blur)="confirmPasswordValidate()" (keyup)="confirmPasswordValidate()">
          <label for="password">Confirm new password</label>
          <div class="ErrorMSG" *ngIf="confirmPasswordValidateError != ''">{{confirmPasswordValidateError}}</div>
        </div>
      </div>
      <div class="form-group">
        <button class="GoBtn hoCLhT" (click)="ConfirmPassword()" [disabled]="ConfirmProcessing">{{(ConfirmProcessing? 'Signin.Process' : 'forgot.CnfNwPass') | translate}}</button>
      </div>
    </div>
  </section>
  </div>
