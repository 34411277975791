import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  KeyValue
} from '@angular/common';
import {
  Subscription
} from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-indices-details',
  templateUrl: './indices-details.component.html',
  styleUrls: ['./indices-details.component.scss']
})
export class IndicesDetailsComponent implements OnInit, OnDestroy {
  indicesItemDetails: any;
  indicesItemDetailedBreakdown: any;
  sendIndicesDetailsSub: Subscription;
  sendIndicesDetailsBreakdownSub: Subscription;
  contractSelector: any;
  isBreakdownShown = false;
  indicesMenuData: any;
  indicesResponse: any;
  contractsResponse: any;

  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit() {

    this.sendIndicesDetailsSub = this.apiService.sendCurrentCurr.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.contractSelector = data;
      }
    });

    this.sendIndicesDetailsSub = this.apiService.sendIndicesList.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.indicesMenuData = data;
      }
    });

    this.sendIndicesDetailsSub = this.apiService.sendIndicesDetails.subscribe((resData: any) => {
      if (resData != null && resData !== undefined && resData.length !== 0) {
        this.indicesItemDetails = resData;
        // console.log('asfdasdf', resData);
      }
    });

    this.sendIndicesDetailsBreakdownSub = this.apiService.sendIndicesDetailsBreakdown.subscribe((resData: any) => {
      if (resData != null && resData !== undefined && resData.length !== 0) {
        this.indicesItemDetailedBreakdown = resData.composite_breakdown;
        this.indicesResponse = resData.indices_using_this;
        this.contractsResponse = resData.contracts_using_this;
      }
    });
  }

  indicesUsingIndex(data: any) {
    this.router.navigate(['/contracts/', data]);
  }

  instrumentsUsingIndex(data: any) {
    this.router.navigate(['/indices/', data]);
  }

  originalOrder = (a: KeyValue < number, string > , b: KeyValue < number, string > ): number => {
    return 0;
  }

  ngOnDestroy(): void {
    if (this.sendIndicesDetailsSub) {
      this.sendIndicesDetailsSub.unsubscribe();
    }
    if (this.sendIndicesDetailsBreakdownSub) {
      this.sendIndicesDetailsBreakdownSub.unsubscribe();
    }
  }

}
