import {
  Component,
  OnInit,
  NgZone
} from '@angular/core';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  Subscription
} from 'rxjs';
// import {
//   THIS_EXPR
// } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss']
})
export class StopsComponent implements OnInit {
  stopOrders = [];

  cancel = {
    orderID: ''
  };
  currContract: any;
  currentMarkPrice: any;

  lastTradePrice = 0;
  LTP: any;

  updateOrderObj = {
    OrderID: '',
    size: 0,
    price: '',
    stopPrice: ''
  };

  selectedOrderIds = null;
  selectedOrderIdp = null;
  selectedOrderIdsp = null;
  cancelAllHide = false;
  markPriceTicker: any;
  orderbyKey: any;

  sortOrder: string = '';
  orderByType: any;
  constructor(public webSocketService: WebsocketService, public zone: NgZone, public apiService: ApiService) {}

  ngOnInit() {
    this.apiService.sendSelectorContract.subscribe((contractData: any) => {
      if (contractData != null && contractData !== undefined && contractData.length !== 0) {
        this.currContract = contractData;
      }
    });

    this.webSocketService.myOrdersSub.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
          data.forEach((element: any) => {
            if (element.t.startsWith('Stop') || element.t.startsWith('TakeProfit')) {
              if (this.stopOrders.length === 0) {
                if (element.st === false) {
                  const temp = {
                    sym: element.sym,
                    ts: element.ts,
                    o: element.o,
                    u: element.u,
                    sp: element.sp,
                    p: element.p,
                    r: element.r,
                    s: element.s,
                    q: element.q,
                    st: element.st,
                    tif: element.tif,
                    t: element.t,
                    odst: element.odst,
                    v: element.v,
                    fp: element.fp,
                    trg: element.trg,
                    trgtyp: element.trgtyp,
                  };
                  this.stopOrders.unshift(temp);
                }
              } else {
                let isFound = false;

                this.stopOrders.forEach((newEl: any) => {
                  if (newEl.o == element.o) {
                    isFound = true;
                    newEl.sym = element.sym;
                    newEl.ts = element.ts;
                    newEl.o = element.o;
                    newEl.u = element.u;
                    newEl.sp = element.sp;
                    newEl.p = element.p;
                    newEl.r = element.r;
                    newEl.s = element.s;
                    newEl.q = element.q;
                    newEl.st = element.st;
                    newEl.tif = element.tif;
                    newEl.t = element.t;
                    newEl.odst = element.odst;
                    newEl.v = element.v;
                    newEl.fp = element.fp;
                    newEl.trg = element.trg;
                    newEl.trgtyp = element.trgtyp;
                  }
                });

                if (!isFound) {
                  if (element.st === false) {
                    const temp = {
                      sym: element.sym,
                      ts: element.ts,
                      o: element.o,
                      u: element.u,
                      sp: element.sp,
                      p: element.p,
                      r: element.r,
                      s: element.s,
                      q: element.q,
                      st: element.st,
                      tif: element.tif,
                      t: element.t,
                      odst: element.odst,
                      v: element.v,
                      fp: element.fp,
                      trg: element.trg,
                      trgtyp: element.trgtyp,
                    };
                    this.stopOrders.unshift(temp);
                  }
                }
              }
            }
          });
          this.checkRemaining();
        }
      });
    });

    this.apiService.getNotifyEmptyOrders.subscribe((data: any) => {
      if (data != null && data !== undefined) {
        this.cancelAllHide = data;
      }
    });

    this.webSocketService.markPrice.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.markPriceTicker = data;
      }
    });

    this.apiService.lastTradeDetails.subscribe((lastPrice: any) => {
      if (lastPrice != null && lastPrice !== undefined && lastPrice.length !== 0) {
        if (this.lastTradePrice == 0) {
          this.LTP = lastPrice[0].p;
        }
        this.lastTradePrice = lastPrice[0].p;
      }
    });
  }

  async cancelOD(ID: any) {
    this.cancel.orderID = ID;
    let result = await this.apiService.CancelOrder(this.cancel).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      this.apiService.ShowToastrAlert('', result.message, 'success');
  }

  async cancelAllStops() {
    for(let i = 0; i < this.stopOrders.length; i++) {
      if (this.stopOrders[i].st === false) {
        this.cancel.orderID = this.stopOrders[i].o;
        let result = await this.apiService.CancelOrder(this.cancel).catch(err => {
          this.cancelAllHide = false;
          this.apiService.ShowToastrAlert('', err.message, 'error');
        });
        if(result == undefined || result== null) {
          return
          }
          this.cancelAllHide = true;
          this.apiService.notifyEmpty(this.cancelAllHide);
          this.apiService.ShowToastrAlert('', result.message, 'success');
      }
    }
  }

  openEditOrder(data: any, type: any) {
    this.selectedOrderIds = null;
    this.selectedOrderIdp = null;
    this.selectedOrderIdsp = null;
    if (type == 'size') {
      this.updateOrderObj.size = data.q;
      this.selectedOrderIds = data.o;
    } else if (type == 'price') {
      this.updateOrderObj.price = data.p;
      this.selectedOrderIdp = data.o;
    } else if (type == 'stopPrice') {
      this.updateOrderObj.stopPrice = data.sp;
      this.selectedOrderIdsp = data.o;
    }
  }


  confirmEditOrder(data: any, type: any) {
    if (this.selectedOrderIds == data.o || this.selectedOrderIdp == data.o || this.selectedOrderIdsp == data.o) {
      if (type == 'size') {
        this.updateOrderObj.price = data.p;
        this.updateOrderObj.stopPrice = data.sp;
      } else if (type == 'price') {
        this.updateOrderObj.size = data.q;
        this.updateOrderObj.stopPrice = data.sp;
      } else if (type == 'stopPrice') {
        this.updateOrderObj.price = data.p;
        this.updateOrderObj.size = data.q;
      }
      this.updateOrderObj.OrderID = data.o;
      this.updateOrder();
      this.selectedOrderIds = null;
      this.selectedOrderIdp = null;
      this.selectedOrderIdsp = null;
    }
  }

  closeEditOrder(data: any, type: any) {
    if (this.selectedOrderIds == data.o || this.selectedOrderIdp == data.o || this.selectedOrderIdsp == data.o) {
      this.selectedOrderIds = null;
      this.selectedOrderIdp = null;
      this.selectedOrderIdsp = null;
    }
  }

 async updateOrder() {
    // console.log('update stop orders', this.updateOrderObj);
    let result = await this.apiService.requestUpdateOrder(this.updateOrderObj).catch(err => {
      this.apiService.ShowToastrAlert('', err.message, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      this.apiService.ShowToastrAlert('', result.message, 'success');
  }


  checkRemaining() {
    this.cancelAllHide = true;
    this.stopOrders.forEach((element: any) => {
      if (element.st == false) {
        this.cancelAllHide = false;
      }
    });
  }
  manualSorting(data: any) {
    this.orderbyKey = data;

    if (this.sortOrder == 'desc') {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = 'desc';
    }
  }

}
