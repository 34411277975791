<footer class="footer" *ngIf="footerHidden != true">
  <div class="footer-content">
    <div>
        <div class="text-center">Copyright © 2020 by {{content?.exchange_name}}</div>
        <div class="mb-2 text-center">All Rights Reserved.</div>

        <div class="footer-menu">
          <div class="jxDBhk"><a href="javascript:void(0)">Reference</a></div>
          <div class="jxDBhk"><a href="javascript:void(0)">Currencies</a></div>
          <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/api-documentation']">API Documentation</a></div>
          <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/websocket-api']">WebSocket API</a></div>
          <!-- <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/fees']">Exchange Fees</a></div>
          <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/trading-rules']">Trading Rules</a></div> -->
          <div class="jxDBhk"><a href="javascript:void(0)">Risk and Advisory</a></div>
          <div class="jxDBhk"><a href="{{content?.support_url}}" target="_blank">Support</a></div>
        </div>
        <div class="footer-menu">
            <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/about-us']">About Us</a></div>
            <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/contact-us']">Contact Us</a></div>
            <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/privacy-policy']">Privacy Policy</a></div>
            <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/cookie-policy']">Cookie Policy</a></div>
            <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/risk-disclosure']">Risk Disclosure</a></div>
            <div class="jxDBhk"><a href="javascript:void(0)" [routerLink]="['/terms-of-service']">Terms of Service</a></div>
        </div>
    </div>
  </div>
</footer>
