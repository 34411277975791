
declare var baseUrl;
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-websocket-api',
  templateUrl: './websocket-api.component.html',
  styleUrls: ['./websocket-api.component.scss']
})
export class WebsocketApiComponent implements OnInit {
  allSettingsSub: Subscription;
  content: any;
  theme: string;
  chartcolorSubscription: Subscription;
  BaseUrl: any;
  constructor(public apiService: ApiService, public router: Router) {
    this.BaseUrl = baseUrl.replace('https://', '') .replace('http://', '');
    this.chartcolorSubscription = this.apiService.ChartColor.subscribe(
      (mode: any) => {
        if (mode == 'Dark') {
          this.theme = mode + '&' + this.BaseUrl;
        } else if (mode == 'Light') {
          this.theme = mode + '&' + this.BaseUrl;
        }

      }
    );


  }

  ngOnInit() {
    this.allSettingsSub = this.apiService.AllSettings.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.content = data;
      }
    });
  }

}
