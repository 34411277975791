<div class="dPAsfZ">
  <div class="container-fluid">
    <div class="content">
        <ul class="nav nav-pills line-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link fuweRi active" data-toggle="pill" href="#ExchangeTradingFees">Exchange Trading Fees
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link fuweRi cLErrt" data-toggle="pill" href="#DepositWithdrawalFees">Deposit & Withdrawal Fees</a>
            </li>
          </ul>
          
          <!-- Tab panes -->
          <div class="tab-content">
            <div id="ExchangeTradingFees" class="tab-pane active"><br>
              <app-exchange-trading-fees></app-exchange-trading-fees>
            </div>
            <div id="DepositWithdrawalFees" class="tab-pane fade"><br>
              <app-deposit-withdrawal-fees></app-deposit-withdrawal-fees>
            </div>
          </div>
    </div>
  </div>
</div>

