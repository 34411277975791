import {
  Injectable,
  NgZone
} from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import {
  HistoryProviderService
} from './history-provider.service';
import {
  WebsocketService
} from '../websocket.service';

@Injectable({
  providedIn: 'root'
})
export class StreamProviderService {

  subscription: {
    channel: string;
    uid: any;
    resolution: any;
    symbolInfo: any;
    listener: any;
    lastBar: any;
  };

  constructor(public webSocketService: WebsocketService, public histroyProviderService: HistoryProviderService, private zone: NgZone) {

    this.webSocketService.tradeData.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
          this.updateVolume(data[0]);
          this.updatePrice(data[0]);
        }
      });
    });

    this.webSocketService.chartTicker.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        const barData = data;
        if (this.subscription) {
          if (barData[0] < this.subscription.lastBar.time) {
            return;
          }

          const lastBar = this.updateBar(barData, this.subscription);

          this.subscription.listener(lastBar);
          // update our own record of lastBar
          this.subscription.lastBar = lastBar;
        }
      }

    });

  }

  public streamProvider = {
    subscribeBars: (symbolInfo, resolution, updateCb, uid, resetCache) => {
      const [baseCurrency, quoteCurrency] = symbolInfo.name.split('/');
      const lastBar = _.get(
        this.histroyProviderService.historyProvider,
        // `history.${symbolInfo.name}.lastBar`, {
        //   time: moment()
        //     .startOf('m')
        //     .valueOf(),
        //   open: 0,
        //   high: 0,
        //   low: 0,
        //   close: 0,
        //   volume: 0,
        // },
      );

      // this.subscription = {
      //   // channel: `${baseCurrency}${quoteCurrency}1`,
      //   // uid,
      //   // resolution,
      //   // symbolInfo,
      //   // listener: updateCb,
      //   // lastBar,
      // };
      // console.log('this.subscription', this.subscription);
    },
    unsubscribeBars: uid => {
      // connection.stop();
    },
  };

  updateVolume = (ticker) => {
    if (!_.isEmpty(this.subscription)) {
      const lastBar = this.subscription.lastBar;
      lastBar.volume += ticker.q;
      this.subscription.listener(lastBar);
    }
  }

  updatePrice = (price) => {
    // console.log('update price starting');
    if (!_.isEmpty(this.subscription)) {
      const lastBar = this.subscription.lastBar;
      // console.log('update price condition matched', price.Rate);
      if (price.p !== 0) {
        //  console.log('price Rate', price.Rate);
        if (price.p < lastBar.low) {
          lastBar.low = price.p;
          // console.log('lastBar low', lastBar);
        } else if (price.p > lastBar.high) {
          lastBar.high = price.p;
          // console.log('lastBar high', lastBar);
        }

        lastBar.close = price.p;

        this.subscription.listener(lastBar);
      }
    }
    // console.log('update price ending');
  }

  updateBar = (barData, subscription) => {
    const lastBar = subscription.lastBar;
    let resolution = subscription.resolution;

    if (resolution.includes('D')) {
      // 1 day in minutes === 1440
      resolution = 1440;
    } else if (resolution.includes('W')) {
      // 1 week in minutes === 10080
      resolution = 10080;
    }

    const coeff = resolution * 60 * 1000;
    // console.log({coeff})
    const isSameInterval =
      Math.floor(barData[0] / coeff) === Math.floor(lastBar.time / coeff);
    let lastBarV2;

    if (!isSameInterval) {
      // create a new candle, use last close as open **PERSONAL CHOICE**
      lastBarV2 = {
        time: barData[0],
        open: lastBar.close,
        high: lastBar.close,
        low: lastBar.close,
        close: barData[4],
        volume: barData[5],
      };
    } else {
      // update lastBar candle!
      if (barData[4] < lastBar.low) {
        lastBar.low = barData[4];
        console.log('lastBar.low', barData[4]);
      } else if (barData[4] > lastBar.high) {
        lastBar.high = barData[4];
        console.log('lastBar.high', barData[4]);
      }

      lastBar.volume += barData[5];
      lastBar.close = barData[4];
      lastBarV2 = lastBar;
    }
    console.log('_lastBar', lastBarV2);
    return lastBarV2;
  }
}
