import {
  Component,
  OnInit,
  OnDestroy
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  Subscription
} from 'rxjs';
import {
  WebsocketService
} from 'src/app/services/websocket.service';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.scss']
})
export class TradeComponent implements OnInit, OnDestroy {

  allSettingsSub: Subscription;
  contractsList: any;
  contractSelector = {
    contractGroupName: '',
    contractName: ''
  };

  Settings: any;
  tempValues: any;
  currentKey: any;
  tempContractValue: any;
  chartType: string;
  selectedKeyValue: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router,
    private webSocketService: WebsocketService
  ) {}

  ngOnInit() {
    // Getting all default seetings from api service
    this.allSettingsSub = this.apiService.AllSettings.subscribe((settingsData: any) => {
      if (settingsData != null && settingsData !== undefined && settingsData.length !== 0) {
        this.Settings = settingsData;
        this.contractMenu();
      }
    });
    this.changeChartType('Mountain');


    this.apiService.sendSelectorContract.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.contractSelector = data;
      }
    });

    this.apiService.sendContractsDetails.subscribe((data: any) => {
      if (data != null && data !== undefined && data.length !== 0) {
        this.contractsList = data;
        this.extractKeyValue();
      }
    });
  }

async contractMenu(){
  let result = await  this.apiService.requestContractsMenu().catch(err => {
  });
  if(result == undefined || result== null) {
    return
    }
    if (result.data != null && result.data !== undefined && result.data.length !== 0) {
      this.apiService.getLoadingStatus(false);
      this.contractsList = result.data;
    }

    // subscribing activated route for id
    if (this.activatedRoute.snapshot.paramMap.get('id') != null) {
      this.contractSelector.contractName = this.activatedRoute.snapshot.paramMap.get('id');

      // getting contract group name from contract list by contract name
      let isFound = false;
      Object.entries(this.contractsList).forEach(([key, value]) => {
        this.tempValues = null;
        this.currentKey = null;
        if (key != null) {
          this.currentKey = key;
          this.tempValues = value;

          if (value != null && value !== undefined) {
            this.tempValues.forEach((element: any) => {
              if (this.contractSelector.contractName == element.name) {
                isFound = true;
                this.contractSelector.contractGroupName = this.currentKey;
                localStorage.setItem('exchange:selectedContract', JSON.stringify(this.contractSelector));
                this.apiService.getContractSelector(this.contractSelector);
                this.apiService.getContractDetails(this.contractsList);
                this.webSocketService.contractChanged(element);
              }
            });
          }
        }
      });
      if (!isFound) {
        this.setContract();
      }
    } else {
      this.setContract();
    }
}

  setContract() {
    if (JSON.parse(localStorage.getItem('exchange:selectedContract')) != null) {
      this.contractSelector = JSON.parse(localStorage.getItem('exchange:selectedContract'));
      this.checkIfValidContract();
    } else {
      // console.log('localhost is null');
      this.defaultContract();
    }
  }

  checkIfValidContract() {
    if (this.contractsList != null && this.contractsList !== undefined && this.contractsList.length !== 0) {
      let isFound = false;
      Object.entries(this.contractsList).forEach(([key, value]) => {
        if (this.contractSelector.contractGroupName == key) {
          // console.log('if key matched');
          this.tempContractValue = value;
          this.tempContractValue.forEach((element: any) => {
            if (element.name == this.contractSelector.contractName) {
              isFound = true;
              // console.log('if value matched');
              this.dataSendToHeader();
              this.webSocketService.getContractCall(true, element.settledIn);
            }
          });
          if (!isFound) {
            // console.log('if value not matched');
            this.dataDefaultContract();
          }
        } else {
          // console.log('if key not matched', this.contractSelector);
          this.dataDefaultContract();
        }
      });
    }
  }

  defaultContract() {
    if (this.Settings.default_pair != null && this.Settings.default_pair !== undefined) {
      const defaultPairGroup = this.Settings.default_pair.split('#');
      this.contractSelector.contractGroupName = defaultPairGroup[0];
      this.contractSelector.contractName = defaultPairGroup[1];
      // console.log('default contract', this.contractSelector);
      this.checkIfValidContract();
    } else {
      this.dataDefaultContract();
    }
  }

  dataDefaultContract() {
    this.contractSelector.contractGroupName = Object.keys(this.contractsList)[0];
    const xyz = Object.values(this.contractsList)[0];
    this.contractSelector.contractName = xyz[0].name;
    this.dataSendToHeader();
  }

  dataSendToHeader() {
    localStorage.setItem('exchange:selectedContract', JSON.stringify(this.contractSelector));
    this.router.navigate(['/trade', this.contractSelector.contractName]);
    this.apiService.getContractSelector(this.contractSelector);
    this.apiService.getContractDetails(this.contractsList);
  }

  changeChartType(type: any) {
    this.chartType = type;
  }

  originalOrder = (
    a: KeyValue < number, string > ,
    b: KeyValue < number, string >
  ): number => {
    return 0;
  }

  selectContracts(data: any) {
    this.contractSelector.contractGroupName = data;
    this.extractKeyValue();
  }

  selectContractsValue(data: any) {
    this.contractSelector.contractName = data.name;
    localStorage.setItem('exchange:selectedContract', JSON.stringify(this.contractSelector));
    this.router.navigate(['/trade', this.contractSelector.contractName]);
    this.apiService.getContractSelector(this.contractSelector);
    this.apiService.sendContractDetails(data);
    this.apiService.getSelectedContractDetails(data);
    this.webSocketService.contractChanged(data);
  }

  extractKeyValue() {
    Object.entries(this.contractsList).forEach(([key, value]) => {
      if (this.contractSelector != null && this.contractSelector !== undefined) {
        if (this.contractSelector.contractGroupName == key) {
          this.selectedKeyValue = value;
          this.getCurrContractDetails();
        }
      }
    });
  }

  getCurrContractDetails() {
    if (this.selectedKeyValue != null  && this.selectedKeyValue !== undefined && this.selectedKeyValue.length !== 0) {
      this.selectedKeyValue.forEach((element: any) => {
        if (element.name == this.contractSelector.contractName) {
          this.apiService.getSelectedContractDetails(element);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.allSettingsSub != null) {
      this.allSettingsSub.unsubscribe();
    }
  }

}
