import {
  Component,
  OnInit,
  NgZone,
  Input
} from '@angular/core';
import {
  WebsocketService
} from 'src/app/services/websocket.service';

@Component({
  selector: 'app-closed-positions',
  templateUrl: './closed-positions.component.html',
  styleUrls: ['./closed-positions.component.scss']
})
export class ClosedPositionsComponent implements OnInit {

  userPositions = [];
  orderbyKey: any;

  sortOrder: string = '';
  orderByType: any;
  constructor(public webSocketService: WebsocketService, public zone: NgZone) {}

  ngOnInit() {
    this.webSocketService.positions.subscribe((data: any) => {
      this.zone.run(() => { // <== added
        if (data != null && data !== undefined && data.length !== 0) {
         // console.log('positions', data);
          data.closed.forEach((element: any) => {
            if (this.userPositions.length === 0) {
              this.userPositions.unshift(element);
            } else {
              let isfound = false;
              this.userPositions.forEach(element2 => {
                if (element.pid == element2.pid) {
                  isfound = true;
                  element2.pid = element.pid;
                  element2.sym = element.sym;
                  element2.size = element.size;
                  element2.value = element.value;
                  element2.collateral_currency = element.collateral_currency;
                  element2.entry_price = element.entry_price;
                  element2.mark_price = element.mark_price;
                  element2.liq_price = element.liq_price;
                  element2.margin = element.margin;
                  element2.leverage_x = element.leverage_x;
                  element2.uPNL = element.uPNL;
                  element2.uPNL_ROE = element.uPNL_ROE;
                  element2.rPNL = element.rPNL;
                }
              });

              if (!isfound) {
                this.userPositions.unshift(element);
              }
            }
          });
        }
      });
    });
  }
  manualSorting(data: any) {
    this.orderbyKey = data;

    if (this.sortOrder == 'desc') {
      this.sortOrder = 'asc';
    } else {
      this.sortOrder = 'desc';
    }
  }
}
