import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(public apiService: ApiService) { }
  PrivacyPolicy: any;
  ngOnInit() {
    this.getpolicy();
  }

  async getpolicy(){
    let result = await this.apiService.getprivacypolicy().catch(err => {
      this.apiService.ShowToastrAlert('', err.data, 'error');
    });
    if(result == undefined || result== null) {
      return
      }
      if (result.data !== undefined && result.data != null) {
        this.PrivacyPolicy = result.data;
      }
  }

}
