       <div class="Go-card">
         <div class="Go-card-header">
           <div class="gNtjxq cYBjDx">{{indicesMenuData?.ticker}}</div>
         </div>
         <div class="Go-card-body">
           <div class="mb-3">
             {{indicesMenuData?.description}}
           </div>
           <div class="mb-3" *ngIf="contractsResponse?.length != 0">
             <h4 class="pb-2 mb-1" style="border-bottom: 1px solid gray;">{{'Contract.IndiceDetail.Header1' | translate}}</h4>
             <ul class="CONINDX pl-4">
              <li *ngFor="let item of contractsResponse" (click)="indicesUsingIndex(item)">
                <div>{{item}}</div>
              </li>
             </ul>
           </div>
           <div class="CONINDX mb-3" *ngIf="indicesResponse?.length != 0">
             <h4 class="pb-2 mb-1" style="border-bottom: 1px solid gray;">{{'Contract.IndiceDetail.Header2' | translate}}</h4>
             <ul class="pl-4">
              <li *ngFor="let item of indicesResponse" (click)="instrumentsUsingIndex(item)">
                <div>{{item}}</div>
              </li>
             </ul>
           </div>


           <div class="mb-3">
             <app-tradingview-chart></app-tradingview-chart>
           </div>
           <div>
             <div class="mb-3" *ngIf="indicesItemDetails?.length != 0">
               <h4 class="mb-3">{{'Contract.IndiceDetail.Header3' | translate}}</h4>

               <table class="table table-striped table-sm mb-0 without-border">
                 <thead>
                   <tr>
                     <th style="width: 33.333%">{{'Contract.IndiceDetail.TH1' | translate}}</th>
                     <th style="width: 33.333%">{{'Contract.IndiceDetail.TH2' | translate}}</th>
                     <th style="width: 33.333%" class="text-left">{{'Contract.IndiceDetail.TH3' | translate}}</th>
                   </tr>
                 </thead>
               </table>
               <div style="height: 364px; overflow: auto;">
                 <table class="table table-striped table-sm without-border">
                   <tbody>
                     <tr *ngFor="let item of indicesItemDetails | keyvalue: originalOrder">
                       <td style="width: 33.333%">{{item.key | date: 'MMM d, y, h:mm:ss a'}}</td>
                       <td style="width: 33.333%">{{contractSelector.currencyName}}</td>
                       <td style="width: 33.333%" class="text-left">{{item.value}}</td>
                     </tr>

                   </tbody>
                 </table>
               </div>

             </div>
             <div class="mb-3" *ngIf="indicesItemDetailedBreakdown?.length != 0">
               <h4 class="mb-3">{{'Contract.IndiceDetail.Header4' | translate}}</h4>
               <table class="table table-striped table-sm mb-0 without-border">
                 <thead>
                   <tr>
                     <th style="width: 35%">{{'Contract.IndiceDetail.TH1' | translate}}</th>
                     <th style="width: 25%" class="text-left">{{'Contract.IndiceDetail.TH4' | translate}}</th>
                     <th style="width: 15%" class="text-left">{{'Contract.IndiceDetail.TH5' | translate}}</th>
                     <th style="width: 25%" class="text-left">{{'Contract.IndiceDetail.TH6' | translate}}</th>
                   </tr>
                 </thead>
               </table>

               <div style="height: 364px; overflow: auto;">
                 <table class="table table-striped table-sm without-border">
                   <tbody>
                    <!-- <tr  *ngIf ="indicesItemDetailedBreakdown == undefined || indicesItemDetailedBreakdown == null">
                      <td colspan="4" style="text-align: center;">
                        <svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
                          <polygon fill="transparent" stroke="#51cbad" stroke-width="1" points="16,0 32,32 0,32"></polygon>
                        </svg>
                      </td>
                     </tr> -->
                     <tr *ngFor="let item of indicesItemDetailedBreakdown">
                       <td style="width: 35%">{{item.ts | date: 'MMM d, y, h:mm:ss a'}}</td>
                       <td style="width: 25%" class="text-left">{{item.exchange}}</td>
                       <td style="width: 15%" class="text-left">{{item.weightage}}</td>
                       <td style="width: 25%" class="text-left">{{item.price}}</td>
                     </tr>
                   </tbody>
                 </table>
               </div>
             </div>
           </div>
         </div>
       </div>
       <div id="loaderContainer" *ngIf="!indicesMenuData && indicesItemDetails == undefined || indicesItemDetails == null && indicesItemDetailedBreakdown == undefined || indicesItemDetailedBreakdown == null" >
        <svg id="triangle" width="100px" height="100px" viewBox="-3 -4 39 39">
          <polygon fill="transparent" stroke="#51cbad" stroke-width="1" points="16,0 32,32 0,32"></polygon>
        </svg>
      </div>
